import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MessageContext from "../../../contexts/MessageContext";
import BuyingProcessService from "../../../services/BuyingProcessService";
import HeaderPublic from "../../components/HeaderPublic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import  { WalkingPersonIcon, OldPersonIcon, FamilyIcon } from "../../../shared/icons/SvgIcons";
import ModalFamily from "../../components/ModalFamily";

const BuyStep3 = ({ step = 3 }) => {
  const buyingData = BuyingProcessService.getInstance().buyingData;
  const companyData = BuyingProcessService.getInstance().businessInfo; 
  const [showModal, setShowModal] = useState(false);
  const [passengerState, setPassengerState] = useState(1);
  const [passengers, setPassengers] = useState(
    buyingData.passengers ? buyingData.passengers : 0
  );
  const [ageRange, setAgeRange] = useState(
    buyingData.ageRange ? buyingData.ageRange : []
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);
  const [prevStep, setPrevStep] = useState(`/buy/step-${step - 1}`);
  const [nextStep, setNextStep] = useState(`/buy/step-${step + 1}`);
  const init = async () => {
    if (BuyingProcessService.getInstance().getInvalidStepOn2() > 0) {
      return navigate(prevStep);
    }
  };
  /* const resetPassengers = () => {
    setPassengers(0);
    setAgeRange(0);
  }; */

  const sumPassenger = (age) => {
    if (passengers < 10) {
      setAgeRange([...ageRange, age]);
      setPassengers(passengers + 1);
      return true;
    }
    return false;
  }; 
  const removePassenger = (age) => {
    if (passengers > 0) {
      if (age === 40 || age === 80) {
        const index = ageRange.findIndex((item) => item === age);
        if (index !== -1) {
          const newAgeRange = [...ageRange];
          newAgeRange.splice(index, 1);
          setAgeRange(newAgeRange);
        }
      }
      setPassengers(passengers - 1);
      return true;
    }
    return false;
  };

  const onClose = (accept, data) => { 
    if (accept) {
      buyingData.passengers = data.passengers;
      buyingData.familyPlan = data.familyPlan;
      buyingData.ageRange = data.ageRange;
      buyingData.birthDate = data.birthDate;
      setPassengers(data.passengers);
      setAgeRange(data.ageRange);
    }else{
      setPassengers(0);
      setAgeRange([]);
      buyingData.passengers = 0;
      buyingData.familyPlan = 0;
      buyingData.ageRange = [];
      buyingData.birthDate = [];      
    }
    setShowModal(false);
  };

  const onOpen = () => {
    /* if(passengerState > 0){      
      buyingData.passengers = 0;
      buyingData.ageRange = [];
      setAgeRange([]);
      setPassengerState(0);
    } */
    setShowModal(true);
  };

  const countPassengersByAge = (age) => {    
    return ageRange.filter((item) => item === age).length;
  };

  const onNext = () => {
    if (passengers === 0 || ageRange === 0) {
      return messageContext.setMessage({
        text: "BUYING_PROCESS.ERROR.REQUIRED.PASSENGERS",
        type: "error",
      });
    }
    let sanitizedAgeRange = ageRange.filter((item) => item === 40 || item === 80);
    const buyingProcessService = BuyingProcessService.getInstance();
    if(passengerState === 1){ 
      buyingProcessService.buyingData.ageRange = sanitizedAgeRange;    
      buyingProcessService.buyingData.passengers = sanitizedAgeRange.length;
      buyingProcessService.buyingData.familyPlan = 0;
      buyingProcessService.buyingData.birthDate = [];
    }
    else{
      buyingProcessService.buyingData.ageRange = ageRange;
      buyingProcessService.buyingData.passengers = passengers;
      buyingProcessService.buyingData.familyPlan = 1;
      buyingProcessService.buyingData.birthDate = buyingData.birthDate;
    }
    return navigate(nextStep);
  };

  useEffect(() => {
    if(companyData && companyData._id && companyData?.role !== "2"){
      setPrevStep(`/${companyData.subdomain}/buy/step-${step - 1}`);
      setNextStep(`/${companyData.subdomain}/buy/step-${step + 1}`);
    }
    init();
  }, []);

  return (
    <>
      <HeaderPublic businessInfo={companyData?.role !== "2" && companyData.subdomain !== 'myglobal' ? companyData : null} />
      <div
        className="container"
        style={{
          minHeight: "83vh",
          textAlign: "center",
          display: "grid",
          gridTemplateColumns: "1fr",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ lineHeight:"1", marginTop:"4rem", marginBottom: "20px" }}>{t("HOW_MANY")}</h1>

        {/* <h4
          style={{
            color: "var(--secondary)",
            marginBottom: "0",
            marginTop: "20px",
          }}
        >
          Importante: Los mayores de 74 a&ntilde;os deben cotizar por separado.
        </h4> */}
        <div
          style={{
            margin: "0 auto",
            width: "100%",
            maxWidth: "620px",
          }}
        >
          <div className="grid mobile-column-3">
            <article className="card mobile-mxw-250">
              {/* <FontAwesomeIcon
                icon={faPersonWalkingLuggage}
                style={{ fontSize: "3rem" }}
                className="icon-primary"
              /> */}
              <WalkingPersonIcon cls={"icon-primary"} />              
              <br></br>
              <p>
                <strong>Pasajeros</strong>
                <br></br>
                <small>hasta 74 a&ntilde;os</small>
              </p>
              <div className="flex-between">
                <button
                  type="button"
                  onClick={() => {
                    /* if (ageRange && ageRange !== 40) {
                      setAgeRange(40);
                      return resetPassengers();
                    } else {
                      if (!ageRange) {
                        setAgeRange(40);
                      }
                      restPassengers();
                    } */
                    //deleteAgeRange(40);
                    return removePassenger(40);
                  }}
                  style={{
                    width: "20%",
                    borderRadius: "5px",
                    padding: "5px",
                  }}
                  /* disabled={ageRange === 80} */
                >
                  <FontAwesomeIcon icon={faMinus} />
                </button>
                <input
                  type="text"
                  style={{
                    border: "none",
                    textAlign: "center",
                    width: "40%",
                    fontSize: "1.5rem",
                  }}
                  value={countPassengersByAge(40)}
                  readOnly={true}
                />
                <button
                  type="button"
                  onClick={() => {
                    /* if (ageRange && ageRange !== 40) {
                      setAgeRange(40);
                      return resetPassengers();
                    } else {
                      if (!ageRange) {
                        setAgeRange(40);
                      }
                      return sumPassengers();
                    } */
                    //sumAgeRange(40);
                    buyingData.familyPlan = 0;
                    if(passengerState === 0){
                      /* buyingData.passengers = 0;
                      buyingData.ageRange = [];
                      buyingData.birthDate = [];
                      setAgeRange([]);
                      setPassengers(0); */
                      setPassengerState(1);
                    }
                    return sumPassenger(40);
                  }}
                  style={{
                    width: "20%",
                    borderRadius: "5px",
                    padding: "5px",
                  }}
                  /* disabled={ageRange === 80} */
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            </article>
            <article className="card mobile-mxw-250">
              {/* <FontAwesomeIcon
                icon={faPerson}
                style={{ fontSize: "3rem" }}
                className="icon-primary"
              /> */}
              <OldPersonIcon cls={"icon-primary"} />              
              <br></br>
              <p>
                <strong>Senior</strong>
                <br></br>
                <small>75 a 84 a&ntilde;os</small>
              </p>
              <div className="flex-between">
                <button
                  type="button"
                  onClick={() => {
                    /* if (ageRange !== 80) {
                      setAgeRange(80);
                      return resetPassengers();
                    } else {
                      if (!ageRange) {
                        setAgeRange(80);
                      }
                      return restPassengers();
                    } */

                    return removePassenger(80);
                  }}
                  style={{
                    width: "20%",
                    borderRadius: "5px",
                    padding: "5px",
                  }}
                  /* disabled={ageRange === 40} */
                >
                  <FontAwesomeIcon icon={faMinus} />
                </button>
                <input
                  type="text"
                  style={{
                    border: "none",
                    textAlign: "center",
                    fontSize: "1.5rem",
                    width: "40%",
                  }}
                  value={countPassengersByAge(80)}
                  readOnly={true}
                />
                <button
                  type="button"
                  onClick={() => {
                    /* if (ageRange && ageRange !== 80) {
                      setAgeRange(80);
                      return resetPassengers();
                    } else {
                      if (!ageRange) {
                        setAgeRange(80);
                      }
                      return sumPassengers();
                    } */
                    buyingData.familyPlan = 0;
                    if(passengerState === 0){
                      /* buyingData.passengers = 0;
                      buyingData.ageRange = [];
                      buyingData.birthDate = [];
                      setAgeRange([]);
                      setPassengers(0); */
                      setPassengerState(1);
                    }
                    return sumPassenger(80);
                  }}
                  style={{
                    maxWidth: "20%",
                    borderRadius: "5px",
                    padding: "5px",
                  }}
                  /* disabled={ageRange === 40} */
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            </article>
            <article className="card mobile-mxw-250">              
              <FamilyIcon cls={"icon-primary"} />              
              <br></br>
              <p>
                <strong>Familiar</strong>
                <br></br>                
                <small>2 adultos y menores</small>
              </p>
              <div className="flex-center">
                <button
                  type="button"
                  onClick={() => {                    
                    //deleteAgeRange(40);
                    setPassengerState(0);
                    return onOpen();
                  }}
                  style={{
                    width: "auto",
                    borderRadius: "5px",
                    padding: "5px 1rem",
                    marginTop: "6px"
                  }}
                >
                  Seleccionar
                </button>                
              </div>
            </article>
            {/* <article className="card mobile-mxw-250">
              <p>
                Si viaja un mayor de <b>85 años</b>, contáctanos para cotizar un
                paquete especial.
              </p>

              <button
                type="button"
                className="secondary outline mobile-full-width btnmob-w-100per"
                onClick={() => {
                  alert("Contacto?");
                }}
                style={{ marginLeft: "auto", marginRight: "auto", marginTop: '3rem' }}
              >
                {t("CONTACT")}
              </button>
            </article> */}
          </div>
          <div
            className="flex-between desktop-min-width mobile-column"
            style={{ width: "100%", marginTop: "20px" }}
          >
            <div className="flex-start mobile-full-width mobile-order-2">
              <button
                type="button"
                className="secondary outline mobile-full-width"
                onClick={() => {
                  navigate(prevStep);
                }}
              >
                {t("GO_BACK")}
              </button>
            </div>
            <div className="flex-end mobile-full-width mobile-order-1">
              <button
                type="submit"
                onClick={onNext}
                className="primary mobile-full-width"
              >
                {t("NEXT")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalFamily
        /* titulo={titulo} */
        prevPassengers={passengerState}
        data={buyingData}
        businessInfo={companyData?.role !== "2" && companyData.subdomain !== 'myglobal' ? companyData : null}
        handleClose={onClose}
        open={showModal}
      />
    </>
  );
};

export default BuyStep3;
