import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_S3_REGION
});

const s3 = new AWS.S3();

export const uploadImage = async (file) => {
  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET,
    Key: `${file.name}`,
    Body: file,
  };

  try {
    const data = await s3.upload(params).promise();
    console.log('File uploaded successfully:', data.Location);
    return data.Location;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

export const deleteImage = async (key) => {
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET,
      Key: key
    };
  
    try {
      const data = await s3.deleteObject(params).promise();
      console.log('File deleted successfully:', key);
      return data;
    } catch (error) {
      console.error('Error deleting file:', error);
      throw error;
    }
  };
