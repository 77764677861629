import Mundo from '../../assets/Mundo.png';

const AboutUsHero2 = () => {
    return (
        <div className="about-us-hero2">
            <div className="about-us-hero2__container container">
                <div className="about-us-hero2__content">
                    <img 
                    className="about-us-hero2__content__left"
                    src={Mundo}>

                    </img>
                    <div className="about-us-hero2__content__right">
                        <p>Tenemos alianzas <span>mundiales</span></p>
                        <p>Para darte el mejor servicio, 
                        hemos desarrollado <span>alianzas 
                        mundiales con las más 
                        prestigiosas redes de 
                        prestadores</span> de servicio en las 
                        áreas de salud, comunicaciones, 
                        legal, traslados, seguros y 
                        reaseguros.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsHero2;