import { useEffect } from "react";
import HeaderPublic from "../components/HeaderPublic";
import CancelPlanComponent from "../components/CancelPlan";
import FooterPublic from "../components/FooterPublic";

const CancelPlan = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="public">
            <HeaderPublic />
            <CancelPlanComponent />
            <FooterPublic />
        </div>
    )
}

export default CancelPlan;