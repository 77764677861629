import { useEffect } from 'react';
import FooterPublic from '../components/FooterPublic';
import HeaderPublic from '../components/HeaderPublic';
import RequestRefundComponent from '../components/RequestRefund';

const RequestRefund = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="public">
            <HeaderPublic />
            <RequestRefundComponent />
            <FooterPublic />
        </div>
    )
}

export default RequestRefund;