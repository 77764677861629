import { useEffect, useState, useRef, useContext } from "react";
import useApiHook from "../../../hooks/ApiHook";
import { salesFindList } from "../../../services/SalesService";
import { useTranslation } from "react-i18next";
import OrderTableHeader from "../../../shared/OrderTableHeader";
import ExportSheet from "../../../shared/ExportSheet";
import { formatDate } from "../../../services/DateService";
import PriceFormat from "../../../shared/PriceFormat";
import { findBySaleId as findBeneficiariesBySaleId } from "../../../services/BeneficiaryService";
import { paidSales } from "../../../services/SalesService";
import ConfirmationModal from "../../../shared/ConfirmationModal";
import SaleDetails from "./SaleDetails";
import MessageContext from "../../../contexts/MessageContext";
import { getCookie } from "../../../shared/CookiesManager";

const limit = 100;
let allItems = [];
let itemsToShow = [];
let orderDirection = "asc";
let orderBy = "code";
let singleItem = null;
let showDetailsModal = false;
let showConfirmationModal = false;
let saleVoucherCodes = [];
let selectAll = false;
const saleBusinessType = {
  ALL: 1,
  BROKER_AND_COMPANIES: 2,
  ONLY_MYGLOBAL: 3,
};
const MYGLOBAL_BUSINESS_NAME = "MyGlobal".toUpperCase();
const paidOptions = {
  PAID: 1,
  UNPAID: 2,
  ALL: 3,
};

const SalesSearch = ({ onSelect, navbar = null }) => {

  const formRef = useRef();
  const [stateVersion, setStateVersion] = useState(0);
  const [visiblePayButton, setVisiblePayButton] = useState(true);
  const { t } = useTranslation();
  const apiHook = useApiHook();
  const messageContext = useContext(MessageContext);

  const reRender = () => {
    setStateVersion((stateVersion) => stateVersion + 1);
  };

  const getDataForExcelExport = () => {
    const headers = [
      t("SALES.TABLE_HEADER.CODE"),
      t("SALES.TABLE_HEADER.VOUCHER_CODE"),
      t("SALES.TABLE_HEADER.PLAN"),
      t("SALES.TABLE_HEADER.BUSINESS"),
      t("SALES.TABLE_HEADER.AMOUNT"),
      t("SALES.TABLE_HEADER.PASSENGERS_NUMBER"),
      t("SALES.TABLE_HEADER.HOLDER"),
      t("SALES.TABLE_HEADER.DATE_OF_PURCHASE"),
    ];
    const data = itemsToShow.map((item) => {
      return [
        item.code,
        item.voucherId,
        item.planName,
        item.businessName,
        item.planPrice,
        item.passengers,
        item.holder,
        item.purchaseDate,
      ];
    });    
    return [headers, ...data];
  };

  const findList = async () => {
    const responseData = await apiHook({
      fnApiCall: salesFindList,
      params: null,
    });
    const salesData = responseData?.data?.data ?? null;
    if (!salesData) return;
    const sales = salesData.map((item, index) => {
      return {
        id: item._id,
        code: `#000${index + 1}`,
        voucherId: item.sale?.voucherId || "N/A",
        planName: item.sale.planName,
        businessName: item.businessName,
        planPrice: `${item.sale.planCurrency} ${PriceFormat({
          price: item.sale.planPrice,
        })}`,
        passengers: item.sale.passengers,
        holder: `${item.sale.passengersData[0].name} ${item.sale.passengersData[0].lastName}`,
        purchaseDate: formatDate(item.created),
        planPriceValue: PriceFormat({
          price: item.sale.planPrice,
          expect: "float",
        }),
        purchaseDateValue: parseInt(
          item.created.split("T")[0].replace(/-/g, "")
        ),
        passengersDocsNumbers: item.sale.passengersData.map((passenger) => {
          return passenger.docNumber;
        }),
        paid: item.paid ?? 0,
        isSelected: false,
        showChechbox:
          !item.paid,
        sale: item.sale,
      };
    });
    allItems = sales;
    filterItemsToShow();
  };

  useEffect(() => {
    findList();
  }, []);

  const onClickRow = async (item) => {
    const response = await apiHook({
      fnApiCall: findBeneficiariesBySaleId,
      params: {
        saleId: item.id,
      },
    });
    if (!response) return;
    saleVoucherCodes = response?.data?.data ?? [];
    singleItem = { ...item };
    showDetailsModal = true;
    reRender();
  };

  const setOrder = ({ newOrderBy, newOrderDirection }) => {
    orderBy = newOrderBy;
    orderDirection = newOrderDirection;
    filterItemsToShow();
  };

  const onSearch = () => {
    filterItemsToShow();
  };

  const filterItemsToShow = () => {    
    if (allItems == null || allItems.length === 0) {
      itemsToShow = [];
      reRender();
      return;
    }

    let tempItems = allItems;

    if (formRef.current) {
      const formData = new FormData(formRef.current);
      const viewBusinessType = parseInt(formData.get("businessType"));

      if (viewBusinessType === saleBusinessType.ONLY_MYGLOBAL) {
        tempItems = tempItems.filter(
          (item) => item.businessName.toUpperCase() === MYGLOBAL_BUSINESS_NAME
        );       
      } else if (viewBusinessType === saleBusinessType.BROKER_AND_COMPANIES) {
        tempItems = tempItems.filter(
          (item) => item.businessName.toUpperCase() !== MYGLOBAL_BUSINESS_NAME
        );
      }

      const paidOption = parseInt(formData.get("paidStatus"));
      if (paidOption === paidOptions.PAID) {
        tempItems = tempItems.filter((item) => item.paid);
        setVisiblePayButton(false);
      } else if (paidOption === paidOptions.UNPAID) {
        tempItems = tempItems.filter(
          (item) =>
            !item.paid
        );
        setVisiblePayButton(true);
      }
      else if (paidOption === paidOptions.ALL) {
        setVisiblePayButton(true);
      }

      if (formData.get("plan").trim().length > 0) {
        tempItems = tempItems.filter((item) =>
          item.planName
            .toUpperCase()
            .includes(formData.get("plan").trim().toUpperCase())
        );
      }
      if (formData.get("business").trim().length > 0) {
        tempItems = tempItems.filter((item) =>
          item.businessName
            .toUpperCase()
            .includes(formData.get("business").trim().toUpperCase())
        );
      }
      if (formData.get("startDate").length > 0) {
        tempItems = tempItems.filter((item) => {
          const startDateValue = parseInt(
            formData.get("startDate").replace(/-/g, "")
          );
          return item.purchaseDateValue >= startDateValue;
        });
      }

      if (formData.get("endDate").length > 0) {
        tempItems = tempItems.filter((item) => {
          const endDateValue = parseInt(
            formData.get("endDate").replace(/-/g, "")
          );
          return item.purchaseDateValue <= endDateValue;
        });
      }

      if (formData.get("docnumber").trim().length > 0) {
        if (tempItems.length > 0) {
          tempItems = tempItems.filter(
            (item) =>
              item.passengersDocsNumbers.filter((doc) =>
                doc
                  .trim()
                  .toUpperCase()
                  .includes(formData.get("docnumber").trim().toUpperCase())
              ).length > 0
          );
        }
      }
    }

    if (!tempItems || tempItems.length === 0) {
      itemsToShow = [];
      reRender();
      return;
    }
    if(orderBy !== 'purchaseDate' && orderBy !== 'code'){    
    itemsToShow = tempItems
      .sort((a, b) => {
        const sortDirection = orderDirection === "asc" ? 1 : -1;
        if (a[orderBy] === b[orderBy]) {
          return 0;
        } else if (a[orderBy] > b[orderBy]) {
          return 1 * sortDirection;
        } else {
          return -1 * sortDirection;
        }
      })
      .slice(0, limit);
    } else if(orderBy === 'purchaseDate'){
      itemsToShow = tempItems
      .sort((a, b) => {
        const sortDirection = orderDirection === "asc" ? 1 : -1;
        if (a.purchaseDateValue === b.purchaseDateValue) {
          return 0;
        } else if (a.purchaseDateValue > b.purchaseDateValue) {
          return 1 * sortDirection;
        } else {
          return -1 * sortDirection;
        }
      })
      .slice(0, limit);
    } else {
      itemsToShow = tempItems
      .sort((a, b) => {        
        const sortDirection = orderDirection === "asc" ? 1 : -1;
        const first = parseInt(a.code.replace('#000','')); 
        const second = parseInt(b.code.replace('#000',''));                       
        if (first === second) {
          return 0;
        } else if (first > second) {
          return 1 * sortDirection;
        } else {
          return -1 * sortDirection;
        }
      })
      .slice(0, limit);
    }    
    reRender();
  };

  const resetForm = () => {
    formRef?.current?.reset();
    filterItemsToShow();
  };

  const getSelectedSales = () => {
    return itemsToShow.filter((item) => item.isSelected);
  };

  const payByLot = async () => {
    showConfirmationModal = false;
    const selectedSales = getSelectedSales();
    if (selectedSales.length === 0) {
      messageContext.setMessage({
        text: t("SALES.SELECT_ONE"),
        type: "warn",
      });
      return;
    }
    const idsList = [];
    selectedSales.map((item) => {
      return idsList.push(item.id);      
    });
    const adminId = getCookie({ name: "_UI" });
    if (!adminId) {
      messageContext.setMessage({
        text: t("AUTHORIZATION_ERROR"),
        type: "error",
      });
      return;
    }
    const response = await apiHook({
      fnApiCall: paidSales,
      params: {
        data: idsList,
        adminId,
      },
    });
    if (!response) {
      return;
    }
    if (response.ok) {
      messageContext.setMessage({
        text: t("SALES.PAID_UPDATE_SUCCESS"),
        type: "success",
      });
    }

    findList();
  };

  const toggleSelectAll = () => {
    selectAll = !selectAll;
    itemsToShow.map((item) => {
      return item.isSelected = item.showChechbox ? selectAll : false;
    });
    reRender();
  };

  return (
    <>
      {stateVersion > 0 && (
        <>
          <nav className="flex-between menu-navbar">
            <h1 style={{ margin: 0 }}>{t("SALES.LIST")}</h1>
            <ExportSheet
              getData={getDataForExcelExport}
              fileName={t("SALES.LIST")}
              style={{ marginTop: "10px" }}
            />
          </nav>
          <details className="filters">
            <summary>{t("FILTERS")}</summary>
            <form
              ref={formRef}
              name="search"
              className="menu-navbar filters"
              onSubmit={(ev) => {
                ev.preventDefault();
              }}
            >
              <div className="mobile-full-width">
                <label htmlFor="plan">{t("SALES.TABLE_HEADER.PLAN")}</label>
                <input
                  type="search"
                  id="plan"
                  style={{ margin: 0 }}
                  name="plan"
                  onChange={onSearch}
                  placeholder={t("SALES.TABLE_HEADER.PLAN")}
                />
              </div>
              <div className="mobile-full-width">
                <label htmlFor="business">
                  {t("SALES.TABLE_HEADER.BUSINESS")}
                </label>
                <input
                  type="search"
                  id="business"
                  style={{ margin: 0 }}
                  name="business"
                  onChange={onSearch}
                  placeholder={t("SALES.TABLE_HEADER.BUSINESS")}
                />
              </div>
              <label className="mobile-full-width">
                {t("START_DATE")}
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  onChange={onSearch}
                />
              </label>
              <label className="mobile-full-width">
                {t("END_DATE")}
                <input
                  type="date"
                  id="endDate"
                  name="endDate"
                  onChange={onSearch}
                />
              </label>
              <div className="mobile-full-width">
                <label htmlFor="plan">
                  {t("SALES.TABLE_HEADER.DOCNUMBER")}
                </label>
                <input
                  type="search"
                  id="docnumber"
                  style={{ margin: 0 }}
                  name="docnumber"
                  onChange={onSearch}
                  placeholder={t("SALES.TABLE_HEADER.DOCNUMBER")}
                />
              </div>
              <div className="mobile-full-width">
                <label htmlFor="view">{t("BUSINESS.BUSINESS_TYPE")}</label>
                <select
                  id="businessType"
                  name="businessType"
                  onChange={onSearch}
                >
                  <option value={saleBusinessType.ALL}>
                    {t("VIEW_ALL_SELLERS")}
                  </option>
                  <option value={saleBusinessType.BROKER_AND_COMPANIES}>
                    {t("VIEW_BROKER_COMPANIES")}
                  </option>
                  <option value={saleBusinessType.ONLY_MYGLOBAL}>
                    {t("VIEW_MYGLOBAL")}
                  </option>
                </select>
              </div>
              <div className="mobile-full-width">
                <label htmlFor="view">{t("VIEW_PAID_STATUS")}</label>
                <select
                  id="paidStatus"
                  name="paidStatus"
                  defaultValue={paidOptions.ALL}
                  onChange={(e)=>{
                    onSearch();                    
                  }}
                >
                  <option
                    value={paidOptions.ALL}                    
                  >
                    {t("ALL")}
                  </option>
                  <option
                    value={paidOptions.PAID}
                  >
                    {t("VIEW_PAID")}
                  </option>
                  <option
                    value={paidOptions.UNPAID}                    
                  >
                    {t("VIEW_UNPAID")}
                  </option>
                </select>
              </div>
              <button
                type="button"
                className="secondary"
                style={{ alignSelf: "flex-end" }}
                onClick={() => {
                  resetForm();
                  setVisiblePayButton(true);
                }}
              >
                {t("CLEAN")}
              </button>
            </form>
          </details>
          {navbar && navbar}
          {visiblePayButton && (
            <button
              className="primary"
              style={{
                marginTop: "var(--spacing)",
              }}
              type="button"
              onClick={() => {
                showConfirmationModal = true;
                reRender();
              }}
            >
              {t("PAY_BY_LOT.PAY")}
            </button>
          )}

          {itemsToShow.length > 0 ? (
            <>
              <figure>
                <table role="grid">
                  <thead>
                    <tr>
                      <th>
                        {itemsToShow.filter((item) => item.showChechbox)
                          .length > 0 && (
                          <label
                            htmlFor="selectAll"
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <input
                              type="checkbox"
                              id="selectAll"
                              name="selectAll"
                              onChange={toggleSelectAll}
                              value={selectAll}
                            />
                            {t("SALES.TABLE_HEADER.PAY_LOT")}
                          </label>
                        )}
                      </th>
                      <OrderTableHeader
                        titleKey={"SALES.TABLE_HEADER.CODE"}
                        orderByKey={"code"}
                        onSetOrder={setOrder}
                        orderBy={orderBy}
                        orderDirection={orderDirection}
                      />
                      <OrderTableHeader
                        titleKey={"SALES.TABLE_HEADER.VOUCHER_CODE"}
                        orderByKey={"voucherId"}
                        onSetOrder={setOrder}
                        orderBy={orderBy}
                        orderDirection={orderDirection}
                      />
                      <OrderTableHeader
                        titleKey={"SALES.TABLE_HEADER.PLAN"}
                        orderByKey={"planName"}
                        onSetOrder={setOrder}
                        orderBy={orderBy}
                        orderDirection={orderDirection}
                      />
                      <OrderTableHeader
                        titleKey={"SALES.TABLE_HEADER.AMOUNT"}
                        orderByKey={"planPriceValue"}
                        onSetOrder={setOrder}
                        orderBy={orderBy}
                        orderDirection={orderDirection}
                      />
                      <OrderTableHeader
                        titleKey={"SALES.TABLE_HEADER.BUSINESS"}
                        orderByKey={"businessName"}
                        onSetOrder={setOrder}
                        orderBy={orderBy}
                        orderDirection={orderDirection}
                      />
                      <OrderTableHeader
                        titleKey={"SALES.TABLE_HEADER.PASSENGERS_NUMBER"}
                        orderByKey={"passengers"}
                        onSetOrder={setOrder}
                        orderBy={orderBy}
                        orderDirection={orderDirection}
                      />
                      <OrderTableHeader
                        titleKey={"SALES.TABLE_HEADER.HOLDER"}
                        orderByKey={"holder"}
                        onSetOrder={setOrder}
                        orderBy={orderBy}
                        orderDirection={orderDirection}
                      />
                      <OrderTableHeader
                        titleKey={"SALES.TABLE_HEADER.DATE_OF_PURCHASE"}
                        orderByKey={"purchaseDate"}
                        onSetOrder={setOrder}
                        orderBy={orderBy}
                        orderDirection={orderDirection}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {itemsToShow.map((item, index) => {
                      return (
                        <tr
                          key={`sales_${index}`}
                          style={{ cursor: "pointer" }}
                        >
                          <td>
                            {item.showChechbox && (
                              <input
                                name={`check_${item.code}`}
                                type="checkbox"
                                className="check-pay"
                                checked={item.isSelected}
                                onChange={(e) => {
                                  item.isSelected = e.target.checked;
                                  reRender();
                                }}
                              />
                            )}
                          </td>
                          <td onClick={() => onClickRow(item)}>{item.code}</td>
                          <td onClick={() => onClickRow(item)} style={{ width: '150px' }}>{item.voucherId}</td>
                          <td onClick={() => onClickRow(item)}>
                            {item.planName}
                          </td>
                          <td onClick={() => onClickRow(item)}>
                            {item.planPrice}
                          </td>
                          <td onClick={() => onClickRow(item)}>
                            {item.businessName}
                          </td>
                          <td onClick={() => onClickRow(item)} style={{ width: '45px' }}>
                            {item.passengers}
                          </td>
                          <td onClick={() => onClickRow(item)}>
                            {item.holder}
                          </td>
                          <td onClick={() => onClickRow(item)}>
                            {item.purchaseDate}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </figure>
              <ConfirmationModal
                isOpen={showConfirmationModal}
                onConfirm={payByLot}
                onCancel={() => {
                  showConfirmationModal = false;
                  reRender();
                }}
                titleKey="PAY_BY_LOT.CONFIRMATION.TITLE"
                messageKey="PAY_BY_LOT.CONFIRMATION.MESSAGE"
              />
            </>
          ) : (
            <p>{t("SALES.NO_RESULTS")}</p>
          )}

          {singleItem && (
            <SaleDetails
              sale={singleItem}
              saleVoucherCodes={saleVoucherCodes}
              showDetailsModal={showDetailsModal}
              onClose={() => {
                showDetailsModal = false;
                singleItem = null;
                reRender();
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default SalesSearch;
