import { useState, useContext, useEffect } from "react";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import useApiHook from "../../../hooks/ApiHook";
import { businessGetById } from "../../../services/BusinessService";
import ResidenceCountrySelect from "../../../shared/ResidenceCountrySelect";
import { getCategories } from "../../../services/CategoryService";
import { getCookie } from "../../../shared/CookiesManager";
import {
  compareDate,
  convertToYYYYMMDD,
  diffTimeDays,
} from "../../../shared/validations/Validation";
import MessageContext from "../../../contexts/MessageContext";
import LoadingContext from "../../../contexts/LoadingContext";
import FormatToastMessage from "../../../shared/FormatMessage";
import BuyingProcessService from "../../../services/BuyingProcessService";
import HeaderPublic from "../../components/HeaderPublic";
/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUmbrellaBeach, faPassport } from "@fortawesome/free-solid-svg-icons"; */
import { businessGetBySubdomain } from "../../../services/BusinessService";
import { MultiviajesIcon, PlanesViajeIcon } from "../../../shared/icons/SvgIcons";

let categories = [];
const catIdNinetyDaysPlan = 24;

const BuyStep1 = ({ step = 1 }) => {
  let { subdomain } = useParams();
  const navigate = useNavigate();
  const apiCall = useApiHook();
  const buyingData = BuyingProcessService.getInstance().buyingData;
  let companyData = BuyingProcessService.getInstance().businessInfo;
  const userId = getCookie({ name: "_UI" });
  const role = getCookie({ name: "_RL" });
  const typeBuyer = getCookie({ name: "_BT" });
  const [stateVersion, setStateVersion] = useState(0);
  const [countryId, setCountryId] = useState(buyingData.residenceCountry || 0);
  const [categoryId, setCategoryId] = useState(buyingData.categoryId);
  const [categoryName, setCategoryName] = useState(buyingData.categoryName);
  const [startDate, setStartDate] = useState(buyingData.startDate);
  const [endDate, setEndDate] = useState(buyingData.endDate);
  const [loading, setLoading] = useState(true);
  const [prevStep, setPrevStep] = useState("/");
  const [nextStep, setNextStep] = useState(`/buy/step-${step + 1}`);
  const messageContext = useContext(MessageContext);
  const loadingContext = useContext(LoadingContext); 

  useEffect(() => {
    if (typeBuyer === "BROKER" && role === "2") {
      toBuy();
    } else {
      refreshBusinessInfo();
    }
  }, []);

  const reRender = () => {
    setStateVersion(stateVersion + 1);
    if (categoryId > 0 && categoryId !== undefined) {
      document
        .getElementById(`wrapCategory-${categoryId}`)
        .classList.add("border-selected");
    }
    setLoading(false);
  };

  const refreshBusinessInfo = async () => {
    const buyingProcessService = BuyingProcessService.getInstance();
    if (subdomain === undefined) {
      subdomain = "myglobal";
    }
    const response = await apiCall({
      fnApiCall: businessGetBySubdomain,
      params: { subdomain },
    });

    if (response.ok) {
      buyingProcessService.businessInfo = response.data;
      companyData = buyingProcessService.businessInfo;
    }
  };

  const toBuy = async () => {
    const response = await apiCall({
      fnApiCall: businessGetById,
      params: { id: userId },
    });
    if (response.ok) {
      const buyingProcessService = BuyingProcessService.getInstance();
      buyingProcessService.businessInfo = response.data;
      buyingProcessService.businessInfo.role = role;
      return (companyData = buyingProcessService.businessInfo);
    }
    return false;
  };

  const onChangeCategory = (id, name) => {
    const selection = document.querySelector(`#wrapCategory-${id}`);
    setCategoryId(parseInt(id));
    setCategoryName(name);
    document.querySelectorAll(".card").forEach((card) => {
      card.classList.remove("border-selected");
    });
    selection.classList.add("border-selected");
  };

  const init = async () => {
    loadingContext.change(loading);
    const response = await apiCall({
      fnApiCall: getCategories,
      params: {},
    });
    if (response.ok) {
      categories = response?.data?.data?.reverse() || [];
      reRender();
    }
  };

  const onNext = () => {
    let errorMessages = [];

    if (countryId <= 0) {
      errorMessages.push(t("BUYING_PROCESS.ERROR.REQUIRED.RESIDENCE_COUNTRY"));
    }
    if (!startDate) {
      errorMessages.push(t("BUYING_PROCESS.ERROR.REQUIRED.START_DATE"));
    }
    if (!endDate) {
      errorMessages.push(t("BUYING_PROCESS.ERROR.REQUIRED.END_DATE"));
    }

    if (!categoryId || !categoryName) {
      errorMessages.push(t("BUYING_PROCESS.ERROR.REQUIRED.CATEGORY"));
    }

    if (errorMessages.length > 0) {
      return showErrors(errorMessages);
    }

    errorMessages = [];

    if (
      !compareDate({
        after: endDate,
        before: startDate,
        operator: "<",
      })
    ) {
      errorMessages.push(t("BUYING_PROCESS.ERROR.BEFORE_AFTER_DATE"));
    }
    if (
      !compareDate({
        after: startDate,
        before: convertToYYYYMMDD(new Date()),
        operator: "<=",
      })
    ) {
      errorMessages.push(t("BUYING_PROCESS.ERROR.NEWER_THAN_NOW"));
    }
    if (errorMessages.length > 0) {
      return showErrors(errorMessages);
    }

    const diffDays = diffTimeDays({ startDate, endDate }) + 1;

    if (categoryId === catIdNinetyDaysPlan && diffDays > 120) {
      errorMessages.push(t("BUYING_PROCESS.ERROR.MAX_FOR_NINETY_DAYS_PLAN"));
      if (errorMessages.length > 0) {
        return showErrors(errorMessages);
      }
    }

    if (diffDays > 120) {
      errorMessages.push(t("BUYING_PROCESS.ERROR.MAX_DAYS"));
    }
    if (diffDays < 1) {
      errorMessages.push(t("BUYING_PROCESS.ERROR.MIN_DAYS"));
    }

    if (errorMessages.length > 0) {
      return showErrors(errorMessages);
    }

    /* const microStartDate = new Date(startDate);
    const microEndDate = new Date(endDate);
    const diffTime = Math.abs(microEndDate - microStartDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); */

    const buyingProcessService = BuyingProcessService.getInstance();
    buyingProcessService.buyingData = {
      residenceCountry: countryId,
      categoryId: parseInt(categoryId),
      categoryName: categoryName,
      startDate: startDate,
      endDate: endDate,
      days: diffDays,
      markup: companyData?.markup,
    };

    return navigate(nextStep);
  };

  const showErrors = (errorMessages) => {
    messageContext.setMessage({
      type: "error",
      text: FormatToastMessage(t("BUYING_PROCESS.ERROR.TITLE"), errorMessages),
    });
  };

  useEffect(() => {
    if (
      companyData &&
      companyData._id &&
      companyData?.role !== "2" &&
      companyData?.subdomain !== "myglobal"
    ) {
      setPrevStep(`/${companyData.subdomain}`);
      setNextStep(`/${companyData.subdomain}/buy/step-${step + 1}`);
    }
    init();
  }, []);

  return (
    <>
      <HeaderPublic
        businessInfo={
          companyData?.role !== "2" && companyData.subdomain !== "myglobal"
            ? companyData
            : null
        }
      />
      <div
        className="container"
        style={{
          minHeight: "85vh",
          textAlign: "center",
          display: "grid",
          gridTemplateColumns: "1fr",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ lineHeight:"1", marginTop:"4rem" }}>{t("TELL_US")}</h1>
        <div
          style={{
            margin: "auto",
            display: "flex",
            maxWidth: "380px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <ResidenceCountrySelect
            currentCountryId={countryId}
            onSelectCountry={setCountryId}
            selectedCountryId={countryId}
            isRequired={true}
            labelKey="BUYING_PROCESS.LABEL.RESIDENCE_COUNTRY"
          />
          {categories && categories.length > 0 && (
            <div className="container-fluid" style={{ padding: "0" }}>
              <h5
                className="emphasys"
                style={{ marginTop: "10px", marginBottom: "0" }}
              >
                Tipo de viaje
              </h5>
              <strong>Debes elegir una opción</strong>
              <div className="grid mobile-column-2" style={{ width: "100%" }}>
                {categories.map((category, index) => {
                  return (
                    <article
                      onClick={() =>
                        onChangeCategory(
                          category.id_categoria,
                          category.categoria
                        )
                      }
                      id={`wrapCategory-${category.id_categoria}`}
                      className="card border-hover mobile-mxw-250"
                      key={`category-${index}`}
                      style={{
                        marginTop: "10px",
                        paddingLeft: ".5rem",
                        paddingRight: ".5rem",
                        cursor: "pointer",
                        paddingBottom: "3rem",
                      }}
                    >
                      {category.id_categoria === "23" && (
                        <>
                          {/* <FontAwesomeIcon
                          icon={faUmbrellaBeach}
                          style={{ fontSize: "3rem" }}
                          className="icon-primary"
                        /> */}
                          <PlanesViajeIcon cls={"icon-primary"} />
                        </>
                      )}
                      {category.id_categoria === "24" && (
                        <>
                          {/* <FontAwesomeIcon
                          icon={faPassport}
                          style={{ fontSize: "3rem" }}
                          className="icon-primary"
                        /> */}
                          <MultiviajesIcon cls={"icon-primary"} />
                        </>
                      )}
                      <label
                        htmlFor={`category-${index}`}
                        key={`category-${index}`}
                        className="buy-step-padding-bottom"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          width: "100%",
                          cursor: "pointer",
                          height: "100%",
                          justifyContent: "center",
                          gap: "1rem",
                        }}
                      >
                        <strong style={{ lineHeight: "0" }}>
                          {category.categoria}
                        </strong>
                        <small style={{ lineHeight: "0.5" }}>
                          {category.id_categoria === "23"
                            ? "Más de 1 viaje al año"
                            : "Hasta 120 días"}
                        </small>
                        <input
                          type="radio"
                          id={`category-${index}`}
                          name="category"
                          checked={String(categoryId) === category.id_categoria}
                          onChange={() =>
                            onChangeCategory(
                              category.id_categoria,
                              category.categoria
                            )
                          }
                          value={category.id_categoria}
                        />
                      </label>
                    </article>
                  );
                })}
              </div>
            </div>
          )}
          <div className="flex-between mobile-column" style={{ width: "100%" }}>
            <div className="mobile-full-width">
              <label htmlFor="startDate">
                {t("BUYING_PROCESS.LABEL.START_DATE")}
              </label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                min={convertToYYYYMMDD(new Date())}
              />
            </div>
            <div className="mobile-full-width">
              <label htmlFor="endDate">
                {t("BUYING_PROCESS.LABEL.END_DATE")}
              </label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                min={convertToYYYYMMDD(new Date())}
              />
            </div>
          </div>
          <div
            className="flex-between mobile-column"
            style={{ width: "100%", marginTop: "20px" }}
          >
            <div className="flex-start mobile-full-width mobile-order-2">
              <button
                type="button"
                className="secondary outline mobile-full-width"
                onClick={() => navigate(prevStep)}
              >
                {t("GO_BACK")}
              </button>
            </div>
            <div className="flex-end mobile-full-width mobile-order-1">
              <button
                type="submit"
                onClick={onNext}
                className="primary mobile-full-width"
              >
                {t("NEXT")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyStep1;
