import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom"; //, Link
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useApiHook from "../../hooks/ApiHook";
import { promoCodeByName } from "../../services/PromoCodeService";
import BuyingProcessService from "../../services/BuyingProcessService";
import MessageContext from "../../contexts/MessageContext";
import FormatToastMessage from "../../shared/FormatMessage";
import {
  /* faPersonWalkingLuggage,
  faMapLocationDot, */
  faChevronUp as Up,
  faChevronDown as Down,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import  { CalendarCheckIcon, ReturnIcon, UserIcon, LocationIcon } from "../../shared/icons/SvgIcons";
import { useTranslation } from "react-i18next";
import PriceFormat from "../../shared/PriceFormat";
import { saleQuotePDF } from "../../services/SalesService";
import { formatDate } from "../../services/DateService";
import LoadingContext from "../../contexts/LoadingContext";
//import { use } from "i18next";

const catIdMultiViajes = 23;

const BuyingProcessDetails = ({
  buyingData,
  totalPrice,
  aditionalBenefits = null,
  hiddenButton = false,
  onNext,
  step,
  businessInfo,
}) => {

  const { t } = useTranslation();
  const loadingContext = useContext(LoadingContext);
  const apiCall = useApiHook();
  const navigate = useNavigate();
  const params = useParams();
  const [isOpened, setIsOpened] = useState(false);
  const [isOpenCode, setIsOpenCode] = useState(false);
  const [discountCode, setDiscountCode] = useState(buyingData.promoCode || "");
  const [discount, setDiscount] = useState(buyingData.discountAmount || 0);
  const [discountPercentage, setDiscountPercentage] = useState(
    buyingData.discountPercentage || 0
  );
  const [benefits, setBenefits] = useState([]);
  //const [originalTotalPrice, setOriginalTotalPrice] = useState(totalPrice);
  const [newTotalPrice, setNewTotalPrice] = useState(
    PriceFormat({ price: totalPrice })
  );
  const messageContext = useContext(MessageContext);
  const scrollableRef = useRef(null);
  const [finishUrl, setFinishUrl] = useState("/");
  const buyingProcessService = BuyingProcessService.getInstance().buyingData;
  useEffect(() => {
    setNewTotalPrice(
      PriceFormat({ price: [totalPrice, discount], operator: "-" })
    );
    if (businessInfo && businessInfo._id && step === 8) {
      setFinishUrl(`/${businessInfo.subdomain}`);
    }
  }, [totalPrice, finishUrl]);
  useEffect(() => {
    if (isOpened) {
      scrollableRef.current.scrollTo(0, 0);
    }
    buildBenefitsList();
  }, [isOpened]);

  useEffect(() => {
    buildBenefitsList();
  }, [buyingData.aditionalBenefits?.length]);

  const getPDFQuote = async () => {
    loadingContext.change(true);
    let selectedBenefits = [];
    const residenceCountry = buyingProcessService.residenceCountry.split("-");
    if (benefits.length > 0) {
      benefits.forEach((item) =>
        selectedBenefits.push({
          description: `${item.quantity}x ${item.description} hasta ${buyingProcessService.days} dias`,
          price: `${buyingProcessService.planCurrency} ${PriceFormat({
            price: [item.unitPrice, item.quantity],
            operator: "*",
            expect: "string",
          })}`,
        })
      );
    }
    const data = {
      benefits: selectedBenefits,
      currency: buyingProcessService.planCurrency,
      originTravel: residenceCountry[2] ?? "",
      destinyTravel: buyingProcessService.destinationName.toUpperCase(),
      startDate: formatDate(buyingProcessService.startDate, "es"),
      endDate: formatDate(buyingProcessService.endDate, "es"),
      duration: buyingProcessService.days,
      category: buyingProcessService.categoryName.toUpperCase(),
      plan: buyingProcessService.planName.toUpperCase(),
      initialPrice: `${buyingProcessService.planCurrency} ${PriceFormat({
        price: buyingData.initialPrice,
      })}`,
      promoCode: buyingProcessService.promoCode ?? null,
      discountAmount: `-${buyingProcessService.planCurrency} ${PriceFormat({
        price: buyingProcessService.discountAmount,
      })}`,
      discountPercentage: buyingProcessService.discountPercentage,
      totalPrice: `${buyingProcessService.planCurrency} ${PriceFormat({
        price: newTotalPrice,
      })}`,
      details: BuyingProcessService.getInstance().selectedPlanDetails || [],
      subdomain: params?.subdomain ?? null,
    };

    const response = await saleQuotePDF({ data });
    loadingContext.change(false);
    if (!response || !response.data) {
      messageContext.setMessage({
        text: "QUOTE.ERROR.GET",
        type: "error",
      });
    }
    window.open(URL.createObjectURL(response.data));
  };

  const buildBenefitsList = () => {
    const result = [];

    if (
      !buyingData.aditionalBenefits ||
      buyingData.aditionalBenefits.length === 0 ||
      aditionalBenefits === null ||
      aditionalBenefits.length === 0
    ) {
      setBenefits([]);
      return;
    }

    buyingData.aditionalBenefits.forEach((benefit, index) => {
      const temp = {
        quantity: 1,
        unitPrice: 0,
        description: "",
        id: benefit.benefitId,
      };

      const currentBenefit = aditionalBenefits.find(
        (item) => item.id === benefit.benefitId
      );
      temp.unitPrice = currentBenefit.precio;
      temp.description = currentBenefit.descripcion;

      const resultIndex = result.findIndex((item) => item.id === temp.id);
      if (resultIndex >= 0) {
        result[resultIndex].quantity++;
      } else {
        result.push(temp);
      }
    });
    setBenefits(result);
    updateDiscount();
  };

  const updateDiscount = () => {
    if (
      !buyingProcessService.discountPercentage ||
      buyingProcessService.withDiscount
    )
      return false;

    buyingProcessService.discountAmount = PriceFormat({
      price: [totalPrice, buyingProcessService.discountPercentage / 100],
      operator: "*",
      expect: "float",
    });
   /*  buyingProcessService.planPrice = PriceFormat({
      price: [totalPrice, buyingProcessService.discountAmount],
      operator: "-",
    }); */
    setDiscount(buyingProcessService.discountAmount);
    setNewTotalPrice(PriceFormat({
      price: [totalPrice, buyingProcessService.discountAmount],
      operator: "-",
    }));
  };
  
  useEffect(() => {    
    if (buyingProcessService.promoCode) {
      updateDiscount();
    }
  }, [buyingProcessService.withDiscount]);

  const resetDiscount = () => {
    buyingProcessService.promoCode = "";
    buyingProcessService.discountPercentage = 0;
    buyingProcessService.discountAmount = "0.00";
    buyingProcessService.planPrice = totalPrice;
    buyingProcessService.withDiscount = false;
    setDiscountCode("");
    setDiscountPercentage(0);
    setDiscount(0);
    setNewTotalPrice(PriceFormat({ price: totalPrice }));
    setIsOpenCode(!isOpenCode);
  };

  const getPromoCode = async () => {
    const name = discountCode;
    const response = await apiCall({
      fnApiCall: promoCodeByName,
      params: {
        name,
        subdomain:
          BuyingProcessService.getInstance()?.businessInfo?.subdomain ?? null,
      },
    });
    if (response.ok) {
      const { data } = response;
      if (data && (data.companyPercentage || data.ownPercentage)) {
        buyingProcessService.promoCode = data.code;
        buyingProcessService.discountPercentage = PriceFormat({
          price: [data.companyPercentage ?? 0, data.ownPercentage ?? 0],
          operator: "+",
          expect: "int",
        });
        buyingProcessService.discountAmount = PriceFormat({
          price: [totalPrice, buyingProcessService.discountPercentage / 100],
          operator: "*",
          expect: "float",
        });
        /* buyingProcessService.planPrice = PriceFormat({
          price: [totalPrice, buyingProcessService.discountAmount],
          operator: "-",
        }); */
        setDiscount(buyingProcessService.discountAmount);
        setDiscountPercentage(buyingProcessService.discountPercentage);
        setNewTotalPrice(
          PriceFormat({
            price: [totalPrice, buyingProcessService.discountAmount],
            operator: "-",
          })
        );
      } else {
        buyingProcessService.promoCode = "";
        buyingProcessService.discountPercentage = 0;
        buyingProcessService.discountAmount = "0.00";
        buyingProcessService.planPrice = totalPrice;
        setDiscountCode("");
        setDiscountPercentage(0);
        setDiscount(0);
        setNewTotalPrice(PriceFormat({ price: totalPrice }));

        return messageContext.setMessage({
          type: "error",
          text: FormatToastMessage(t("BUYING_PROCESS.ERROR.TITLE"), [
            "No hemos podido cargar tu código de descuento",
          ]),
        });
      }
    }
  };

  return (
    <div className="buying-process-details-wrapper">
      <div
        ref={scrollableRef}
        className={`buying-process-content-scrollable ${
          isOpened ? "buying-process-content-scrollable-opened" : ""
        }`}
      >
        <h3>{t("BUYING_PROCESS.DETAILS")}</h3>
        <article className="card" style={{ marginTop: "0" }}>
          <h3>
            Plan: <strong className="emphasys">{buyingData.planName}</strong>
          </h3>
          <p>
            <strong>
              {buyingData.categoryId === catIdMultiViajes
                ? "Más de 1 viaje al año hasta 30 días"
                : "Planes por viaje: hasta 90 días"}
            </strong>
          </p>

          <ul className="items-buy">
            <li>
              <span>
                <CalendarCheckIcon cls={"icon-primary"} />
              </span>{" "}
              Del {buyingData.startDate.split("-").reverse().join("/")} al{" "}
              {buyingData.endDate.split("-").reverse().join("/")}
            </li>
            <li>
              <span>
                <ReturnIcon cls={"icon-primary"} />
              </span>{" "}
              Duraci&oacute;n del viaje: {buyingData.days} d&iacute;as
            </li>
            <li>
              <span>                
                <UserIcon cls={"icon-primary"} />
              </span>{" "}
              {buyingData.passengers}
              {buyingData.passengers > 1 ? " pasajeros" : " pasajero"}{" "}
              {buyingData.ageRange === 80 ? "Senior" : ""}
            </li>
            <li>
              <span>
                <LocationIcon cls={"icon-primary"} />
              </span>{" "}
              Destino: {buyingData.destinationName}
            </li>
          </ul>
          <div className="container summary-sale">
            <h3 className="summary-title">Costo total del plan</h3>
            <p className="flex-between product-sale">
              <small>{buyingData.planName}</small>
              <small>
                {buyingData.planCurrency}{" "}
                {PriceFormat({ price: buyingData.initialPrice })}
              </small>
            </p>
            {benefits && benefits.length > 0 && (
              <div>
                {benefits.map((benefit, index) => (
                  <div
                    key={index}
                    className="flex-between"
                    style={{ textAlign: "left" }}
                  >
                    {benefit.quantity} x {benefit.description}
                    <span>
                      {buyingData.planCurrency}&nbsp;
                      {PriceFormat({
                        price: [benefit.unitPrice, benefit.quantity],
                        operator: "*",
                      })}
                    </span>
                  </div>
                ))}
              </div>
            )}
            <p className="flex-between discount-sale">
              {discount && discount !== 0 && buyingProcessService?.promoCode ? (
                <>
                  <small>
                    Descuento{" "}
                    <a
                      href="/"
                      className="primary mx-auto discount-percentage"
                      role="button"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {discountPercentage}%{" "}
                    </a>
                  </small>
                  <small>
                    {" "}
                    -{buyingData.planCurrency}{" "}
                    {PriceFormat({ price: discount })}
                  </small>
                </>
              ) : (
                ""
              )}
            </p>
            <p className="flex-between emphasys total-sale">
              <strong>Total:</strong>
              <strong>
                {buyingData.planCurrency} {newTotalPrice}
              </strong>
            </p>
            <button
              onClick={getPDFQuote}
              className="secondary"
              type="button"
              style={{
                borderRadius: "20px",
                padding: "5px 16px",
                width: "fit-content",
                margin: "20px auto 20px",
              }}
            >
              Descargar cotizacion
            </button>
            <div
              className="flex-center"
              style={{ margin: "2.5rem auto", flexFlow: "column wrap" }}
            >
              {!buyingData.promoCode || !discount ? (
                <>
                  <a
                    href="/"
                    className="emphasys"
                    onClick={(e) => {
                      e.preventDefault();
                      return setIsOpenCode(!isOpenCode);
                    }}
                    style={{ backgroundColor: "transparent", border: "none" }}
                  >
                    ¿Tienes un código de descuento?
                  </a>
                  {isOpenCode && step !== 8 && (
                    <div className="flex-center" style={{ width: "100%" }}>
                      <input
                        type="text"
                        value={discountCode}
                        className="input-code"
                        onInput={(e) => {
                          setDiscountCode(e.target.value);
                        }}
                      />
                      <button
                        type="button"
                        className="btn-code"
                        onClick={getPromoCode}
                      >
                        <small>Aplicar</small>
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <button
                  className="primary mx-auto btn-plan mobile-full-width"
                  /* role="button" */
                  style={{
                    fontSize: ".8rem",
                    padding: "3px 15px",
                    width: "fit-content",
                  }}
                >
                  Código de descuento aplicado&nbsp;{" "}
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{
                      backgroundColor: "#000000",
                      padding: "2px 3px",
                      marginBottom: "-3px",
                      borderRadius: "50%",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      resetDiscount();
                    }}
                  />
                </button>
              )}
            </div>
          </div>
          {hiddenButton === true ? (
            ""
          ) : (
            <>
              {step !== 8 ? (
                <div className="flex-center desktop">
                  <button
                    type="button"
                    className="primary mx-auto btn-plan mobile-full-width"
                    onClick={() => {
                      if (discountCode && discountCode !== "") {
                        buyingProcessService.withDiscount = true;
                      }
                      onNext();
                    }}
                    style={{ marginTop: "1rem", marginBottom: "0" }}
                    disabled={hiddenButton}
                  >
                    {t("ACCEPT")}
                  </button>
                </div>
              ) : (
                <div className="flex-center desktop">
                  <button
                    type="button"
                    className="primary mx-auto btn-plan mobile-full-width"
                    onClick={() => {
                      navigate(finishUrl);
                    }}
                    style={{ marginTop: "1rem", marginBottom: "0" }}
                    disabled={hiddenButton}
                  >
                    {t("BUYING_PROCESS.FINISH")}
                  </button>
                </div>
              )}
            </>
          )}
        </article>
      </div>
      <div className="buying-process-wrapper-bar flex-between mobile-flex">
        <div>
          <h3>
            Plan: <strong className="emphasys">{buyingData.planName}</strong>
          </h3>
          <p className="flex-between emphasys total-sale">
            <strong>Total:</strong>
            <strong>
              {buyingData.planCurrency} {newTotalPrice}
            </strong>
          </p>
        </div>
        <div className="flex-between">
          {!hiddenButton ? (
            <button
              type="button"
              className="primary mx-auto btn-plan"
              onClick={() => {
                if (discountCode && discountCode !== "") {
                  buyingProcessService.withDiscount = true;
                }
                onNext();
              }}
              style={{
                marginTop: 0,
                marginBottom: 0,
                padding: "0.35rem 2.4rem",
                fontSize: "0.8rem",
                width: "auto",
              }}
            >
              {t("ACCEPT")}
            </button>
          ) : (
            ""
          )}
          <div
            style={{
              backgroundColor: "transparent",
            }}
            className="icon-clickable"
            onClick={() => setIsOpened(!isOpened)}
          >
            <FontAwesomeIcon
              icon={isOpened ? Down : Up}
              style={{ color: "var(--primary)" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyingProcessDetails;
