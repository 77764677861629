import { useEffect, useState, createRef } from "react";
import { Link } from "react-router-dom";
import { faTable } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useApiHook from "../../../hooks/ApiHook";
import { promoCodeFindList } from "../../../services/PromoCodeService";
import { salesFindList } from "../../../services/SalesService";
import { useTranslation } from "react-i18next";
import OrderTableHeader from "../../../shared/OrderTableHeader";
import ExportSheet from "../../../shared/ExportSheet";
import { formatDate } from "../../../services/DateService";

const limit = 100;
let items = [];
let itemsToShow = [];
let orderDirection = "asc";
let orderBy = "code";

const PromoCodeSearch = ({ onSelect, navbar, addButton }) => {
  
  const [stateVersion, setStateVersion] = useState(0);
  const { t } = useTranslation();
  const apiHook = useApiHook();
  const searchRef = createRef();

  const setItems = (data) => {
    items = data;
  };

  const reRender = () => {
    setStateVersion((stateVersion) => stateVersion + 1);
  };

  const findList = async () => {
    const promoCodeResponseData = await apiHook({
      fnApiCall: promoCodeFindList,
      params: null,
    });
    if (
      !promoCodeResponseData ||
      !promoCodeResponseData.data ||
      promoCodeResponseData.data === null ||
      promoCodeResponseData.data.length === 0
    ) {
      reRender();
      return;
    }
    const salesResponseData = await apiHook({
      fnApiCall: salesFindList,
      params: null,
    });
    const salesData = salesResponseData?.data?.data ?? null;
    if (!salesData) return;

    const data = promoCodeResponseData.data.map((item) => {
      let total = item.ownPercentage ?? 0;
      total += item.companyPercentage ?? 0;
      return {
        id: item.id ?? null,
        _id: item._id ?? null,
        code: item.code ?? 0,
        usesNumber: getSalesNumberByPromoCode(salesData, item.code) ?? 0,
        usesNumberLimit: item?.usesNumber?.toString() ?? t("PROMO_CODE.NO_LIMIT"),
        ownPercentage: item.ownPercentage ?? 0,
        companyPercentage: item.companyPercentage ?? 0,
        totalPercentage: total,
        dateStart: item.dateEnd ? formatDate(item.dateStart) : t("NO_DATA"),
        dateEnd: item.dateEnd ? formatDate(item.dateEnd) : t("NO_DATA"),
        isPaused: item.isPaused ? t("YES") : t("NO"),
      };
    });
    setItems(data);
    filterItemsToShow();
    reRender();
  };

  useEffect(() => {
    findList();
  }, []);

  const onClickRow = (item) => {
    items = [];

    onSelect(item);
  };

  const setOrder = ({ newOrderBy, newOrderDirection }) => {
    orderBy = newOrderBy;
    orderDirection = newOrderDirection;
    filterItemsToShow(searchRef.current.value);
  };

  const onSearch = (e) => {
    filterItemsToShow(e.target.value);
  };

  const filterItemsToShow = (value) => {
    if (items == null || items.length === 0) {
      itemsToShow = [];
      reRender();
      return;
    }
    let tempItems = [];
    if (value != null) {
      tempItems = items.filter((item) =>
        // search by code
        item.code.toUpperCase().includes(value.trim().toUpperCase())
      );
    } else {
      tempItems = items;
    }
    if (!tempItems || tempItems.length === 0) {
      itemsToShow = [];
      reRender();
      return;
    }
    itemsToShow = tempItems
      .sort((a, b) => {
        const sortDirection = orderDirection === "asc" ? 1 : -1;
        if (a[orderBy] === b[orderBy]) {
          return 0;
        } else if (a[orderBy] > b[orderBy]) {
          return 1 * sortDirection;
        } else {
          return -1 * sortDirection;
        }
      })
      .slice(0, limit);
    reRender();
  };

  const getDataForExport = () => {
    const headers = [
      t("PROMO_CODE.CODE"),
      t("PROMO_CODE.USE_NUMBER"),
      t("PROMO_CODE.USE_LIMIT"),
      t("PROMO_CODE.OWN_PERCENTAGE"),
      t("PROMO_CODE.COMPANY_PERCENTAGE"),
      t("PROMO_CODE.TOTAL_PERCENTAGE"),
      t("START_DATE"),
      t("END_DATE"),
      t("PROMO_CODE.IS_PAUSED"),
    ];
    const data = itemsToShow.map((item) => {
      const cells = [...Object.values(item)];
      cells.splice(0, 2);
      return cells;
    });
    return [headers, ...data];
  };

  const getSalesNumberByPromoCode = (salesData, promoCode) => {
    const sales = salesData.filter(
      (sale) => sale?.sale?.promoCode?.toUpperCase() === promoCode?.toUpperCase()
    );    
    return sales.length;
  };

  return (
    <>
      {stateVersion > 0 && (
        <>
          <nav className="flex-between flex-wrap">
            <div className="flex-start flex-wrap">
              <h1 style={{ margin: 0 }}>{t("PROMO_CODE_LIST")}</h1>
              {addButton && addButton}
            </div>
            <form
              name="search"
              className="menu-navbar filters mobile-full-width"
              onSubmit={(ev) => {
                ev.preventDefault();
              }}
            >
              <input
                type="search"
                style={{ margin: 0 }}
                name="search"
                onChange={onSearch}
                ref={searchRef}
                placeholder={t("SEARCH")}
                className="search-input"
              />
            </form>
            <Link
              style={{
                marginLeft: "var(--spacing)",
                backgroundColor: "var(--secondary)",
              }}
              className="icon-clickable"
              to="/admin/promo-code/stats"
            >
              <FontAwesomeIcon
                color="var(--primary-inverse)"
                size="xl"
                icon={faTable}
              />
            </Link>
            <ExportSheet
              style={{ marginTop: "10px" }}
              getData={getDataForExport}
              fileName={t("PROMO_CODE_LIST")}
            />
          </nav>
          {navbar && navbar}
          {itemsToShow.length > 0 ? (
            <figure>
              <table role="grid">
                <thead>
                  <tr>
                    <OrderTableHeader
                      titleKey={"PROMO_CODE.CODE"}
                      orderByKey={"code"}
                      onSetOrder={setOrder}
                      orderBy={orderBy}
                      orderDirection={orderDirection}
                    />
                    <OrderTableHeader
                      titleKey={"PROMO_CODE.USE_NUMBER"}
                      orderByKey={"usesNumber"}
                      onSetOrder={setOrder}
                      orderBy={orderBy}
                      orderDirection={orderDirection}
                    />
                    <OrderTableHeader
                      titleKey={"PROMO_CODE.OWN_PERCENTAGE"}
                      orderByKey={"ownPercentage"}
                      onSetOrder={setOrder}
                      orderBy={orderBy}
                      orderDirection={orderDirection}
                    />
                    <OrderTableHeader
                      titleKey={"PROMO_CODE.COMPANY_PERCENTAGE"}
                      orderByKey={"companyPercentage"}
                      onSetOrder={setOrder}
                      orderBy={orderBy}
                      orderDirection={orderDirection}
                    />
                    <OrderTableHeader
                      titleKey={"PROMO_CODE.TOTAL_PERCENTAGE"}
                      orderByKey={"totalPercentage"}
                      onSetOrder={setOrder}
                      orderBy={orderBy}
                      orderDirection={orderDirection}
                    />
                    <OrderTableHeader
                      titleKey={"START_DATE"}
                      orderByKey={"dateStart"}
                      onSetOrder={setOrder}
                      orderBy={orderBy}
                      orderDirection={orderDirection}
                    />
                    <OrderTableHeader
                      titleKey={"END_DATE"}
                      orderByKey={"dateEnd"}
                      onSetOrder={setOrder}
                      orderBy={orderBy}
                      orderDirection={orderDirection}
                    />
                    <OrderTableHeader
                      titleKey={"PROMO_CODE.IS_PAUSED"}
                      orderByKey={"isPaused"}
                      onSetOrder={setOrder}
                      orderBy={orderBy}
                      orderDirection={orderDirection}
                    />
                  </tr>
                </thead>
                <tbody>
                  {itemsToShow.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => onClickRow(item)}
                        style={{ cursor: "pointer" }}
                      >
                        <td>{item.code}</td>
                        <td>{item.usesNumber} / {item.usesNumberLimit}</td>
                        <td>{item.ownPercentage}</td>
                        <td>{item.companyPercentage}</td>
                        <td>{item.totalPercentage}</td>
                        <td>{item.dateStart}</td>
                        <td>{item.dateEnd}</td>
                        <td>{item.isPaused}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </figure>
          ) : (
            <p>{t("PROMO_CODE_NO_RESULTS")}</p>
          )}
        </>
      )}
    </>
  );
};

export default PromoCodeSearch;
