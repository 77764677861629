import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//import { useTranslation } from 'react-i18next';
//import MessageContext from '../../../contexts/MessageContext';
import BusinessEditComponent from '../../components/business/BusinessEdit';
import Header from '../../../shared/Header';
import GoBackLink from '../../../shared/GoBackLink';
import useAuthHook from "../../../hooks/AuthHook";


let addStep = 1;

const BusinessEdit = () => {    
    const [stateVersion, setStateVersion] = useState(1);
    const navigate = useNavigate();
    /* const msgContext = useContext(MessageContext);  */
    //const { t } = useTranslation();

    useAuthHook();


    const onEdit = (b) => {
       /*  msgContext.setMessage({
            text: t('BUSINESS_ADD_SUCCESS'),
            type: 'success'
        }); */
        setStep(1);
        return navigate('/admin/business');
    }

   const setStep = (step) => { 
        addStep = step; 
        reRender();
    }

    const reRender = () => {
        setStateVersion(stateVersion + 1);
    }

    return (
        <>
            <Header />
            <div className='container'>
                <BusinessEditComponent 
                onEdit={onEdit}
                setStep={setStep}
                step={addStep}
                goBackButton={                
                    <GoBackLink to="/admin/business" />                
                }
                />
            </div>
         </>
    );
}

export default BusinessEdit;
