import MessageContext from "../contexts/MessageContext";
import LoadingContext from "../contexts/LoadingContext";
import { useContext } from "react";
import { getCookie } from "../shared/CookiesManager";

const useApiHook = () => {
    const messageContext =  useContext(MessageContext);
    const loadingContext = useContext(LoadingContext);

    return async({fnApiCall, params, noLoading}) => {
        try {
            const obj = {
                headers: {
                'Authorization': `Bearer ${getCookie({ name: 'SSID' })}`
                },
                data: {
                    ...params
                }
            }  
                     

            if(!noLoading) {
                loadingContext.change(true);
            }            

            const response = await fnApiCall(obj);
            
            loadingContext.change(false);

            return {
                data: response.data,
                info: response?.info ?? null,
                ok: response.status === 200,
            }
        } catch (error) {               
            messageContext.setMessage({                
                text: error.response.data.message, 
                type: (error.response.status === 500 || error.response.status === 404) ? 'error' : 'warn'
            });
        } finally {
            loadingContext.change(false);
        }

        return {data: false, pages: 0, ok: false}
    };
}

export default useApiHook;