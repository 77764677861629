import PromoCodeSearch from "../../components/promoCode/PromoCodeSearch";
import { Link, useNavigate } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../../../shared/Header";
import useAuthHook from "../../../hooks/AuthHook";


const PromoCodeList = () => {

  //const { t } = useTranslation();
  const navigate = useNavigate();

  useAuthHook();    

  const onSelect = (item) => {
    return navigate(`/admin/promo-code/${item._id}`);
  };

  return (
    <>
      <Header />
      <div className="container">
        <PromoCodeSearch
          addButton={
            <Link
              className="icon-clickable promo-code-add add-btn"
              to="/admin/promo-code/add"
            >
              <FontAwesomeIcon
                color="var(--primary-inverse)"
                size="xl"
                icon={faPlus}
              />
            </Link>
          }
          onSelect={onSelect}
        />
      </div>
    </>
  );
};

export default PromoCodeList;
