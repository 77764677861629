import { useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useApiHook from "../../../hooks/ApiHook";
import {
  promoCodeEdit,
  promoCodeGetById,
  promoCodeDelete,
} from "../../../services/PromoCodeService";
import { salesFindList } from "../../../services/SalesService";
import DateFormat from "../../../shared/DateFormat";
import ConfirmationModal from "../../../shared/ConfirmationModal";
import MessageContext from "../../../contexts/MessageContext";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../../../shared/Header";
import GoBackLink from "../../../shared/GoBackLink";
import useAuthHook from "../../../hooks/AuthHook";


let item = null;
let isConfirmationPauseModalOpen = false;
let isConfirmationDeleteModalOpen = false;

const PromoCodeDetails = ({ navbar }) => {
  const { id } = useParams();
  const [stateVersion, setStateVersion] = useState(0);
  const apiHook = useApiHook();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const messageContext = useContext(MessageContext);

  useAuthHook();    

  
  const init = async () => {
    await getPromoCodeById();
  };

  const getPromoCodeById = async () => {
    const promoCodeResponse = await apiHook({
      fnApiCall: promoCodeGetById,
      params: { id },
    });
    if (!promoCodeResponse.ok) {
      return;
    }
    const salesResponseData = await apiHook({
      fnApiCall: salesFindList,
      params: null,
    });
    const salesData = salesResponseData?.data?.data ?? null;
    if (!salesData) return;
    item = promoCodeResponse.data;
    item.usesNumberLimit = item?.usesNumber?.toString() ?? t("PROMO_CODE.NO_LIMIT");
    item.usesNumber = getSalesNumberByPromoCode(salesData, item.code) ?? 0;
    
    reRender();
  };

  useEffect(() => {
    init();

    return () => {
      item = null;
    }
  }, []);

  const onSwitchPause = async () => {
    hideConfirmationPauseModal();
    const data = {
      id: item._id,
      isPaused: item.isPaused === 0 ? 1 : 0,
    };
    const response = await apiHook({
      fnApiCall: promoCodeEdit,
      params: {
        data,
      },
    });
    if (!response.ok) {
      return;
    }
    item.isPaused = data.isPaused;
    reRender();
  };

  const onDelete = async () => {
    hideConfirmationDeleteModal();
    const response = await apiHook({
      fnApiCall: promoCodeDelete,
      params: {
        data: {
          id: item._id,
          code: item.code,
        },
      },
    });
    if (!response.ok) {
      return;
    }
    messageContext.setMessage({
      text: t("PROMO_CODE_DELETE_SUCCESS"),
      type: "success",
    });
    return navigate("/admin/promo-code");
  };

  const reRender = () => {
    setStateVersion(stateVersion + 1);
  };

  const showConfirmationPauseModal = () => {
    isConfirmationPauseModalOpen = true;
    reRender();
  };

  const hideConfirmationPauseModal = () => {
    isConfirmationPauseModalOpen = false;
    reRender();
  };

  const showConfirmationDeleteModal = () => {
    isConfirmationDeleteModalOpen = true;
    reRender();
  };

  const hideConfirmationDeleteModal = () => {
    isConfirmationDeleteModalOpen = false;
    reRender();
  };

  const getTotalPercentage = () => {
    let total = item.ownPercentage ?? 0;
    total += item.companyPercentage ?? 0;
    return total;
  }

  const getSalesNumberByPromoCode = (salesData, promoCode) => {
    const sales = salesData.filter(
      (sale) => sale?.sale?.promoCode?.toUpperCase() === promoCode?.toUpperCase()
    );    
    return sales.length;
  };

  return (
    <>
      <Header />
      <div className="container">
      {navbar && navbar}
      <nav className="flex-between">
        <GoBackLink to="/admin/promo-code" />
        <FontAwesomeIcon
          size="lg"
          style={{ cursor: "pointer" }}
          color="var(--danger)"
          onClick={showConfirmationDeleteModal}
          icon={faTrash}
        />
      </nav>
      {stateVersion > 0 && (
        <>
          {item && (
            <div
              style={{
                marginTop: "50px",
                marginRight: "auto",
                marginLeft: "auto",
                maxWidth: "800px",
              }}
            >
              <div className="grid">
                <p>
                  {t("PROMO_CODE.CODE")}: <strong>{item.code}</strong>
                </p>
                <fieldset>
                  <label>
                    <input
                      type="checkbox"
                      checked={item.isPaused}
                      onChange={showConfirmationPauseModal}
                      id="switch"
                      name="switch"
                      role="switch"
                    />
                    {t("PROMO_CODE.IS_PAUSED")}
                  </label>
                </fieldset>
              </div>
              <div className="grid">
                <p>
                  {t("START_DATE")}:{" "}
                  <strong>
                    {item.dateStart ? (
                      <DateFormat date={item.dateStart} />
                    ) : (
                      t("NO_DATA")
                    )}
                  </strong>
                </p>
                <p>
                  {t("END_DATE")}:{" "}
                  <strong>
                    {item.dateEnd ? (
                      <DateFormat date={item.dateEnd} />
                    ) : (
                      t("NO_DATA")
                    )}
                  </strong>
                </p>
              </div>
              <div className="grid">
                <p>
                  {t("PROMO_CODE.USE_NUMBER")}:{" "}
                  <strong>{item.usesNumber} / {item.usesNumberLimit}</strong>
                  
                </p>
                <p>                
                  {t("PROMO_CODE.COMPANY_PERCENTAGE")}:{" "}
                  <strong>
                    {item.companyPercentage
                      ? `${item.companyPercentage}%`
                      : t("NO_DATA")}
                  </strong>
                  <br/>
                  {t("PROMO_CODE.OWN_PERCENTAGE")}:{" "}
                  <strong>
                    {item.ownPercentage
                      ? `${item.ownPercentage}%`
                      : t("NO_DATA")}
                  </strong>
                  <br/>
                  {t("PROMO_CODE.TOTAL_PERCENTAGE")}:{" "}
                  <strong>
                    {getTotalPercentage()}%
                  </strong>
                </p>
              </div>
              <div className="grid">
                <div>
                  <p
                    style={{
                      marginBottom: "5px",
                    }}
                  >
                    {t("BUSINESS.LIST")}
                  </p>
                  <ul>
                    {item.businessList.length > 0 ? 
                      <>
                      {item.businessList.map((business, index) => (
                        <li key={index}>
                          <strong>{business.name}</strong>
                        </li>
                      ))}
                      </>
                    : 
                      <li>{t('NO_DATA')}</li>
                    }
                    
                  </ul>
                </div>
                
              </div>
              <ConfirmationModal
                isOpen={isConfirmationPauseModalOpen}
                titleKey={
                  item.isPaused
                    ? "PROMO_CODE_UNPAUSE.TITLE"
                    : "PROMO_CODE_PAUSE.TITLE"
                }
                messageKey={
                  item.isPaused
                    ? "PROMO_CODE_UNPAUSE.MESSAGE"
                    : "PROMO_CODE_PAUSE.MESSAGE"
                }
                onConfirm={onSwitchPause}
                onCancel={hideConfirmationPauseModal}
              />
              <ConfirmationModal
                isOpen={isConfirmationDeleteModalOpen}
                titleKey="PROMO_CODE_DELETE.TITLE"
                messageKey="PROMO_CODE_DELETE.MESSAGE"
                onConfirm={onDelete}
                onCancel={hideConfirmationDeleteModal}
              />
            </div>
          )}
        </>
      )}
      </div>
    </>
  );
};

export default PromoCodeDetails;
