import { useTranslation } from 'react-i18next';
import Header from '../../shared/Header';
import useAuthHook from '../../hooks/AuthHook';

const Dashboard = () => {
    const { t } = useTranslation();

    useAuthHook();
    return (
        <>
        <Header />
        <div className='container'><h2>{ t('DASHBOARD')}</h2></div>
        </>
        
    )
}

export default Dashboard;