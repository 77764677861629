import PromoCodeAddComponent from '../../components/promoCode/PromoCodeAdd';
import { useNavigate } from 'react-router-dom';
import Header from '../../../shared/Header';
import GoBackLink from '../../../shared/GoBackLink';
import useAuthHook from "../../../hooks/AuthHook";


const PromoCodeAdd = () => {
    const navigate = useNavigate();

    useAuthHook();    

    const onAdd = () => {
        navigate('/admin/promo-code/');
    }

    return (
        <>
        <Header />            
            <div style={{
                maxWidth: 800, 
                marginRight: 'auto', 
                marginLeft: 'auto'
            }}>
            <PromoCodeAddComponent 
                onAdd={onAdd} 
                goBackButton={                
                    <GoBackLink to="/admin/promo-code" />                
                }
            />
        </div>
        </>
        
    );
}

export default PromoCodeAdd;