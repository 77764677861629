import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import {
  /* BrowserRouter, */
  RouterProvider,
} from "react-router-dom";
import  './i18n/i18n';


import MessageContext from './contexts/MessageContext';
import { useEffect, useState } from 'react';
import LoadingContext from './contexts/LoadingContext';
import { useTranslation } from 'react-i18next';
import router from './routes/router';
import  '@picocss/pico';
import "./App.css";

let isLoading = false;

const toastFontStyle = {
  fontSize: '0.8rem'
}

function App() {
  const [stateVersion, setStateVersion] = useState(1);
  const {t} = useTranslation();

  const checkTextMessage = (message) => {    
    if (typeof message.text === 'string') {      
      return t(message.text)
    }
    return message.text;
  };

  const notify = (message) => {        
    switch (message.type) {
      case 'success':
        toast.success(checkTextMessage(message), {icon:false});
        break;
      case 'error':
        toast.error(checkTextMessage(message), {icon:false});
        break;
      case 'info':
        toast.info(checkTextMessage(message));
        break;
      case 'warn':
        toast.warn(checkTextMessage(message), { 
          icon: false,
          style: {
            ...toastFontStyle,
            color: '#000'
          }
        });
        break;
      default:
        toast(t(message.text));
        break;
    }
  }

  const setIsLoading = (value) => {
    const hasChanged = isLoading !== value;
    isLoading = value;
    if (hasChanged) {
      reRender();
    }  
   }

  const reRender = () => {
    setStateVersion(stateVersion => stateVersion + 1);
  }

  useEffect(() => {
  }, [stateVersion]);

  return (
    <>      
      { stateVersion > 0 &&
        <>
          <LoadingContext.Provider 
            value={{ change: (loading) => setIsLoading(loading)}}>
            <MessageContext.Provider value={{ 
              setMessage: (message) => notify(message), 
            }}>              
                <RouterProvider router={router} />              
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                toastStyle={{...toastFontStyle}}
                closeButton={false}
              />
              { isLoading &&
              <div 
                aria-busy="true"
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  backgroundfilter: 'blur(5px)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                </div>
              }
            </MessageContext.Provider>
          </LoadingContext.Provider>
        </> 
      }
    </>
  );
}

export default App;
