import ArrowUp from "./icons/ArrowUp";
import ArrowDown from "./icons/ArrowDown";
import { useTranslation } from "react-i18next";

/**
 * 
 * @param string orderBy Campo por el que se ordena actualmente 
 * @param string orderDirection Dirección de la ordenación actual
 * @param function onSetOrder Función que se ejecuta al cambiar la ordenación
 * @param string titleKey Clave de traducción del título de la cabecera
 * @param string orderByKey Campo por el que se ordena al hacer click en la cabecera
 * @returns 
 */
const OrderTableHeader = ({ 
    orderBy, 
    orderDirection, 
    onSetOrder, 
    titleKey, 
    orderByKey
 }) => {
    const { t } = useTranslation();

    const setOrder = ({ newOrderBy }) => {
        if (newOrderBy !== orderBy) {
            orderDirection = 'asc';
            orderBy = newOrderBy;
        } else {
            orderDirection = orderDirection === 'asc' ? 'desc' : 'asc';
        }
        onSetOrder({ 
            newOrderBy: orderBy, 
            newOrderDirection: orderDirection 
        });
    }

    return (
        <th onClick={() => setOrder({newOrderBy: orderByKey})}
            style={{ 
                cursor: 'pointer', 
                color: orderBy === orderByKey ? 'var(--secondary)' : 'var(--text-color)'
            }}>
            { t(titleKey) }
            { orderBy === orderByKey &&
                <>
                    {orderDirection === 'asc' ? <ArrowDown /> : <ArrowUp />}
                </>
            }
        </th>
    )
}

export default OrderTableHeader;