import Compressor from "compressorjs"
import { uploadImage } from "../../services/FileService"
import { v4 as uuidv4 } from 'uuid'

export const compressImage = async (images) => {
    const compressed = []
    if (images?.length > 0) {
        const promises = images.map(async (item) => {
            if (item.size > process.env.IMAGE_MAX_SIZE) {
                await new Promise((resolve, reject) => {
                    new Compressor(item, {
                        quality: .6,
                        success: resolve,
                        error: reject
                    })
                })
                    .then((file) => {
                        compressed.push(file)
                    })
                    .catch((err) => {
                        return Promise.reject(err)
                    })
            } else {
                compressed.push(item)
            }
        })
        await Promise.all(promises)
    }
    return compressed
}

export const onUploadImage = async (img) => {
    const resizedImage = await compressImage([img]);

    let imageName = resizedImage[0].name;

    if (resizedImage[0].type === "image/jpeg") {
        imageName = `${resizedImage[0].name.split('.jpg')[0]}${uuidv4()}.jpg`;
    } else if (resizedImage[0].type === "image/png") {
        imageName = `${resizedImage[0].name.split('.png')[0]}${uuidv4()}.png`;
    }

    if (!(resizedImage[0].size > process.env.REACT_APP_IMAGE_MAX_SIZE)) {
        const image = await uploadImage(resizedImage[0], imageName);
        return image
    } else {
        return false
    }
}

export const fromBase64ToBlob = async (base64Image) => {
    const base64 = base64Image.split(',')[1];
    const mimeType = base64Image.match(/data:([^;]+);/)[1];

    const response = await fetch(`data:${mimeType};base64,${base64}`);
    const blob = await response.blob();

    const extension = mimeType.split('/')[1];
    const blobImage = new File([blob], `${uuidv4()}.${extension}`, { type: mimeType });

    return blobImage;
};

export const convertBanners = async (newImageData) => {
    let convertedImages = [];

    for (let i = 0; i < newImageData.length; i++) {
        const banner = newImageData[i];

        let imageToUpload;
        if (banner.img.includes(process.env.REACT_APP_AWS_BUCKET)) {
            imageToUpload = banner.img;
        } else {
            const image = await fromBase64ToBlob(banner.img);
            imageToUpload = await onUploadImage(image);
            if (!imageToUpload) {
                throw new Error(`La imagen ${i + 1} excede el tamaño de imagen permitido.`);
            }
        }
        const newBanner = { img: imageToUpload, link: banner.link };
        convertedImages.push(newBanner);
    }

    return convertedImages;
};

export function roundEdges(canvas) {
    const context = canvas.getContext("2d");
    const { width, height } = canvas;
    if (context) {
        context.fillStyle = "#fff";
        context.globalCompositeOperation = "destination-in";
        context.beginPath();
        context.scale(1, height / width);
        context.arc(width / 2, width / 2, width / 2, 0, Math.PI * 2);
        context.closePath();
        context.fill();
    }
    return canvas;
}

export const resizeImage = (imageDataUrl, width, height, callback) => {
    const img = new Image();
    img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        const resizedImage = canvas.toDataURL("image/png");
        callback(resizedImage);
    };
    img.src = imageDataUrl;
};
