import { useEffect, useState } from "react"; //, useContext
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useApiHook from "../../../hooks/ApiHook";
//import MessageContext from "../../../contexts/MessageContext";
import BuyingProcessService from "../../../services/BuyingProcessService";
import PriceFormat from "../../../shared/PriceFormat";
import {
  getPlansByDestination,
  getBenefitsCostsByPlan,
} from "../../../services/CategoryService";
import ModalBenefits from "../../components/ModalBenefits";

import HeaderPublic from "../../components/HeaderPublic";

//let dataPrint = [];
let availablePlans = [];
const BuyStep4 = ({ step = 4 }) => {
  const buyingData = BuyingProcessService.getInstance().buyingData;
  BuyingProcessService.getInstance().benefitsList = [];
  const companyData = BuyingProcessService.getInstance().businessInfo;
  const markup = parseFloat(companyData?.markup / 100 + 1).toFixed(2) || 2.0
  //const [cierre, setCierre] = useState(false);
  const [stateVersion, setStateVersion] = useState(0);
  const [titulo, setTitulo] = useState("");
  const [availableBenefits, setBenefits] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [prevStep, setPrevStep] = useState(`/buy/step-${step - 1}`);
  const [nextStep, setNextStep] = useState(`/buy/step-${step + 1}`);
  const navigate = useNavigate();
  const apiCall = useApiHook();
  const { t } = useTranslation();
  //const messageContext = useContext(MessageContext);
  const reRender = () => {
    setStateVersion(stateVersion + 1);
  };

  const onClose = () => {
    setShowModal(false);
  };

  const onOpen = () => {
    setShowModal(true);
  };

  const init = async () => {
    if (BuyingProcessService.getInstance().getInvalidStepOn3() > 0) {
      return navigate(prevStep);
    }
    window.sessionStorage.removeItem("priceWithDiscount");
    const data = {
      days: buyingData.days,
      ages: buyingData.ageRange,
      categoryId: buyingData.categoryId,
      destinationId: buyingData.destinationId,
      familyPlan: buyingData.familyPlan,
    }; 
    /* if (dataPrint.length > 0) {      
      dataPrint = [];
    }
    dataPrint.push( {
      dias: buyingData.days,
      edades: buyingData.ageRange,
      id_categoria: buyingData.categoryId,
      id_destino: buyingData.destinationId,
      familiar: buyingData.familyPlan
    }); */   
    const response = await apiCall({
      fnApiCall: getPlansByDestination,
      params: { data },
    });
    if (response.ok) {
      availablePlans = response.data.data;
      /* console.log("availablePlans", availablePlans);
      console.log("businessInfo", companyData); */
      reRender();
    }
  };

  const getBenefits = async (planId, name) => {
    setTitulo(name);
    const data = {
      destinationId: buyingData.destinationId,
      categoryId: buyingData.categoryId,
      planId: planId,
    };
    const response = await apiCall({
      fnApiCall: getBenefitsCostsByPlan,
      params: { data },
      noLoading: true,
    });
    if (response.ok) {
      setBenefits(response.data.data);
      //availableBenefits = response.data.data;
      reRender();
    }
  };

  const onNext = async (
    id = 0,
    price = 0,
    basePrice = 0,
    currency = 0,
    name = ""
  ) => {
    const data = {
      destinationId: buyingData.destinationId,
      categoryId: buyingData.categoryId,
      planId: id,
    };
    const response = await apiCall({
      fnApiCall: getBenefitsCostsByPlan,
      params: { data },
      noLoading: false,
    });
    const buyingProcessService = BuyingProcessService.getInstance();
    buyingProcessService.selectedPlanDetails = response?.data?.data ?? [];
    buyingData.aditionalBenefits = [];
    buyingProcessService.buyingData.planId = id;
    buyingProcessService.buyingData.planPrice =
      buyingData.familyPlan === 0 || buyingData.familyPlan === undefined
        ? PriceFormat({
            price: [price, buyingData.passengers],
            operator: "*",
            expect: "float",
          })
        : PriceFormat({ price: price, expect: "float" });
    buyingProcessService.buyingData.planContinentalPrice = PriceFormat({
      price: basePrice,
      expect: "float",
    });
    buyingProcessService.buyingData.planCurrency = currency;
    buyingProcessService.buyingData.planName = name;
    buyingProcessService.buyingData.initialPrice =
      buyingData.familyPlan === 0 || buyingData.familyPlan === undefined
        ? PriceFormat({
            price: [price, buyingData.passengers],
            operator: "*",
            expect: "float",
          })
        : PriceFormat({ price: price, expect: "float" });

    return navigate(nextStep);
  };

  useEffect(() => {
    if (companyData && companyData._id && companyData?.role !== "2") {
      setPrevStep(`/${companyData.subdomain}/buy/step-${step - 1}`);
      setNextStep(`/${companyData.subdomain}/buy/step-${step + 1}`);
    }    
    init();
  }, []);

  return (
    <>
      <HeaderPublic
        businessInfo={
          companyData?.role !== "2" && companyData.subdomain !== "myglobal"
            ? companyData
            : null
        }
      />
      <div
        className="container"
        style={{
          minHeight: "83vh",
          textAlign: "center",
          display: "grid",
          gridTemplateColumns: "1fr",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {availablePlans && availablePlans.length > 0 ? (
          <>
            <h1
              style={{
                lineHeight: "1",
                marginBottom: "1rem",
                marginTop: "4rem",
              }}
            >
              {t("PAX_OPTIONS")}
            </h1>
            {/* <h4>
          Todos nuestros productos{" "}
          <span style={{ color: "#b700ff", marginBottom: "0" }}>
            INCLUYEN ASISTENCIA MEDICA ANTE COVID
          </span>
        </h4> */}
            <ul
              className="basic-features-plan"
              style={{
                width: "100%",
                maxWidth: "320px",
                margin: "20px auto",
              }}
            >
              <li>Acepta pago con tarjeta de crédito/débito</li>
              <li>Acepta plan familiar</li>
            </ul>
          </>
        ) : (
          <>
            <h1 style={{ marginBottom: "1rem", marginTop: "4rem" }}>
              Sin planes disponibles
            </h1>
            <h4 style={{ color: "#b700ff", marginBottom: "4rem" }}>
              {t("NO_PLANS")}
            </h4>
          </>
        )}
        <div
          style={{
            maxWidth: "992px",
            margin: "1rem auto",
            width: "100%",
          }}
        >
          {availablePlans && availablePlans.length > 0 && (
            <div className="grid grid-3 single-column-grid">
              {availablePlans.map((plan, index) => {
                return (
                  <article
                    id={`wrapPlan-${plan.id}`}
                    className={`card ${index === 2 ? "recomended" : ""}`}
                    key={`plan-${index}`}
                    style={{ minWidth: "30%", position: "relative" }}
                  >
                    <header
                      className="card-header"
                      style={{ background: "#DDDDDD" }}
                    >
                      <h3
                        style={{
                          color: "#000000 !important",
                          marginBottom: "5px",
                        }}
                      >
                        {plan.nombre}
                      </h3>
                      <p>
                        <small>Precio total por año</small>
                      </p>
                      <h1
                        style={{
                          color: "#000000",
                          marginBottom: "1rem",
                          marginTop: "1rem",
                        }}
                      >
                        {buyingData.ageRange[0] === 40 ||
                        buyingData.familyPlan === 1 ? (
                          <>
                            <small>{plan.moneda}</small>{" "}
                            {PriceFormat({
                              price: [plan.precio_grupal, markup],
                              operator: "*",
                              expect: "float",
                            })}
                          </>
                        ) : (
                          <>
                            <small>{plan.moneda}</small>{" "}
                            {PriceFormat({
                              price: [plan.precio_grupal, markup],
                              operator: "*",
                              expect: "float",
                            })}
                          </>
                        )}
                      </h1>
                      <div className="flex-center">
                        <button
                          type="button"
                          className="primary mx-auto btn-plan"
                          onClick={() =>
                            onNext(
                              plan.id,
                              buyingData.ageRange[0] === 40 ||
                                buyingData.familyPlan === 1
                                ? PriceFormat({
                                    price: [plan.precio, markup],
                                    operator: "*",
                                    expect: "float",
                                  })
                                : PriceFormat({
                                    price: [plan.precio_adulto_mayor, markup],
                                    operator: "*",
                                    expect: "float",
                                  }),
                              buyingData.ageRange[0] === 40 ||
                                buyingData.familyPlan === 1
                                ? plan.precio_base
                                : plan.precio_base_adulto,
                              plan.moneda,
                              plan.nombre
                            )
                          }
                        >
                          {t("SELECT")}
                        </button>
                      </div>
                    </header>
                    
                    {/* <p className="notice-small">
                      <small>
                        Viajes ilimitados durante un año, hasta 30 días cada
                        uno.
                      </small>
                    </p> */}

                    {/* <ul className="basic-features-plan">
                      <li>
                        {plan.acepta_pago_tdc === "SI"
                          ? "ACEPTA pago con tarjeta de crédito/débito"
                          : "NO acepta pago con tarjeta de crédito/débito"}
                      </li>
                      <li>
                        {plan.acepta_plan_familiar === "SI"
                          ? "ACEPTA plan familiar"
                          : "NO acepta plan familiar"}
                      </li>
                    </ul> */}
                    <footer className="card-footer">
                      <div className="flex-center">
                        <Link
                          to="/buy/step-4"
                          className="btn btn-link"
                          role="link"
                          onClick={(e) => {
                            e.preventDefault();
                            getBenefits(plan.id, plan.nombre);
                            onOpen();
                          }}
                        >
                          {t("SEE_MORE_DETAILS")}
                        </Link>
                      </div>
                    </footer>
                  </article>
                );
              })}
            </div>
          )}

          <div
            className="flex-between desktop-min-width"
            style={{ width: "100%", marginTop: "20px" }}
          >
            <div className="flex-start mobile-full-width">
              <button
                type="button"
                className="secondary outline mobile-full-width"
                onClick={() => {
                  navigate(prevStep);
                }}
              >
                {t("GO_BACK")}
              </button>
            </div>
            {/* <div className="flex-end">
                <button type="submit" onClick={onNext} className="primary">
                  {t("NEXT")}
                </button>
              </div> */}
          </div>
        </div>
      </div>        

      <ModalBenefits
        titulo={titulo}
        data={availableBenefits}
        businessInfo={
          companyData?.role !== "2" && companyData.subdomain !== "myglobal"
            ? companyData
            : null
        }
        handleClose={onClose}
        open={showModal}
      />

       {/* <section style={{ paddingLeft: "1rem" }}>
        <h3 style={{ color: "green" }}>Datos enviados a continental:</h3>
        {dataPrint && dataPrint.length > 0 ? (
          <>
            <pre>{JSON.stringify(dataPrint, null, 2)}</pre>
          </>
        ) : (
          "Sin datos"
        )}
      </section>
      <hr></hr>
      <br></br>
      <section style={{ paddingLeft: "1rem" }}>
        <h3 style={{ color: "red", paddingLeft: "1rem" }}>
          Datos recibidos continental:
        </h3>
        <p style={{ paddingLeft: "1rem" }}>
          Markup a aplicar: {parseFloat(markup - 1).toFixed(2) * 100}%
        </p>
        {availablePlans && availablePlans.length > 0 ? (
          <>
            {availablePlans.map((plan, index) => {
              return <pre key={index}>{JSON.stringify(plan, null, 2)}</pre>;
            })}
          </>
        ) : (
          "Sin respuesta de continental"
        )}
      </section> */}            
    </>
  );
};

export default BuyStep4;
