import { useEffect } from "react";
import HeaderPublic from "../components/HeaderPublic";
import FooterPublic from "../components/FooterPublic";
import MoreBenefits1 from "../components/MoreBenefits1";
import MoreBenefits2 from "../components/MoreBenefits2";
import MoreBenefits3 from "../components/MoreBenefits3";

const MoreBenefits = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className="public more-benefits">
            <HeaderPublic />
            <MoreBenefits1 />
            <MoreBenefits2 />
            <MoreBenefits3 />
            <FooterPublic />
        </div>
    )
}

export default MoreBenefits;