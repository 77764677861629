import { useRef } from "react";
import { CircleStencil, Cropper } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import { useTranslation } from "react-i18next";
import { resizeImage, roundEdges } from "./utils";

const ImageCircleCropperModal = ({
    isOpen,
    imageContent,
    onCrop,
    onCancel,
    titleKey = "IMAGE_CROPPER_MODAL.TITLE",
    confirmTextKey = 'CONFIRM',
}) => {
    const { t } = useTranslation();
    const cropperRef = useRef(null);
    const onCropEvent = () => {
        const cropper = cropperRef.current;
        if (cropper) {
            const canvas = cropper.getCanvas();
            if (canvas) {
                const croppedImage = roundEdges(canvas).toDataURL();
                resizeImage(croppedImage, 32, 32, (resizedImage) => {
                    onCrop(resizedImage);
                });
            }
        }
    };

    return (
        <dialog open={isOpen}>
            <article>
                <header>
                    <a href="#close"
                        onClick={onCancel}
                        aria-label="Close"
                        className="close">
                    </a>
                    <h3>{t(titleKey)}</h3>
                </header>
                <Cropper
                    ref={cropperRef}
                    src={imageContent}
                    stencilComponent={CircleStencil}
                />
                <footer>
                    <a href="#confirm"
                        role="button"
                        className="primary"
                        onClick={onCropEvent}
                    >{t(confirmTextKey)}</a>
                </footer>
            </article>
        </dialog>
    );

}

export default ImageCircleCropperModal;