import { Link } from "react-router-dom";

const HomeContact = () => {
    return (
        <section id="home-contact">
            <div className="container">
                <div className='flex-center wrapper'>
                    <div className='flex-start item'>
                        <div className='icon'>

                        </div>
                        <div className='flex-start content'>
                            <p className="title">Envianos un mail</p>
                            <a href="mailto:info@myglobalassist.com">info@myglobalassist.com</a>
                        </div>
                    </div>
                    <div className='flex-start item'>
                        <div className='icon'>

                        </div>
                        <div className='flex-start content'>
                            <p className="title">¿Cambiaste de opinión?</p>
                            <Link to="mailto:info@myglobalassist.com">Modificalo aquí</Link>
                        </div>
                    </div>
                    <div className='flex-start item'>
                        <div className='icon'>

                        </div>
                        <div className='flex-start content'>
                            <p className="title">¿Quieres cancelar tu Plan?</p>
                            <Link to="mailto:info@myglobalassist.com">Hacelo aquí</Link>
                        </div>
                    </div>    
                </div>  
            </div>          
        </section>
    )
}
export default HomeContact;