import { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useApiHook from "../../../hooks/ApiHook";
import ConfirmationModal from "../../../shared/ConfirmationModal";
import {
  businessGetById,
  businessDeleteById,
} from "../../../services/BusinessService";
import MessageContext from "../../../contexts/MessageContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faCopy } from "@fortawesome/free-solid-svg-icons";
import Header from "../../../shared/Header";
import GoBackLink from "../../../shared/GoBackLink";
import useAuthHook from "../../../hooks/AuthHook";

let business = null;
let isConfirmationDeleteModalOpen = false;

const colorCircle = {
  width: "30px",
  height: "30px",
  marginLeft: "15px",
  borderRadius: "50%",
  borderColor: "#fff",
  boxShadow: "0 0 5px 5px rgba(0,0,0,.15)",
  borderWidth: "2px",
  borderStyle: "solid",
};

const BusinessDetails = () => {
  const { t } = useTranslation();
  const apiCall = useApiHook();
  const { id } = useParams();
  const navigate = useNavigate();
  const [stateVersion, setStateVersion] = useState(0);
  const messageContext = useContext(MessageContext);

  useAuthHook();

  const init = async () => {
    const response = await apiCall({
      fnApiCall: businessGetById,
      params: { id },
    });
    if (response.ok) {
      business = response.data;
    }
    reRender();
  };

  useEffect(() => {
    init();

    return () => {
      business = null;
    };
  }, []);

  const showConfirmationDeleteModal = () => {
    isConfirmationDeleteModalOpen = true;
    reRender();
  };

  const hideConfirmationDeleteModal = () => {
    isConfirmationDeleteModalOpen = false;
    reRender();
  };

  const onDelete = async () => {
    hideConfirmationDeleteModal();
    const response = await apiCall({
      fnApiCall: businessDeleteById,
      params: { id },
    });
    if (response.ok) {
      messageContext.setMessage({
        type: "success",
        text: "BUSINESS_DELETE_SUCCESS",
      });
      return navigate("/admin/business");
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      messageContext.setMessage({
        type: "success",
        text: "BUSINESS.URL_COPIED",
      });
    })
    .catch(error => {
      messageContext.setMessage({
        type: "error",
        text: "BUSINESS.URL_NOT_COPIED",
      });
    });
  };

  const reRender = () => {
    setStateVersion(stateVersion + 1);
  };

  return (
    <div className="business-details container">
      <Header />
      {business && (
        <>
          <nav className="flex-between">
            <GoBackLink to="/admin/business" />
            <div className="flex-end" style={{ rowGap: "var(--spacing)" }}>
              <FontAwesomeIcon
                color="var(--secondary)"
                onClick={() => navigate(`/admin/business/edit/${id}`)}
                icon={faEdit}
                size="lg"
                style={{ cursor: "pointer" }}
              />
              <FontAwesomeIcon
                onClick={showConfirmationDeleteModal}
                icon={faTrash}
                color="var(--danger)"
                size="lg"
                style={{ cursor: "pointer" }}
              />
            </div>
          </nav>
          <div
            className="header"
            style={{
              backgroundImage: `url(${business.headerUrl})`,
            }}
          >
            {business.logoUrl && business.logoUrl.length > 0 && (
              <img
                src={business.logoUrl}
                alt="logo"
                style={{
                  width: "100%",
                  maxWidth: "100px",
                  borderWidth: "2px",
                  borderColor: "var(--primary-inverse)",
                  borderStyle: "solid",
                  boxShadow: "0 0 5px 5px rgba(0,0,0,.15)",
                  borderRadius: "5px",
                }}
              />
            )}
          </div>
          <div className="content">
            <div className="grid">
              <p>
                {business.name && business.name.length > 0 && (
                  <>
                    {t("BUSINESS.NAME")}: <strong>{business.name}</strong>
                  </>
                )}

                {business.subdomain && business.subdomain.length > 0 && (
                  <>
                    <br />
                    {t("BUSINESS.SUBDOMAIN")}:{" "}
                    <strong>{business.subdomain.toLowerCase()}</strong>
                  </>
                )}
                {business.user && business.user.length > 0 && (
                  <>
                    <br />
                    {t("PROFILE.USER")}: <strong>{business.user}</strong>
                  </>
                )}
              </p>
              {business && (
                <p>
                  {business.rut && business.rut.length > 0 && (
                    <>
                      {t("BUSINESS.RUT")}: <strong>{business.rut}</strong>
                    </>
                  )}
                  <br />
                  {business.address && business.address.length > 0 && (
                    <>
                      {t("BUSINESS.ADDRESS")}:{" "}
                      <strong>{business.address}</strong>
                    </>
                  )}
                </p>
              )}
            </div>
            <div className="grid">
              <p>
                {business.paymentType && (
                  <>
                    {t("BUSINESS.PAYMENT_TYPE")}:{" "}
                    <strong>
                      {t(
                        business.paymentType === 2
                          ? "BUSINESS.PAYMENT_TYPE_ONLINE"
                          : "BUSINESS.PAYMENT_TYPE_ACCOUNT"
                      )}
                    </strong>
                  </>
                )}
                <br />
                {business.commission && (
                  <>
                    {t("BUSINESS.COMMISSION")}:{" "}
                    <strong>{business.commission}</strong>
                  </>
                )}
                {business.subdomain && business.subdomain.length > 0 && (
                  <>
                    <br />
                    {t("BUSINESS.URL")}:{" "}
                    <strong>
                      <a
                        href={`https://www.myglobalassist.com/${business.subdomain.toLowerCase()}`} target="_blank" >{`https://www.myglobalassist.com/${business.subdomain.toLowerCase()}`}</a>
                      &nbsp;
                      <FontAwesomeIcon
                        color="var(--secondary)"
                        onClick={() => copyToClipboard(`https://www.myglobalassist.com/${business.subdomain.toLowerCase()}`)}
                        icon={faCopy}
                        size="lg"
                        style={{ cursor: "pointer" }}
                      />
                    </strong>
                  </>
                )}
              </p>
              <div className="grid">
                {business.primaryColor && business.secondaryColor && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <p style={{ margin: 0 }}>{t("BUSINESS.COLOR_THEME")}:</p>
                    <span
                      style={{
                        ...colorCircle,
                        backgroundColor: business.primaryColor,
                      }}
                    ></span>
                    <span
                      style={{
                        ...colorCircle,
                        backgroundColor: business.secondaryColor,
                      }}
                    ></span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <ConfirmationModal
            isOpen={isConfirmationDeleteModalOpen}
            titleKey="BUSINESS_DELETE.TITLE"
            messageKey="BUSINESS_DELETE.MESSAGE"
            onConfirm={onDelete}
            onCancel={hideConfirmationDeleteModal}
          />
        </>
      )}
    </div>
  );
};

export default BusinessDetails;
