import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../../shared/Header";
import useAuthHook from "../../../hooks/AuthHook";
import SalesLiquidation from "../../components/sales/SalesLiquidation";

const Liquidation = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    useAuthHook();

    return (
        <>
            <Header />
            <div className="container">
                <SalesLiquidation />
            </div>
        </>
    );
};

export default Liquidation;