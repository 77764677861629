import { useTranslation } from "react-i18next";
import { useRef, useContext, useState, useEffect } from "react";
import {
  isValidDate,
  compareDate,
  sanitizeInputNumber,
  inRange,
} from "../../../shared/validations/Validation";
import FormatToastMessage from "../../../shared/FormatMessage";
import useApiHook from "../../../hooks/ApiHook";
import { promoCodeAdd } from "../../../services/PromoCodeService";
import Required from "../../../shared/Required";
import MessageContext from "../../../contexts/MessageContext";
import BusinessModalSearch from "../business/BusinessModalSearch";
import RequiredData from "../../../shared/RequiredData";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import PromoCodeServicesModal from "./PromoCodeServicesModal";

let businessList = [];
//let countries = [];
//let selectedCountryId = 0;
//let categories = [];
let isBusinessModalOpen = false;
//let isServicesModalOpen = false;

const PromoCodeAdd = ({ onAdd, navbar = null, goBackButton = null }) => {

  const { t } = useTranslation();
  const apiHook = useApiHook();
  const messageContext = useContext(MessageContext);
  const [stateVersion, setStateVersion] = useState(0);

  const refCode = useRef();
  const refOwnPercentage = useRef();
  const refDateStart = useRef();
  const refUsesNumber = useRef();
  const refCompanyPercentage = useRef();
  const refDateEnd = useRef();

  const init = async () => {
    // const responseData = await apiHook({
    //   fnApiCall: getDestinationCountries,
    //   params: null,
    // });
    // if (responseData.ok) {
    //   countries = responseData.data.data;
    // }
    // reRender();
  };

  // const getCategories = async ({countryId}) => {
  //   selectedCountryId = countryId;
  //   const responseData = await apiHook({
  //     fnApiCall: getCategoriesByCountry,
  //     params: {countryId},
  //   });
  //   if (responseData.ok) {
  //     categories = responseData.data.data;
  //   }
  //   reRender();
  // }

  //const getServices = async ({ categoryId }) => {};

  useEffect(() => {
    init();
  }, []);

  const toggleBusinessModal = () => {
    isBusinessModalOpen = !isBusinessModalOpen;
    reRender();
  };

  /* const toggleServicesModal = () => {
    isServicesModalOpen = !isServicesModalOpen;
    reRender();
  }; */

  const addBusiness = (selectedBusiness) => {
    const currentBusiness = businessList.find(
      (business) => business._id === selectedBusiness._id
    );
    if (currentBusiness) {
      messageContext.setMessage({
        type: "warn",
        text: t("BUSINESS.ALREADY_SELECTED"),
      });
      reRender();
      return;
    }
    toggleBusinessModal();
    businessList.push(selectedBusiness);
    reRender();
  };

  const removeBusiness = (businessId) => {
    businessList = businessList.filter(
      (business) => business._id !== businessId
    );
    reRender();
  };

  const onClickAdd = async () => {
    if (!refCode.current.value || refCode.current.value.trim() === "")
      return messageContext.setMessage({
        type: "error",
        text: FormatToastMessage(t("PROMO_CODE.ERROR.TITLE_ERRORS"), [
          t("PROMO_CODE.ERROR.CODE_REQUIRED"),
        ]),
      });

    if (refUsesNumber.current.value.trim() !== "") {
      if (
        !sanitizeInputNumber(refUsesNumber.current.value) ||
        !inRange({ min: { min: 1, value: refUsesNumber.current.value } })
      )
        return messageContext.setMessage({
          type: "error",
          text: FormatToastMessage(t("PROMO_CODE.ERROR.TITLE_ERRORS"), [
            t("PROMO_CODE.ERROR.USES_NUMBER_INVALID"),
          ]),
        });
    }
    if (refDateStart.current.value.trim() !== "") {
      if (!isValidDate(refDateStart.current.value)) {
        return messageContext.setMessage({
          type: "error",
          text: FormatToastMessage(t("PROMO_CODE.ERROR.TITLE_ERRORS"), [
            t("PROMO_CODE.ERROR.DATE_START_INVALID"),
          ]),
        });
      }
    }
    if (refDateEnd.current.value.trim() !== "") {
      if (!isValidDate(refDateStart.current.value)) {
        return messageContext.setMessage({
          type: "error",
          text: FormatToastMessage(t("PROMO_CODE.ERROR.TITLE_ERRORS"), [
            t("PROMO_CODE.ERROR.DATE_END_INVALID"),
          ]),
        });
      }
    }
    if (
      refDateStart.current.value.trim() !== "" &&
      refDateEnd.current.value.trim() !== ""
    ) {
      if (
        !compareDate({
          before: refDateStart.current.value,
          after: refDateEnd.current.value,
          operator: "<=",
        })
      ) {
        return messageContext.setMessage({
          type: "error",
          text: FormatToastMessage(t("PROMO_CODE.ERROR.TITLE_ERRORS"), [
            t("PROMO_CODE.ERROR.DATE_START_BEFORE_DATE_END"),
          ]),
        });
      }
    }

    if (refOwnPercentage.current.value.trim() !== "") {
      if (
        inRange({
          range: { value: refOwnPercentage.current.value, min: 0, max: 100 },
        }) !== true
      ) {
        if (
          inRange({ range: { value: refOwnPercentage.current.value } }) ===
            "NaN" ||
          !sanitizeInputNumber(refOwnPercentage.current.value) ||
          !inRange({ min: { min: 1, value: refOwnPercentage.current.value } })
        ) {
          return messageContext.setMessage({
            type: "error",
            text: FormatToastMessage(t("PROMO_CODE.ERROR.TITLE_ERRORS"), [
              t("PROMO_CODE.ERROR.PERCENTAGE_INVALID"),
            ]),
          });
        }
      }
    } else if (
      !inRange({
        range: { value: refOwnPercentage.current.value, min: 0, max: 100 },
      })
    ) {
      return messageContext.setMessage({
        type: "error",
        text: FormatToastMessage(t("PROMO_CODE.ERROR.TITLE_ERRORS"), [
          t("PROMO_CODE.ERROR.PERCENTAGE_INVALID"),
        ]),
      });
    }

    if (refCompanyPercentage.current.value.trim() !== "") {
      if (
        inRange({
          range: {
            value: refCompanyPercentage.current.value,
            min: 0,
            max: 100,
          },
        }) !== true
      ) {
        if (
          inRange({ range: { value: refCompanyPercentage.current.value } }) ===
            "NaN" ||
          !sanitizeInputNumber(refCompanyPercentage.current.value) ||
          !inRange({
            min: { min: 0, value: refCompanyPercentage.current.value },
          })
        ) {
          return messageContext.setMessage({
            type: "error",
            text: FormatToastMessage(t("PROMO_CODE.ERROR.TITLE_ERRORS"), [
              t("PROMO_CODE.ERROR.PERCENTAGE_INVALID"),
            ]),
          });
        }
      }
    } else if (
      !inRange({
        range: {
          value: refCompanyPercentage.current.value,
          min: 0,
          max: 100,
        },
      })
    ) {
      return messageContext.setMessage({
        type: "error",
        text: FormatToastMessage(t("PROMO_CODE.ERROR.TITLE_ERRORS"), [
          t("PROMO_CODE.ERROR.PERCENTAGE_INVALID"),
        ]),
      });
    }
    if (
      parseInt(refCompanyPercentage.current.value) === 0 &&
      parseInt(refOwnPercentage.current.value) === 0
    ) {
      return messageContext.setMessage({
        type: "error",
        text: FormatToastMessage(t("PROMO_CODE.ERROR.TITLE_ERRORS"), [
          t("PROMO_CODE_PERCENTAGE_REQUIRED"),
        ]),
      });
    }

    const businessIdList = [];
    businessList.forEach((business) => {
      businessIdList.push(business._id);
    });

    const promoCode = {
      code: refCode.current.value.toUpperCase(),
      isPaused: false,
      businessList: businessIdList,
      usesNumber:
        refUsesNumber.current.value !== "" ? refUsesNumber.current.value : null,
      dateStart:
        refDateStart.current.value !== "" ? refDateStart.current.value : null,
      dateEnd:
        refDateEnd.current.value !== "" ? refDateEnd.current.value : null,
      ownPercentage:
        refOwnPercentage.current.value !== ""
          ? refOwnPercentage.current.value
          : null,
      companyPercentage:
        refCompanyPercentage.current.value !== ""
          ? refCompanyPercentage.current.value
          : null,
      // hardcoded hasta que se pueda consumir API
      servicesTypesIds: [1, 2, 3],
    };    
    const responseData = await apiHook({
      fnApiCall: promoCodeAdd,
      params: { data: promoCode },
    });

    if (responseData.ok) {
      businessList = [];
      messageContext.setMessage({
        type: "success",
        text: responseData.data.message,
      });
      onAdd({ promoCodeId: responseData.data.data });
    }
  };

  const reRender = () => {
    setStateVersion(stateVersion + 1);
  };

  return (
    <>
      <nav className="flex-start">
        {goBackButton && goBackButton}
        <h1
          style={{
            margin: 0,
            marginLeft: "var(--spacing)",
          }}
        >
          {t("PROMO_CODE.ADD")}
        </h1>
      </nav>
      <div className="grid">
        <label>
          {t("PROMO_CODE.CODE")}
          <Required />
          <input type="text" placeholder={t("PROMO_CODE.CODE")} ref={refCode} />
        </label>
      </div>
      <div className="grid">
        <label>
          {t("START_DATE")}
          <input type="date" ref={refDateStart} />
        </label>
        <label>
          {t("END_DATE")}
          <input type="date" ref={refDateEnd} />
        </label>
      </div>
      <div className="grid">
        <label>
          {t("PROMO_CODE.USE_NUMBER")}
          <input
            type="number"
            placeholder={t("PROMO_CODE.USE_NUMBER")}
            ref={refUsesNumber}
          />
        </label>
        <label>
          {t("PROMO_CODE.COMPANY_PERCENTAGE")}
          <input
            type="number"
            placeholder={t("PROMO_CODE.COMPANY_PERCENTAGE")}
            ref={refCompanyPercentage}
          />
        </label>
        <label>
          {t("PROMO_CODE.OWN_PERCENTAGE")}
          <input
            type="number"
            placeholder={t("PROMO_CODE.OWN_PERCENTAGE")}
            ref={refOwnPercentage}
          />
        </label>
      </div>
      <div className="grid">
        <fieldset>
          <label style={{ marginBottom: "20px" }}>
            {t("BUSINESS.LIST")}
            <Required />
          </label>
          <div style={{ maxWidth: "350px" }}>
            <table role="grid">
              <tbody>
                {businessList.length > 0 && (
                  <>
                    {businessList.map((business, index) => {
                      return (
                        <tr key={`business_${index}`} className="hover-row">
                          <td>{business.name}</td>
                          <td style={{ width: "100px" }}>
                            <FontAwesomeIcon
                              style={{
                                marginLeft: "auto",
                                marginRight: "0",
                              }}
                              className="remove-item"
                              color="var(--danger)"
                              icon={faTrash}
                              onClick={() => removeBusiness(business._id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td style={{ border: "none", textAlign: "left" }}>
                    <button
                      className="secondary"
                      style={{
                        margin: 0,
                        width: "fit-content",
                        fontSize: "calc(var(--font-size) * 0.8)",
                        paddingRight: "calc(var(--spacing)*1.2)",
                        paddingLeft: "calc(var(--spacing)*1.2)",
                        paddingBottom: "calc(var(--spacing)*0.2)",
                        paddingTop: "calc(var(--spacing)*0.2)",
                      }}
                      onClick={toggleBusinessModal}
                    >
                      {t("ADD")}
                    </button>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </fieldset>
      </div>
      <div className="grid" style={{ marginTop: "50px" }}>
        <fieldset>
          <RequiredData />
        </fieldset>
        <fieldset className="flex-end form-end-buttons">
          <button type="submit" onClick={onClickAdd}>
            {t("SAVE")}
          </button>
        </fieldset>
      </div>
      <BusinessModalSearch
        isOpen={isBusinessModalOpen}
        onClose={toggleBusinessModal}
        onSelect={addBusiness}
      />
    </>
  );
};

export default PromoCodeAdd;

/* const addBtnStyle = {
  margin: 0,
  width: "fit-content",
  fontSize: "calc(var(--font-size) * 0.8)",
  paddingRight: "calc(var(--spacing)*1.2)",
  paddingLeft: "calc(var(--spacing)*1.2)",
  paddingBottom: "calc(var(--spacing)*0.2)",
  paddingTop: "calc(var(--spacing)*0.2)",
}; */
