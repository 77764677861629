import { useEffect, useState, useRef } from "react";
import useApiHook from "../../hooks/ApiHook";
import { salesFindList } from "../../services/SalesService";
import { findBySaleId as findBeneficiariesBySaleId } from "../../services/BeneficiaryService";
import { businessGetById } from "../../services/BusinessService";
import { useTranslation } from "react-i18next";
import OrderTableHeader from "../../shared/OrderTableHeader";
import ExportSheet from "../../shared/ExportSheet";
import { formatDate } from "../../services/DateService";
import { getCookie } from "../../shared/CookiesManager";
import PriceFormat from "../../shared/PriceFormat";
import { useNavigate } from "react-router-dom";
import SaleDetails from "../../admin/components/sales/SaleDetails";
import BuyingProcessService from "../../services/BuyingProcessService";

const limit = 100;
let items = [];
let itemsToShow = [];
let orderDirection = "asc";
let orderBy = "code";
let singleItem = null;
let showDetailsModal = false;
let saleVoucherCodes = [];

const SalesSearch = ({ onSelect, navbar = null }) => {
  const formRef = useRef();
  const [stateVersion, setStateVersion] = useState(1);
  const { t } = useTranslation();
  const apiHook = useApiHook();
  const navigate = useNavigate();
  const userId = getCookie({ name: "_UI" });
  const role = getCookie({ name: "_RL" });
  const businessType = getCookie({ name: "_BT" });
  const [buyUrl, setBuyUrl] = useState("/buy/step-1");
  const date = new Date();
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  const setItems = (data) => {
    items = data;
  };

  const reRender = () => {
    setStateVersion((stateVersion) => stateVersion + 1);
  };

  const getDataForExport = () => {
    const headers = [
      t("SALES.TABLE_HEADER.CODE"),
      t("SALES.TABLE_HEADER.PLAN"),
      t("SALES.TABLE_HEADER.AMOUNT"),
      t("SALES.TABLE_HEADER.PASSENGERS_NUMBER"),
      t("SALES.TABLE_HEADER.HOLDER"),
      t("SALES.TABLE_HEADER.DATE_OF_PURCHASE"),
    ];    
    /* const data = itemsToShow.map((item) => {
      return [...Object.values(item)];
    }); */
    const data = itemsToShow.map((item) => {
      return [
        item.code,
        item.planName,
        item.planPrice,
        item.passengers,
        item.holder,        
        item.purchaseDate,
      ];
    });   
    
    return [headers, ...data];
  };

  const findList = async () => {
    const responseData = await apiHook({
      fnApiCall: salesFindList,
      params: null,
    });
    let salesData = responseData?.data?.data ?? null;
    if (role === "2" || role === "1")
      salesData = salesData?.filter((item) => item?.refBusiness === userId);
    else salesData = salesData?.filter((item) => item.refBusiness === null);

    if (!salesData) return;

    // TODO: filtrar por broker y compañía
    const sales = salesData.map((item, index) => {
      return {
        id: item._id,
        code: `#000${index + 1}`,
        planName: item.sale.planName,
        businessName: item.businessName,
        planPrice: `${item.sale.planCurrency} ${PriceFormat({
          price: item.sale.planPrice,
        })}`,
        passengers: item.sale.passengers,
        holder: `${item.sale.passengersData[0].name} ${item.sale.passengersData[0].lastName}`,
        purchaseDate: formatDate(item.created),
        planPriceValue: PriceFormat({
          price: item.sale.planPrice,
          expect: "float",
        }),
        purchaseDateValue: parseInt(
          item.created.split("T")[0].replace(/-/g, "")
        ),
        passengersDocsNumbers: item.sale.passengersData.map((passenger) => {
          return passenger.docNumber;
        }),
        sale: item.sale,
      };
    });
    setItems(sales);
    filterItemsToShow();
   reRender();
  };

  useEffect(() => {
    findList();
  }, []);

  const onClickRow = async (item) => {
    const response = await apiHook({
      fnApiCall: findBeneficiariesBySaleId,
      params: {
        saleId: item.id,
      },
    });
    if (!response) return;
    saleVoucherCodes = response?.data?.data ?? [];
    singleItem = { ...item };
    showDetailsModal = true;
    reRender();
  };

  const setOrder = ({ newOrderBy, newOrderDirection }) => {
    orderBy = newOrderBy;
    orderDirection = newOrderDirection;
    filterItemsToShow();
  };

  const onSearch = () => {
    filterItemsToShow();
  };

  const filterItemsToShow = () => {
    if (items == null || items.length === 0 || formRef.current == null) {
      itemsToShow = [];
      reRender();
      return;
    }
    const formData = new FormData(formRef.current);
    let tempItems = items;
    
    if (formData.get("plan").trim().length > 0) {
      tempItems = tempItems.filter((item) =>
        item.planName
          .toUpperCase()
          .includes(formData.get("plan").trim().toUpperCase())
      );
    }

    if (formData.get("startDate").length > 0) { 
      
      tempItems = tempItems.filter(
        (item) =>{
          let date = item.purchaseDate.split('/').reverse().join('-');
          return new Date(date).getTime() >=
          new Date(formData.get("startDate").trim()).getTime();
        }
      );
    }

    if (formData.get("endDate").length > 0) {
      tempItems = tempItems.filter(
        (item) =>{
          let date = item.purchaseDate.split('/').reverse().join('-');
          return new Date(date).getTime() <=
          new Date(formData.get("endDate").trim()).getTime()
        }
      );
    }

    if (formData.get("docnumber").trim().length > 0) {
      if (tempItems.length > 0) {
        tempItems = tempItems.filter(
          (item) =>
            item.passengersDocsNumbers.filter((doc) =>
              doc
                .trim()
                .toUpperCase()
                .includes(formData.get("docnumber").trim().toUpperCase())
            ).length > 0
        );
      }
    }

    if (!tempItems || tempItems.length === 0) {
      itemsToShow = [];
      reRender();
      return;
    }

    if(orderBy !== "purchaseDate" && orderBy !== "code"){
    itemsToShow = tempItems
      .sort((a, b) => {
        const sortDirection = orderDirection === "asc" ? 1 : -1;
        if (a[orderBy] === b[orderBy]) {
          return 0;
        } else if (a[orderBy] > b[orderBy]) {
          return 1 * sortDirection;
        } else {
          return -1 * sortDirection;
        }
      })
      .slice(0, limit);
    } else if(orderBy === "purchaseDate"){
      itemsToShow = tempItems
      .sort((a, b) => {
        const sortDirection = orderDirection === "asc" ? 1 : -1;
        if (a.purchaseDateValue === b.purchaseDateValue) {
          return 0;
        } else if (a.purchaseDateValue > b.purchaseDateValue) {
          return 1 * sortDirection;
        } else {
          return -1 * sortDirection;
        }
      })
      .slice(0, limit);
    } else {
      itemsToShow = tempItems
      .sort((a, b) => {        
        const sortDirection = orderDirection === "asc" ? 1 : -1;
        const first = parseInt(a.code.replace('#000','')); 
        const second = parseInt(b.code.replace('#000',''));                       
        if (first === second) {
          return 0;
        } else if (first > second) {
          return 1 * sortDirection;
        } else {
          return -1 * sortDirection;
        }
      })
      .slice(0, limit);
    }    
    reRender();
  };

  const resetForm = () => {
    formRef?.current?.reset();
    filterItemsToShow();
  };

  const toBuy = async () => {
    const response = await apiHook({
      fnApiCall: businessGetById,
      params: { id: userId },
    });
    if (response.ok) {
      const buyingProcessService = BuyingProcessService.getInstance();
      buyingProcessService.businessInfo = response.data;
      buyingProcessService.businessInfo.role = role;
      if (role === "1") {
        setBuyUrl(`/${response.data?.subdomain}/buy/step-1`);
      }
      navigate(buyUrl);
    }
    return false;
  };

  return (
    <>
      {stateVersion > 0 && (
        <>
          <nav className="flex-between menu-navbar">
            <h1
              className="flex-start"
              style={{ alignItems: "center", margin: 0 }}
            >
              {t("PROFILE.SALES")}&nbsp;
              {businessType?.toUpperCase() === "BROKER" && (
                <button
                  className="btn btn-primary"
                  style={{ margin: "auto", width: "auto" }}
                  onClick={toBuy}
                >
                  {t("BUYING_PROCESS.BUYING_PAX")}
                </button>
              )}
              <button
                className="btn secondary"
                style={{ margin: "auto", width: "auto" }}
                onClick={()=>{
                  navigate("/profile/sales/");
                }}
              >
                {t("SALES.LIQUIDATIONS")}
              </button>
            </h1>
            <ExportSheet
              getData={getDataForExport}
              fileName={t("PROFILE.SALES")}
              style={{ marginTop: "10px" }}
            />
          </nav>
          <details className="filters">
            <summary>{t("FILTERS")}</summary>
            <form
              ref={formRef}
              name="search"
              className="menu-navbar filters"
              onSubmit={(ev) => {
                ev.preventDefault();
              }}
            >
              <div className="mobile-full-width">
                <label htmlFor="plan">{t("SALES.TABLE_HEADER.PLAN")}</label>
                <input
                  type="search"
                  id="plan"
                  style={{ margin: 0 }}
                  name="plan"
                  onChange={onSearch}
                  placeholder={t("SALES.TABLE_HEADER.PLAN")}
                />
              </div>
              <label className="mobile-full-width">
                {t("START_DATE")}
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  onChange={onSearch}
                  defaultValue={`${year}-${month}-01`}
                />
              </label>
              <label className="mobile-full-width">
                {t("END_DATE")}
                <input
                  type="date"
                  id="endDate"
                  name="endDate"
                  onChange={onSearch}
                  defaultValue={`${year}-${month}-${day}`}
                />
              </label>
              <div className="mobile-full-width">
                <label htmlFor="plan">
                  {t("SALES.TABLE_HEADER.DOCNUMBER")}
                </label>
                <input
                  type="search"
                  id="docnumber"
                  style={{ margin: 0 }}
                  name="docnumber"
                  onChange={onSearch}
                  placeholder={t("SALES.TABLE_HEADER.DOCNUMBER")}
                />
              </div>
              <div className="flex-end full-width">
                <button
                  type="button"
                  className="secondary mobile-full-width"
                  onClick={resetForm}
                >
                  {t("CLEAN")}
                </button>
              </div>
            </form>
          </details>
          {navbar && navbar}
          {itemsToShow.length > 0 ? (
            <figure>
              <table role="grid">
                <thead>
                  <tr>
                    <OrderTableHeader
                      titleKey={"SALES.TABLE_HEADER.CODE"}
                      orderByKey={"code"}
                      onSetOrder={setOrder}
                      orderBy={orderBy}
                      orderDirection={orderDirection}
                    />
                    <OrderTableHeader
                      titleKey={"SALES.TABLE_HEADER.PLAN"}
                      orderByKey={"planName"}
                      onSetOrder={setOrder}
                      orderBy={orderBy}
                      orderDirection={orderDirection}
                    />
                    <OrderTableHeader
                      titleKey={"SALES.TABLE_HEADER.AMOUNT"}
                      orderByKey={"planPrice"}
                      onSetOrder={setOrder}
                      orderBy={orderBy}
                      orderDirection={orderDirection}
                    />
                    <OrderTableHeader
                      titleKey={"SALES.TABLE_HEADER.PASSENGERS_NUMBER"}
                      orderByKey={"passengers"}
                      onSetOrder={setOrder}
                      orderBy={orderBy}
                      orderDirection={orderDirection}
                    />
                    <OrderTableHeader
                      titleKey={"SALES.TABLE_HEADER.HOLDER"}
                      orderByKey={"holder"}
                      onSetOrder={setOrder}
                      orderBy={orderBy}
                      orderDirection={orderDirection}
                    />
                    <OrderTableHeader
                      titleKey={"SALES.TABLE_HEADER.DATE_OF_PURCHASE"}
                      orderByKey={"purchaseDate"}
                      onSetOrder={setOrder}
                      orderBy={orderBy}
                      orderDirection={orderDirection}
                    />
                  </tr>
                </thead>
                <tbody>
                  {itemsToShow.map((item, index) => {
                    return (
                      <tr key={index} style={{ cursor: "pointer" }}>
                        <td onClick={() => onClickRow(item)}>{item.code}</td>
                        <td onClick={() => onClickRow(item)}>
                          {item.planName}
                        </td>
                        <td onClick={() => onClickRow(item)}>
                          {item.planPrice}
                        </td>
                        <td onClick={() => onClickRow(item)}>
                          {item.passengers}
                        </td>
                        <td onClick={() => onClickRow(item)}>{item.holder}</td>
                        <td onClick={() => onClickRow(item)}>
                          {item.purchaseDate}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </figure>
          ) : (
            <p>{t("SALES.NO_RESULTS")}</p>
          )}
          {singleItem && (
            <SaleDetails
              sale={singleItem}
              saleVoucherCodes={saleVoucherCodes}
              showDetailsModal={showDetailsModal}
              onClose={() => {
                showDetailsModal = false;
                singleItem = null;
                reRender();
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default SalesSearch;
