import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const salesFindList = ({headers}) => { 
    return axios.get(`${API_URL}/sale/findList`, { headers });    
}

export const createSale = ({data, headers}) => {
    return axios.post(`${API_URL}/sale/createSale`, data, { headers });
};

export const findDetails = ({data, headers}) => {
    return axios.get(`${API_URL}/sale/findDetails/${data.id}`, { headers });
}

export const paidSales = ({data, headers}) => {
    return axios.post(`${API_URL}/sale/paidSales`, data, { headers });
};

export const saleQuotePDF = ({data}) => {           
    return axios.post(`${API_URL}/sale/quotePDF`, data, { "Content-Type": "application/json", responseType: 'blob'  });
};