import axios from 'axios';
import { getCookie } from '../shared/CookiesManager';

const API_URL = process.env.REACT_APP_API_URL;

export const loginService = ({data}) => {
    return axios.post(`${API_URL}/user/login`, data);
};
export const logoutService = ({data}) => {
    const headers = {
        'Authorization': `Bearer ${getCookie({ name: 'SSID' })}`
    };
    return axios.post(`${API_URL}/user/logout`, data, { headers });
};

export const forgotService = ({data}) => {
    return axios.post(`${API_URL}/user/forgot`, data);
}