import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useApiHook from "../hooks/ApiHook";
import { getResidenceCountries } from "../services/CountryService";
import Required from "./Required";
import BuyingProcessService from "../services/BuyingProcessService";

let countries = [];

const ResidenceCountrySelect = ({
  currentCountryId,
  selectedCountryId,
  onSelectCountry,
  isRequired = true,
  labelKey = "COUNTRY.RESIDENCE",
}) => {    
  const buyingData = BuyingProcessService.getInstance().buyingData;
  const [stateVersion, setStateVersion] = useState(1);
  const { t } = useTranslation();
  const apiCall = useApiHook();

  const reRender = () => {
    const currentVersion = stateVersion;
    if (stateVersion === currentVersion)
      setStateVersion((stateVersion) => stateVersion + 1);
  };

  const paisesInfo = [
    {
      pais: "Argentina",
      ciudad: "Buenos Aires",
      iso: "AR",
    },
    {
      pais: "Bolivia",
      ciudad: "La Paz",
      iso: "BO",
    },
    {
      pais: "Brasil",
      ciudad: "Sao Paulo",
      iso: "BR",
    },
    {
      pais: "Chile",
      ciudad: "Santiago",
      iso: "CL",
    },
    {
      pais: "Colombia",
      ciudad: "Bogotá",
      iso: "CO",
    },
    {
      pais: "Costa Rica",
      ciudad: "San Jose",
      iso: "CR",
    },
    {
      pais: "Dominican Republic",
      ciudad: "Santo Domingo",
      iso: "DO",
    },
    {
      pais: "Ecuador",
      ciudad: "Quito",
      iso: "EC",
    },
    {
      pais: "El Salvador",
      ciudad: "San Salvador",
      iso: "SV",
    },
    {
      pais: "España",
      ciudad: "Madrid",
      iso: "ES",
    },
    {
      pais: "Guatemala",
      ciudad: "Guatemala City",
      iso: "GT",
    },
    {
      pais: "Honduras",
      ciudad: "Tegucigalpa",
      iso: "HN",
    },
    {
      pais: "México",
      ciudad: "Mexico City",
      iso: "MX",
    },
    {
      pais: "Nicaragua",
      ciudad: "Managua",
      iso: "NI",
    },
    {
      pais: "Panama",
      ciudad: "Panama City",
      iso: "PA",
    },
    {
      pais: "Paraguay",
      ciudad: "Asuncion",
      iso: "PY",
    },
    {
      pais: "Peru",
      ciudad: "Lima",
      iso: "PE",
    },
    {
      pais: "Uruguay",
      ciudad: "Montevideo",
      iso: "UY",
    },
    {
      pais: "Venezuela",
      ciudad: "Caracas",
      iso: "VE",
    },
  ];
  let userCountryISO = null;
  if (!currentCountryId) {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    for (const paisInfo of paisesInfo) {
      if (userTimezone.toLowerCase().includes(paisInfo.ciudad.toLowerCase())) {
        userCountryISO = paisInfo.iso;
        break;
      }
    }
  } else {
    userCountryISO = currentCountryId.split("-")[1];
  }
  const isoCheck = (name) => {
    switch (name) {
      case "Dominican Republic":
      case "República Dominicana":
        return "DO";
      case "Panama":
      case "Panamá":
        return "PA";
      case "México":
      case "Mexico":
        return "MX";
      case "Peru":
      case "Perú":
        return "PE";
      default:
        return "NULL";
    }
  };

  const init = async () => {
    const response = await apiCall({
      fnApiCall: getResidenceCountries,
      params: {},
    });
    if (response.ok) {
      countries = response.data.data;
      if (countries.length > 0) {
        const correctedCountries = countries.map((country) => {
          return {
            ...country,
            iso:
              country.iso && country.iso !== "--"
                ? country.iso
                : isoCheck(country.name),
          };
        });
        const foundCountry = correctedCountries.find(
          (country) => country.iso === userCountryISO
        );
        if (foundCountry) {
          // Si el país detectado está en la respuesta, selecciónalo automáticamente
          onSelectCountry(
            `${foundCountry.id}-${foundCountry.iso}-${foundCountry.name}`
          );
          buyingData.residenceCountryId = `${foundCountry.id}-${foundCountry.iso}-${foundCountry.name}`;
        } else {
          // Si el país detectado no está en la respuesta, selecciona Argentina como predeterminado
          const defaultCountry = countries.find(
            (country) => country.name === "Argentina"
          );
          if (defaultCountry) {
            onSelectCountry(
              `${defaultCountry.id}-${defaultCountry.iso}-${defaultCountry.name}`
            );
            buyingData.residenceCountryId = `${defaultCountry.id}-${defaultCountry.iso}-${defaultCountry.name}`;
          } else {
            // En caso de que Argentina no esté en la respuesta, selecciona el primer país
            const firstCountry = countries[0];
            onSelectCountry(
              `${firstCountry.id}-${firstCountry.iso}-${firstCountry.name}`
            );
            buyingData.residenceCountryId = `${firstCountry.id}-${firstCountry.iso}-${firstCountry.name}`;
          }
        }
        reRender();
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {countries && countries.length > 0 && (
        <>
          <div className="container" style={{ width: "100%", padding: 0 }}>
            <label htmlFor="country" style={{ marginLeft: "0" }}>
              {t(labelKey)}
              {isRequired && <Required />}
            </label>
            <select
              defaultValue={selectedCountryId ? selectedCountryId : "17-UY-Uruguay"}
              onChange={(e) => {
                onSelectCountry(e.target.value);
              }}
              required
              id="country"
            >
              {countries.map((country) => {
                return (
                  <option
                    value={`${country.id}-${
                      country.iso && country.iso !== "--"
                        ? country.iso
                        : isoCheck(country.name)
                    }-${country.name}`}
                    key={`country_${country.id}`}
                  >
                    {country.name}
                  </option>
                );
              })}
            </select>
          </div>
        </>
      )}
    </>
  );
};

export default ResidenceCountrySelect;
