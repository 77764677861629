import Header from '../../shared/Header';
import SalesSearch from '../components/SalesSearch';
import useAuthHook from '../../hooks/AuthHook';

const DashboardProfile = () => {
   
    useAuthHook(2);

    return (
        <><Header />
            <div className="container">
                <SalesSearch onSelect={() => {}} />
            </div>
        </>
        
    )
}

export default DashboardProfile;