export const SouthAmericaIcon = ({ cls }) => {
  return (
    <svg
      version={"1.1"}
      id={"Capa_1"}
      xmlns={"http://www.w3.org/2000/svg"}
      xmlnsXlink={"http://www.w3.org/1999/xlink"}
      x={"0px"}
      y={"0px"}
      viewBox={"0 0 1080 1080"}
      style={{
        enableBackground: "new 0 0 1080 1080",
        height: "4rem",
      }}
      /* xmlSpace={"preserve"} */
      className={cls}
    >
      <path
        d="M633.37,147.7c0,0,25.97-30.47,86.28-30.47s96.14,44.92,96.14,44.92s32.26,16.68,32.26,86.52
	s-60.49,96.98-91.98,133.9S703.89,544.34,682.75,540c-21.14-4.34-18.02-106.38-68.44-129.74c-50.42-23.36-81.91-81.99-82.99-139.54
	C530.23,213.18,633.37,147.7,633.37,147.7z"
        fill={"#E8E8E8"}
      />
      <g>
        <path
          className={cls}
          d="M173.56,24.76c14.34,6.31,29.07,11.88,42.93,19.12c16.71,8.73,29.74,21.74,37.54,39.26
		c5.72,12.86,14.78,19.65,29.38,20.35c18.35,0.88,26.3,8.12,31.01,25.97c4.1,15.53,7.01,31.4,11.82,46.69
		c5.52,17.57,10.56,19.12,28.89,18.21c16.24-0.81,32.94-0.98,48.84,1.87c20.76,3.72,34.11,17.08,38.48,38.75
		c1.9,9.43,5.27,18.64,8.75,27.65c2.79,7.22,8.71,10.11,16.33,7.62c18.76-6.14,33.02,0.66,43.5,15.96
		c5.66,8.27,10.82,7.24,17.15,1.87c5.37-4.55,10.79-9.05,15.92-13.86c1.53-1.43,3.03-3.81,3.06-5.78
		c0.25-16.02,0.14-32.04,0.14-48.61c-11.55-3.51-23.2-7.5-35.09-10.55c-11.61-2.98-21.11-8.77-27.52-18.78
		c-8.77-13.68-20.21-20.59-37.08-21.6c-44.18-2.64-57.02-35.96-54.92-68.32c1.23-18.98,17.68-30.03,35.9-24.44
		c10.35,3.18,20.71,8.3,29.3,14.85c23.24,17.7,49.14,25.26,77.93,24.42c12.4-0.36,24.79-0.78,37.19-0.88
		c5.01-0.04,10.88-0.84,14.86,1.38c11.81,6.6,18.98,0.19,27.76-6.48c99.67-75.67,247.55-20.62,273.6,101.61
		c0.49,2.29,0.92,4.61,1.19,6.94c1.01,8.71-3.54,15.65-11.42,17.55c-7.55,1.82-15.1-2.77-17.64-11.13
		c-2.82-9.28-4.67-18.89-8.02-27.96c-22.18-59.93-85.7-98.38-149.34-90.72c-25.04,3.02-47.99,12.05-67.3,25.59
		c-32.19,22.57-54.29,57.65-59.14,98.24c-7.36,61.6,21.32,117.09,76.11,146.42c10.15,5.44,17.65,12.76,23.18,22.82
		c13.72,24.92,27.89,49.58,42.47,75.38c2.09-3.15,3.77-5.39,5.14-7.8c12.86-22.74,25.04-45.91,38.83-68.08
		c5.23-8.4,13.02-16.54,21.58-21.32c35.37-19.73,59.69-48.02,72.18-86.56c3.68-11.34,10.98-16.24,19.71-13.55
		c9.15,2.82,12.51,11.31,8.79,23.12c-10.87,34.58-30.6,63.07-59.04,85.53c-3.15,2.48-6.32,4.93-9.46,7.42
		c-0.24,0.19-0.34,0.57-0.73,1.24c6.62,13.85,18.27,21.22,32.86,25.07c17.46,4.6,35.23,8.45,52.16,14.55
		c38.68,13.95,51.66,38.94,38.97,78.07c-4.92,15.17-14.34,29.35-23.79,42.48c-13.86,19.26-31.44,36.02-43.96,56.03
		c-10.28,16.44-15.65,35.99-22.89,54.28c-3.69,9.31-6.16,19.13-10.17,28.28c-3.93,8.97-11.95,11.97-19.66,8.65
		c-8.31-3.57-10.96-11.39-7.57-21.08c3.53-10.09,7.25-20.16,9.98-30.49c9.75-36.88,29.29-68.04,54.67-95.98
		c8.11-8.93,15.98-18.1,23.48-27.54c8.55-10.78,12.72-23.33,13.85-37.09c0.88-10.73-2.86-18.88-12.81-22.56
		c-15.33-5.67-30.79-11.42-46.68-15.02c-26.04-5.9-49.32-15.29-64.07-41.35c-9.1,16.09-17.4,30.74-25.67,45.4
		c-7.42,13.13-14.67,26.34-22.26,39.37c-11.93,20.5-34.4,20.49-46.22-0.12c-15.5-27.02-30.57-54.29-46.12-81.29
		c-2.23-3.87-5.45-7.99-9.27-10.01c-39.87-21.14-68.19-52.41-84.38-94.61c-0.35-0.91-1.02-1.7-1.85-3.03
		c-5.82,3.91-11.32,8.16-17.31,11.54c-18.08,10.19-33.7,7.38-46.97-9.46c-5.84-7.4-10.82-10.25-20.84-8.04
		c-19.14,4.22-35.07-3.84-44.2-21.78c-5.4-10.61-8.81-22.36-12.07-33.9c-3.95-13.97-8.69-19.57-23.26-20.49
		c-12.29-0.78-24.79-0.45-37.03,0.9c-21.55,2.38-39.12-4.34-48.47-24.2c-7.59-16.13-12.13-33.91-16.24-51.38
		c-2.48-10.51-5.23-16.89-17.66-17.58c-19.1-1.06-32.8-12.7-40.35-29.31c-12.75-28.08-37.07-38.9-63.64-47.46
		c-0.61,0.98-1.33,1.62-1.23,2.06c11,48.24,24.46,95.11,57.92,133.99c31.94,37.12,72.47,57.3,119.36,67.14
		c12.09,2.54,24.08,5.79,35.87,9.47c11.68,3.64,20.16,10.98,25.23,22.75c2.04,4.73,7.04,8.9,11.69,11.63
		c7.17,4.2,15.39,6.57,22.81,10.39c8.9,4.58,18.1,8.99,26,15.01c28.63,21.8,34.12,44.92,19.15,77.63
		c-9.73,21.26-21.52,41.58-25.1,65.34c-3.12,20.69,2.26,38.36,17.83,51.68c12.91,11.05,27.44,20.23,41.4,30.01
		c13.97,9.79,29.28,17.99,41.99,29.18c30.3,26.69,40.97,60,29.86,99.77c-8.32,29.77-5.43,60.17-0.57,89.86
		c8.66,52.99,4.85,104.75-8.9,156.31c-7.62,28.55-0.6,54.48,17.51,77.34c9.86,12.44,23.55,18.77,39.19,20
		c5.9,0.46,11.98-1.35,18.37-3.58c-33.68-13.1-38.14-41.47-38.1-70.78c0.03-23.04,2.68-46.18,5.69-69.07
		c2.45-18.65,13.04-32.73,29.91-41.66c1.48-0.78,2.89-1.77,4.46-2.29c30.75-10.35,48.9-31.47,57.87-62.74
		c6.95-24.24,24.5-41.11,46.72-52.86c9.7-5.13,18.31-3.43,22.83,4.41c4.55,7.89,1.94,16.44-7.89,21.63
		c-18.95,9.99-30.61,25.39-36.74,45.62c-9.74,32.14-29.69,55.35-61.48,67.01c-18.84,6.91-27.62,19.74-28.09,39.38
		c-0.52,21.74-2.09,43.47-2.04,65.2c0.03,12.42,5.68,21.38,18.54,26.91c24.36,10.47,26.06,40.01,3.97,54.89
		c-12.63,8.51-26.81,9.98-41.49,7.59c-42.14-6.87-65.15-34.37-77.05-72.97c-6.53-21.18-6.93-42.63-0.82-64.39
		c13.15-46.8,15.26-94.13,7.6-142.36c-5.79-36.48-7.32-73.25,3.03-109.72c5.63-19.86-0.23-38.49-14.45-52.86
		c-10.46-10.57-23.24-18.98-35.49-27.62c-15.32-10.8-31.8-20.01-46.65-31.39c-34.72-26.59-46.09-61.73-33.05-103.45
		c5.66-18.1,14-35.42,21.94-52.72c11.51-25.09,8.51-36.49-15.7-50.4c-9.24-5.31-19.28-9.28-29.1-13.53
		c-14.86-6.44-27.42-14.99-34.2-30.74c-1.49-3.46-6.82-6.29-10.93-7.42c-17.41-4.81-35.2-8.29-52.54-13.32
		c-85.4-24.76-136.29-83.54-162.4-166.31c-4.33-13.72-7.59-27.77-11.54-41.61c-7.24-25.37-2.12-37.13,21.44-48.64
		C164.17,24.76,168.87,24.76,173.56,24.76z M554.45,190.28c8.4-15.96,15.92-30.24,22.94-43.58c-26.95-1.61-53.97-2.31-80.77-5.09
		c-28.23-2.92-48.99-22.98-73.15-36.49c-2.2,21.86,7.04,32.96,29.2,34.07c22.87,1.14,40.26,10.6,53.13,29.32
		c2.59,3.77,5.81,8.29,9.74,9.74C528.3,182.94,541.53,186.39,554.45,190.28z"
        />
        <path
          className={cls}
          d="M718.51,353.84c-58.58-1.17-105.23-49.03-104.19-106.89c1.05-58.6,49.27-104.6,108.47-103.48
		c56.66,1.07,103.31,49.81,102.16,106.76C823.8,308.1,775.63,354.99,718.51,353.84z M719.67,173.62c-41.16,0-74.9,33.41-75.18,74.46
		c-0.29,41.4,33.68,75.58,75.13,75.61c41.41,0.02,75.51-34.24,75.2-75.56C794.51,207.04,760.8,173.63,719.67,173.62z"
        />
      </g>
    </svg>
  );
};
export const MultiviajesIcon = ({ cls }) => {
  return (
    <svg
      version={"1.1"}
      id={"Capa_2"}
      xmlns={"http://www.w3.org/2000/svg"}
      xmlnsXlink={"http://www.w3.org/1999/xlink"}
      x={"0px"}
      y={"0px"}
      viewBox={"0 0 1080 1080"}
      style={{
        enableBackground: "new 0 0 1080 1080",
        height: "4rem",
      }}
      /* xmlSpace={"preserve"} */
      className={cls}
    >
      <path
        d="M425.08,461.36c-48.67-10.22-71.85-69.59-44.3-113.34c54.85-87.13,154.59-198.18,292.44-160.55
	c218.53,59.65,307.23,296.09,293.38,386.97c-7.79,51.12-73.2,30.99-128.95,3.6c-69.83-34.31-143.12-60.08-218.62-75.94
	L425.08,461.36z"
        fill={"#E8E8E8"}
      />
      <path
        className={cls}
        d="M117.79,986.7c-6.24-4.11-10.94-9.24-8.12-17.29c2.78-7.94,9.54-9.04,17.07-8.96c21.2,0.21,42.4,0.07,64.9,0.07
	c-5.57-15.2,0.2-27.8,6.2-40.51c7.39-15.65,14.35-31.51,22.02-48.44c-10.98,0-20.49,0.13-30-0.03
	c-12.37-0.21-20.09-7.33-19.51-19.55c0.62-13,1.45-26.23,4.43-38.84c9.12-38.64,42.45-65.02,82.11-65.49
	c33.15-0.39,66.32-0.06,99.47,0.06c10.8,0.04,16.68,5.15,16.36,13.69c-0.3,8.12-6.02,12.45-16.59,12.44
	c-31.41-0.04-62.83-0.12-94.24-0.17c-40.26-0.05-68.08,29.96-65.18,71.71c2.95,0,6.01,0,9.08,0c164.34,0,328.68-0.05,493.02,0.09
	c12.92,0.01,23.93-3.45,33.11-12.7c56.55-56.98,113.14-113.91,169.71-170.87c1-1.01,1.87-2.14,2.86-3.28
	c-37.44-17.95-72.35-22.43-105.48,12.42c-27.65,29.09-56.28,57.24-84.12,86.14c-11.65,12.09-25.3,17.72-41.99,17.63
	c-82.89-0.44-165.79-0.63-248.68-0.94c-10.23-0.04-16.27-4.88-16.39-12.85c-0.11-8.22,6.2-13.3,16.94-13.29
	c36.94,0.04,73.88,0.03,110.82,0.43c6.09,0.07,8.44-1.81,10-7.66c22.83-85.86,45.91-171.65,68.91-257.46c0.66-2.46,0.99-5,1.6-8.16
	c-14.98-4.02-29.49-7.97-44.03-11.82c-22.63-5.99-28.96-13.74-30.41-37.1c-0.41-6.52-1.15-13.02-1.93-21.66
	c-5.7,8.36-10.19,14.99-14.72,21.59c-9.87,14.35-20.91,18.83-37.51,14.46c-41.84-11.02-83.63-22.19-125.39-33.53
	c-14.78-4.01-23.04-14.28-24.15-29.54c-1.52-20.88-3.03-41.77-3.84-62.68c-0.2-5.16,1.19-11.13,3.84-15.54
	c58.32-96.81,143.54-152.95,255.67-167.8c38.74-5.13,77.17-2.28,115.22,6.27c1.39,0.31,2.81,0.51,4.87,0.88
	c4.39-12.1,8.68-24.06,13.09-35.98c2.26-6.11,7.04-10.53,13.2-8.78c4.47,1.27,8.83,5.76,11.37,9.95c1.49,2.45-0.19,7.28-1.32,10.76
	c-3.38,10.43-7.19,20.72-10.29,29.53c24.22,12.33,48.21,23.36,70.95,36.53c15.24,8.83,28.89,20.46,42.89,31.3
	c8.11,6.28,9.14,14.12,3.82,20.34c-5.14,6.01-13.21,5.89-20.8-0.63c-19.91-17.12-41.37-31.83-66.19-43.93
	c10.7,74.55,10.13,148.02-1.09,222.06c58.57,15.7,116.52,31.23,177.01,47.44c-3.21-20.29-5.27-39.25-9.32-57.77
	c-9.5-43.45-28.16-82.89-54.73-118.57c-0.87-1.17-1.76-2.32-2.57-3.52c-4.89-7.29-4.15-14.89,1.85-19.36
	c6.09-4.53,14.04-3.43,18.97,3.79c10.15,14.86,20.67,29.6,29.29,45.35c27.75,50.65,41.63,105.07,41.29,162.91
	c-0.03,4.88-1.73,10.38-4.36,14.51c-10.93,17.16-22.44,33.94-33.87,50.77c-9.93,14.61-21.28,18.83-38.38,14.29
	c-40.97-10.89-81.93-21.81-122.86-32.85c-17.09-4.61-24.64-14.16-25.81-31.71c-0.52-7.83-1.24-15.64-2.06-25.82
	c-5.01,7.22-9.04,12.9-12.94,18.67c-12.39,18.32-22.37,22.04-43.77,16.25c-14.8-4-29.62-7.96-45.21-12.15
	c-23.75,88.63-47.32,176.59-71.12,265.39c2.91,0.4,5.04,0.94,7.18,0.95c31.41,0.19,62.82,0.15,94.23,0.53
	c10,0.12,17.75-3.25,24.72-10.46c28.29-29.29,56.86-58.3,85.41-87.33c30.5-31.01,66.48-39.65,107.79-26.01
	c10.75,3.55,21.48,7.17,32.19,10.83c18.16,6.22,21.52,20.89,7.83,34.68c-53.89,54.29-107.74,108.61-161.88,162.65
	c-5.16,5.15-5.86,8.98-2.78,15.47c11.83,24.92,22.89,50.2,34.56,75.19c5.41,11.58,9.16,23.2,4.66,36.8c17.62,0,34.16,0,50.7,0
	c22.11,0,44.21,0.11,66.32-0.06c7.32-0.06,14.3,0.33,17.62,8.14c3.31,7.79-1.49,13.07-6.49,18.1
	C661.17,986.7,389.48,986.7,117.79,986.7z M742.71,402.47c0.87-1.24,1.14-1.47,1.18-1.72c11.47-73.8,10.7-147.54-1.49-221.17
	c-0.39-2.37-3.32-5.42-5.7-6.19c-12.4-4.02-25.08-7.14-37.49-11.12c-5.64-1.81-9.02-0.08-12.83,3.96
	c-41.81,44.3-75.94,93.8-101.59,149.07c-6.05,13.04-11.44,26.39-17.39,40.19C626.68,371.37,684.6,386.89,742.71,402.47z
	 M367.82,301.76c2.49,0.79,4.9,1.66,7.36,2.32c52.7,14.1,105.45,28.02,158.06,42.45c7.21,1.98,9.81,0.73,12.53-6.32
	c25.12-65.15,60.97-123.85,107.32-176.11c1.97-2.22,3.77-4.6,6.14-7.5C536.52,144.47,419.26,212.54,367.82,301.76z M510.09,872.26
	c-1.58-0.31-2.42-0.61-3.27-0.62c-65.08-0.04-130.16-0.12-195.24,0.19c-2.7,0.01-6.59,3.13-7.89,5.79
	c-7.61,15.65-14.62,31.58-21.81,47.43c-5.23,11.54-10.42,23.09-16.01,35.47c4.37,0,7.54,0,10.7,0c64.79,0,129.58,0,194.37,0
	c15.55,0,15.6,0.01,19.68-15.18C497.14,921.03,503.59,896.7,510.09,872.26z M537.35,871.98c-7.87,29.44-15.66,58.62-23.54,88.08
	c72.53,0,143.85,0,215.44,0c-0.22-1.31-0.2-2.21-0.53-2.95c-12.21-26.95-24.61-53.81-36.54-80.88c-2.27-5.14-5.82-4.66-9.82-4.66
	c-45.61,0.01-91.23,0-136.84,0.02C542.95,871.6,540.38,871.83,537.35,871.98z M937.3,481.69c-57.07-15.27-112.36-30.06-168.64-45.12
	c1.24,17.19,2.21,33.1,3.78,48.95c0.2,2.01,3.24,4.78,5.47,5.39c41.11,11.26,82.28,22.32,123.52,33.13
	c2.53,0.66,7.07-0.12,8.35-1.89C919.03,509.27,927.7,495.96,937.3,481.69z M564.84,381.94c1.16,17.18,2.01,33.08,3.59,48.9
	c0.22,2.17,3.79,5.07,6.32,5.77c40.88,11.2,81.83,22.17,122.84,32.89c2.5,0.65,6.99-0.27,8.3-2.09
	c9.29-12.92,17.99-26.25,27.46-40.33C676.79,411.93,621.4,397.09,564.84,381.94z M360.98,327.34c1.09,17.04,1.86,32.62,3.33,48.15
	c0.21,2.27,3.41,5.47,5.81,6.13c41.36,11.38,82.8,22.49,124.3,33.34c2.33,0.61,6.58-0.79,7.93-2.68
	c9.09-12.72,17.63-25.82,27.06-39.87C473,357.31,417.5,342.46,360.98,327.34z M718.27,870.7c12.29,27.02,24.43,53.96,36.82,80.79
	c3.77,8.16,10.73,10.91,17.7,7.74c7-3.19,9.59-10.29,6.15-18.32c-4.33-10.12-9.07-20.08-13.65-30.1
	c-7.78-17.05-15.57-34.11-23.29-51.02C733.96,863.48,726.63,866.86,718.27,870.7z M277.41,871.58c-8.55,0-15.77-0.53-22.85,0.25
	c-2.94,0.32-6.91,2.8-8.15,5.38c-9.9,20.69-19.32,41.61-28.74,62.52c-3.97,8.82-1.89,15.82,5.31,19.35
	c7.34,3.6,14.43,0.38,18.66-8.83c9.45-20.58,18.76-41.23,28.13-61.85C272.14,883.2,274.5,878,277.41,871.58z"
      />
    </svg>
  );
};
export const PlanesViajeIcon = ({ cls }) => {
  return (
    <svg
      version={"1.1"}
      id={"Capa_3"}
      xmlns={"http://www.w3.org/2000/svg"}
      xmlnsXlink={"http://www.w3.org/1999/xlink"}
      x={"0px"}
      y={"0px"}
      viewBox={"0 0 1080 1080"}
      style={{
        enableBackground: "new 0 0 1080 1080",
        height: "4rem",
      }}
      /* xmlSpace={"preserve"} */
      className={cls}
    >
      <path
        d="M572.48,143.2L970.1,243.33c13.18,3.68,20.84,17.38,17.07,30.53L786.79,992.23
	c-6.31,22.03-29.22,34.83-51.29,28.65l-195.69-54.81l-127.8-34.93l213.5-9.94l28.97-36.64L649.5,295.5l-24-33l-116,1l33.88-106.68
	C548.38,145.72,560.75,139.93,572.48,143.2z"
        fill={"#E8E8E8"}
      />
      <g>
        <path
          className={cls}
          d="M542,89.17c7.05,0,14.09,0,21.14,0c0.69,0.49,1.32,1.22,2.09,1.43c115.75,30.96,231.52,61.83,347.25,92.89
		c9,2.42,18.3,4.91,26.38,9.34c26.97,14.76,36.98,42.29,28.26,74.86c-60.11,224.39-120.12,448.8-180.64,673.07
		c-2.95,10.93-9.3,21.46-16.1,30.68c-8.27,11.21-21.65,15.45-34.64,19.4c-6.46,0-12.92,0-19.37,0c-0.45-0.36-0.84-0.93-1.35-1.06
		c-71.04-18.99-142.08-38-213.16-56.83c-4.74-1.26-9.81-1.74-14.73-1.74c-114.17-0.09-228.35-0.05-342.52-0.09
		c-22.54-0.01-34.73-11.61-34.74-33.81c-0.1-202.79-0.11-405.57,0.17-608.36c0.01-6.96,2.08-14.7,5.56-20.72
		c6.31-10.92,17.41-13.57,29.61-13.56c102.43,0.13,204.87,0,307.3,0.24c6.85,0.02,9.47-2,11.18-8.61
		c9.3-36.02,18.68-72.04,29.09-107.75c2.95-10.13,8.37-19.96,14.51-28.62C515.54,98.29,529.06,93.84,542,89.17z M131.68,911.95
		c4.01,0,7.01,0,10.01,0c153.65,0,307.3,0.01,460.95-0.01c27.27,0,38.76-11.54,38.76-38.97c0.01-186.79,0.02-373.58,0-560.37
		c0-26.82-11.54-38.5-38.23-38.51c-153.96-0.04-307.91-0.02-461.87,0c-3.19,0-6.37,0.31-9.63,0.49
		C131.68,487.34,131.68,699.02,131.68,911.95z M611.34,936.35c3.09,1.12,4.71,1.85,6.4,2.3c31.91,8.52,63.81,17.07,95.74,25.5
		c23.33,6.16,38.01-2.39,44.31-25.84c30.88-114.92,61.73-229.84,92.55-344.78c30.2-112.64,60.34-225.29,90.51-337.93
		c6.55-24.44-1.67-39.12-26.03-45.65c-117.54-31.51-235.09-62.96-352.66-94.36c-21.69-5.79-36.76,2.85-42.9,24.51
		c-4.54,16.03-8.74,32.15-13.05,48.24c-5.45,20.35-10.86,40.71-16.53,61.97c4.26,0,7.5,0,10.74,0c32.49,0,64.98,0.04,97.46-0.01
		c18.19-0.03,35.58,4.88,46.07,19.5c9.46,13.18,21.68,17.13,35.61,20.29c5.51,1.25,10.99,2.73,16.31,4.61
		c5.39,1.91,9.14,0.73,13.4-3.3c26.82-25.42,53.92-50.55,81.09-75.6c2.36-2.18,6.17-4.64,8.89-4.23c7.17,1.08,14.11,3.68,22.32,6
		c-19.43,32.09-38.28,63.22-57.53,95.01c6.54,1.82,12.26,2.66,17.32,4.96c19.47,8.83,37.2,6.52,53.34-7.38
		c8.7-7.49,17.28-4.88,26.64-0.47c-6.99,10.63-13.31,21.09-20.52,30.9c-4.92,6.68-6.55,13.32-5.59,21.55
		c1.39,11.98,1.5,24.1,2.17,36.42c-10.99-0.85-19.07-3.81-23.2-14.36c-2.68-6.86-7.09-13.06-10.89-19.46
		c-1.18-1.98-2.55-4.76-4.38-5.3c-14.34-4.23-28.82-8-43.01-11.85c0,37.65,0,73.76,0,110.64c-4.91-1.29-9.4-3.08-14.02-3.56
		c-8.35-0.86-11.71-5.36-13.99-13.21c-10.06-34.55-20.8-68.91-31.49-103.28c-0.87-2.81-2.69-6.69-4.97-7.47
		c-9.12-3.14-18.59-5.26-28.55-7.93c0,81.16,0,160.98,0,240.15c10.31,2.71,20.43,5.37,31.64,8.32
		c-5.48,20.42-10.8,40.25-16.14,60.16c-5.88-1.41-10.46-2.5-15.83-3.79c0,4.24,0,7.79,0,11.33c0,76.29,0.01,152.58-0.01,228.87
		C662.54,904.04,647,925.26,611.34,936.35z"
        />
        <path
          className={cls}
          d="M233.17,838.48c0-10.24,0-19.78,0-29.86c102.44,0,204.33,0,306.65,0c0,10.05,0,19.62,0,29.86
		C437.82,838.48,335.9,838.48,233.17,838.48z"
        />
        <path
          className={cls}
          d="M722.47,705.54c10.08,2.69,19.34,5.15,29.7,7.91c-18.56,69.29-36.98,138.09-55.64,207.76
		c-10.2-2.71-19.66-5.21-29.72-7.88C685.41,843.89,703.78,775.29,722.47,705.54z"
        />
        <path
          className={cls}
          d="M783.99,594.7c-5.4,20.14-10.52,39.24-15.87,59.21c-9.88-2.58-19.1-4.99-29.64-7.74
		c5.3-19.83,10.45-39.12,15.84-59.3C764.25,589.48,773.49,591.92,783.99,594.7z"
        />
        <path
          className={cls}
          d="M796.85,550.6c2.79-10.4,5.22-19.42,7.92-29.47c9.53,2.46,18.68,4.82,28.96,7.47
		c-2.55,9.94-4.96,19.38-7.62,29.77C816.1,555.71,806.91,553.27,796.85,550.6z"
        />
        <path
          className={cls}
          d="M680.17,519.62c2.78-10.46,5.16-19.44,7.83-29.51c9.67,2.53,18.84,4.93,29.09,7.62
		c-2.65,10.07-5.13,19.5-7.8,29.64C699.38,524.73,690.23,522.29,680.17,519.62z"
        />
        <path
          className={cls}
          d="M775.39,513.21c-2.6,9.99-4.99,19.16-7.69,29.48c-9.71-2.49-18.85-4.83-29.18-7.47
		c2.63-9.96,5.1-19.33,7.81-29.64C756.25,508.19,765.36,510.58,775.39,513.21z"
        />
        <path
          className={cls}
          d="M233.17,531.13c0,0,9.58-116.19,153.21-128.66C530,390,572.36,483.11,572.68,581.05
		s-65.56,136.81-139.62,154.38C359,753,229.34,712.26,233.17,531.13z"
        />
        <path
          fill={"#E8E8E8"}
          d="M386.44,354.75c97.16-0.09,175.98,78.57,176.63,176.28c0.65,97.12-79.12,177.18-176.57,177.21
		c-97.46,0.03-176.89-79.62-176.68-177.16C210.03,433.9,289.22,354.84,386.44,354.75z M430.76,569.51c0-24.51,0.15-48.14-0.32-71.76
		c-0.03-1.66-4.65-4.52-7.22-4.61c-13.17-0.47-26.37-0.22-39.56-0.22c-13.4,0-26.8,0-41.69,0c0,26.1,0,51.14,0,76.6
		C371.97,569.51,400.61,569.51,430.76,569.51z M462.25,569.45c23.73,0,47.93,0,72.28,0c7.2-25.49,7.36-50.74-0.12-76.05
		c-24.46,0-48.38,0-72.16,0C462.25,518.91,462.25,543.93,462.25,569.45z M310.47,492.93c-22.73,0-45.25-0.12-67.76,0.22
		c-1.87,0.03-4.82,2.94-5.36,5.01c-6.17,23.7-5.64,47.36,0.98,71.18c24.45,0,48.6,0,72.14,0
		C310.47,543.93,310.47,519.1,310.47,492.93z M348.24,600.88c6.49,28.32,15.98,54.25,30.43,78.26c5.06,8.4,11.17,8.71,15.43-0.06
		c8.54-17.6,16.57-35.47,24.04-53.55c3.14-7.59,4.32-16,6.55-24.65C398.56,600.88,373.98,600.88,348.24,600.88z M424.63,462.06
		c-6.46-29.59-16.63-57.05-33.08-81.91c-1.04-1.57-9.48-1.16-10.72,0.75c-15.97,24.78-26.23,51.96-32.52,81.15
		C373.85,462.06,398.71,462.06,424.63,462.06z M340.41,677.3c-8.6-25.8-16.96-50.87-25.3-75.88c-20.79,0-42.18,0-64.65,0
		C270.92,639.28,300.64,663.96,340.41,677.3z M433.06,677.12c39.27-13.13,69.18-37.95,89.79-76.55c-21.65,0-41.29-0.2-60.92,0.26
		c-2.31,0.05-5.79,3.28-6.59,5.74C447.69,629.88,440.5,653.34,433.06,677.12z M522.77,461.97c-20.71-38.12-50.41-63.29-90.19-76.2
		c8.52,25.42,16.91,50.46,25.53,76.2C478.37,461.97,499.69,461.97,522.77,461.97z M250.24,461.95c22.92,0,44.41,0,64.54,0
		c8.71-25.81,17.14-50.82,25.74-76.31C300.55,398.93,270.71,423.89,250.24,461.95z"
        />
      </g>
    </svg>
  );
};
export const EuropeIcon = ({ cls }) => {
  return (
    <svg
      version={"1.1"}
      id={"Capa_4"}
      xmlns={"http://www.w3.org/2000/svg"}
      xmlnsXlink={"http://www.w3.org/1999/xlink"}
      x={"0px"}
      y={"0px"}
      viewBox={"0 0 1080 1080"}
      style={{
        enableBackground: "new 0 0 1080 1080",
        height: "4rem",
      }}
      /* xmlSpace={"preserve"} */
      className={cls}
    >
      <path
        d="M678.23,345c39.39-32.83,94.81-42.06,144.33-25.41C868.78,335.13,925,369.78,925,446.4
	c0,57.79-55.23,101.32-87.86,140.12C796.32,635.07,776.26,672,776.26,672S634.27,555.7,634.27,440.6
	C634.27,395.38,654.12,365.1,678.23,345z"
        fill={"#E8E8E8"}
      />
      <g>
        <path
          className={cls}
          d="M526.21,928.67c4.5-5.31,9.85-9.54,12.44-15.06c7.11-15.13,6.81-31.67,3.73-47.43
		c-1.36-6.96-8.05-13.48-13.6-18.88c-10.92-10.6-22.98-20.01-34.1-30.42c-8.1-7.58-16.17-15.4-22.95-24.12
		c-9.37-12.05-9.76-24.63-2.82-35.14c8.12-12.29,24.9-20.27,38.08-15.93c9.2,3.02,18.45,7.88,25.82,14.12
		c24.59,20.79,48.56,42.32,72.35,64.03c21.93,20.02,31.65,46.63,38.31,74.63c4.13,17.35,7.5,34.91,12.27,52.08
		c2.92,10.5,13.01,13.66,22.55,8.63c5.25-2.77,7.64-7.11,4.92-12.32c-8.32-15.93-12.16-32.23-6.57-49.92
		c6.4-20.22,20.35-34.55,36.61-47.08c3.71-2.86,7.49-5.69,11.53-8.02c6.64-3.83,13.84-2.09,17.78,3.84
		c3.95,5.95,2.7,13.02-3.25,17.99c-8.75,7.3-18.33,13.81-26.14,22c-12.79,13.41-16.83,28.81-7.72,46.6
		c10.68,20.85,4.84,39.68-14.56,50.11c-20.7,11.13-45.74,5.44-55.87-14.21c-5.26-10.21-7.7-21.97-10.73-33.22
		c-4.86-18.06-8.42-36.5-13.88-54.36c-5.89-19.27-18.15-34.08-34.2-46.65c-16.79-13.15-31.92-28.41-47.87-42.64
		c-6.55-5.84-13.16-11.64-20.07-17.04c-6.38-4.99-12.38-4.26-18.47,4.03c6.94,7.21,13.63,14.85,21.03,21.71
		c12.68,11.74,26.36,22.44,38.62,34.58c25.12,24.89,28.34,76.77,7.25,105.3c-8.56,11.58-20.34,17.49-34.4,18.71
		c-13.41,1.16-23.23-7.67-24.71-21.85c-0.79-7.57-1.04-15.2-1.51-22.8c-0.99-15.74-7.9-28.37-21.42-36.31
		c-21.75-12.77-39.07-29.8-52.05-51.25c-3.86-6.37-8.2-7.69-14.42-4.01c-4.28,2.53-8.73,4.79-12.9,7.5
		c-18.21,11.82-37.95,18.68-59.8,16.61c-16.15-1.53-27.45,5-34.62,18.68c-8.13,15.51-16.2,31.15-22.76,47.35
		c-6.15,15.21-15.66,26.32-31.27,30.88c-26.92,7.88-53.85,16.02-81.25,21.89c-24.36,5.22-43.38-4.21-57.96-25.31
		c-18.52-26.81-22.94-54.37-11.18-84.76c9.72-25.12,18.01-50.56,22.33-77.4c3.65-22.7,17.95-31.73,40.76-27.34
		c13.74,2.65,26.96,7.96,40.68,10.74c12.2,2.47,24.9,5,37.14,4.12c21.16-1.52,32.08-25,21.69-43.53
		c-4.15-7.4-8.72-14.64-12.03-22.41c-9.59-22.54-2.48-41.05,19.75-51.54c6.56-3.1,13.62-5.63,20.72-6.96
		c19.35-3.63,34.72-13.41,47.32-28.17c7.8-9.14,15.56-18.32,23.71-27.14c16.12-17.45,36.19-26.85,59.98-26.81
		c21.54,0.03,41.73-4.69,61.09-13.91c21.06-10.04,43.11-9.83,65.37-5.3c15.65,3.18,29.41,0.94,41.21-10.98
		c10.76-10.88,24.17-17.36,39.9-20.16c-0.71-1.87-1.09-3.47-1.87-4.83c-30.9-53.64-30.96-107.28-0.05-160.93
		c6.97-12.1,5.76-24.98-2.78-33.12c-4.76-4.55-9.88-4.99-13.64,0.02c-4.65,6.2-7.96,13.39-11.9,20.13
		c-4.37,7.49-7.09,17.27-13.51,22c-20.43,15.07-21.54,34.66-17.18,56.59c3.08,15.5,6.39,30.98,8.76,46.59
		c1.13,7.42,1.57,15.38,0.13,22.67c-3.82,19.34-25.95,31.54-47.13,24.64c-10.69-3.48-20.69-10.65-29.44-18.01
		c-8.18-6.88-13.72-16.82-21.55-24.23c-4.51-4.28-10.96-7.23-17.06-8.92c-6.37-1.77-13.4-1.06-20.08-1.9
		c-14.28-1.82-22.39-11.04-22.81-25.45c-0.36-12.54,3.63-23.97,11.11-33.58c11.49-14.77,23.69-29.03,36.21-42.95
		c10.91-12.14,18.63-24.83,22.5-41.53c11.13-48.06,33.88-90.33,75.08-119.26c39.97-28.07,85.27-38.24,133.57-29.07
		c22.09,4.19,40.34,17.34,55.25,33.94c16.88,18.79,15.58,39.17-2.6,56.86c-6.88,6.69-14.55,12.59-23.56,20.3
		c4.24,1.67,7.12,3.73,10.03,3.79c14.86,0.34,29.73,0.15,45.44,0.15c0.42-7.55,0.81-15.35,1.3-23.15c1.05-16.85,8.73-29.94,22-40.26
		c12.01-9.34,23.98-18.81,35.19-29.08c11.03-10.1,16.51-22.59,15.5-38.4c-1.47-23.04,15.29-40.44,38.55-41.02
		c19.92-0.5,39.87-0.5,59.79,0.01c22.35,0.58,38.5,17.19,38.54,39.61c0.16,86.47,0.09,172.93,0.05,259.4
		c-0.01,10.01-5.45,16.23-13.53,15.99c-8.02-0.24-12.84-6.34-12.84-16.66c-0.03-83.53-0.02-167.07-0.02-250.6
		c0-2.34,0.07-4.69-0.02-7.03c-0.38-9.37-4.79-14.22-14.06-14.34c-18.76-0.25-37.52-0.26-56.27,0c-9.75,0.14-14.73,5.31-13.84,15.12
		c2.03,22.26-6.67,40.31-21.91,55.22c-11.26,11.02-23.59,21.09-36.25,30.5c-10.11,7.51-15.16,16.63-14.94,29.03
		c0.14,7.6,0.03,15.21,0.03,23.49c23.33,9.03,44,22.92,61.15,42.04c64.59,72.02,44.92,187.42-39.71,234.23
		c-5.98,3.31-11.58,8.9-15.11,14.78c-13.25,22.08-25.72,44.65-38.17,67.21c-4.62,8.36-10.88,14.02-20.59,14.13
		c-10.13,0.12-16.53-5.75-21.31-14.4c-12.87-23.33-25.95-46.54-39.41-69.54c-2.5-4.27-6.78-7.78-10.86-10.81
		c-10.54-7.85-21.31-15.41-32.25-22.69c-2.53-1.68-6.2-2.74-9.2-2.47c-16.62,1.5-30.56,8.52-42.75,20.13
		c-15.29,14.56-34.51,17.31-54.21,13.25c-22.4-4.62-43.3-3.03-64,7.24c-16.42,8.14-34.39,12.04-52.78,11.4
		c-24.01-0.83-42.43,8.73-56.99,27.26c-5.97,7.59-12.4,14.85-18.89,22.01c-14.98,16.53-33.04,27.7-55.24,32.02
		c-6.53,1.27-12.86,3.87-19.06,6.4c-7.27,2.96-9.41,8.53-6.06,15.68c2.61,5.55,5.26,11.16,8.62,16.27
		c13.26,20.2,13.4,40.93,1.7,61.41c-11.9,20.83-31.72,28.38-54.45,26.79c-14.13-0.99-28.07-5.16-42.01-8.3
		c-10.26-2.31-20.35-5.37-30.56-7.9c-5.44-1.35-9.1,0.49-9.81,6.55c-3.27,27.88-11.92,54.27-22.78,79.95
		c-10.58,25.02-4.53,46.76,11.51,67.1c5.53,7.01,12.46,11.5,21.19,9.82c20.06-3.86,39.94-8.67,59.9-13.11
		c0.57-0.13,1.09-0.47,1.66-0.57c22.67-3.81,37.26-15.14,44.23-38.64c4.62-15.58,14.69-29.84,23.81-43.71
		c10.89-16.56,27.03-25.02,47.22-23.4c20.25,1.62,37.87-4.35,54.67-15.33c7.67-5.01,16.68-8.78,25.61-10.87
		c13.18-3.08,23.11,3.71,30.74,14.2c6.03,8.29,11.45,17.15,18.42,24.57c6.54,6.96,14.13,13.4,22.32,18.26
		c25.57,15.15,39.39,36.84,40.15,66.7c0.1,3.8,0.49,7.6,0.83,11.39C523.87,925.64,524.41,926.07,526.21,928.67z M700.17,274.47
		c-6.14-14.18-0.03-25.29,11.15-34.8c6.24-5.31,12.65-10.43,18.69-15.95c6.34-5.79,6.46-12.49,0.57-18.47
		c-12.9-13.12-27.5-24.87-46.07-27.08c-44.29-5.27-85.27,4.23-119.29,34.44c-28.94,25.69-46.54,58.91-54.06,96.39
		c-4.11,20.47-13.97,36.06-27.4,50.74c-11.45,12.52-22.66,25.3-33.14,38.63c-4.24,5.39-6.24,12.54-9.75,19.92
		c4.35,0.69,6.33,1.36,8.28,1.25c25.02-1.4,42.88,9.88,56.65,30.17c5.15,7.58,12.55,13.86,19.71,19.79
		c5.75,4.76,14.05,6.24,19.53,1.39c3.19-2.81,3.52-10.61,2.76-15.82c-2.41-16.48-5.89-32.81-9.07-49.17
		c-5.76-29.67-1.12-55.81,24.09-75.85c3.98-3.17,5.61-9.27,8.37-13.99c5.54-9.52,9.73-20.27,16.95-28.28
		c15.72-17.45,37.99-16.54,53.69,0.93c4.2,4.67,7.48,10.15,10.43,14.2C668.12,293.51,683.65,284.29,700.17,274.47z M753.58,617.38
		c12.24-21.65,24.08-41.81,35.11-62.41c6.17-11.53,14.45-19.8,26.08-26.11c44.5-24.12,70.04-74.15,64-123.49
		c-6.31-51.54-41.73-93.35-90.96-107.37c-48.89-13.92-101.75,3.17-133.17,43.06c-47.41,60.19-30.43,149.02,36.19,186.74
		c12.77,7.23,22.13,16.37,28.91,29.35C730.14,577.09,741.72,596.42,753.58,617.38z"
        />
        <path
          className={cls}
          d="M261.78,605.7c-13.71-3.72-28.01-6.02-40.98-11.48c-19.38-8.16-21.58-25.73-5.82-39.62
		c6.99-6.16,15.19-10.92,22.75-16.44c7.08-5.18,14.53-10.02,20.85-16.02c3.26-3.09,6.36-8.49,6.17-12.69
		c-0.72-15.81,3.17-30.23,10.59-43.81c5.35-9.79,5.53-18.54,0.2-28.93c-9.64-18.76-12.21-39.07-6.64-59.75
		c4.13-15.34,13.45-26.77,29.89-28.31c18.24-1.71,35.65,2.73,46.22,19.11c3.58,5.56,2.23,14.35,2.94,21.68
		c0.46,4.69-0.69,9.96,1.09,14.01c4.5,10.28,4.25,19.76-1.24,29.4c-2.89,5.08-5.88,10.17-8.07,15.56
		c-4.75,11.69-4.35,22.56,5.02,32.51c8.65,9.2,11.97,20.73,10.72,33.28c-1.04,10.48-1.99,21.05-4.2,31.31
		c-2.94,13.68-11.52,24.21-21.99,33.08C310.01,594.93,287.68,603.25,261.78,605.7z M235.51,571.57c23.31,9.49,48.71,5.4,70.34-7.92
		c20.45-12.59,21.94-32.58,22.95-52.78c0.26-5.09-3.19-11.16-6.58-15.48c-6.94-8.84-11.61-18.29-12.1-29.52
		c-0.68-15.48,5.06-29.13,12.23-42.45c1.57-2.91,2.56-7.43,1.43-10.25c-3.72-9.29-5.71-18.34-2.38-28.16
		c0.53-1.55-0.15-5.02-0.99-5.28c-6.29-1.92-12.97-4.88-19.09-4.1c-3.1,0.4-7.62,7.95-7.47,12.12c0.43,12.06,0.06,25.46,5.18,35.76
		c9.95,20.03,8.45,37.66-1.14,56.89c-4.23,8.48-5.59,18.94-6.07,28.61c-0.56,11.19-3.62,20.92-11.34,28.6
		c-6.81,6.77-14.52,12.69-22.21,18.49C251.34,561.35,243.89,565.92,235.51,571.57z"
        />
        <path
          className={cls}
          d="M989.98,583.14c0,38.08,0.05,76.15-0.01,114.23c-0.06,34.94-28.57,52.86-60.25,37.86
		c-6.87-3.25-13.69-6.66-20.25-10.49c-13.46-7.86-27.24-8.34-41.2-2.06c-9.59,4.32-18.57,10.11-28.37,13.78
		c-12.44,4.66-25.55,7.5-38.11,11.86c-3.48,1.21-7.74,4.54-8.72,7.78c-4.08,13.4-7.03,27.14-10.42,40.75
		c-0.64,2.55-1.08,5.22-2.17,7.58c-2.95,6.35-8.12,9.41-14.98,7.74c-6.77-1.65-11.38-6.75-9.77-13.54c4.68-19.85,9.67-39.7,16-59.07
		c3.48-10.64,12.6-17.49,24.17-17.46c13.89,0.04,25.39-5.73,37.24-11.56c11.51-5.67,23.1-11.45,35.18-15.65
		c17.12-5.94,34.05-3.66,50.1,4.8c6.47,3.41,13.12,6.51,19.39,10.26c16.79,10.05,26.25,1.36,26.09-15.89
		c-0.72-75.56-0.31-151.14-0.31-226.7c0-2.64-0.15-5.29,0.08-7.9c0.66-7.59,5.96-12.71,13.02-12.76
		c7.09-0.05,12.52,4.99,13.21,12.56c0.29,3.2,0.1,6.44,0.1,9.66C989.98,506.99,989.98,545.07,989.98,583.14z"
        />
        <path
          className={cls}
          d="M248.16,492.15c0.3,21.1-8.76,33.96-28.15,40.43c-10.87,3.63-20.33,1.82-29.77-4.84
		c-6.75-4.76-14.46-8.49-22.22-11.38c-13.57-5.04-23.95-13.31-30.29-26.33c-8.33-17.12-4.78-33.69,9.99-45.62
		c14.48-11.69,31.17-18.43,49.69-20.1c18.8-1.69,31.29,5.2,38.3,22.55c5.85,14.47,9.96,29.65,14.83,44.51
		C249.75,491.63,248.95,491.89,248.16,492.15z M223.77,493.16c-0.6-0.48-1.2-0.96-1.8-1.45c-0.48-3.18-0.56-6.48-1.52-9.51
		c-2.46-7.8-5.48-15.41-7.94-23.21c-2.45-7.78-8.24-10.24-15.07-8.16c-9.97,3.04-20.01,6.55-29.14,11.5
		c-10.71,5.81-11.19,14.15-2.15,22.57c3.07,2.86,7.43,4.39,11.27,6.38c8.97,4.63,17.87,9.42,27.05,13.59
		c2.98,1.35,8.08,2.6,9.85,1.1C218.27,502.63,220.71,497.53,223.77,493.16z"
        />
        <path
          className={cls}
          d="M319.69,242.21c0,31.03-14.48,47.99-37.2,49.06c-7.34,0.34-15.07-4.64-22.39-7.74
		c-6.6-2.8-12.57-7.32-19.34-9.42c-19.61-6.1-34.59-23.16-35.85-42.18c-0.67-10.18,2.15-19.35,9.74-26.23
		c16.57-15.02,35.96-24.06,58.58-24.28c15.31-0.15,27.52,6.32,33.13,21.27C312.17,218.14,316.94,233.97,319.69,242.21z
		 M295.38,251.57c-4.23-12.86-7.95-23.87-11.47-34.94c-2.54-8-8.46-10.76-15.72-8.61c-9.44,2.79-18.95,6.1-27.56,10.78
		c-11.9,6.47-12.26,16.47-1.51,24.86c3.33,2.6,7.86,3.62,11.76,5.52c7.53,3.65,15.23,7.03,22.41,11.29
		c3.99,2.37,7.35,7.08,12.14,3.02C289.26,260.27,291.99,255.74,295.38,251.57z"
        />
        <path
          className={cls}
          d="M753.88,327.06c50.96,0.15,91.98,41.39,91.9,92.4c-0.08,50.62-42,92.34-92.52,92.08
		c-50.62-0.27-92.17-42.12-92.03-92.68C661.39,368.13,703,326.9,753.88,327.06z M753.87,353.48c-36.43-0.12-66.18,29.36-66.21,65.6
		c-0.03,36.11,29.99,66.12,66.04,66.03c35.83-0.09,65.49-29.64,65.66-65.42C819.53,383.12,790.33,353.59,753.87,353.48z"
        />
      </g>
    </svg>
  );
};
export const WorldIcon = ({ cls }) => {
  return (
    <svg
      version={"1.1"}
      id={"Capa_5"}
      xmlns={"http://www.w3.org/2000/svg"}
      xmlnsXlink={"http://www.w3.org/1999/xlink"}
      x={"0px"}
      y={"0px"}
      viewBox={"0 0 1080 1080"}
      style={{
        enableBackground: "new 0 0 1080 1080",
        height: "4rem",
      }}
      /* xmlSpace={"preserve"} */
      className={cls}
    >
      <path
        d="M243.26,497.49c0,0,18.86-228.73,301.59-253.28s366.11,158.74,366.74,351.55
	c0.63,192.81-129.06,269.32-274.84,303.9S235.72,854.06,243.26,497.49z"
        fill={"#E8E8E8"}
      />
      <g>
        <path
          className={cls}
          d="M552.06,99c5.35,4.53,10.5,9.16,7.39,17.29c-3.04,7.96-9.62,8.49-16.91,8.66
		c-15.18,0.35-30.34,1.24-45.51,1.99c-9.95,0.49-16.22-3.86-16.72-11.78c-0.46-7.41,4.93-12.66,14.38-13.92
		c5.91-0.79,11.83-1.49,17.74-2.23C525.64,99,538.85,99,552.06,99z"
        />
        <path
          className={cls}
          d="M981,567.56c-1.7,13.01-3.35,26.02-5.11,39.02c-1.32,9.7-7.25,15.22-14.9,14.03
		c-7.92-1.23-11.82-7.74-10.76-17.56c1.62-15.07,3.38-30.14,4.53-45.25c0.55-7.29,1.37-13.91,9.61-16.23
		c8.16-2.29,12.85,2.5,16.64,8.76C981,556.08,981,561.82,981,567.56z"
        />
        <path
          className={cls}
          d="M527.94,981c-2.22-2.51-5.08-4.69-6.55-7.58c-4.44-8.71,1.83-17.76,12.35-18.24
		c16.61-0.76,33.23-1.41,49.84-2.19c9.46-0.45,15.54,3.65,16.39,11.25c0.83,7.38-4.54,13.07-13.8,14.41
		c-6.19,0.9-12.41,1.57-18.61,2.35C554.36,981,541.15,981,527.94,981z"
        />
        <path
          className={cls}
          d="M99,512.44c1.69-13,3.34-26,5.1-39c1.36-10.04,6.71-15.23,14.61-14.36c8.24,0.9,12.21,7.65,11.04,18.07
		c-1.66,14.79-3.39,29.59-4.47,44.42c-0.53,7.31-1.36,14.03-9.38,16.49c-7.98,2.45-12.9-2.29-16.89-8.4
		C99,523.92,99,518.18,99,512.44z"
        />
        <path
          className={cls}
          d="M651.53,213.84c-0.73-23.52,5.94-43.18,20.75-59.79c25.71-28.82,67.66-35.74,101.25-16.85
		c33.62,18.91,50.51,59.26,37.43,96.04c-6.22,17.5-17.73,33.14-26.97,49.55c-1.53,2.72-3.72,5.06-5.91,8
		c73.8,73.74,110.46,162.47,105.61,266.74c-3.86,83.01-34.79,155.72-91.58,216.45C679.24,894.68,499.14,919.44,361.1,834.1
		c-141.26-87.33-201.84-259.92-144.2-412.99c32.7-86.84,91.08-151.33,174.76-191.54C475.35,189.37,562.02,185.48,651.53,213.84z
		 M815.12,700.21c-5.33-0.76-8.99-1.27-12.65-1.8c-39.61-5.7-61.37-33.78-55.52-72.53c1.36-8.98,4.76-17.67,7.38-26.45
		c4.68-15.67-1.65-29.39-16.89-35.51c-4.2-1.69-8.76-2.78-13.26-3.36c-24.13-3.11-47.26,3.35-70.65,7.55
		c-20.83,3.74-41.69,8.25-62.72,9.68c-22.21,1.51-37.02-12.37-46.95-30.75c-9.74-18.03-8.91-36.5,2.26-53.94
		c10.5-16.4,26.75-23.46,45.1-27.13c9.8-1.96,19.84-3.7,29.08-7.31c13.03-5.09,18.59-16.65,13.57-27.34
		c-10.18-21.7-28.44-33.44-51.41-37.45c-21.64-3.78-37.63,5.26-49.28,23.74c-10.39,16.48-21.06,32.9-32.99,48.28
		c-20.54,26.46-48.13,40.97-81.56,44.27c-26.97,2.67-51.61-9.36-62.43-30.87c-8.43-16.76-10.44-42.12,11.33-65.76
		c13.29-14.43,24.63-31.01,34.87-47.83c13.95-22.92,21.67-48.07,18.78-75.48c-1.51-14.3-6.31-27.23-16.16-37.74
		c-1.04,0.1-1.66,0.02-2.15,0.23c-1.83,0.82-3.64,1.69-5.41,2.64c-103.9,55.67-165.59,141.55-174.05,259.37
		c-9.65,134.57,48.49,236.05,164.29,304.61c4.13,2.45,9.7,3.58,14.56,3.47c25.27-0.55,49.25-6.85,72.26-17.26
		c7.76-3.51,15.07-0.66,18.04,6.22c2.86,6.62,0.16,13.38-7.2,17.09c-4.34,2.19-8.87,4.06-13.44,5.72
		c-8.44,3.07-16.98,5.87-25.48,8.79c0.01,0.75,0.02,1.51,0.02,2.26c18.25,3.5,36.38,7.92,54.78,10.31
		c53.1,6.9,104.69,0.11,154.51-19.53c2.44-0.96,5.27-3.91,5.76-6.36c4.58-23.02,0.48-47.44-23.73-61.02
		c-8.41-4.72-10.49-11.77-6.42-18.64c3.92-6.62,11.33-8.05,19.53-3.48c18.52,10.31,30.73,25.68,35.48,46.38
		c2,8.73,2.55,17.78,3.86,27.46C742.83,791.97,783.93,752.42,815.12,700.21z M430.47,241.09c3.39,6.79,7.06,12.96,9.65,19.56
		c12.68,32.39,8.42,64.22-4.23,95.49c-11.99,29.65-30.69,54.95-51.89,78.6c-15.2,16.96-7.4,42.63,14.82,48.06
		c6.53,1.59,13.71,1.84,20.43,1.12c31.2-3.31,53.2-21.01,70.1-46.2c9.43-14.05,18.46-28.37,28.2-42.2
		c8.47-12.02,19.77-20.48,34.08-24.95c40-12.5,92.78,13.33,106.98,52.66c5.47,15.15,3.67,29.17-6.29,42.19
		c-10.23,13.36-24.31,19.52-40.14,22.94c-10.07,2.18-20.48,3.6-30,7.27c-19.27,7.43-24.54,28.27-12.52,44.57
		c9.71,13.17,22.93,13.83,37.1,11.08c20.26-3.93,40.41-8.43,60.67-12.32c23.17-4.44,46.41-7.68,70-2.45
		c29.32,6.51,54.15,36.98,41.05,72.46c-2.08,5.64-3.97,11.39-5.37,17.23c-5.63,23.43,3.96,39.51,27.4,45.24
		c7.17,1.76,14.76,1.77,22.12,2.83c4.94,0.71,6.95-1.78,8.8-5.96c16.42-37.18,25.6-76.11,26.78-116.65
		c2.34-80.68-22.34-152.42-73.26-215.03c-6.95-8.55-14.78-16.37-22.56-24.93c-8.46,11.78-15.39,22.42-16.34,36.05
		c-0.46,6.63-4.68,11.03-11.8,11.53c-6.61,0.46-12.37-2.74-12.92-8.88c-1.89-20.85-15.06-35.43-26.22-51.35
		c-11.82-16.88-22.87-34.3-34.52-51.3c-1.61-2.35-4.5-4.51-7.22-5.31c-18.95-5.53-37.93-11.05-57.09-15.78
		c-9-2.22-18.43-2.7-27.95-4c4.63,48.61,32.19,70.67,75.59,61.87c9.7-1.97,16.45,1.56,18.08,9.46c1.51,7.32-3.17,13.21-12.37,15.54
		c-48.04,12.22-89.69-12.05-102.73-60.11c-2.45-9.02-3.85-18.33-5.84-27.98C502.92,221.65,466.2,227.73,430.47,241.09z
		 M732.58,308.51c6.51-8.84,11.74-16.02,17.04-23.13c5.31-7.12,11.38-13.78,15.9-21.37c7.71-12.96,16.31-25.85,21.22-39.93
		c8.39-24.04-2.59-50.05-24.08-63.26c-21.34-13.11-48.29-10.61-66.97,6.23c-19.35,17.44-25.09,45.14-10.92,69.88
		C698.79,261.41,716.04,284.03,732.58,308.51z"
        />
        <path
          className={cls}
          d="M329.76,176.06c-3.51,4.43-5.65,8.62-9.04,11.16c-13.54,10.12-27.44,19.75-41.25,29.51
		c-7,4.95-14.57,4.2-19.11-1.75c-4.46-5.85-3.78-13.07,2.96-18.12c14.45-10.82,29.08-21.43,44.21-31.25
		c3.34-2.17,9.65-2.12,13.47-0.48C324.47,166.62,326.43,171.66,329.76,176.06z"
        />
        <path
          className={cls}
          d="M978.59,485c-1.32,7.42-4.79,12.16-11.94,13.54c-6.73,1.3-13.14-3.08-14.59-10.22
		c-3.62-17.9-7.16-35.82-10.24-53.81c-1.22-7.12,2.51-12.33,9.48-14.25c7.15-1.97,13.32,1.15,15.01,7.9
		C970.99,446.95,974.57,466.03,978.59,485z"
        />
        <path
          className={cls}
          d="M824.26,872.09c-2.88,4.43-4.3,8.57-7.2,10.74c-14.45,10.8-29.12,21.32-44.05,31.42
		c-6.89,4.66-14.91,2.51-18.64-3.85c-3.61-6.15-1.71-13.02,5.02-17.83c13.74-9.83,27.23-20.06,41.45-29.15
		c3.88-2.48,10.41-2.94,14.93-1.61C819.14,862.79,821.21,868.16,824.26,872.09z"
        />
        <path
          className={cls}
          d="M439.32,130.79c-2.22,1.98-5.6,6.97-10.23,8.78c-15.98,6.25-32.39,11.44-48.66,16.94
		c-7.58,2.56-14.51-0.32-17.17-6.93c-2.7-6.71,0.02-13.98,7.64-16.81c17.17-6.39,34.47-12.5,51.98-17.87
		C431.71,112.19,439.59,118.83,439.32,130.79z"
        />
        <path
          className={cls}
          d="M101.37,594.45c1.5-7.37,4.77-12.29,12.19-13.25c7.38-0.95,12.62,2.67,14.12,9.69
		c3.88,18.2,7.48,36.49,10.42,54.86c1.12,7.02-2.99,12.19-10.22,13.73c-6.87,1.46-12.73-1.46-14.31-7.93
		C108.97,632.65,105.37,613.5,101.37,594.45z"
        />
        <path
          className={cls}
          d="M211.39,800.06c4.59,3.04,8.3,4.71,10.95,7.41c11.66,11.86,23.01,24.02,34.48,36.07
		c6.48,6.81,6.99,14.22,1.33,19.75c-5.51,5.37-12.65,5.35-19.05-1.13c-12.49-12.64-24.93-25.39-36.48-38.87
		c-2.65-3.1-3.25-9.9-1.84-13.96C202.05,805.63,207.37,803.35,211.39,800.06z"
        />
        <path
          className={cls}
          d="M300.92,882.02c4.58,1.97,7.59,2.94,10.27,4.47c13.93,7.96,27.75,16.11,41.67,24.08
		c8.77,5.02,11.72,11.83,7.92,18.89c-3.83,7.11-11.02,8.84-20.04,3.82c-15.02-8.35-29.79-17.16-44.58-25.92
		c-6.04-3.58-8.23-9.59-5.41-15.52C292.69,887.78,297.56,885.13,300.92,882.02z"
        />
        <path
          className={cls}
          d="M166.47,356.45c-0.66,1.96-1.52,4.97-2.67,7.86c-5.61,14.1-11.43,28.12-16.91,42.27
		c-3.94,10.16-9.99,14.18-17.89,11.28c-7.64-2.8-10.39-9.67-6.69-19.33c6.14-16.04,12.72-31.92,19.41-47.75
		c2.64-6.24,7.73-9.2,14.6-7.72C162.75,344.44,165.96,348.78,166.47,356.45z"
        />
        <path
          className={cls}
          d="M150.32,698.41c1.92,1.68,6.72,4.19,8.93,8.14c8.4,14.97,16.01,30.39,23.83,45.68
		c3.8,7.42,2.24,14.31-3.92,18.13c-6.47,4.02-14.1,2.3-18.25-5.3c-8.65-15.82-17.06-31.8-24.76-48.1
		C131.85,707.82,137.94,698.64,150.32,698.41z"
        />
        <path
          className={cls}
          d="M910.37,307.4c1.67,1.34,6.29,3.37,8.24,6.88c8.75,15.77,17.04,31.82,24.9,48.06
		c3.8,7.86,0.78,14.83-6.42,17.88c-6.67,2.83-12.98,0.12-16.89-7.4c-7.93-15.25-15.89-30.49-23.65-45.83
		C891.65,317.31,897.46,307.21,910.37,307.4z"
        />
        <path
          className={cls}
          d="M959.63,674.6c-0.38,1.58-0.62,3.62-1.35,5.46c-6.35,15.97-12.54,32-19.29,47.8
		c-3.57,8.35-11.25,11.22-18.22,7.82c-6.62-3.23-8.57-10.24-5.24-18.49c6.32-15.67,12.58-31.35,18.88-47.03
		c2.68-6.65,7.5-10.34,14.75-8.9C955.88,662.58,959.25,667.34,959.63,674.6z"
        />
        <path
          className={cls}
          d="M857.91,839.56c-3.2-2.37-8.24-4.25-9.69-7.67c-1.63-3.83-1.77-10.3,0.49-13.43
		c10.87-15.09,22.55-29.6,34.39-43.96c4.65-5.64,11.18-5.87,16.99-1.64c5.53,4.02,7.57,10.39,3.5,15.91
		c-11.72,15.87-24.13,31.23-36.58,46.54C865.43,837.26,862.04,837.72,857.91,839.56z"
        />
        <path
          className={cls}
          d="M654.27,967.32c-7.07-2.27-11.53-5.36-12.82-11.84c-1.26-6.34,0.83-11.87,6.98-14.14
		c17.71-6.54,35.54-12.8,53.5-18.59c6.47-2.09,12.16,0.88,14.84,7.18c2.45,5.76,1.78,12.66-4.15,15.13
		C693.42,953.08,673.76,959.98,654.27,967.32z"
        />
        <path
          className={cls}
          d="M464.02,975.47c-18.8-4.97-37.7-9.58-56.33-15.09c-7.02-2.08-9.23-8.43-7.23-15.4
		c1.93-6.74,7.2-10.15,13.85-8.7c18.44,4.04,36.8,8.51,55.05,13.36c7.15,1.9,9.9,7.85,8.3,14.88
		C476.14,971.2,471.27,974.42,464.02,975.47z"
        />
        <path
          className={cls}
          d="M235.68,252.96c-2.42,4.59-3.69,8.1-5.86,10.92c-9.96,12.93-20.18,25.66-30.27,38.49
		c-5.81,7.39-13.09,9.22-19.26,4.76c-6.43-4.65-7.47-12.04-1.73-19.62c10.55-13.92,21.53-27.51,32.46-41.14
		c4.06-5.06,9.9-6.79,15.37-3.77C230.14,244.67,232.5,249.26,235.68,252.96z"
        />
        <path
          className={cls}
          d="M881.66,266.73c-2.31,2.79-4.47,7.55-8.02,9.1c-3.56,1.55-10.11,1.6-12.58-0.69
		c-8.34-7.73-15.9-16.43-22.84-25.47c-3.75-4.88-2.14-11.53,2.87-14.4c4.03-2.3,11.89-3.8,14.53-1.54
		c8.8,7.55,16.1,16.87,23.76,25.7C880.56,260.8,880.6,263.16,881.66,266.73z"
        />
        <path
          className={cls}
          d="M618.85,105.81c8.32,1.92,18.46,3.8,28.29,6.7c7.08,2.09,9.69,8.06,7.98,15.02
		c-1.63,6.6-6.57,10.41-13.42,9.34c-10.13-1.6-20.27-3.6-30.12-6.42c-7.05-2.02-9.57-7.99-7.9-15.04
		C605.29,108.6,610.26,105.78,618.85,105.81z"
        />
        <path
          className={cls}
          d="M539.71,596.31c57.24-0.22,97.33,56.37,76.61,110.44c-6.95,18.14-19.3,34.29-29.84,50.94
		c-5.81,9.18-13.13,17.4-19.5,26.25c-7.26,10.08-13.31,20.69-14.42,33.55c-0.64,7.35-5.54,11.5-12.72,11.46
		c-6.75-0.03-11.71-3.95-12.31-10.9c-1.33-15.39-9.62-27.33-18.16-39.29c-12.5-17.49-25.84-34.47-37.14-52.71
		c-14.19-22.92-18.65-47.98-9.75-74.26C473.74,618.51,504.64,596.45,539.71,596.31z M539.13,777.9
		c12.37-16.5,23.98-30.45,33.79-45.56c8.19-12.62,16.2-25.97,20.8-40.14c6.62-20.35,0.63-39.26-14.85-54.3
		c-15.51-15.08-34.32-19.29-54.9-13.43c-34.29,9.75-50.87,48.03-32.87,81.2C504.66,730.65,522.54,753.28,539.13,777.9z"
        />
        <path
          className={cls}
          d="M442.15,628.45c-0.29,27.07-27.37,55.75-60.82,61.53c-17,2.94-31.96-0.94-45.11-11.61
		c-23.46-19.02-39.15-43.7-47.24-72.38c-10.18-36.07,14.95-65.6,52.53-62.06c32.08,3.02,60.22,16.11,83.08,39.22
		C435.85,594.55,442.5,608.31,442.15,628.45z M340.47,569.44c-21.77,0.17-31.82,12.15-26.68,29.4
		c6.93,23.26,19.28,43.64,38.41,58.91c5.18,4.13,13.02,7.09,19.55,6.94c14.98-0.34,27.47-7.69,36.94-19.38
		c10.51-12.97,10.84-28.27-0.06-40.96C389.9,582.52,364.76,573.07,340.47,569.44z"
        />
        <path
          className={cls}
          d="M733.58,221.35c-6.96,0.07-12.6-5.3-12.86-12.24c-0.28-7.34,5.39-13.4,12.59-13.44
		c7.07-0.05,13.26,6.2,13.08,13.2C746.2,215.68,740.45,221.29,733.58,221.35z"
        />
        <path
          className={cls}
          d="M552.91,677.9c0.1,7.18-5.94,13.22-13.05,13.06c-6.83-0.15-12.38-5.8-12.47-12.69
		c-0.1-7.18,4.97-12.55,12.09-12.83C546.98,665.15,552.81,670.56,552.91,677.9z"
        />
      </g>
    </svg>
  );
};
export const WalkingPersonIcon = ({ cls }) => {
  return (
    <svg
      version={"1.1"}
      id={"Capa_6"}
      xmlns={"http://www.w3.org/2000/svg"}
      xmlnsXlink={"http://www.w3.org/1999/xlink"}
      x={"0px"}
      y={"0px"}
      viewBox={"0 0 1080 1080"}
      style={{
        enableBackground: "new 0 0 1080 1080",
        height: "4rem",
      }}
      /* xmlSpace={"preserve"} */
      className={cls}
    >
      <path
        d="M177.65,674h230.38c8.27,0,14.97,6.7,14.97,14.97v142.28c0,8.27-6.7,14.97-14.97,14.97H183.94
	c-8.01,0-14.6-6.31-14.96-14.31l-6.29-142.28C162.31,681.11,169.12,674,177.65,674z"
        fill={"#E8E8E8"}
      />
      <path
        d="M812.35,704.48l105.68-10.66c10.23-1.03,19.57,5.67,21.24,15.23L976,923c1.79,10.26-3.03,31.91-14,33
	l-96.24,18.59c-9.98,0.99-19.15-5.39-21.09-14.66l-49.05-234.02C793.43,715.49,801.15,705.61,812.35,704.48z"
        fill={"#E8E8E8"}
      />
      <g>
        <path
          className={cls}
          d="M120.87,656.62c7.96-22.43,13.19-26.1,37.16-26.13c12.14-0.02,24.28-0.1,36.42-0.19
		c0.56,0,1.12-0.36,3.02-1.01c-2.43-26.01,2.13-49.5,31.68-60.41c-7.19-18.28,1.26-33.72,8.23-49.55
		c24.4-55.48,48.51-111.1,72.99-166.55c16.35-37.04,44.86-56.21,85.57-56.34c48.88-0.15,97.75-0.07,146.63-0.03
		c40.28,0.04,69,17.78,84.61,55.5c1.51,3.64,3.39,7.14,4.65,10.86c13.64,40.1,36.97,73.72,66.74,103.61
		c18.83,18.9,35.84,39.6,53.62,59.54c16.11,18.07,18.05,33.48,7.19,54.85c-0.9,1.76-1.73,4.03-1.42,5.86
		c4.91,28.34,10.02,56.65,15.25,85.91c20.28-3.8,39.83-7.43,59.36-11.12c43.38-8.21,83.75,18.65,92.04,62.18
		c11.68,61.35,22.73,122.82,33.9,184.27c3.61,19.84-8.81,38.96-29.27,44.03c-21.17,5.25-42.63,9.34-63.92,14.14
		c-2.36,0.53-5.84,1.88-6.42,3.63c-6.08,18.42-19.68,29.14-36.47,36.81c-8.5,0-17.01,0-25.51,0c-0.68-0.54-1.28-1.32-2.05-1.58
		c-20.39-6.83-32.33-20.77-35.06-42.17c-2.65-20.72,5.69-36.42,22.16-48.69c2.2-1.64,3.34-6.67,2.79-9.75
		c-14.67-82.09-29.61-164.12-44.54-246.16c-3.51-19.29-7.16-38.55-10.83-58.25c-19.91,1.6-33.36-7.46-45.31-21.62
		c-22.9-27.14-46.99-53.27-70.35-80.03c-12.76-14.62-25.03-29.67-37.3-44.27c0,2.56,0,5.89,0,9.22c0,156.67,0,313.33-0.01,470
		c0,3.95,0.08,7.9-0.14,11.84c-2.06,36.75-33.45,62.92-69.43,57.88c-14.46-2.02-25.44-10.36-34.91-20.12
		c-5.89,4.49-10.81,9.13-16.51,12.43c-41.95,24.35-92.35-5.58-93.3-55.67c-0.61-31.87-0.12-63.76-0.13-95.64c0-3.25,0-6.51,0-10.77
		c-4.53,0-8.09,0-11.64,0c-64.06,0-128.13,0-192.19,0c-23.13,0-29.22-4.13-37.31-25.28C120.87,757.46,120.87,707.04,120.87,656.62z
		 M548.87,667.46c0-88.96,0-177.93,0-266.89c0-14.79,2.72-21.01,10.58-22.37c11.19-1.94,14.85,6.61,19.51,14.28
		c16.78,27.61,28.85,57.94,51.69,82.05c27.96,29.52,54.3,60.59,81.12,91.17c4.64,5.29,10.5,8.12,15.88,4.49
		c4.34-2.93,7.83-9.02,8.86-14.27c0.75-3.8-2.22-9.29-5.1-12.7c-11.97-14.12-23.94-28.34-37-41.42
		c-40.71-40.78-74.19-85.95-93.65-141.02c-8.94-25.3-29.24-36.61-56.21-36.47c-48.88,0.26-97.77,0.21-146.65,0.02
		c-27.01-0.11-45.51,12.14-56.25,36.77c-25.93,59.46-52,118.86-77.9,178.34c-1.67,3.83-4.08,8.55-3.12,12.01
		c1.27,4.58,4.71,9.77,8.73,12.06c5.38,3.07,11.3,0.83,14.86-4.89c1.59-2.57,2.8-5.37,4.16-8.09
		c25.08-50.17,50.19-100.33,75.19-150.54c3.81-7.66,9.07-12.08,18.08-9.78c8.61,2.19,11.22,8.4,10.97,16.53
		c-0.08,2.73-0.01,5.46-0.01,8.2c0,69.53-0.05,139.06,0.17,208.6c0.01,3.29,1.55,8.12,3.98,9.6c10.84,6.59,15.06,16.13,15.06,28.22
		c-0.01,46.76-0.01,93.52,0,140.28c0,12.55-4.69,22.24-16.14,28.43c-2.1,1.13-4.3,4.4-4.31,6.7c-0.24,35.52-0.41,71.05,0.05,106.57
		c0.19,14.29,7.45,24.9,21.01,30.22c23.14,9.08,45.7-7.19,45.74-33.12c0.12-79.25,0.03-158.5,0.1-237.75
		c0-3.89,0.13-7.99,1.32-11.63c2.44-7.42,8.33-10.1,15.8-9.17c7.39,0.91,11.24,5.45,11.83,12.74c0.25,3.02,0.14,6.07,0.14,9.1
		c0.01,77.43,0,154.85,0.01,232.28c0,3.34-0.14,6.7,0.15,10.01c1.3,14.49,11.02,25.27,24.76,27.66
		c14.46,2.51,26.78-3.38,32.56-17.21c2.71-6.49,3.84-14.09,3.86-21.19C548.96,845.99,548.87,756.72,548.87,667.46z M379.89,804.5
		c0-48.62,0-96.3,0-144.22c-77.88,0-154.47,0-230.68,0c0,48.43,0,96.25,0,144.22C226.78,804.5,303.16,804.5,379.89,804.5z
		 M815.5,905.72c16.79,2.53,29.43,10.4,37.3,24.42c3.1,5.52,6.35,6.06,11.62,4.95c17.48-3.68,35-7.18,52.53-10.62
		c13.1-2.57,16.45-7.68,13.91-21.18c-4.04-21.42-7.92-42.88-11.91-64.31c-4.31-23.14-8.67-46.27-13.13-70
		c-36.84,9.84-72.71,19.41-109.22,29.16C802.94,834.22,809.16,869.64,815.5,905.72z M899.01,741.51
		c-1.62-29.11-18.86-49.69-44.78-52.8c-8.16-0.98-16.84,0.97-25.11,2.51c-16.89,3.15-33.66,6.93-51.44,10.65
		c4.43,23.16,8.66,45.32,13.09,68.48C827.41,760.59,863.02,751.1,899.01,741.51z M361.43,470.72c-0.53-0.17-1.05-0.34-1.58-0.51
		c-17.13,33.78-34.26,67.56-51.61,101.77c21.8,13.68,24.74,34.9,23.37,57.84c10.59,0,20.08,0,29.81,0
		C361.43,576.33,361.43,523.52,361.43,470.72z M228.16,630.49c23.17,0,44.95,0.02,66.72-0.03c1.75,0,3.5-0.43,5.21-0.65
		c0.37-1.25,0.82-2.07,0.84-2.91c0.09-4.85,0.23-9.7,0.08-14.54c-0.33-10.66-5.04-15.42-15.79-15.59
		c-12.12-0.19-24.24-0.07-36.36-0.05c-15.29,0.03-20.55,5.32-20.69,20.79C228.12,621.42,228.16,625.33,228.16,630.49z
		 M810.24,935.14c-11.35-0.07-21.42,9.63-21.28,20.51c0.15,11.64,9.98,21.6,21.46,21.72c10.59,0.11,21.33-10.33,21.39-20.79
		C831.87,945.06,821.97,935.22,810.24,935.14z"
        />
        <path
          className={cls}
          d="M481.68,73.5c13.95,6.23,29.45,10.31,41.54,19.09c33.09,24.04,44.29,68.14,28.95,105.09
		c-15.57,37.5-54.39,59.71-94.73,54.18c-38.35-5.25-70.2-38.13-75.64-78.07c-6.17-45.27,25.03-88.95,70.28-98.46
		c2.01-0.42,3.94-1.22,5.91-1.84C465.88,73.5,473.78,73.5,481.68,73.5z M529.57,162.22c-0.08-32.82-26.85-59.57-59.59-59.55
		c-32.71,0.02-59.53,26.87-59.55,59.62c-0.01,32.23,27.57,59.97,59.61,59.95C502.13,222.22,529.64,194.48,529.57,162.22z"
        />
      </g>
    </svg>
  );
};
export const OldPersonIcon = ({ cls }) => {
  return (
    <svg
      version={"1.1"}
      id={"Capa_7"}
      xmlns={"http://www.w3.org/2000/svg"}
      xmlnsXlink={"http://www.w3.org/1999/xlink"}
      x={"0px"}
      y={"0px"}
      viewBox={"0 0 1080 1080"}
      style={{
        enableBackground: "new 0 0 1080 1080",
        height: "4rem",
      }}
      /* xmlSpace={"preserve"} */
      className={cls}
    >
      <path
        d="M334,590c0,0-23.23-12,78.38-50S493,473,518,448s52-60,188-35s123,272,95,354c-17.84,52.26-62,56-60,122
	s12,123-64,108s-74.01,8.87-79-84c-5-93,13-451-43.5-392.5l-117,79l-80,23C357.5,622.5,337.5,616.5,334,590z"
        fill={"#E8E8E8"}
      />
      <g>
        <path
          className={cls}
          d="M540.1,540.25c-19.16,16.95-36.59,32.36-54,47.79c-7.65,6.78-15.54,13.33-22.87,20.44
		c-17.9,17.38-39.28,26.78-63.94,29.52c-12.76,1.42-25.4,4.01-38.17,5.15c-6.63,0.59-8.09,3.2-8.05,9.33
		c0.24,37.1,0.12,74.21,0.12,111.32c0,65.74,0,131.48-0.01,197.22c0,3.21,0.08,6.44-0.19,9.63c-0.92,10.92-8.54,18.26-18.68,18.15
		c-10.01-0.1-17.73-7.74-18.53-18.49c-0.22-2.91-0.09-5.84-0.09-8.76c0-103.72-0.06-207.44,0.12-311.16
		c0.01-6.47-1.59-10.06-7.6-13.64c-20.5-12.21-30.56-30.93-29.56-54.85c0.99-23.65,12.77-40.97,33.62-51.65
		c34.01-17.43,68.19-34.54,102.5-51.35c13.02-6.38,22.68-15.66,30.98-27.46c12.56-17.85,24.77-36.39,39.97-51.85
		c27.08-27.55,61.16-42.63,99.84-45.45c20.35-1.49,40.87-1.41,61.29-1.02c26.31,0.5,48.53,10.85,67,29.77
		c32.73,33.53,52.02,74.32,64.54,118.72c17.98,63.78,23.4,129.16,22.96,195.07c-0.26,40.26-13.77,76.67-36.39,109.74
		c-14.24,20.82-27.77,42.11-32.66,67.24c-2.89,14.81-4.3,30.01-5.06,45.1c-0.86,16.9,0.11,33.89-0.38,50.82
		c-0.33,11.57-8.3,19.19-18.66,19.22c-9.7,0.03-18.49-7.05-18.53-17.36c-0.15-35.55-2.99-71.25,5.79-106.39
		c6.98-27.93,19.83-52.86,36.26-76.2c18.18-25.83,30.12-54.25,31.8-85.91c1.08-20.35,0.08-40.89-1.09-61.27
		c-2.79-48.78-8.79-97.1-24.24-143.76c-11.13-33.64-26.89-64.6-52.34-89.96c-11.33-11.29-24.73-17.7-40.75-17.19
		c-24.74,0.8-49.86-0.11-74.1,3.96c-32.35,5.44-57.64,24.45-76.65,51.27c-6.92,9.76-13.48,19.79-20.05,29.8
		c-10.01,15.27-23.19,26.79-39.56,34.92c-35.01,17.39-69.95,34.93-104.83,52.58c-11.78,5.96-16.6,16.95-13.06,28.32
		c3.68,11.81,14.31,18.18,27.4,16.39c4.05-0.55,8.09-1.13,12.14-1.73c14.44-2.13,28.84-4.54,43.32-6.34
		c13.23-1.65,24.48-6.96,34.38-15.81c26.58-23.75,53.29-47.35,79.99-70.95c12.63-11.16,26.89-13.75,42.22-6.63
		c12.27,5.7,19.27,15.74,20.8,29.3c0.39,3.47,0.42,7,0.42,10.5c0.02,138.78,0.02,277.57,0.01,416.35c0,2.92,0.04,5.86-0.26,8.75
		c-1.08,10.37-8.67,17.42-18.5,17.38c-9.67-0.04-17.5-7.33-18.56-17.52c-0.33-3.18-0.16-6.42-0.16-9.63
		c-0.01-136.15,0-272.31-0.01-408.46C540.1,549.71,540.1,546.23,540.1,540.25z"
        />
        <path
          className={cls}
          d="M689.72,203.53c-0.14,62.22-50.7,112.43-112.89,112.11c-61.46-0.32-111.94-51.4-111.44-112.77
		c0.5-61.84,50.83-111.76,112.55-111.65C639.58,91.31,689.86,141.83,689.72,203.53z M503.01,203.49
		c0.03,41.17,33.69,74.89,74.68,74.8c41.08-0.1,74.59-33.78,74.57-74.95c-0.03-41.16-33.7-74.86-74.72-74.78
		C536.44,128.63,502.98,162.27,503.01,203.49z"
        />
      </g>
    </svg>
  );
};
export const CalendarCheckIcon = ({ cls }) => {
  return (
    <svg
      version={"1.1"}
      id={"Capa_8"}
      xmlns={"http://www.w3.org/2000/svg"}
      xmlnsXlink={"http://www.w3.org/1999/xlink"}
      x={"0px"}
      y={"0px"}
      viewBox={"0 0 1080 1080"}
      style={{
        enableBackground: "new 0 0 1080 1080",
        height: "1.25rem",
        marginTop: "-3px",
      }}
      /* xmlSpace={"preserve"} */
      className={cls}
    >
      <path
        d="M640.46,792.04c0,0,10.63-129,170.1-142.85s206.48,89.53,206.84,198.27s-72.79,151.89-155.01,171.4
	C780.16,1038.37,636.21,993.14,640.46,792.04z"
        fill={"#E8E8E8"}
      />
      <g>
        <path
          className={cls}
          d="M996.25,652.16c-5.35,5.96-10.78,11.93-20.01,8.55c-8.5-3.11-10.48-9.9-10.47-18.24
		c0.11-68.74,0.06-137.48,0.06-206.22c0-15.77,0-31.53,0-47.81c-284.12,0-567.43,0-851.67,0c0,3.78,0,7.18,0,10.58
		c0,151.42,0,302.83,0,454.25c0,33.49,18.16,51.73,51.54,51.73c136.53,0.01,273.06,0.01,409.59,0.01c2.53,0,5.07-0.04,7.6,0.01
		c11.12,0.23,17.95,6.11,17.85,15.33c-0.09,9.17-6.93,15.04-18.13,15.05c-141.28,0.04-282.56,0.16-423.84-0.1
		c-35.1-0.06-64.58-24.28-72.99-58.35c-0.52-2.09-1.35-4.11-2.03-6.16c0-210.38,0-420.76,0-631.15c0.68-2.05,1.51-4.07,2.03-6.16
		c8.39-34.04,37.89-57.84,72.94-58.39c19.94-0.31,39.9-0.07,59.84-0.08c5.58,0,11.16,0,17.32,0c0-11.66-0.24-22.08,0.05-32.47
		c0.7-24.73,16.61-46.5,39.86-55.03c3.18-1.17,6.31-2.5,9.46-3.75c6.97,0,13.94,0,20.91,0c48.24,12.43,54.97,48.97,50.85,91.25
		c21.7,0,41.58,0,61.46,0c20.09,0,40.17,0,60.72,0c0-11.48-0.19-21.58,0.04-31.66c0.57-25.65,16.64-47.59,40.81-56.12
		c2.89-1.02,5.7-2.3,8.54-3.46c6.97,0,13.94,0,20.91,0c48.24,12.43,54.97,48.97,50.92,90.55c41.71,0,81.67,0,122.11,0
		c0-10.78-0.19-20.88,0.04-30.96c0.57-25.65,16.64-47.59,40.81-56.12c2.89-1.02,5.7-2.3,8.54-3.46c6.97,0,13.94,0,20.91,0
		c48.3,12.42,54.95,49.03,50.86,91.28c27.14,0,52.43-0.62,77.69,0.15c32.04,0.98,54.94,16.83,68.54,45.77
		c2.78,5.91,4.25,12.43,6.32,18.68C996.25,377.14,996.25,514.65,996.25,652.16z M357.5,205.89c0,9.49,0.04,18.31-0.01,27.13
		c-0.2,36.24-25.98,63.41-60.35,63.63c-34.83,0.22-61.12-27.05-61.3-63.61c-0.04-9.05-0.01-18.09-0.01-27.5
		c-27.3,0-53.27-0.62-79.19,0.19c-22.6,0.7-41.77,19.86-42.19,42.45c-0.67,35.78-0.1,71.59,0,107.39c0,0.49,0.69,0.98,1.07,1.48
		c283.13,0,566.26,0,849.89,0c0-37.75,1.05-75.09-0.42-112.32c-0.8-20.28-18.28-37.38-38.51-38.52
		c-27.06-1.53-54.28-0.38-82.32-0.38c0,9.96,0.09,19.38-0.02,28.81c-0.38,35.17-26.89,62.1-60.99,62.02
		c-34.03-0.08-60.34-27.12-60.64-62.37c-0.08-9.39-0.01-18.79-0.01-28.15c-41.18,0-81.2,0-121.68,0c0,10.17,0.11,19.65-0.02,29.13
		c-0.47,34.55-26.96,61.33-60.66,61.4c-33.7,0.07-60.47-26.72-60.95-61.09c-0.14-9.74-0.02-19.48-0.02-29.66
		C438.46,205.89,398.66,205.89,357.5,205.89z M266.25,190.21c0,14.57-0.1,29.14,0.02,43.71c0.16,18.59,12.94,32.18,30.17,32.3
		c17.28,0.12,30.48-13.42,30.59-31.86c0.17-29.45,0.17-58.91,0-88.36c-0.11-18.42-13.37-31.95-30.65-31.8
		c-17.3,0.15-29.95,13.63-30.11,32.31C266.16,161.07,266.25,175.64,266.25,190.21z M509.59,190.39c0,14.89-0.17,29.77,0.04,44.66
		c0.25,17.77,13.43,31.15,30.32,31.17c16.91,0.02,30.24-13.31,30.38-31.09c0.23-29.77,0.2-59.54,0.02-89.31
		c-0.11-18.35-13.53-31.86-30.82-31.62c-16.94,0.24-29.7,13.55-29.91,31.54C509.45,160.62,509.59,175.51,509.59,190.39z
		 M752.92,189.79c0,14.57-0.08,29.14,0.02,43.71c0.13,19.16,13.09,32.9,30.7,32.72c17.24-0.17,29.99-13.72,30.08-32.38
		c0.13-29.14,0.14-58.28,0-87.41c-0.09-18.58-12.99-32.15-30.23-32.23c-17.36-0.08-30.33,13.36-30.53,31.89
		C752.8,160.65,752.92,175.22,752.92,189.79z"
        />
        <path
          className={cls}
          d="M781.43,996.25c-18.91-4.84-38.53-7.86-56.62-14.82c-79.88-30.76-131.37-114.53-123.41-198.69
		c8.54-90.35,73.69-162.6,160.79-178.32c113.04-20.41,216.76,55.38,232.4,169.76c0.25,1.83,1.09,3.58,1.66,5.36
		c0,12.67,0,25.35,0,38.02c-0.55,1.43-1.4,2.81-1.6,4.29c-10.7,81.93-65.32,145.19-144.89,167.38c-10.57,2.95-21.47,4.72-32.22,7.03
		C805.51,996.25,793.47,996.25,781.43,996.25z M631.28,799.17c-0.04,91.52,75,166.33,167.17,166.65
		c91.52,0.31,167.26-75.05,167.37-166.51c0.11-92.27-74.48-167.86-165.78-168.03C706.22,631.1,631.32,705.62,631.28,799.17z"
        />
        <path
          className={cls}
          d="M266.52,570.41c-10.14,0-20.27,0.19-30.4-0.04c-17.68-0.41-30.35-13.11-30.64-30.83
		c-0.24-14.88-0.24-29.77,0-44.66c0.29-17.84,12.83-30.59,30.55-30.84c20.27-0.28,40.54-0.31,60.81,0.01
		c17.12,0.27,29.81,13.09,30.16,30.29c0.31,15.2,0.31,30.41,0,45.61c-0.36,17.63-13.18,30.07-31.03,30.43
		C286.16,570.57,276.34,570.41,266.52,570.41z M296.28,495.09c-20.61,0-40.32,0-59.98,0c0,15.14,0,29.57,0,44.37
		c20.18,0,39.89,0,59.98,0C296.28,524.49,296.28,510.06,296.28,495.09z"
        />
        <path
          className={cls}
          d="M449.14,570.41c-10.14,0-20.27,0.18-30.4-0.04c-17.71-0.39-30.45-13.04-30.75-30.72
		c-0.26-14.88-0.24-29.77-0.01-44.66c0.27-17.87,12.77-30.7,30.44-30.96c20.27-0.3,40.54-0.3,60.81,0
		c17.17,0.26,29.9,13.01,30.28,30.18c0.33,15.2,0.3,30.41,0.01,45.61c-0.34,17.7-13.08,30.17-30.91,30.54
		C468.78,570.58,458.96,570.41,449.14,570.41z M478.82,495.12c-20.71,0-40.42,0-59.98,0c0,15.18,0,29.6,0,44.31
		c20.21,0,39.93,0,59.98,0C478.82,524.42,478.82,510,478.82,495.12z"
        />
        <path
          className={cls}
          d="M631.26,463.98c10.14-0.01,20.28-0.22,30.4,0.04c17.18,0.43,29.95,13,30.32,30.15
		c0.33,15.51,0.38,31.05-0.03,46.55c-0.44,16.63-13.16,29.29-29.87,29.58c-20.58,0.37-41.18,0.37-61.76,0
		c-16.66-0.3-29.41-13.06-29.81-29.65c-0.38-15.83-0.43-31.68,0.03-47.5c0.48-16.2,13.08-28.51,29.35-29.09
		C610.35,463.69,620.81,463.99,631.26,463.98z M661.07,539.83c0-15.5,0-30.15,0-44.78c-20.32,0-40.03,0-59.47,0
		c0,15.35,0,30,0,44.78C621.63,539.83,640.89,539.83,661.07,539.83z"
        />
        <path
          className={cls}
          d="M814.01,463.97c9.82-0.01,19.64-0.16,29.45,0.02c17.82,0.33,30.68,12.8,31.03,30.42
		c0.31,15.2,0.31,30.41,0,45.61c-0.36,17.22-12.99,30.01-30.16,30.3c-20.58,0.34-41.18,0.38-61.76-0.03
		c-16.59-0.33-29.21-13.23-29.57-29.89c-0.34-15.51-0.35-31.04,0.01-46.55c0.39-16.73,12.95-29.26,29.64-29.8
		C793.1,463.71,803.56,463.98,814.01,463.97z M843.75,495.05c-20.51,0-40.22,0-59.65,0c0,15.35,0,30.01,0,44.44
		c20.26,0,39.75,0,59.65,0C843.75,524.56,843.75,510.13,843.75,495.05z"
        />
        <path
          className={cls}
          d="M449.21,737.73c10.14,0,20.28-0.25,30.4,0.06c16.66,0.5,29.43,13.01,29.85,29.63
		c0.4,15.83,0.45,31.68-0.03,47.5c-0.48,16.14-13.15,28.77-29.34,29.1c-20.89,0.43-41.81,0.43-62.71,0
		c-16.16-0.33-28.87-13.04-29.33-29.12c-0.45-15.82-0.41-31.68-0.03-47.5c0.41-16.67,13.09-29.12,29.82-29.61
		C428.3,737.47,438.76,737.73,449.21,737.73z M478.65,813.32c0-15.54,0-29.96,0-44.6c-20.23,0-39.95,0-59.63,0
		c0,15.26,0,29.91,0,44.6C439.19,813.32,458.68,813.32,478.65,813.32z"
        />
        <path
          className={cls}
          d="M266.67,737.73c10.14,0,20.28-0.24,30.4,0.05c16.67,0.49,29.46,12.98,29.89,29.59
		c0.41,15.83,0.44,31.68-0.02,47.5c-0.48,16.15-13.12,28.8-29.29,29.15c-20.89,0.44-41.81,0.44-62.71,0
		c-16.56-0.35-29.09-13.32-29.43-30.03c-0.32-15.51-0.36-31.04,0.02-46.55c0.41-16.7,13.05-29.15,29.78-29.66
		C245.75,737.47,256.21,737.73,266.67,737.73z M295.93,813.39c0-15.6,0-30.25,0-44.79c-20.23,0-39.72,0-59.53,0
		c0,15.13,0,29.78,0,44.79C256.45,813.39,275.94,813.39,295.93,813.39z"
        />
        <path
          className={cls}
          d="M266.57,600.84c9.82,0,19.64-0.16,29.45,0.03c17.8,0.34,30.63,12.83,30.98,30.48
		c0.3,15.2,0.32,30.41-0.01,45.61c-0.36,17.21-13.02,29.96-30.21,30.24c-20.26,0.33-40.54,0.31-60.81,0.01
		c-17.64-0.26-30.23-13.15-30.5-30.94c-0.23-14.88-0.25-29.77,0.01-44.66c0.3-17.79,12.91-30.34,30.69-30.73
		C246.3,600.66,256.44,600.84,266.57,600.84z M236.2,676.15c20.65,0,40.36,0,59.98,0c0-15.16,0-29.58,0-44.34
		c-20.19,0-39.91,0-59.98,0C236.2,646.79,236.2,661.21,236.2,676.15z"
        />
        <path
          className={cls}
          d="M448.41,707.29c-9.5,0-19,0.11-28.5-0.02c-18.98-0.25-31.7-12.72-31.94-31.47
		c-0.19-14.57-0.2-29.14,0.01-43.71c0.26-18.36,12.83-30.99,31.17-31.2c19.63-0.22,39.27-0.2,58.91-0.01
		c18.76,0.18,31.28,12.92,31.5,31.89c0.16,14.25,0.17,28.51,0,42.76c-0.24,18.93-12.77,31.44-31.68,31.73
		C468.05,707.41,458.23,707.29,448.41,707.29z M418.82,631.65c0,15.51,0,29.93,0,44.66c20.2,0,39.92,0,59.69,0
		c0-15.23,0-29.88,0-44.66C458.36,631.65,438.88,631.65,418.82,631.65z"
        />
        <path
          className={cls}
          d="M601.27,631.24c0,15.45,0,29.93,0,44.75c1.8,0.75,3.82,1.47,5.73,2.42c7.16,3.58,10.35,9.53,8.44,17.2
		c-1.89,7.59-7.42,11.53-15.38,11.63c-14.74,0.2-28.81-12.65-29.41-28.1c-0.65-16.76-0.66-33.6,0.02-50.36
		c0.62-15.23,13.23-27.4,28.6-27.77c20.58-0.51,41.19-0.33,61.78-0.06c9.58,0.12,15.96,6.82,15.75,15.44
		c-0.21,8.56-6.65,14.68-16.45,14.79C641.03,631.39,621.71,631.24,601.27,631.24z"
        />
        <path
          className={cls}
          d="M769.2,855.27c3.24-4.64,5.01-8.16,7.64-10.81c34.83-35.02,69.77-69.92,104.71-104.82
		c2.23-2.23,4.42-4.63,7.03-6.34c6.7-4.41,13.37-3.76,18.99,1.87c5.62,5.64,6.19,12.31,1.79,19c-1.72,2.61-4.09,4.81-6.32,7.04
		c-39.83,39.87-79.67,79.72-119.53,119.56c-12.34,12.33-18.43,12.27-30.92-0.22c-19.03-19.02-38.35-37.78-56.84-57.32
		c-3.81-4.02-6.94-11.31-6.19-16.49c1.81-12.48,16.55-15.39,26.49-5.71c14.74,14.36,29.17,29.03,43.69,43.62
		C762.17,847.11,764.36,849.83,769.2,855.27z"
        />
      </g>
    </svg>
  );
};
export const ReturnIcon = ({ cls }) => {
  return (
    <svg
      version={"1.1"}
      id={"Capa_9"}
      xmlns={"http://www.w3.org/2000/svg"}
      xmlnsXlink={"http://www.w3.org/1999/xlink"}
      x={"0px"}
      y={"0px"}
      viewBox={"0 0 1080 1080"}
      style={{
        enableBackground: "new 0 0 1080 1080",
        height: "1.25rem",
        marginTop: "-3px",
      }}
      /* xmlSpace={"preserve"} */
      className={cls}
    >
      <path
        d="M274.37,920.17c0,0-216.44-176.62-46.12-477.15s411.65-257.79,605.5-125.49s181.16,315.14,115.33,485.22
	S626.83,1173.58,274.37,920.17z"
        fill={"#E8E8E8"}
      />
      <g>
        <path
          className={cls}
          d="M517.41,961.75c-16.15-1.8-32.36-3.2-48.45-5.47c-63.09-8.88-121.11-31.43-174.1-66.57
		c-65.84-43.67-116.03-101.3-149.27-172.99c-10.23-22.06-17.51-45.55-25.23-68.69c-4.15-12.44,1.79-23.24,12.65-26.56
		c10.71-3.27,20.56,2.73,25.38,15.03c10.38,26.49,18.74,54.04,31.81,79.14c42.6,81.82,107.91,139.75,192.7,175.65
		c57.37,24.28,117.58,34.22,179.37,28.55c131.29-12.04,233.01-73.63,302.45-186.48c9.32-15.15,16.55-31.61,24.25-47.7
		c3.61-7.54,6.03-15.64,9.65-25.24c-4.59,1.86-7.61,2.96-10.53,4.28c-31.95,14.51-63.78,29.28-95.91,43.38
		c-5.38,2.36-13.17,4.22-17.82,2.02c-5.67-2.67-11.7-9.36-12.92-15.28c-1.12-5.44,3.18-12.63,6.58-18.13
		c1.87-3.02,6.68-4.41,10.37-6.09c48.18-21.96,96.39-43.85,144.6-65.73c18.91-8.58,28.2-4.86,35.94,14.48
		c21.05,52.61,42.05,105.24,63.08,157.87c0,3.14,0,6.28,0,9.41c-2.6,3.42-4.65,7.52-7.89,10.14c-11.4,9.25-25.64,4.31-32.01-11.29
		c-13.83-33.89-27.3-67.92-40.94-101.88c-1.17-2.9-2.53-5.72-4.57-10.3c-25.82,73.39-66.22,134.34-123.37,183.81
		c-62.43,54.04-134.73,86.92-216.34,99.16c-16.36,2.45-32.9,3.67-49.35,5.45C537.49,961.75,527.45,961.75,517.41,961.75z"
        />
        <path
          className={cls}
          d="M562.59,118.25c16.15,1.79,32.35,3.18,48.43,5.45c62.39,8.84,119.95,30.88,172.46,65.5
		c66.16,43.62,117,101.06,150.41,173.07c10.23,22.06,17.68,45.46,25.61,68.51c4.5,13.08-0.93,23.99-12.07,27.63
		c-10.92,3.57-20.93-2.64-25.92-15.48c-10.2-26.21-18.51-53.43-31.4-78.26c-42.5-81.83-107.7-139.79-192.43-175.77
		c-57.33-24.34-117.4-33.67-179.27-28.9c-86.06,6.63-162.31,37.91-227.36,95.17c-48.5,42.69-83.66,94.58-105.74,155.31
		c-0.93,2.55-1.81,5.11-3.34,9.43c13.12-5.92,24.68-11.1,36.21-16.34c23.66-10.76,47.29-21.59,70.97-32.31
		c8.41-3.81,16.64-3.69,23.61,2.94c5.94,5.65,8.41,13.29,4.8,20.55c-2.58,5.19-7.38,10.59-12.54,12.99
		c-50.27,23.35-100.76,46.22-151.33,68.93c-15.09,6.77-25.04,1.97-31.61-14.45C101.94,411.93,81.91,361.6,61.81,311.3
		c-1.16-2.89-2.54-5.69-3.81-8.54c0-3.14,0-6.28,0-9.41c8.77-15.97,23.38-20.14,33.83-9.2c3.33,3.48,5.51,8.33,7.36,12.91
		c13.38,33.07,26.53,66.23,39.79,99.34c1.18,2.94,2.58,5.78,4.37,9.77c1.42-3.28,2.25-4.96,2.89-6.72
		c24.88-69.27,64.89-128.27,120.51-176.48c62.42-54.11,134.75-86.97,216.37-99.25c16.36-2.46,32.9-3.68,49.36-5.47
		C542.51,118.25,552.55,118.25,562.59,118.25z"
        />
      </g>
    </svg>
  );
};
export const UserIcon = ({ cls }) => {
  return (
    <svg
      version={"1.1"}
      id={"Capa_10"}
      xmlns={"http://www.w3.org/2000/svg"}
      xmlnsXlink={"http://www.w3.org/1999/xlink"}
      x={"0px"}
      y={"0px"}
      viewBox={"0 0 1080 1080"}
      style={{
        enableBackground: "new 0 0 1080 1080",
        height: "1.25rem",
        marginTop: "-3px",
      }}
      /* xmlSpace={"preserve"} */
      className={cls}
    >
      <g>
        <path
          d="M210,822c0,0-5-314,345-314s413.54,260.57,413.54,353.79S754,1009,754,1009s-206.02,26-326.51,0
		S210,940.36,210,940.36L210,822z"
          fill={"#E8E8E8"}
        />
        <path
          className={cls}
          d="M651.9,455.07c59.58,12.62,111.31,37.05,155.81,75.49c78.96,68.22,120.25,154.72,123.08,259.22
		c0.62,22.93-0.25,45.91,0.33,68.84c0.3,11.55-3.97,19.22-13.76,25.23c-89.19,54.7-185.33,89.57-289.4,101.77
		c-166.04,19.48-320.57-14.01-463.59-100.84c-11.19-6.79-15.81-14.92-15.46-27.91c1.14-42.93-3.03-86.2,6.82-128.58
		c33.49-144,122.4-234.09,265.04-271.59c1.57-0.41,3.17-0.75,4.73-1.21c0.26-0.08,0.39-0.63,1.2-2.04
		c-64.71-45.13-99.5-106.97-96.97-186.78c1.9-59.8,26.91-109.88,71.64-149.74c85.71-76.38,218.08-67.88,295.93,18.35
		C777.29,223.89,770.49,376.84,651.9,455.07z M548.28,486.29c-24.54,0-41.34-0.21-58.13,0.03c-128.95,1.85-245.27,87.08-287,209.26
		c-16.22,47.51-17.13,96.38-16.86,145.65c0.05,9.28,2.53,14.53,10.81,19.3c130.54,75.13,270.5,104.89,420.23,88.37
		c95.34-10.52,184.09-40.75,266.86-89.07c6.83-3.99,9.82-8.18,9.5-16.34c-0.74-18.41,0.1-36.89-0.49-55.31
		c-4.29-135.01-92.85-250.91-220.05-288.97C629.7,486.21,585.26,485.2,548.28,486.29z M712.67,275.89
		c0.23-95.31-76.99-173.14-172.01-173.38c-95.34-0.24-172.8,76.64-173.31,172.03c-0.51,95.8,77.1,174.12,172.59,174.16
		C634.98,448.74,712.44,371.24,712.67,275.89z"
        />
      </g>
    </svg>
  );
};
export const LocationIcon = ({ cls }) => {
  return (
    <svg
      version={"1.1"}
      id={"Capa_11"}
      xmlns={"http://www.w3.org/2000/svg"}
      xmlnsXlink={"http://www.w3.org/1999/xlink"}
      x={"0px"}
      y={"0px"}
      viewBox={"0 0 1080 1080"}
      style={{
        enableBackground: "new 0 0 1080 1080",
        height: "1.25rem",
        marginTop: "-3px",
      }}
      /* xmlSpace={"preserve"} */
      className={cls}
    >
      <path
        d="M380.74,193.35c76.82-67.89,184.91-86.97,281.48-52.55C752.36,172.93,862,244.57,862,403
	c0,269-290.08,466.46-290.08,466.46S295,629,295,391C295,297.5,333.72,234.9,380.74,193.35z"
        fill={"#E8E8E8"}
      />
      <g>
        <path
          className={cls}
          d="M65,996.46c6.79-15.83,13.57-31.67,20.37-47.5c1.09-2.55,2.05-5.19,3.43-7.57c3.89-6.71,9.54-9.61,17.15-6.79
		c7.05,2.62,10.45,9.74,7.8,17.43c-3,8.74-6.79,17.21-10.2,25.81c-1.1,2.78-2.11,5.6-3.49,9.26c30.64,0,60.25,0.06,89.86-0.15
		c1.86-0.01,4.72-1.79,5.42-3.45c15.86-37.78,31.48-75.67,47.76-115.01c-6.1,0-10.88,0-15.66,0c-23.8,0-47.6,0.16-71.4-0.12
		c-5.39-0.06-8.47,1.3-10.22,6.67c-2.1,6.43-5.09,12.58-7.69,18.85c-5.54,13.34-12.07,17.88-20.6,14.31
		c-8.99-3.76-10.6-11.36-5.15-24.57c18.6-45.06,37.23-90.11,55.83-135.18c10.95-26.52,22.09-52.97,32.67-79.65
		c3.87-9.75,10.12-13.61,20.5-13.53c39.56,0.32,79.13,0.13,118.69,0.13c3.3,0,6.6,0,12,0c-3.61-5.19-6.14-8.88-8.72-12.53
		c-39.6-56.13-72.13-115.8-90.07-182.57c-21.75-80.97-17.66-160.05,22.38-234.77c41.62-77.67,105.61-128.58,191.27-150.89
		c17.8-4.64,36.32-6.5,54.51-9.64c12.37,0,24.74,0,37.11,0c2.7,0.51,5.4,0.96,8.09,1.52c22.89,4.81,46.43,7.65,68.55,14.83
		c63.23,20.51,113.21,59.68,151.07,114.09c30.26,43.5,47.43,92.08,52.1,144.77c4.03,45.47-2.11,89.99-15.46,133.57
		c-18.83,61.5-49.51,116.93-86.33,169.24c-2.56,3.64-5.08,7.32-8.59,12.37c5.49,0,8.84,0,12.18,0c38.95,0,77.91-0.01,116.86,0.01
		c14.8,0.01,16.94,1.48,22.71,15.43c31.48,76.15,62.9,152.33,94.46,228.45c13.45,32.45,27.2,64.78,40.82,97.16
		c0,6.18,0,12.36,0,18.54c-316.67,0-633.33,0-950,0C65,1008.81,65,1002.63,65,996.46z M539.71,814.23
		c44.68-37.97,85.27-77.73,122.31-121.04c59.94-70.09,110.4-145.75,136.27-235.54c14.06-48.82,18.2-98.29,7.31-148.34
		c-12.82-58.91-41.62-108.75-87.09-148.39c-60.59-52.83-131.44-74.94-211.09-65.64c-68.43,7.99-125.76,38.93-171.09,90.89
		c-52.33,60-73.81,130.45-66.64,209.29c5.08,55.82,24.32,107.59,50.39,156.82c44.34,83.71,105.03,154.66,173.12,219.69
		C508.12,786.23,523.75,799.77,539.71,814.23z M160.22,840.59c33.22,0,64.74-0.01,96.25,0c19.62,0.01,24.91,8.09,17.35,26.39
		c-15.21,36.8-30.44,73.59-45.63,110.4c-1.22,2.97-2.17,6.04-3.39,9.47c17.67,0,34.34,0,51.14,0c1.36-2.96,2.73-5.73,3.9-8.57
		c21.2-51.36,42.37-102.73,63.56-154.1c9.89-23.97,19.67-47.99,29.74-71.88c5.69-13.49,14.8-15.54,26.21-6.36
		c1.44,1.16,2.81,2.42,4.22,3.62c91.36,77.74,182.7,155.51,274.2,233.09c2.96,2.51,7.6,4.19,11.49,4.27
		c21.95,0.4,43.91,0.22,65.86,0.16c2.24-0.01,4.47-0.44,7.88-0.8c-3.32-3.06-5.54-5.19-7.86-7.21
		c-41.43-36.23-82.87-72.44-124.3-108.68c-2.55-2.23-5.29-4.38-7.37-7.01c-6.94-8.79-2.2-20.79,8.86-22.53
		c3.02-0.48,6.17-0.25,9.26-0.25c89.06-0.01,178.11-0.01,267.17-0.01c3.25,0,6.49,0,11.01,0c-4.91-11.88-9.97-22.16-13.36-32.95
		c-3.15-10.05-8.14-13.76-19.13-13.26c-23.14,1.04-46.37,0.44-69.56,0.2c-9.27-0.09-14.68-5.13-15.13-12.93
		c-0.45-8.02,5.19-13.98,14.5-14.76c4.91-0.41,9.89-0.14,14.84-0.15c18.71-0.01,37.42,0,57.37,0
		c-10.83-26.18-21.25-50.87-31.19-75.75c-2.29-5.73-5.13-7.82-11.47-7.77c-44.22,0.31-88.44,0.03-132.66,0.39
		c-3.96,0.03-8.98,2.29-11.65,5.22c-21.91,23.94-43.38,48.28-64.96,72.52c-1.13,1.27-2.12,2.67-4.29,5.4c5.03,0,8.32,0,11.62,0
		c36.18,0,72.36-0.05,108.54,0.07c4.27,0.01,8.79,0.25,12.73,1.67c6.24,2.25,9.78,7.89,7.66,13.98c-1.58,4.52-5.97,8.69-10.15,11.44
		c-2.66,1.75-7.21,0.77-10.91,0.78c-45.46,0.03-90.91,0.19-136.37-0.13c-8.51-0.06-15.08,1.77-21.47,7.91
		c-12.92,12.41-26.66,23.98-40.3,35.61c-12.45,10.62-16.3,10.54-28.78,0.17c-51.99-43.21-99.61-90.83-142.28-143.24
		c-7.23-8.89-14.35-12.84-25.67-11.94c-6.26,0.5-9.09,2.36-11.37,8.04c-11.98,29.78-24.37,59.4-36.64,89.07
		c-5.41,13.09-7.45,14.51-21.37,14.52c-33.71,0.03-67.41-0.11-101.12,0.2c-3.31,0.03-8.43,1.85-9.59,4.3
		C171.48,812.25,166.32,825.78,160.22,840.59z M639.16,986.58C556.34,916.13,475.09,847,392.95,777.11
		c-29.09,70.42-57.66,139.58-86.52,209.47C417.37,986.58,527.14,986.58,639.16,986.58z M979.72,987.11
		c-0.62-2.16-0.84-3.34-1.29-4.42c-14.93-36.24-30.06-72.39-44.66-108.76c-2.43-6.05-6.4-5.46-10.98-5.46
		c-80.37,0.02-160.75,0.01-241.12,0.01c-2.64,0-5.27,0-8.8,0c1.1,1.85,1.38,2.74,1.98,3.26C717.62,909.22,760.4,946.7,803.33,984
		c2.08,1.8,5.45,2.97,8.23,2.98C867.19,987.16,922.82,987.11,979.72,987.11z M319.5,683.4c-31.2,0-61.01-0.14-90.8,0.29
		c-2.14,0.03-5.03,3.87-6.18,6.54c-7.21,16.68-14.03,33.52-20.98,50.32c-3.47,8.38-6.92,16.77-10.43,25.28
		c1.77,0.47,2.36,0.76,2.95,0.76c20.67,0.06,41.34,0.17,62.01,0.1c9.53-0.03,21.26,2.89,27.98-1.57
		c6.67-4.43,8.45-16.39,12.12-25.15C303.86,721.6,311.37,703.15,319.5,683.4z"
        />
        <path
          className={cls}
          d="M539.68,495.6c-94.06-0.2-156.16-100.52-114.53-185.02c4.55-9.24,11.83-12.65,19.42-9.09
		c7.28,3.41,9.93,11.49,5.71,20.68c-13.9,30.24-14.9,60.72,0.72,90.15c19.05,35.89,49.36,55.84,90.67,55.14
		c41.3-0.7,71.4-21.48,88.69-58.18c17.21-36.53,13.11-72.45-12.29-104.44c-32.58-41.02-84.56-50.32-129.57-24.12
		c-1.6,0.93-3.15,1.96-4.8,2.79c-8.62,4.36-15.98,2.65-20.06-4.61c-3.82-6.8-1.71-14.17,5.73-19.15
		c15.63-10.46,32.82-17.32,51.35-20.07c66.7-9.89,128.51,32.64,143.75,98.55C683.06,418.63,621.89,495.77,539.68,495.6z"
        />
      </g>
    </svg>
  );
};
export const FamilyIcon = ({ cls }) => {
  return(
  <svg
    version={"1.1"}
    id={"Capa_12"}
    xmlns={"http://www.w3.org/2000/svg"}
    xmlnsXlink={"http://www.w3.org/1999/xlink"}
    x={"0px"}
    y={"0px"}
    viewBox={"0 0 1080 1080"}
    style={{
      enableBackground: "new 0 0 1080 1080",
      height: "4rem"
    }}
    /* xmlSpace={"preserve"} */
    className={cls}
  >
    <path      
      d="M344,788c0,0-22.67-72.39,78.16-136.69c0,0,11.16-3.89,0-52.1s2.59-108.29,53.21-125.25
	s115.41,16.61,107.52,87.83C575,633,554.48,692.55,604.24,717.78C654,743,708.67,889.63,604,896c-115,7-184,5-222-17
	C348.2,859.43,344,788,344,788z" fill={"#E8E8E8"}
    />
    <g>
      <path
        className={cls}
        d="M87.5,668.93c3.3-15.44,5.46-31.23,10.11-46.25c22.42-72.47,69.26-122.92,139.38-151.87
		c2.96-1.22,5.91-2.47,9.03-3.77c-81.92-59.37-67.05-162.23-18.31-208.59c53.69-51.07,136.1-51.22,189.8,0
		c49.62,47.32,62.95,149.66-16.33,207.23c22.82,12.66,46.37,25.72,71.2,39.49c17.97-16.22,41.05-26.15,67.8-26.08
		c26.44,0.07,49.34,9.71,67.45,26.18c24.45-13.59,47.97-26.66,71.71-39.85c-41.15-31.12-61.64-73.74-55.92-127.16
		c3.83-35.8,20.9-65.27,48.7-88.1c56.63-46.51,138.73-38.89,188.1,17.13c45.24,51.33,48.02,145.24-25.71,199.09
		c13.29,6.48,27.17,12,39.79,19.61c73.8,44.51,113.09,110.03,118.06,196.06c1.71,29.65-11.17,52.97-31.89,72.59
		c-29.9,28.31-66.42,45.3-104.61,58.84c-70.38,24.94-143.42,36.8-217.59,42.84c-58.54,4.77-117.13,5.35-175.68,1.51
		c-89.03-5.83-176.71-19-260.09-52.76c-28.49-11.54-55.27-26.06-78.69-46.38c-18.08-15.69-29.93-35.03-36.3-57.93
		C87.5,690.12,87.5,679.53,87.5,668.93z M449.48,535.35c-48.29-38.25-102.68-52.51-163.15-41.84
		c-84.65,14.93-150.29,85.67-162.03,170.64c-4.59,33.23,5.74,57.49,32.64,75.85c8.01,5.46,16.04,10.98,24.49,15.69
		c40.74,22.73,85.02,35.62,130.21,45.68c19.1,4.25,38.43,7.48,57.69,11.18c13.09-87.05,32.97-116.4,106.04-157.04
		C439.79,621.43,431.41,581.35,449.48,535.35z M710.36,811.88c1.37,0,2.27,0.12,3.12-0.02c46.17-7.74,91.95-17.2,135.88-33.76
		c31.49-11.87,62.07-25.66,86.92-49.27c11.57-10.99,20.95-23.71,20.64-40.5c-1.02-55.85-21.02-104-61.6-142.59
		c-47.64-45.3-104.86-62.5-169.7-53.14c-35.63,5.15-67.4,19.88-95.03,42.99c18.12,45.96,9.5,85.91-25.82,119.95
		C671.01,687.18,706.11,738.69,710.36,811.88z M422.16,354.68c-0.05-55.03-44.32-99.28-99.4-99.35c-55.09-0.07-99.42,44-99.67,99.08
		c-0.25,55.23,44.73,100.24,99.84,99.92C378.06,454.01,422.21,409.67,422.16,354.68z M757.29,454.33
		c54.82,0.09,99.23-44.05,99.63-99.03c0.4-55.15-44.13-99.91-99.45-99.97c-55.01-0.06-99.23,43.85-99.62,98.91
		C657.45,409.38,702.03,454.24,757.29,454.33z M674.53,817.19c-0.81-51.51-21.02-92.16-64.91-118.43
		c-48.73-29.16-98.63-27.59-145.61,4.05c-39.84,26.83-58.3,66.05-58.38,114.45C495.71,827.22,584.64,827.31,674.53,817.19z
		 M604.23,579.32c0.78-34.97-27.6-64.02-63.24-64.74c-35.26-0.72-64.34,27.3-65.16,62.79c-0.82,35.65,27.23,64.79,63.2,65.63
		C574.41,643.82,603.42,615.49,604.23,579.32z"
      />
      <path
        className={cls}
        d="M927.42,681c0,3.55,0.16,6.2-0.03,8.83c-0.71,9.55-7.02,16.11-16.04,16.82c-9.13,0.71-16.55-4.55-18.22-14.13
		c-1.82-10.38-2.07-21.04-4.03-31.38c-3.61-19.03-12.03-36.03-23.27-51.8c-7.63-10.7-5.87-21.9,3.57-27.59
		c8.6-5.19,17.64-3.04,24.55,6.22C914.72,615.85,925.94,647.14,927.42,681z"
      />
      <path
        className={cls}
        d="M838.93,579.24c-9.86-0.17-16.62-7.47-16.43-17.74c0.19-10.13,7.46-17.39,17.19-17.16
		c9.49,0.22,16.86,8.15,16.65,17.91C856.13,572.2,848.75,579.41,838.93,579.24z"
      />
    </g>
  </svg>
  );
};
