const FormatToastMessage = (title, arrayErrors) => {    
  return (
    <>
      <strong>{title}</strong>
      <ul style={{ color: 'var(--primary-inverse)'}}>
        {arrayErrors && arrayErrors.length > 0
          ? arrayErrors.map((error, index) => 
              <li key={index}>
                {error}
              </li>
            )
          : ""}
      </ul>
    </>
  );
};

export default FormatToastMessage;