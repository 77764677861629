import Queremos from "../../assets/Queremos.png";
import Comprometemos from "../../assets/Comprometemos.png";
import Sentimos from "../../assets/Sentimos.png";

const AboutUsHero3 = () => {

    return (
        <div className="about-us-hero3">
            <div className="about-us-hero3__container">
                <div className="about-us-hero3__wrapper">
                    <div className="about-us-hero3__slider">
                        <div className="about-us-hero3__item">
                            <div className="about-us-hero3__item_back"></div>
                            <div className="about-us-hero3__item_content">
                                <img src={Queremos} alt="Queremos" />
                                <p className="title">Queremos</p>
                                <p>Que disfrutes al máximo tu viaje con nuestro apoyo en todo lo que necesites.</p>
                            </div>
                        </div>
                        <div className="about-us-hero3__item">
                            <div className="about-us-hero3__item_back"></div>
                            <div className="about-us-hero3__item_content">
                                <img src={Comprometemos} alt="Comprometemos" />
                                <p className="title">Comprometemos</p>
                                <p>Entregar nuestro máximo 
                                    esfuerzo. Permanecer a tu 
                                    lado en todo momento.
                                    Entender y valorar tu 
                                    situación.</p>
                            </div>
                        </div>
                        <div className="about-us-hero3__item">
                            <div className="about-us-hero3__item_back"></div>
                            <div className="about-us-hero3__item_content">
                                <img src={Sentimos} alt="Sentimos" />
                                <p className="title">Sentimos</p>
                                <p>Dedicación por brindar 
                                    ayuda, incluso cuando
                                no la necesites</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsHero3;