import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MessageContext from "../../../contexts/MessageContext";
import FormatToastMessage from "../../../shared/FormatMessage";
import BuyingProcessService from "../../../services/BuyingProcessService";
import {
  diffTimeYears,
  convertToYYYYMMDD,
} from "../../../shared/validations/Validation";
import HeaderPublic from "../../components/HeaderPublic";
import PassengerForm from "../../components/PassengerForm";
import BuyingProcessDetails from "../../components/BuyingProcessDetails";

const BuyStep6 = ({ step = 6 }) => {
  let dataPassengers = [];
  const buyingData = BuyingProcessService.getInstance().buyingData;
  const companyData = BuyingProcessService.getInstance().businessInfo;
  //const [stateVersion, setStateVersion] = useState(0);
  const [lengthPassengers, setLengthPassengers] = useState(
    dataPassengers.length
  );
  const [ageMajor, setAgeMajor] = useState(false);
  const [statePassengers, setStatePassengers] = useState(dataPassengers);
  const [isComplete, setIsComplete] = useState(false);
  const [prevStep, setPrevStep] = useState(`/buy/step-${step - 1}`);
  const [nextStep, setNextStep] = useState(`/buy/step-${step + 1}`);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);

  /* const reRender = () => {
    setStateVersion(stateVersion + 1);
  }; */
  const init = async () => {
    if (BuyingProcessService.getInstance().getInvalidStepOn5() > 0) {
      return navigate(prevStep);
    }
  };

  const savePassengerData = (data, index) => {
    data.age = diffTimeYears({
      startDate: data.bornDate,
      endDate: convertToYYYYMMDD(new Date()),
    });
    dataPassengers = statePassengers;
    dataPassengers[index] = data;
    setLengthPassengers(dataPassengers.length);
    setAgeMajor(dataPassengers[0]?.age >= 18);
    setIsComplete(
      dataPassengers.every(
        (data) =>
          data.name !== "" &&
          data.lastName !== "" &&
          data.bornDate !== "" &&
          data.typeDoc &&
          data.typeDoc !== "0" &&
          data.docNumber !== ""
      )
    );
    setStatePassengers(dataPassengers);
  };

  const completeVerify = () => {
    const complete = lengthPassengers === buyingData.passengers;
    const isAdult = ageMajor;
    const fieldsComplete = isComplete;
    return complete && fieldsComplete && isAdult;
  };

  const onNext = () => {
    if (!completeVerify()) {
      document.scrollingElement.scrollTop = 70;
      return messageContext.setMessage({
        type: "error",
        text: FormatToastMessage(t("BUYING_PROCESS.ERROR.TITLE"), [
          "Por favor, asegúrate de que todos los formularios estén completos.",
          "El titular de la reserva debe ser mayor de 18 años.",
          "El tipo de documento debe estar seleccionado.",
        ]),
      });
    }

    const buyingProcessService = BuyingProcessService.getInstance();
    buyingProcessService.buyingData.passengersData = statePassengers;
    return navigate(nextStep);
  };

  useEffect(() => {
    if (companyData && companyData._id && companyData?.role !== "2") {
      setPrevStep(`/${companyData.subdomain}/buy/step-${step - 1}`);
      setNextStep(`/${companyData.subdomain}/buy/step-${step + 1}`);
    }
    init();
  }, []);

  return (
    <>
      <HeaderPublic
        businessInfo={
          companyData?.role !== "2" && companyData.subdomain !== "myglobal"
            ? companyData
            : null
        }
      />
      <div
        className="container"
        style={{
          minHeight: "83vh",
          textAlign: "center",
          display: "grid",
          gridTemplateColumns: "1fr",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <h1
          style={{ lineHeight: "1", marginBottom: "1rem", marginTop: "4rem" }}
        >
          {t("BUYING_PROCESS.COMPLETE_DATA")}
        </h1>
        <div className="complete-data">
          <div className="card wrap-benefits">
            <h3>Completa los datos de los pasajeros</h3>
            {Array.from({ length: buyingData.passengers }, (_, index) => (
              <PassengerForm
                key={index}
                titulo={`Pasajero ${index + 1}`}
                subtitulo={index === 0 ? "- Titular de la reserva" : null}
                handleData={savePassengerData}
                index={index}
                benefits={buyingData?.aditionalBenefits || null}
                dates={buyingData?.birthDate[index] || null}
              />
            ))}
            <div
              className="flex-between mobile-column desktop-min-width"
              style={{ width: "100%", marginTop: "0" }}
            >
              <div className="flex-start mobile-full-width">
                <button
                  type="button"
                  className="secondary outline mobile-full-width"
                  onClick={() => {
                    navigate(prevStep);
                  }}
                  style={{ marginTop: "0", marginBottom: 0 }}
                >
                  {t("GO_BACK")}
                </button>
              </div>
              {/* <div className="flex-end desktop">
                <button
                  type="button"
                  className="primary mx-auto btn-plan mobile-full-width"
                  onClick={onNext}
                  style={{ marginTop:"0", marginBottom:0}}
                >
                  {t("CONTINUE")}
                </button>
                </div> */}
            </div>
          </div>
          <div className="card details-buy">
            <BuyingProcessDetails
              buyingData={buyingData}
              aditionalBenefits={
                BuyingProcessService.getInstance().benefitsList
              }
              totalPrice={buyingData.planPrice}
              onNext={onNext}
              step={step}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyStep6;
