export const formatDate = (date, locale) => {
    const spreadDate = date.split("T")[0].split("-");
  let dateFormatted = "";
  const dateParse = new Date(
    // le resto 1 al mes porque en javascript los meses empiezan en 0
    Date.UTC(spreadDate[0], spreadDate[1]-1, spreadDate[2], 3, 0, 0)
  );
  const dateOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
  if (locale && typeof locale === "string")
    dateFormatted = dateParse.toLocaleDateString(locale, dateOptions);
  else dateFormatted = dateParse.toLocaleDateString(undefined, dateOptions);

  return dateFormatted
}