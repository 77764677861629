export const InitStyle = (businessInfo) => {
  const root = document.querySelector(":root");
  const primary = businessInfo?.primaryColor ?? "var(--original-primary)";
  const secondary = businessInfo?.secondaryColor ?? "var(--original-secondary)";

  root.style.setProperty("--primary", primary);
  root.style.setProperty("--secondary", secondary);

  root.style.setProperty("--primary-hover", primary);
  root.style.setProperty("--secondary-hover", secondary);

  root.style.setProperty("--primary-focus", primary);
  root.style.setProperty("--secondary-focus", secondary);
};

export const ResetStyle = () => {
  const root = document.querySelector(":root");
  root.style.setProperty("--primary", "var(--original-primary)");
  root.style.setProperty("--secondary", "var(--original-secondary)");
  root.style.setProperty("--primary-hover", "var(--original-primary)");
  root.style.setProperty("--secondary-hover", "var(--original-secondary)");
  root.style.setProperty("--primary-focus", "var(--original-primary)");
  root.style.setProperty("--secondary-focus", "var(--original-secondary)");
};
