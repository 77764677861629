import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

let benefits1 = [
    {
        title: '72 hs para denunciar un evento médico',
        desc: 'Única compañía que dispones de 72 hs. para notificar de una Emergencia.',
        isOpened: false
    },
    {
        title: 'Plan familiar',
        desc: `En todos los paquetes, con la compra 
        de 2 adultos mayores de 23 años, 
        viajan hasta 4 menores (23 años).`,
        isOpened: false
    },
    {
        title: 'Demora de equipaje',
        desc: `Se le reintegrará los gastos ocasionados por la
        compra de productos de aseo personal y vestimenta.`,
        isOpened: false
    },
    {
        title: 'Descuento de medicamentos',
        desc: `Descuentos en farmacias de EEUU de hasta 75%.`,
        isOpened: false
    },
    {
        title: 'Futuras mamás',
        desc: `Aumentamos tu cobertura para futuras mamás.`,
        isOpened: false
    },
   
]

let benefits2 = [
    {
        title: 'Mayor cobertura',
        desc: `Nuestras coberturas son por evento y no por
        máximo global, lo que hace aumentar
        tu protección por un menor costo.`,
        isOpened: false
    },
    {
        title: 'Enfermedades preexistentes',
        desc: `Aumentamos tu cobertura de enfermedades
        preexistentes hasta el 10% de los gastos médicos.`,
        isOpened: false
    },
    {
        title: 'Atención médica para deportes',
        desc: `Aumenta tu cobertura para la práctica de
        deportes amateurs y profesionales.`,
        isOpened: false
    },
    {
        title: 'Asistencia psicológica',
        desc: `Servicio de apoyo psicológico 24 hs en
        momentos de tensión emocional`,
        isOpened: false
    },
    {
        title: 'Repatriación sanitaria',
        desc: `Traslado del paciente en una aerolínea comercial
        en vuelo regular, con acompañamiento médico o
        de enfermera si fuese necesario.`,
        isOpened: false
    },
]


export const MoreBenefits2 = () => {

    const [stateVersion, setStateVersion] = useState(0);

    const reRender = () => setStateVersion(stateVersion + 1);

    const toggleBenefit = (index, list) => {        
        if (window.innerWidth > 992) return;
        list[index].isOpened = !list[index].isOpened;  
        reRender();      
    }

    return (
        <div className="more-benefits-2">
            <div className="container">
                <p className='section-title'><span>Beneficios</span> que puedes <span>sumar</span></p>
                <div className="benefits-list-wrapper">
                    <div className="benefits-list">                    
                        { benefits1 && benefits1.map((benefit, index) => (
                            <div className="benefit-wrapper" key={`benefit1_${index}`}>
                                <div className={`benefit ${benefit.isOpened ? 'benefit-opened' : ''}`}>                            
                                    <div className="benefit-text">
                                        <p className="title">{benefit.title}</p>
                                        <p className="desc">{benefit.desc}</p>
                                    </div>      
                                    <div className='benefit-icon'
                                        onClick={() => toggleBenefit(index, benefits1)}>
                                        <FontAwesomeIcon 
                                        size='sm'
                                        icon={ benefit.isOpened ? faMinus : faPlus} 
                                        color="var(--primary)" />         
                                    </div>                                     
                                </div>
                            </div>
                        ))}                                           
                    </div>
                    <div className="benefits-list">                    
                        { benefits2 && benefits2.map((benefit, index) => (
                            <div className="benefit-wrapper" key={`benefit2_${index}`}>
                                <div className={`benefit ${benefit.isOpened ? 'benefit-opened' : ''}`}>                            
                                    <div className="benefit-text">
                                        <p className="title">{benefit.title}</p>
                                        <p className="desc">{benefit.desc}</p>
                                    </div>      
                                    <div className='benefit-icon'
                                        onClick={() => toggleBenefit(index, benefits2)}>
                                        <FontAwesomeIcon 
                                        size='sm'
                                        icon={ benefit.isOpened ? faMinus : faPlus} 
                                        color="var(--primary)" />         
                                    </div>                                     
                                </div>
                            </div>
                        ))}                                           
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MoreBenefits2;