export const getCookie = ( {name} ) => {
  let cookieValue = document.cookie;
  if (name !== undefined && name !== null) {
    cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith(`${name}=`))
      ?.split("=")[1];
  }
  return cookieValue;
};

export const setCookie = ( {name, value, days, path, sameSite} ) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = `${name}=${value || ""}${expires}; path=${
    path || "/"
  }; sameSite=${sameSite || "Lax"}`;
};

export const updateCookie = ( {name, value, days, path, sameSite} ) => {
  let cookieValue = false;
  let expires = "";
  if (name !== undefined && name !== null) {
    cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith(`${name}=`));
    if (cookieValue) {
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = `${name}=${value || ""}${expires}; path=${
        path || "/"
      }; sameSite=${sameSite || "Lax"}`;
    }
  } else{
    return false;
  }

  return cookieValue;
};

export const deleteCookie = ({ name, path='/' }) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path};`;
};

export const deleteAllCookies = ({path}) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    deleteCookie({name: cookies[i].split("=")[0], path: path || "/"});
  }
};
