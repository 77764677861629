import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';

const GoBackLink = ({ to }) => {
    return (
        <Link to={to}>
            <FontAwesomeIcon 
            size='2x'
            style={{cursor: 'pointer'}}
            icon={faArrowLeft} />
        </Link>
    )
}

export default GoBackLink;