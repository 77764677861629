import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const promoCodeFindList = ({headers}) => {    
    return axios.get(`${API_URL}/code/promoCodeFindList/`, {headers});
}

export const promoCodeAdd = ({data, headers}) => {    
    return axios.post(`${API_URL}/code/promoCodeAdd/`, data, {headers});
}

export const promoCodeGetById = ({data, headers}) => {    
    return axios.get(`${API_URL}/code/promoCode/${data.id}`, {headers});
}

export const promoCodeEdit = ({data, headers}) => {    
    return axios.post(`${API_URL}/code/promoCodeEdit/`, data, {headers});
}

export const promoCodeDelete = ({data, headers}) => {    
    return axios.post(`${API_URL}/code/promoCodeDel/`, data, {headers});
}

export const promoCodeByName = ({data}) => {  
    return axios.get(`${API_URL}/code/promoCodeByCode/${data.name}/${data.subdomain ?? ''}`);
}

