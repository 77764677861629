import { Link } from "react-router-dom";

const FooterPublic = ({ businessInfo = null }) => {
  return businessInfo?.businessType === "COMPANY" &&
    businessInfo.subdomain !== "myglobal" &&
    businessInfo?.banners !== undefined &&
    businessInfo.banners.length >= 3 ? (
    <>
      <Link
        to={
          businessInfo.banners[businessInfo.banners.length - 1].link !== ""
            ? businessInfo.banners[businessInfo.banners.length - 1].link
            : ""
        }
      >
        <div
          className="footer-wrapper"
          style={
            businessInfo?.businessType === "COMPANY" &&
            businessInfo?.banners[businessInfo.banners.length - 1]
              ? {
                  ...homeHeroOneStyle,
                  backgroundImage: `url(${
                    businessInfo.banners[businessInfo.banners.length - 1].img
                  })`,
                }
              : {}
          }
        ></div>
      </Link>
    </>
  ) : (
    <div className="footer-wrapper">
      <footer className="footer-public">
        {(!businessInfo || businessInfo?.subdomain === "myglobal") && (
          <nav className="flex-center links">
            <Link to="/sobre-nosotros">Sobre nosotros</Link>
            <hr />
            <Link to="/solicitar-asistencia">Solicitar asistencia</Link>
            <hr />
            <Link to="/cancelar-plan">Cancelar plan</Link>
            <hr />
            <Link to="/solicitar-reintegro">Solicitar reintegro</Link>
            {/* <hr />
          <Link to="/">Preguntas frecuentes</Link> */}
          </nav>
        )}
        <p className="about">
          Dedicados a la prestación de{" "}
          <strong>servicios de asistencia de viajes</strong> a nivel nacional e
          internacional.
        </p>
        <nav className="flex-center contact">
          <p>Contactanos</p>
          <a href="mailto:info@myglobalassist.com">info@myglobalassist.com</a>
          <a href="tel:+59829038384">Ventas | +598 2903 8384</a>
          <a href="tel:+59829035555">Asistencia | +598 2903 5555</a>
        </nav>
        <div className="home-logo">
          <>
          {(businessInfo?.businessType === "COMPANY" && businessInfo?.subdomain === "myglobal") || !businessInfo ? (
            <Link
              to="/"
              style={{
                backgroundImage: `url(/imgs/Logo-My-Global-Assist-color.png)`,
              }}
            ></Link>
          ) : (
          <Link
            to={"/" + businessInfo?.subdomain}
            style={{
              backgroundImage: `url(${
                businessInfo?.logoUrl ?? "/imgs/Logo-My-Global-Assist-color.png"
              })`,
            }}
          ></Link>
        )}
          </>
          {businessInfo ? <p className="powered">Powered by MyGlobal</p> : null}
        </div>
        {/* <div className="flex-center follow">
          <p>Seguinos en: </p>
          <a href="/"></a>
          <a href="/"></a>
        </div> */}
      </footer>
    </div>
  );
};

export default FooterPublic;
const homeHeroOneStyle = {
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  minHeight: "30vh",
};
