import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { sendPayment } from "../../services/PaymentService";
import PriceFormat from "../../shared/PriceFormat";

const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY_BAMBOO;

const PaymentBamboo = ({ buyingData, handlePay }) => {  

  const [isLoaded, setIsLoaded] = useState(0);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  
  const finalPrice = (!buyingData.withDiscount) ? buyingData.planPrice : PriceFormat({price:[buyingData.planPrice, buyingData.discountAmount], operator: "-", expect: 'float'});
  const managePurchase = async (data) => {
    const response = await sendPayment({data: data.data});
    if (response.ok) {
      handlePay({ token: data.token, payData: response.data.data, result: true });
      return setPaymentSuccess(true);
    } else {      
      return handlePay({ token: data.token, payData: response, result: false });
    }
  };

  // Utilizar function para asegurar que las eleve al nivel superior del hoisting
  function OpenIframe() {
    if (!document.getElementById("custom_iframe")) {
      // Create iframe
      var main_iframe = document.getElementById("custom_iframe");
      // iframe styles
      main_iframe.setAttribute("frameborder", "0");
      main_iframe.style.borderRadius = "10px";
      main_iframe.style.display = "block";
      main_iframe.scroll = "no";
    }
    //Load Iframe
    window.PWCheckout.LoadIframe();
  }

  function onTokenCreated(token) {
    let prePrice = finalPrice.toString();
    let decimalPrice = prePrice.split('.');
    if(decimalPrice.length > 1){
      if(decimalPrice[1].length === 1){
        prePrice = `${prePrice}0`;
      }
    }else{
      prePrice = `${prePrice}.00`
    }    
    const expense = parseInt(prePrice.replace(".", ""));    
    const data = {
      TrxToken: token.TokenId,
      Order: uuidv4(),
      Amount: expense,
      Customer: {
        Email: buyingData.contactData.email,
        FirstName: buyingData.passengersData[0].name,
        LastName: buyingData.passengersData[0].lastName,
        DocNumber: buyingData.passengersData[0].docNumber,
        DocumentTypeId: parseInt(buyingData.passengersData[0].typeDoc),
        PhoneNumber: buyingData.contactData.phone,
        BillingAddress: {
          AddressType: 1,
          Country: "Uruguay",
          State: "Montevideo",
          City: "MONTEVIDEO",
          AddressDetail: "Av. Uruguay 807"
        }
      },
      DataUY: {
        IsFinalConsumer: false,
        Invoice: null,
        TaxableAmount: 0
      },
      Currency: buyingData.planCurrency,
      Capture: true,
    };
    managePurchase({data, token});
  }

  useEffect(() => {
    if (
      isLoaded === 0 &&
      buyingData &&
      buyingData.passengersData[0] !== undefined
    ) {
      const script = document.createElement("script");
      script.src = `https://api.siemprepago.com/v1/Scripts/PWCheckoutNoModal.js?key=${PUBLIC_KEY}`;
      //script.src = `https://testapi.siemprepago.com/v1/Scripts/PWCheckoutNoModal.js?key=${PUBLIC_KEY}`;
      script.type = "text/javascript";
      document.body.appendChild(script);

      script.onload = () => {
        // Configurar las propiedades del formulario de pago
        window.PWCheckout.SetProperties({
          name: "MyGlobalAssist",
          owner: `${buyingData.passengersData[0].name} ${buyingData.passengersData[0].lastName}`,
          email: `${buyingData.contactData.email}`,
          button_label: `Pagar ${buyingData.planCurrency} ${finalPrice}`,
          description: `Plan ${buyingData.planName}`,
          currency: "USD",
          amount: `${finalPrice}`,
          lang: "ESP",
          form_id: "commerce_form",
          checkout_card: "0",
          autoSubmit: "false",
          iframeId: "custom_iframe",
          country_code: "UY",
        });

        window.PWCheckout.Bind("tokenCreated", onTokenCreated);
        OpenIframe();
      };

      setIsLoaded(1);
      return () => {
        // Elimina el script cuando el componente se desmonte
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <>
      {!paymentSuccess ? (
        <>
          <iframe
            id="custom_iframe"
            title="custom_iframe"
            style={{
              height: "500px",
              width: "500px",
              maxHeight: "475px",
              maxWidth: "345px",
              margin: "auto",
              overflow: "hidden",
              zIndex: "999",
            }}
            onLoad={() => {
              setIsIframeLoaded(true);
            }}
          ></iframe>
          <form id="commerce_form">
            <input id="PWToken" type="hidden" />
          </form>
        </>
      ) : (
        <div className="alert alert-success" role="alert">
          <h5 className="alert-heading">Pago realizado con éxito!</h5>
          <h4 className="emphasys">
            Estamos generando tu/s voucher/s de compra...
          </h4>
          <progress></progress>
        </div>
      )}

      {!isIframeLoaded ? (
        <aside
          id="modal-preload"
          aria-busy="true"
          style={{
            margin: "0",
            position: "absolute",
            height: "100%",
            width: "100%",
            left: "0",
            top: "0",
            zIndex: "100",
            paddingTop: "20vh",
            backgroundColor: "white",
          }}
        ></aside>
      ) : (
        ""
      )}
    </>
  );
};

export default PaymentBamboo;
