const { Link } = require("react-router-dom");

const HomeHeroTwo = ({ businessInfo = null }) => {

  let images = businessInfo?.banners?.filter((banner) => banner.img !== "");
  return businessInfo?.businessType === "COMPANY" &&
    businessInfo.subdomain !== "myglobal" &&
    images !== undefined && images.length > 0 ? (
    <>
      {images.map((image, index) => {
          return(
            <span key={index}>
          <>
            {index !== 0 && index !== images.length - 1 && (
              <Link
                to={
                 image.link !== undefined &&
                 image.link !== null &&
                 image.link !== ""
                    ? image.link
                    : ""
                }
              >
                <section                  
                  style={
                    businessInfo?.businessType === "COMPANY" &&
                   image.img !== undefined &&
                   image.img !== null &&
                   image.img !== ""
                      ? {
                          ...homeHeroOneStyle,
                          backgroundImage: `url(${images[index].img})`,
                        }
                      : {}
                  }
                  className="flex-center"
                  id="home-hero-1"
                >
                  <div className="container" style={{ zIndex: "-10" }}>
                    <p className="hero-one-lema">
                      <strong>Asistencia al viajero</strong> humana y accesible
                      <br />
                      <button
                        /* role="button" */
                        to=""
                        className="primary mob-quote-btn"
                      >
                        Cotizar asistencia
                      </button>
                    </p>
                  </div>
                </section>
              </Link>
            )}
          </>          
          </span>
          )
        })}
    </>
  ) : (
    <section id="home-hero-2">
      <div className="container flex-center" id="hero-2-content-wrapper">
        <p className="title">
          Vayas donde vayas,
          <br />
          estaremos <span>contigo</span>
        </p>
        <div className="flex-center column">
          <p>
            Tu destino vive en cada paso y no hay obstáculo que te detenga.
            Juntos, nos aseguraremos de que{" "}
            <strong>tu única meta sea disfrutar al máximo</strong> sin
            preocupaciones.
          </p>
          <p>
            Estaremos allí para apoyarte y{" "}
            <strong>protegerte ante cualquier inconveniente</strong> que surja,
            porque nuestro objetivo es que disfrutes de tu viaje siempre
            acompañado. Tenemos{" "}
            <strong>planes diseñados para diferentes ocasiones</strong>, si
            estás de vacaciones, estudiando, eres un mochilero o amante de la
            aventura.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HomeHeroTwo;

const homeHeroOneStyle = {
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
};
