import { useTranslation } from "react-i18next";
import AuthLogin from "../../../shared/auth/AuthLogin";

const Login = () => {
  const { t } = useTranslation();
  return (
    <>
      <header>
        <h1 style={{ textAlign:'center',paddingTop:'3rem' }}>{t("LOGIN_ACCESS")}</h1>
      </header>
      <AuthLogin role={1} />
    </>
  );
};

export default Login;
