import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const RequestAssistance = () => {
    return (
        <div className="public request-assistance">
            <div className="container">
                <div className="wrapper">
                    <p>Solicitar <span>Asistencia</span></p>
                    <p>Nos dedicamos a la prestación de servicios de asistencia nacional e internacional.</p>
                    <a href="tel:+59829035555">
                        <FontAwesomeIcon 
                        className="icon"
                        icon={faPhone} 
                        size="sm"
                        color="var(--primary-inverse)" />
                        <span>+598 2903 5555</span></a>
                </div>
            </div>
        </div>
    );
}

export default RequestAssistance;