import { useTranslation } from "react-i18next";

const ConfirmationModal = ({ 
    isOpen, 
    onConfirm, 
    onCancel, 
    titleKey, 
    messageKey,
    confirmTextKey = 'CONFIRM',
    cancelTextKey = 'CANCEL'
 }) => {
    const { t } = useTranslation();

    return (
        <dialog open={isOpen}>
            <article>                
                <header>
                    <a href="#close"                         
                        onClick={onCancel}
                        aria-label="Close" 
                        className="close">
                    </a>
                    <h3>{t(titleKey)}</h3>
                </header>
                <p>{t(messageKey)}</p>
                <footer>                    
                    <a href="#confirm" 
                        role="button" 
                        className="primary"
                        onClick={onConfirm}
                        >{t(confirmTextKey)}</a>
                </footer>
            </article>
        </dialog>
    );
}

export default ConfirmationModal;