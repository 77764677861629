import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  diffTimeYears,
  convertToYYYYMMDD,
} from "../../shared/validations/Validation";

const ModalFamily = ({ data, handleClose, businessInfo, open }) => {  
  let dataTravel = data;
  const dataFamily = {};
  const { t } = useTranslation();
  const [newPassengers, setNewPassengers] = useState(3);
  const [isOpen, setIsOpen] = useState(false);
  const [stateVersion, setStateVersion] = useState(0);
  const [ages, setAges] = useState(
    dataTravel.ageRange !== undefined && dataTravel.ageRange.length > 0
      ? dataTravel.ageRange
      : []
  );
  const [birthday, setBirthday] = useState(
    dataTravel.birthDate !== undefined && dataTravel.birthDate.length > 0
      ? dataTravel.birthDate
      : ["2000-01-01", "2000-01-01", "2000-01-01"]
  );

  const reRender = () => {
    setStateVersion(stateVersion + 1);
  };

  const closing = (accept = false) => {
    if (accept) {
      setNewPassengers(newPassengers);      
      dataFamily.passengers = newPassengers;
      dataFamily.familyPlan = 1;
      dataFamily.ageRange = ages;
      dataFamily.birthDate = birthday;
    } else {
      dataFamily.familyPlan = 0;
      dataFamily.passengers = 0;
      dataFamily.ageRange = [];
      dataFamily.birthDate = [];
      setNewPassengers(0);
      setAges([]);
      setBirthday([]);
    }
    setIsOpen(false);
    handleClose(accept, dataFamily);
  };

  useEffect(() => {
    if (open) {
      setIsOpen(true);
    }
    if (
      dataTravel.passengers === undefined ||
      dataTravel.passengers === 0 ||
      dataTravel.passengers < 3
    ) {
      setNewPassengers(3);
    } else {
      setNewPassengers(dataTravel.passengers);
    }
    if (
      birthday.length === 0 ||
      dataTravel.birthDate === undefined ||
      dataTravel.birthDate.length === 0
    ) {
      setBirthday(["2000-01-01", "2000-01-01", "2000-01-01"]);
    } else {
      setBirthday(dataTravel.birthDate);
    }
    setAgesData();
    reRender();
  }, [open]);

  const setAgesData = () => {
    const newAges = [];
    for (let i = 0; i < newPassengers; i++) {
      const age = diffTimeYears({
        startDate: birthday[i],
        endDate: convertToYYYYMMDD(new Date()),
      });
      newAges[i] = age;
    }
    setAges(newAges);
  };

  const updatedBornDate = (op) => {
    const newBirthday = [...birthday];
    const newAges = [...ages];
    if (op === "+") {
      newBirthday.push("2000-01-01");
      newAges.push(
        diffTimeYears({
          startDate: "2000-01-01",
          endDate: convertToYYYYMMDD(new Date()),
        })
      );
    } else if (op === "-") {
      newBirthday.pop();
      newAges.pop();
    }
    setBirthday(newBirthday);
    dataFamily.birthDate = newBirthday;
    setAges(newAges);
    dataFamily.ageRange = newAges;
  };

  const handleBornDate = (value, id) => {
    const updatedBirthDay = value;
    const index = id;
    const newBirthday = [...birthday];
    newBirthday[index] = updatedBirthDay;
    setBirthday(newBirthday);
    dataFamily.birthDate = newBirthday;
    const age = diffTimeYears({
      startDate: updatedBirthDay,
      endDate: convertToYYYYMMDD(new Date()),
    });
    const newAges = [...ages];
    newAges[index] = age;
    setAges(newAges);
    dataFamily.ageRange = newAges;
  };

  return (
    <>
      <dialog id="modal-dialog" open={isOpen}>
        <article
          style={{
            position: "relative",
            maxHeight: "85vh",
            minHeight: "85vh",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <header
            style={{
              backgroundColor: `${!businessInfo ? "#b700ff" : businessInfo.primaryColor}`,
              display: "grid",
              gridTemplateColumns: "1fr",
              alignContent: "center",
              alignItems: "center",
              paddingTop: "1rem",
              paddingBottom: "1rem",
              height: "18vh",
              marginBottom: "1.5rem",
            }}
          >
            <span
              href="#close"
              className="primary"
              data-target="modal-dialog"
              onClick={() => closing(false)}
              style={{
                cursor: "pointer",
                backgroundColor: "white",
                color: "black",
                fontSize: "20px",
                fontWeight: "bold",
                position: "absolute",
                right: "10px",
                top: "10px",
                zIndex: "1",
                padding: "2px 5px 0",
                borderRadius: "50%",
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
              }}
            >
              <FontAwesomeIcon icon={faClose} style={{ color: `${!businessInfo ? "#b700ff" : businessInfo.primaryColor}` }} />
            </span>
            <h1
              className="center white"
              style={{
                display: "inline-flex",
                margin: "auto",
                fontWeight: "400",
              }}
            >
              Plan Familiar
            </h1>
            <h4
              className="center white"
              style={{
                display: "inline-flex",
                margin: "0.5rem auto auto",
                fontWeight: "600",
              }}
            >
              Viajando 2 adultos se incluye hasta 4 menores de 23 a&ntilde;os GRATIS
            </h4>
          </header>
          <div
            style={{
              height: "58vh",
              maxHeight: "85vh",
              overflow: "hidden",
              overflowY: "auto",
              position: "relative",
            }}
          >
            {newPassengers > 0 && (
              <>
                <div
                  className="flex"
                  style={{
                    flexFlow: "column nowrap",
                    margin: "auto",
                    width: "auto",
                  }}
                >
                  <h3
                    className="center"
                    style={{
                      marginBottom: "1rem",
                    }}
                  >
                    Cantidad Pasajeros
                  </h3>
                  <label
                    className="flex-center"
                    style={{
                      margin: "auto auto 2rem",
                      maxWidth: "150px",
                    }}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        if (newPassengers - 1 < 3) {
                          setNewPassengers(3);
                        } else {
                          setNewPassengers(newPassengers - 1);
                        }
                        updatedBornDate("-");
                      }}
                      style={{
                        maxWidth: "20%",
                        borderRadius: "5px",
                        margin: "auto",
                        padding: "3px 5px",
                      }}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </button>
                    <input
                      type="text"
                      min="3"
                      max="9"
                      value={newPassengers}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          if (e.target.value > 10) {
                            e.target.value = 10;
                            setNewPassengers(10);
                          } else if (e.target.value < 3) {
                            e.target.value = 3;
                            setNewPassengers(3);
                          } else {
                            setNewPassengers(newPassengers + 1);
                          }
                        } else {
                          e.target.value = 3;
                          setNewPassengers(3);
                        }
                      }}
                      style={{
                        display: "inline-flex",
                        maxWidth: "50%",
                        margin: "auto",
                        textAlign: "center",
                      }}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        if (newPassengers + 1 > 10) {
                          setNewPassengers(10);
                        } else {
                          setNewPassengers(newPassengers + 1);
                        }

                        updatedBornDate("+");
                      }}
                      style={{
                        maxWidth: "20%",
                        borderRadius: "5px",
                        margin: "auto",
                        padding: "3px 5px",
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </label>
                </div>

                <h3 className="center" style={{ marginBottom: "1rem" }}>
                  Fecha de nacimiento de los pasajeros
                </h3>
                <div
                  className="flex-center"
                  style={{
                    flexWrap: "wrap",
                    maxWidth: "500px",
                    width: "100%",
                    margin: "0 auto",
                  }}
                >
                  {Array.from({ length: newPassengers }, (_, index) => (
                    <div
                      key={index}
                      style={{
                        maxWidth: "100%",
                        minWidth: "150px",
                        width: "30%",
                        margin: "0",
                        marginBottom: "0",
                      }}
                    >
                      <label style={{ textAlign: "left" }}>
                        Pasajero {index + 1}:<br />
                        <input
                          type="date"
                          id={`bornDate-${index}`}
                          name={`bornDate-${index}`}
                          value={
                            birthday.length > 0 && birthday[index] !== undefined
                              ? birthday[index]
                              : "2000-01-01"
                          }
                          onChange={(e) => {
                            handleBornDate(e.target.value, index);
                          }}
                          placeholder="Fecha de nacimiento..."
                          style={{ textTransform: "uppercase" }}
                        />
                      </label>
                    </div>
                  ))}
                </div>
                <div
                  className="flex-end mobile-full-width mobile-order-1"
                  style={{
                    margin: "0 auto",
                    maxWidth: "500px",
                    width: "100%",
                  }}
                >
                  <button
                    type="submit"
                    onClick={() => closing(true)}
                    className="primary mobile-full-width"
                    style={{
                      maxWidth: "140px",
                      padding: "0.5rem 1rem",
                      width: "auto",
                    }}
                  >
                    {t("ACCEPT")}
                  </button>
                </div>
              </>
            )}
          </div>
        </article>
      </dialog>
    </>
  );
};

export default ModalFamily;
