import { Link } from "react-router-dom";

export const MoreBenefits3 = ({businessInfo = null}) => {
    
    return (
        <div className="more-benefits-3">
            <div className="container">
                <p>Un viaje perfecto<br/> comienza con <span>My Global a tu lado.</span></p>
                <Link role="button"
                    to={ businessInfo ? `/${businessInfo.subdomain}/buy/step-1` : '/buy/step-1'}
                    className="secondary-btn">
                        Cotizar asistencia
                    </Link>
            </div>
        </div>
    )
}

export default MoreBenefits3;