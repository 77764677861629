import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BrokerEditComponent from '../../components/broker/BrokerEdit';
import Header from '../../../shared/Header';
import GoBackLink from '../../../shared/GoBackLink';
import useAuthHook from "../../../hooks/AuthHook";

const BrokerEdit = () => {    
    const [stateVersion, setStateVersion] = useState(1);
    const navigate = useNavigate();

    useAuthHook();


    const onEdit = (b) => {
        
        return navigate('/admin/broker');
    }

   const setStep = (step) => { 
        reRender();
    }

    const reRender = () => {
        setStateVersion(stateVersion + 1);
    }

    return (
        <>
            <Header />
            <div className="container">
            <BrokerEditComponent 
            onEdit={onEdit}
            goBackButton={                
                <GoBackLink to="/admin/broker" />                
            }
            />
            </div>
         </>
    );
}

export default BrokerEdit;
