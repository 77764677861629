import { useState, useContext } from "react";
import MessageContext from "../../contexts/MessageContext";
import LoadingContext from "../../contexts/LoadingContext";
import { validateEmail } from "../../shared/validations/Validation";
import { cancelPlan } from "../../services/PaymentService";
const CancelPlan = () => {
    const [name, setName] = useState("");
    const [lastname, setLastname] = useState("");
    const [type, setType] = useState("");
    const [docnumber, setDocnumber] = useState("");
    const [birthdate, setBirthdate] = useState("");
    const [voucher, setVoucher] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");
    const [reason, setReason] = useState("");
    const [disableClick, setDisableClick] = useState(false);
    const messageContext = useContext(MessageContext);
    const loadingContext = useContext(LoadingContext);
    const handleName = (ev) => {
        const updatedName = ev.target.value;
        setName(updatedName);
    };
    const handleLastname = (ev) => {
        const updatedLastname = ev.target.value;
        setLastname(updatedLastname);
    };
    const handleType = (ev) => {
        const updatedType = ev.target.value;
        setType(updatedType);
    };
    const handleDocnumber = (ev) => {
        const updatedDocnumber = ev.target.value;
        setDocnumber(updatedDocnumber);
    };
    const handleBirthdate = (ev) => {
        const updatedBirthdate = ev.target.value;
        setBirthdate(updatedBirthdate);
    };
    const handleVoucher = (ev) => {
        const updatedVoucher = ev.target.value;
        setVoucher(updatedVoucher);
    };
    const handleEmail = (ev) => {
        const updatedEmail = ev.target.value;
        setEmail(updatedEmail);
    };
    const handleTel = (ev) => {
        const updatedTel = ev.target.value;
        setTel(updatedTel);
    };
    const handleReason = (ev) => {
        const updatedReason = ev.target.value;
        setReason(updatedReason);
    };
    
    const sendInfo = async () => {
        const data = {
            name: name.trim(),
            lastname: lastname.trim(),
            type: type.trim(),
            docnumber: docnumber.trim(),
            birthdate: birthdate.trim(),
            voucher: voucher.trim(),
            email: email.trim(),
            tel: tel.trim(),
            reason: reason.trim(),
        };
        if (!data.name || !data.lastname || !data.type || !data.docnumber || !data.birthdate || !data.voucher || !data.email || !data.tel || !data.reason) {
            return messageContext.setMessage({
                type: "error",
                text: "Por favor, completa todos los campos",
            });
        }
        if (!validateEmail(email)) {
            return messageContext.setMessage({
                type: "error",
                text: "Por favor, ingresa un email válido",
            });
        }
        loadingContext.change(true);
        setDisableClick(true);
        const response = await cancelPlan({ data
        });
        if (response.ok) {
            messageContext.setMessage({
                type: "success",
                text: "Cancelación solicitada con éxito. Nos pondremos en contacto a la brevedad.",
            });
        }
        else {
            messageContext.setMessage({
                type: "error",
                text: "Hubo un error al solicitar la cancelación. Por favor, verifca los datos e intenta nuevamente.",
            });
        }
        setName("");
        setLastname("");
        setType("");
        setDocnumber("");
        setBirthdate("");
        setVoucher("");
        setEmail("");
        setTel("");
        setReason("");
        setDisableClick(false);
        loadingContext.change(false);
    }
    return (
        <>
            <div className="cancel-plan cancel-plan-points">
                <div className="container">
                    <p className="title"><span>Cancelación</span> de plan</p>
                    <div className="item-list">
                        <div className="item">
                            <div className="number">
                                <div className="digit"><span>1.</span></div>
                                <div className="line"></div>
                            </div>
                            <div className="content">
                                <p>Ingresa tus datos</p>
                                <p>en el siguiente formulario</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="number">
                                <div className="line desktop"></div>
                                <div className="digit"><span>2.</span></div>
                                <div className="line"></div>
                            </div>
                            <div className="content">
                                <p>Adjunta tu factura digital</p>
                                <p>Formatos PDF o JPG</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="number">
                                <div className="line desktop"></div>
                                <div className="digit"><span>3.</span></div>                                
                            </div>
                            <div className="content">
                                <p>Nos contactaremos</p>
                                <p>por correo dentro de las 72 hs hábiles</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cancel-plan cancel-plan-form">
                <div className="container">
                    <form>
                        <input 
                        type="text" 
                        name="name" 
                        value={name}
                        placeholder="Nombre" 
                        onChange={handleName}
                        />
                        
                        <input 
                        type="text" 
                        name="lastname"
                        value={lastname} 
                        placeholder="Apellido" 
                        onChange={handleLastname}
                        />
                        <select className="half"
                        name="type"
                        value={type}
                        onChange={handleType}
                        >
                            <option value="">Tipo de documento</option>
                            <option value="DNI">Documento nacional</option>
                            <option value="Pasaporte">Pasaporte</option>
                        </select>
                        <input 
                        type="text" 
                        name="docnumber" 
                        value={docnumber}
                        placeholder="Nº documento de viaje" 
                        className="half"
                        onChange={handleDocnumber}
                        />
                        <input 
                        type="date" 
                        name="birthdate" 
                        value={birthdate}
                        placeholder="Fecha de nacimiento"
                        onChange={handleBirthdate} 
                        />
                        <input 
                        type="text" 
                        name="voucher" 
                        value={voucher}
                        placeholder="Nº de Voucher" 
                        onChange={handleVoucher}
                        />
                         <input 
                        type="email" 
                        name="email" 
                        value={email}
                        placeholder="Email"
                        onChange={handleEmail} 
                        />
                        <input 
                        type="tel" 
                        name="tel"
                        value={tel} 
                        placeholder="(Prefijo país) + Teléfono"
                        onChange={handleTel} 
                        />
                        <textarea  
                        name="reason"
                        value={reason}
                        placeholder="Motivo de cancelación"
                        onChange={handleReason}
                        >

                        </textarea>
                        <button
                        type="button"
                        disabled={disableClick}
                        onClick={sendInfo}
                        > 
                            Solicitar
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default CancelPlan;