import { useTranslation } from "react-i18next";
import useApiHook from "../../../hooks/ApiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logoutService } from "../../../services/AuthService";
import { getCookie, deleteCookie } from "../../CookiesManager";
import useResetStyleHook from "../../../hooks/ResetStyleHook";

const Logout = () => {
  useResetStyleHook();
  const { t } = useTranslation();
  const callLogout = useApiHook();
  const navigate = useNavigate();
  const clearCookies = () => {
    deleteCookie({ name: "SSID" });
    deleteCookie({ name: "_RL" });
    deleteCookie({ name: "_UI" });
    deleteCookie({ name: "_UR" });
    deleteCookie({ name: "_UN" });
    deleteCookie({ name: "_BT" });
  };
  const sendLogout = async () => {
    const data = {
      user: getCookie({ name: "_UI" }),
      role: getCookie({ name: "_RL" }),
    };
    const response = await callLogout({
      fnApiCall: logoutService,
      params: { data: data },
    });
    if (response.ok) {
      clearCookies();
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }else{      
      navigate(data.role === 1 ? "/admin/login" : "/profile/login");
    }
  };
  useEffect(() => {    
    sendLogout();
  }, []);

  return (
    <>
      <header>
        <h1 style={{ textAlign:'center',paddingTop:'3rem' }}>{t("COMING_OUT")}</h1>
      </header>
      {/* <AuthLogout /> */}
    </>
  );
};

export default Logout;