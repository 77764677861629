import { useEffect, useState, useRef } from "react"; //, useContext
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useApiHook from "../../../hooks/ApiHook";
import BuyingProcessService from "../../../services/BuyingProcessService";
import { getAditionalBenefits } from "../../../services/CategoryService";
import HeaderPublic from "../../components/HeaderPublic";
import BuyingProcessDetails from "../../components/BuyingProcessDetails";
import PriceFormat from "../../../shared/PriceFormat";

let aditionalBenefits = [];
let selectedBenefits = [];
const BuyStep5 = ({ step = 5 }) => {
  const buyingData = BuyingProcessService.getInstance().buyingData;
  const companyData = BuyingProcessService.getInstance().businessInfo;
  const [stateVersion, setStateVersion] = useState(0);
  const [priceUpdate, setPrice] = useState(parseFloat(buyingData.planPrice));
  const [benefitsAdded, setBenefitsAdded] = useState(
    buyingData.aditionalBenefits || []
  );
  const [outAge, setOutAge] = useState(false);
  const [prevStep, setPrevStep] = useState(`/buy/step-${step - 1}`);
  const [nextStep, setNextStep] = useState(`/buy/step-${step + 1}`);
  const checkboxesRef = useRef([]);
  const navigate = useNavigate();
  const apiCall = useApiHook();
  const { t } = useTranslation();    
  const reRender = () => {
    setStateVersion(stateVersion + 1);
  };
  const outRangeAge = (maxAge) => {
    const filterAges = buyingData.ageRange.filter((age) => age > maxAge);
    if (filterAges.length > 0) {
      if (!outAge) setOutAge(true);
      return true;
    }
    if (outAge) setOutAge(false);
    return false;
  };

  const onAddPrice = (price, benefitId, passengerId, description, coverage) => {
    /* const currentPrice = parseFloat(priceUpdate);
    const updated = (currentPrice + price).toFixed(2); */
    //setPrice(updated);

    setPrice(PriceFormat({ price: [priceUpdate, price], operator: "+" }));

    const newBenefit = { benefitId, passengerId, description, coverage, price };
    selectedBenefits.push(newBenefit);
    reRender();
  };

  const onRestPrice = (price, benefitId, passengerId) => {
    /* const currentPrice = parseFloat(priceUpdate);
    const updated = (currentPrice - price).toFixed(2); */
    //setPrice(updated);
    setPrice(PriceFormat({ price: [priceUpdate, price], operator: "-" }));
    selectedBenefits = selectedBenefits.filter(
      (benefit) =>
        !(
          benefit.benefitId === benefitId && benefit.passengerId === passengerId
        )
    );
    reRender();
  };

  const init = async () => {
    if (BuyingProcessService.getInstance().getInvalidStepOn4() > 0) {      
      return navigate(prevStep);
    }
    /* Aqui ha datos hardcodeados porque continental solo responde a esto, pero deberia ser dinamico */
    const data = {
      categoryId: 24,
      days: buyingData.days,
      planId: 21,
    };
    const response = await apiCall({
      fnApiCall: getAditionalBenefits,
      params: { data },
    });
    if (response.ok) {
      aditionalBenefits = response.data.data;
      BuyingProcessService.getInstance().benefitsList = aditionalBenefits;      
      reRender();
    }
  };

  const onNext = () => {
    const buyingProcessService = BuyingProcessService.getInstance();
    buyingProcessService.buyingData.buyAccepted = true;
    buyingProcessService.buyingData.aditionalBenefits = selectedBenefits;
    buyingProcessService.buyingData.planPrice = priceUpdate;
    setBenefitsAdded(selectedBenefits);
    return navigate(nextStep);
  };

  useEffect(() => {
    if(companyData && companyData._id && companyData?.role !== "2"){
      setPrevStep(`/${companyData.subdomain}/buy/step-${step - 1}`);
      setNextStep(`/${companyData.subdomain}/buy/step-${step + 1}`);
    }
    init();
    if (benefitsAdded && benefitsAdded.length > 0) {
      benefitsAdded.forEach((benefit) => {
        const checkbox =
          checkboxesRef.current[`${benefit.benefitId}-${benefit.passengerId}`];
        if (checkbox) {
          checkbox.checked = true;
        }
      });
    }
  }, [benefitsAdded]);

  return (
    <>
      <HeaderPublic businessInfo={companyData?.role !== "2" && companyData.subdomain !== 'myglobal' ? companyData : null} />
      <div
        className="container"
        style={{
          minHeight: "83vh",
          textAlign: "center",
          display: "grid",
          gridTemplateColumns: "1fr",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ lineHeight: "1", marginBottom: "1rem", marginTop: "4rem" }}>
          {t("BENEFITS.TITLE")}
        </h1>
        <div className="extra-benefits">
          <div className="card wrap-benefits">
            <h3>Agrega m&aacute;s beneficios a tu plan</h3>
            <div>
              {aditionalBenefits && aditionalBenefits.length > 0 ? (
                <>
                  {aditionalBenefits.map((benefit, index) => {
                    return (
                      <div key={`kwrap-${index}`}>
                        {!outRangeAge(parseInt(benefit.edad_maxima)) ? (
                          <article
                            id={`wrapBenefit-${benefit.id}`}
                            key={`benfit-${index}`}
                            style={{
                              position: "relative",
                              marginTop: "0",
                              marginBottom: "1.5rem",
                              padding: "1.5rem",
                              paddingLeft: "1.3rem",
                            }}
                          >
                            <details className="add-benefit">
                              <summary>
                                <span>
                                  {benefit.descripcion} hasta
                                  <b className="bold emphasys">
                                    &nbsp;{benefit.cobertura}
                                  </b>
                                </span>
                                <h6 className="price-person">
                                  {benefit.cobertura.startsWith("USD")
                                    ? "USD "
                                    : "EUR "}
                                  {PriceFormat({ price: benefit.precio })} por
                                  persona
                                </h6>
                              </summary>
                              <p
                                style={{
                                  marginBottom: "5px",
                                  paddingTop: "5px",
                                }}
                              >
                                <strong className="icon-primary">
                                  ¿Qué pasajeros tendrán este upgrade?
                                </strong>
                              </p>
                              {Array.from(
                                { length: buyingData.passengers },
                                (_, id) => (
                                  <p
                                    key={`passenger${benefit.id}-${id}`}
                                    className="flex-between"
                                    style={{ borderTop: "none" }}
                                  >
                                    <small>{`Pasajero ${id + 1}`}</small>
                                    <label htmlFor={`passenger-${id + 1}`}>
                                      <small>agregar&nbsp;</small>
                                      <input
                                        type="checkbox"
                                        id={`${benefit.id}-${id + 1}`}
                                        name={`${benefit.id}-${id + 1}`}
                                        value={id + 1}
                                        ref={(el) =>
                                          (checkboxesRef.current[
                                            `${benefit.id}-${id + 1}`
                                          ] = el)
                                        }
                                        onChange={(e) => {
                                          buyingData.withDiscount = false;
                                          if (e.target.checked) {
                                            onAddPrice(
                                              benefit.precio,
                                              benefit.id,
                                              id + 1,
                                              benefit.descripcion,
                                              benefit.cobertura
                                            );
                                          } else {
                                            onRestPrice(
                                              benefit.precio,
                                              benefit.id,
                                              id + 1
                                            );
                                          }
                                        }}
                                      />
                                    </label>
                                  </p>
                                )
                              )}
                            </details>
                          </article>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                  <article
                    style={{
                      position: "relative",
                      marginTop: "0",
                      marginBottom: "1.5rem",
                      padding: "1.5rem",
                      paddingLeft: "1.3rem",
                      display: outAge ? "block" : "none",
                    }}
                  >
                    <h6 className="emphasys">
                      Lo sentimos, no hay oferta de beneficios adicionales para el rango de edades de los pasajeros.
                    </h6>
                  </article>
                </>
              ) : (
                <>
                <article
                    style={{
                      position: "relative",
                      marginTop: "0",
                      marginBottom: "1.5rem",
                      padding: "1.5rem",
                      paddingLeft: "1.3rem",
                    }}
                  >
                    <h6 className="emphasys">
                      Lo sentimos, no hay oferta de beneficios adicionales para
                      este plan.
                    </h6>
                  </article>
                  </>
              )}
            </div>
            <div
              className="flex-between desktop-min-width"
              style={{ width: "100%", marginTop: "20px" }}
            >
              <div
                className="flex-start mobile-full-width"
                style={{ marginTop: "15px" }}
              >
                <button
                  type="button"
                  className="secondary outline mobile-full-width"
                  onClick={() => {
                    aditionalBenefits = [];
                    selectedBenefits = [];
                    navigate(prevStep);
                  }}
                >
                  {t("GO_BACK")}
                </button>
              </div>
            </div>
          </div>
          <div className="card details-buy">
            <BuyingProcessDetails
              buyingData={{
                ...buyingData,
                aditionalBenefits: selectedBenefits,
              }}
              aditionalBenefits={aditionalBenefits}
              totalPrice={priceUpdate}
              onNext={onNext}
              step={step}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyStep5;
