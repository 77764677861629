import { useState} from 'react';
import { useNavigate } from 'react-router-dom';
import BrokerAddComponent from '../../components/broker/BrokerAdd';
import Header from '../../../shared/Header';
import GoBackLink from '../../../shared/GoBackLink';
import useAuthHook from '../../../hooks/AuthHook';

let addStep = 1;

const BusinessAdd = () => {    
    const [stateVersion, setStateVersion] = useState(1);
    const navigate = useNavigate();
    useAuthHook();

    const goToList = (b) => {
        setStep(1);
        return navigate('/admin/broker');
    }

   const setStep = (step) => { 
        addStep = step; 
        reRender();
    }

    const reRender = () => {
        setStateVersion(stateVersion + 1);
    }

    return (
        <>
            <Header />
            <div className='container'>
                <BrokerAddComponent 
                    goBackButton={                
                        <GoBackLink 
                        style={{marginRight: 'var(--spacing)'}} 
                        to="/admin/broker" />                
                    }
                    onAdd={goToList}
                    onGoBack={goToList}
                    setStep={setStep}
                    step={addStep}
                />
            </div>
        </>
        
    );
}

export default BusinessAdd;
