import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthMiddleware from "../shared/auth/AuthMiddleware"; 

const useAuthHook = (roleValue = 1) => {
    const navigate = useNavigate();
     useEffect(() => {        
        // Aquí se debe verificar si el usuario está autenticado
        const auth = AuthMiddleware({role: roleValue});
        if(auth === 403){
            return navigate('/logout');
        }
        if(!auth) return navigate('/');
        else if(auth !== true) return navigate(auth);
      }, []);
 }

export default useAuthHook;