import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const ModalBenefits = ({ titulo, data, businessInfo, handleClose, open }) => {
  const [title, setTitle] = useState("");
  const [benefits, setBenefits] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isWaiting, setIsWaiting] = useState(true);
  const closing = () => {
    setTitle("");
    setBenefits([]);
    setIsOpen(false);
    handleClose();
  };

  useEffect(() => {
    setIsWaiting(true);
    if (open) {
      setTitle(titulo);
      setIsOpen(true);
    }
    if (data && data.length > 0) {
      setBenefits(data);
      setIsWaiting(false);
    }
  }, [open, data, titulo]);

  return (
    <>
      <dialog id="modal-dialog" open={isOpen}>
        <article
          style={{
            position: "relative",
            maxHeight: "80vh",
            minHeight: "80vh",
            overflow: "hidden",
            paddingLeft: "0",
            paddingRight: "0",
            width: "100%",
          }}
        >
          <header
            style={{
              backgroundColor: `${!businessInfo ? "#b700ff" : businessInfo.primaryColor}`,
              display: "grid",
              gridTemplateColumns: "1fr",
              alignContent: "center",
              alignItems: "center",
              paddingTop: "2rem",
              paddingBottom: "2rem",
              height: "18vh",
              marginBottom: "1.5rem",
            }}
          >
            <span
              href="#close"
              className="primary"
              data-target="modal-dialog"
              onClick={closing}
              style={{
                cursor: "pointer",
                backgroundColor: "white",
                color: "black",
                fontSize: "20px",
                fontWeight: "bold",
                position: "absolute",
                right: "10px",
                top: "10px",
                zIndex: "1",
                padding: "2px 5px 0",
                borderRadius: "50%",
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
              }}
            >
              <FontAwesomeIcon icon={faClose} style={{ color: `${!businessInfo ? "#b700ff" : businessInfo.primaryColor}` }} />
            </span>
            <h1
              className="center white"
              style={{
                display: "inline-flex",
                margin: "auto",
                fontWeight: "400",
              }}
            >
              Plan {title}
            </h1>
          </header>
          <div
            style={{
              height: "55vh",
              maxHeight: "55vh",
              overflow: "hidden",
              overflowY: "auto",
              position: "relative",
            }}
          >
            {benefits.length > 0 && (
              <>
                <ul>
                  <br></br>
                  {benefits.map((benefit, index) => {
                    return (
                      <li
                        id={`wrapBenefit-${benefit.id_beneficio}`}
                        key={`benfit-${index}`}
                        className="flex-between"
                        style={{
                          position: "relative",
                          marginTop: "0",
                          marginBottom: "8px",
                          padding: "0 10px",
                          listStyle: "none",
                        }}
                      >
                        <small style={{ width: "70%"}}>{benefit.nombre_beneficio}</small>
                        <small style={{ width: "30%"}}>
                          {benefit.planes[0].valor !== "Si" &&
                          benefit.planes[0].valor !== "No"
                            ? "hasta"
                            : ""}{" "}
                          <b className="large-bold">
                            {benefit.planes[0].valor}
                          </b>
                        </small>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
            {isWaiting ? (
              <aside
                id="modal-preload"
                aria-busy="true"
                style={{
                  margin: "0",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  left: "0",
                  top: "0",
                  zIndex: "100",
                  paddingTop: "20vh",
                  backgroundColor: "white",
                }}
              >
              </aside>
            ) : ''}
          </div>
        </article>
      </dialog>
    </>
  );
};

export default ModalBenefits;
