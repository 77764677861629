import { createBrowserRouter } from 'react-router-dom';
import PromoCodeList from '../admin/pages/promoCode/PromoCodeList';
import PromoCodeAdd from '../admin/pages/promoCode/PromoCodeAdd';
import PromoCodeDetails from '../admin/pages/promoCode/PromoCodeDetails';
import Dashboard from '../admin/pages/Dashboard';
import BusinessList from '../admin/pages/business/BusinessList';
import BusinessAdd from '../admin/pages/business/BusinessAdd';
import BusinessEdit from '../admin/pages/business/BusinessEdit';
import BusinessDetails from '../admin/pages/business/BusinessDetails';
import Login from '../admin/pages/auth/Login';
import Logout from '../shared/pages/auth/Logout';
import Forgot from '../admin/pages/auth/Forgot';
import LoginProfile from '../profile/pages/auth/ProfileLogin';
import ForgotProfile from '../profile/pages/auth/ProfileForgot';
import DashboardProfile from '../profile/pages/DashboardProfile';
import Home from '../public/pages/Home';
import BuyStep1 from '../public/pages/buy/BuyStep1';
import BuyStep2 from '../public/pages/buy/BuyStep2';
import BuyStep3 from '../public/pages/buy/BuyStep3';
import BuyStep4 from '../public/pages/buy/BuyStep4';
import BuyStep5 from '../public/pages/buy/BuyStep5';
import BuyStep6 from '../public/pages/buy/BuyStep6';
import BuyStep7 from '../public/pages/buy/BuyStep7';
import BuyStep8 from '../public/pages/buy/BuyStep8';
import SalesList from '../admin/pages/sales/SalesList';
import BrokerList from '../admin/pages/broker/BrokerList';
import BrokerDetails from '../admin/pages/broker/BrokerDetails';
import BrokerAdd from '../admin/pages/broker/BrokerAdd';
import BrokerEdit from '../admin/pages/broker/BrokerEdit';
import HomeCompany from '../public/pages/HomeCompany';
import SaleDetails from '../admin/pages/sales/SaleDetails';
import AboutUs from '../public/pages/AboutUs';
import RequestAssistance from '../public/pages/RequestAssistance';
import RequestRefund from '../public/pages/RequestRefund';
import CancelPlan from '../public/pages/CancelPlan';
import MoreBenefitis from '../public/pages/MoreBenefitis';
import Liquidation from '../admin/pages/sales/Liquidation';
import PromoCodeStats from '../admin/pages/promoCode/PromoCodeStats';
import ProfileLiquidation from '../profile/pages/sales/Liquidation';

const routesList = [
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/sobre-nosotros",
    element: <AboutUs />
  },
  {
    path: "/solicitar-asistencia",
    element: <RequestAssistance />
  },
  {
    path: "/solicitar-reintegro",
    element: <RequestRefund />
  },
  {
    path: "/cancelar-plan",
    element: <CancelPlan />
  },
  {
    path: "/mejora-tu-plan",
    element: <MoreBenefitis />
  },
  {
    path: "/admin",
    element: <Dashboard />
  },
  {
    path: "/admin/login",
    element: <Login />
  },
  {
    path: "/logout",
    element: <Logout />
  },
  {
    path: "/admin/forgot",
    element: <Forgot />
  },
  {
    path: "/admin/sales",
    element: <SalesList />
  },
  {
    path: "/admin/sales/:id",
    element: <SaleDetails />
  },
  {
    path: "/admin/liquidaciones",
    element: <Liquidation />
  },
  {
    path: "/admin/promo-code",
    element: <PromoCodeList />,
  },
  {
    path: "/admin/promo-code/add/",
    element: <PromoCodeAdd />,
  },
  {
    path: "/admin/promo-code/stats/",
    element: <PromoCodeStats />,
  },
  {
    path: "/admin/promo-code/:id",
    element: <PromoCodeDetails />,
  },
  {
    path: "/admin/business",
    element: <BusinessList />,
  },
  {
    path: "/admin/broker",
    element: <BrokerList />,
  },
  {
    path: "/admin/broker/:id",
    element: <BrokerDetails />,
  },
  {
    path: "/admin/broker/add/",
    element: <BrokerAdd />,
  },  
  {
    path: "/admin/broker/edit/:id",
    element: <BrokerEdit />,
  },
  {
    path: "/admin/business/:id",
    element: <BusinessDetails />,
  },
  {
    path: "/admin/business/add/",
    element: <BusinessAdd />,
  },
  {
    path: "/admin/business/edit/:id",
    element: <BusinessEdit />,
  },
  {
    path: "/profile",
    element: <DashboardProfile />
  },
  {
    path: "/profile/sales",
    element: <ProfileLiquidation />
  },
  {
    path: "/profile/login",
    element: <LoginProfile />
  },
  {
    path: "/profile/forgot",
    element: <ForgotProfile />
  },
  {
    path: "/buy/step-1",
    element: <BuyStep1 />
  },
  {
    path: "/buy/step-2",
    element: <BuyStep2 />
  },
  {
    path: "/buy/step-3",
    element: <BuyStep3 />
  },
  {
    path: "/buy/step-4",
    element: <BuyStep4 />
  },
  {
    path: "/buy/step-5",
    element: <BuyStep5 />
  },
  {
    path: "/buy/step-6",
    element: <BuyStep6 />
  },
  {
    path: "/buy/step-7",
    element: <BuyStep7 />
  },
  {
    path: "/buy/step-8",
    element: <BuyStep8 />
  },
  {
    path: "/:subdomain",
    element: <HomeCompany />
  },
  {
    path: "/:subdomain/buy/step-1",
    element: <BuyStep1 />
  },
  {
    path: "/:subdomain/buy/step-2",
    element: <BuyStep2 />
  },
  {
    path: "/:subdomain/buy/step-3",
    element: <BuyStep3 />
  },
  {
    path: "/:subdomain/buy/step-4",
    element: <BuyStep4 />
  },
  {
    path: "/:subdomain/buy/step-5",
    element: <BuyStep5 />
  },
  {
    path: "/:subdomain/buy/step-6",
    element: <BuyStep6 />
  },
  {
    path: "/:subdomain/buy/step-7",
    element: <BuyStep7 />
  },
  {
    path: "/:subdomain/buy/step-8",
    element: <BuyStep8 />
  }
]

const router = createBrowserRouter(routesList);
export default router;