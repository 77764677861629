const PriceFormat = ({ price, operator, expect }) => {
  let prices = [];
  if (price instanceof Array) {
    if (price.length > 1) {
      prices = price.map((p) => parseFloat(p));
    } else if (price.length === 1) {
      prices.push(parseFloat(price[0]));
    }
  } else if (typeof price === "string") {
    prices.push(parseFloat(price));
  } else if (typeof price === "number") {
    prices.push(price);
  }
  const formattedPrices = prices.map((p) => {
    let formattedPrice = p.toFixed(2);    
    if (formattedPrice.endsWith(".00")) {
      formattedPrice = formattedPrice.slice(0, -3);
    } else if (formattedPrice.endsWith("0")) {
      formattedPrice = formattedPrice.slice(0, -1);
    }    
    return formattedPrice;
  });

  let result = "";
  if (formattedPrices.length > 1 && operator) {
    if (operator === "+") {
      result = formattedPrices.reduce(
        (a, b) => parseFloat(a) + parseFloat(b),
        0
      );
    } else if (operator === "-") {
      result = formattedPrices.reduce((a, b) => parseFloat(a) - parseFloat(b));
    } else if (operator === "*") {
      result = formattedPrices.reduce((a, b) => parseFloat(a) * parseFloat(b));
    } else if (operator === "/") {
      result = formattedPrices.reduce((a, b) => parseFloat(a) / parseFloat(b));
    }
    result = parseFloat(result).toFixed(2);
  } else if (formattedPrices.length === 1) {
    result = formattedPrices[0];
  }
  
  if (expect === "int") {
    return parseInt(result);
  } else if (expect === "float") {
    return parseFloat(result);
  } else {
    let isFloat = result.split(".")[1];
    if (isFloat && isFloat.length === 1) {
      result = result + "0";
    }else if(!isFloat){
      result = result + ".00";
    }
    return result.toString();
  }
};

export default PriceFormat;
