import { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useApiHook from "../../../hooks/ApiHook";
import MessageContext from "../../../contexts/MessageContext";
import FormatToastMessage from "../../../shared/FormatMessage";
import BuyingProcessService from "../../../services/BuyingProcessService";
import HeaderPublic from "../../components/HeaderPublic";
import PaymentBamboo from "../../components/PaymentBamboo";
import { createSale } from "../../../services/SalesService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import BuyingProcessDetails from "../../components/BuyingProcessDetails";

const API_URL = process.env.REACT_APP_API_URL;
const BuyStep8 = ({ step = 8 }) => {
  const buyingData = BuyingProcessService.getInstance().buyingData;
  const companyData = BuyingProcessService.getInstance().businessInfo;
  //const [stateVersion, setStateVersion] = useState(0);
  const navigate = useNavigate();
  const apiCall = useApiHook();
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [failBuy, setFailBuy] = useState(false);
  const [waitingSale, setWaitingSale] = useState(false);
  //const [attempt, setAttempt] = useState(0);
  const [vouchersResult, setVouchersResult] = useState([]);
  const [clickedLinks, setClickedLinks] = useState([]);
  const [btnHidden, setBtnHidden] = useState(true);
  const [prevStep, setPrevStep] = useState(`/buy/step-${step - 1}`);
  const [nextStep, setNextStep] = useState(`/buy/step-${step + 1}`);
  const messageContext = useContext(MessageContext);
  let vouchersResponse = []; //[token, result]
  /* const reRender = () => {
    setStateVersion(stateVersion + 1);
  }; */

  const onPayment = ({ token, payData, result }) => {
    if (result) {
      const buyingProcessService = BuyingProcessService.getInstance();
      buyingProcessService.buyingData.paymentData = {
        token: token,
        payData: payData,
        result: result,
      };
      buyingProcessService.buyingData.refBusiness = companyData?._id || null;
      buyingProcessService.buyingData.commission = companyData?.commission || 0;
      return onNext();
    }
    //document.scrollingElement.scrollTop = 70;
    /* if (attempt < 3) {
      setAttempt(attempt + 1);
      const iframe = document.getElementById("custom_iframe");
      const refresh = iframe.src;
      iframe.src = refresh; */
    setFailBuy(true);
    /* setTimeout(() => {
        window.location.reload();
      }, 5000); */
    return messageContext.setMessage({
      type: "error",
      text: FormatToastMessage(t("BUYING_PROCESS.ERROR.TITLE"), [
        `${payData.error}`,
      ]),
    });
    /* } else {
      setFailBuy(true);
      return messageContext.setMessage({
        type: "error",
        text: FormatToastMessage(t("BUYING_PROCESS.ERROR.TITLE"), [
          "Pago no realizado.",
        ]),
      });
    } */
  };

  const onCredit = () => {
    const buyingProcessService = BuyingProcessService.getInstance();
    buyingProcessService.buyingData.paymentData = {
      token: "Broker",
      payData: 1,
      result: true,
    };
    buyingProcessService.buyingData.refBusiness = companyData?._id || null;
    buyingProcessService.buyingData.commission = companyData?.commission || 0;
    setWaitingSale(true);
    return onNext();
  };
  const init = async () => {
    if (BuyingProcessService.getInstance().getInvalidStepOn7() > 0) {
      return navigate(prevStep);
    }
  };

  const onNext = async () => {
    const response = await apiCall({
      fnApiCall: createSale,
      params: {
        data: {
          ...buyingData,
          planDetails:
            BuyingProcessService.getInstance().selectedPlanDetails ?? [],
        },
      },
      noLoading: true,
    }); //createSale({data: buyingProcessService.buyingData});
    if (response.ok) {
      messageContext.setMessage({
        type: "success",
        text: FormatToastMessage(t(response.data.message)),
      });
      vouchersResponse = response.data.data;
      setVouchersResult(vouchersResponse);
      setSuccess(true);
      setWaitingSale(false);
      setBtnHidden(false);
    } else {
      setSuccess(false);
      setFailBuy(true);
      setWaitingSale(false);
    }

    //return navigate(nextStep); Este paso esta para eliminar
  };

  const handleLinkClick = (index) => {
    if (clickedLinks.includes(index)) {
      setClickedLinks(
        clickedLinks.filter((clickedIndex) => clickedIndex !== index)
      );
    } else {
      setClickedLinks([...clickedLinks, index]);
    }
  };

  useEffect(() => {
    if (companyData && companyData._id && companyData?.role !== "2") {
      setPrevStep(`/${companyData.subdomain}/buy/step-${step - 1}`);
      setNextStep(`/${companyData.subdomain}${nextStep}`);
    }
    init();
  }, []);

  return (
    <>
      <HeaderPublic
        businessInfo={
          companyData?.role !== "2" && companyData.subdomain !== "myglobal"
            ? companyData
            : null
        }
      />
      <div
        className="container"
        style={{
          minHeight: "83vh",
          textAlign: "center",
          display: "grid",
          gridTemplateColumns: "1fr",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ marginBottom: "1rem", marginTop: "4rem" }}>
          {t("BUYING_PROCESS.COMPLETE_DATA")}
        </h1>
        <div className="complete-data">
          <div className="card wrap-benefits">          
            {!success && !failBuy ? (
              <>
                {!companyData ||
                companyData?.paymentType === 2 ||
                !companyData._id ||
                companyData.subdomain === "myglobal" ? (
                  <>
                    <h3>Ingresa la informaci&oacute;n de pago</h3>
                    <article className="card" style={{ position: "relative" }}>
                      <PaymentBamboo
                        buyingData={buyingData}
                        handlePay={onPayment}
                      />
                    </article>
                  </>
                ) : (
                  <>
                    <article
                      className="card"
                      style={{ paddingBottom: "3rem", paddingTop: "3rem" }}
                    >
                      <h3>Compra con Cuenta Corriente</h3>
                      <h4>
                        Compras como{" "}
                        {companyData?.role === "2" ? "Broker:" : "Empresa:"}{" "}
                        {companyData.name}
                      </h4>
                      <div className="flex-center" style={{ width: "100%" }}>
                        <button
                          className="btn btn-primary"
                          style={{ margin: "auto" }}
                          onClick={onCredit}
                        >
                          {t("BUYING_PROCESS.BUYING_PAX")}
                        </button>
                      </div>
                      {waitingSale && (
                        <>
                          <h4 className="emphasys">
                            Estamos generando tu/s voucher/s de compra...
                          </h4>
                          <progress></progress>
                        </>
                      )}
                    </article>
                  </>
                )}
              </>
            ) : (
              <>
                <h3 className="emphasys">
                  Descarga tu
                  {vouchersResult && vouchersResult.length > 1 ? "s" : ""}{" "}
                  Voucher
                  {vouchersResult && vouchersResult.length > 1 ? "s" : ""} de
                  compra
                </h3>
                <article className="card">
                  <>
                    {vouchersResult && vouchersResult.length > 0 && success ? (
                      <>
                        {vouchersResult.map((voucher, index) => {
                          return (
                            <div
                              key={index}
                              className="space-between"
                              style={{ marginTop: "1rem" }}
                            >
                              <p style={{ marginBottom: "0" }}>
                                Descarga Voucher Pasajero {index + 1}
                              </p>
                              <Link
                                to={`${API_URL}/sale/voucherPDF/${voucher.codigo.replace(
                                  "CA",
                                  "MGA"
                                )}`}
                                id={`voucher-${index + 1}`}
                                target="_blank"
                                className="primary btn-plan"
                                role="button"
                                style={{ borderRadius: "0.5rem" }}
                                onClick={() => handleLinkClick(index)}
                              >
                                {clickedLinks.includes(index) && (
                                  <>
                                    <FontAwesomeIcon icon={faCheckSquare} />{" "}
                                  </>
                                )}
                                Voucher {voucher.codigo.replace("CA", "MGA")}
                              </Link>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <div style={{ display: 'flex', flexFlow: "column nowrap", alignItems: "center", alignContent:"center", width:"100%" }}>
                          <h4 className="emphasys">
                            Parece que hubo un error en la compra.
                            <br></br>
                            Por favor, intenta nuevamente o contacta con
                            nosotros.
                          </h4>
                          <br></br>
                          <div
                            className="flex-center"
                            style={{ width: "100%" }}
                          >
                            <Link
                              to="mailto:info@myglobalassist.com"
                              role="button"
                              className="secondary outline"
                              target="_blank"
                              /* onClick={() => {
                                
                              }} */
                              style={{ margin: "auto", minWidth: "200px", fontWeight: "bold" }}
                            >
                              {t("CONTACT")}
                            </Link>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                </article>
              </>
            )}

            {/* <div
              className="flex-between"
              style={{ width: "100%", marginTop: "20px", minWidth: "380px" }}
            >
              <>
                <div className="flex-start" style={{ marginTop: "15px" }}>
                  <button
                    type="button"
                    className="secondary outline"
                    onClick={() => {
                      navigate(`/buy/step-${step - 1}`);
                    }}
                  >
                    {t("GO_BACK")}
                  </button>
                </div>
                <div className="flex-end">
                  <button
                    type="button"
                    className="primary mx-auto btn-plan"
                    onClick={onNext}
                    style={{ marginTop: "1rem", marginBottom: "0" }}
                  >
                    {t("CONFIRM")}
                  </button>
                </div>
              </>
            </div> */}
          </div>
          <div className="card details-buy">
            <BuyingProcessDetails
              buyingData={buyingData}
              aditionalBenefits={
                BuyingProcessService.getInstance().benefitsList
              }
              totalPrice={buyingData.planPrice}
              hiddenButton={btnHidden}
              onNext={onNext}
              step={step}
              businessInfo={companyData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyStep8;
