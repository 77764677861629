import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getCookie } from "./CookiesManager";

const Header = ({ role }) => {
  const { t } = useTranslation();  
  const roleCookie = parseInt(getCookie({ name: "_RL" }));
  const menuItems = [
    {
      id: "sales",
      href: "/admin/sales/",
      label: t("SALES.LIST"),
    }, 
    {
      id: "liquidacioines",
      href: "/admin/liquidaciones/",
      label: t("SALES.LIQUIDATIONS"),
    }, 
    {
      id: "brokers",
      href: "/admin/broker/",
      label: t("BROKER_LIST"),
    },
    {
      id: "business",
      href: "/admin/business/",
      label: t("BUSINESS_LIST"),
    },
    {
      id: "promo-code",
      href: "/admin/promo-code",
      label: t("PROMO_CODE_LIST"),
    },
    {
      id: "logout",
      href: "/logout",
      label: t("OUT"),
    }
  ]

  const printMenu = () => <>        
    <ul className="desktop">
      { menuItems.map((item, index) => 
          <li key={`item_desktop_${index}`}>
            <Link to={item.href}>{item.label}</Link>
          </li>                     
      ) }                                   
    </ul>   
    <details 
      role="list" 
      dir="rtl" 
      className="menu-mobile mobile">
      <summary 
        aria-haspopup="listbox" 
        role="link">Menú</summary>
      <ul role="listbox">
        { menuItems.map((item, index) => 
          <li key={`item_mobile_${index}`}>
            <Link to={item.href}>{item.label}</Link>
          </li>
        ) }
      </ul>
    </details>  
  </>     
  
  return (
    <header className="container">
      <nav>
        <ul>
          <li>
            <strong>MyGlobal ({t('PROFILE.USER')}: {getCookie({name:"_UN"}) ?? ''})</strong>
          </li>
        </ul>
        {getCookie({ name: "SSID" }) && getCookie("_RL") && getCookie("_UI") ? (
          <>
            {roleCookie === 1 ? (      
              printMenu()         
            ) : roleCookie === 2 ? (
              <>
                <ul>
                  <li>
                    <Link to="/logout" className="primary" role="button" style={{ width: '120px' }}>{t("OUT")}</Link>
                  </li>
                </ul>
              </>
            ) : (
              <>
                <ul>
                  <li>
                    <Link to="/users/login" className="primary" role="button" style={{ width: '120px' }}>{t("LOGIN")}</Link>
                  </li>
                </ul>
              </>
            )}
          </>
        ) : (
            <>
            {/* <ul>
              <li>
                <Link to="/users/login"  className="primary" role="button" style={{ width: '120px' }}>{t("LOGIN")}</Link>
              </li>
            </ul> */}
          </>
        )}
      </nav>
    </header>
  );
};

export default Header;
