import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Required from "../../../shared/Required";
import RequiredData from "../../../shared/RequiredData";
import FormatToastMessage from "../../../shared/FormatMessage";
import {
  validateEmail,
  strongPassword,
  inRange,
  sanitizeInputNumber,
} from "../../../shared/validations/Validation";
import MessageContext from "../../../contexts/MessageContext";
import useApiHook from "../../../hooks/ApiHook";
import { businessAdd } from "../../../services/BusinessService";


const errorsFields = {
  name: "BROKER.ERROR.NAME_REQUIRED",
  commission: "BUSINESS.ERROR.COMMISSION_REQUIRED",
  markup: "BUSINESS.ERROR.MARKUP_REQUIRED",
  paymentType: "BUSINESS.ERROR.PAYMENT_TYPE_REQUIRED",
  user: "BUSINESS.ERROR.USER_REQUIRED",
  password: "BUSINESS.ERROR.PASSWORD_REQUIRED",
  repassword: "BUSINESS.ERROR.PASSWORD_CONFIRM_REQUIRED",
};

const BrokerAdd = ({ onAdd, step, setStep, onGoBack, navbar = null, goBackButton = null }) => {
  
  const { t } = useTranslation();
  const apiHook = useApiHook();
  const messageContext = useContext(MessageContext);
  const [stateVersion, setStateVersion] = useState(0);
  const [nameValue, setNameValue] = useState(null);
  const [commissionValue, setCommissionValue] = useState(null);
  const [markupValue, setMarkupValue] = useState(null);
  const [paymentValue, setPaymentValue] = useState("0");
  const [userValue, setUserValue] = useState(null);
  const [passwordValue, setPasswordValue] = useState(null);
  const [repasswordValue, SetRepeatPassValue] = useState(null);

  const getValuesStep1 = () => { 
    return {
      name: nameValue || "required",
      commission: commissionValue || "required",
      markup: markupValue || "required",
      businessType: 'BROKER',
      paymentType: paymentValue !== "0" ? paymentValue : "required"
    };
  };



  const getValuesStep2 = () =>{ 
    return {
      user: userValue || "required",
      password: passwordValue || "required",
      repassword: repasswordValue || "required",
    };
  };

  const reRender = () => {
    setStateVersion(stateVersion + 1);
  };

  const moveToStep = (stepValue) => {
    if (stepValue < 1) {
      return onGoBack();
    }
    let errorMessages = [];

    if (stepValue === 2) {
        
        errorMessages = validateRequiredFields(errorMessages, getValuesStep1());
        if (errorMessages.length > 0) {
          return;
        }

        errorMessages = [];

        if (nameValue.length > 120) {
          errorMessages.push(t("BUSINESS.ERROR.NAME_MAX_LENGTH"));
        }

        if (inRange({ range: { value: commissionValue, min: 1, max: 100 } }) !== true) {
          if (
            inRange({ range: { value: commissionValue } }) === "NaN" ||
            !sanitizeInputNumber(commissionValue)
          ) {
            errorMessages.push(t("BUSINESS.ERROR.MARKUP_NOT_NUMBER"));
          }
          else if (!inRange({ range: { value: commissionValue, min: 1, max: 100 } })) {
            errorMessages.push(t("BUSINESS.ERROR.MARKUP_OUT_RANGE"));
          }
        }
        if (inRange({ range: { value: markupValue, min: 1, max: 100 } }) !== true) {
          if (
            inRange({ range: { value: markupValue } }) === "NaN" ||
            !sanitizeInputNumber(markupValue)
          ) {
            errorMessages.push(t("BUSINESS.ERROR.MARKUP_NOT_NUMBER"));
          }
          else if (!inRange({ range: { value: markupValue, min: 1, max: 100 } })) {
            errorMessages.push(t("BUSINESS.ERROR.MARKUP_OUT_RANGE"));
          }
        }
        errorMessages = showErrorMessages(errorMessages);
        if (errorMessages.length > 0) {
          return;
        }

    errorMessages = [];
    }    

    setStep(stepValue);
    reRender();
  };  



  const onClickAdd = async () => {
    let errorMessages = [];    
    errorMessages = validateRequiredFields(errorMessages, getValuesStep2());
    if (errorMessages.length > 0) {
      return;
    }
    errorMessages = [];
    
    if (!validateEmail(userValue) || userValue.length > 75) {
      errorMessages.push(t("BUSINESS.ERROR.EMAIL_INVALID"));
    }
    if (!strongPassword(passwordValue)) {
      errorMessages.push(t("BUSINESS.ERROR.PASSWORD_REQUERIMENTS"));
    }
    if (strongPassword(passwordValue)) {
      if (passwordValue !== repasswordValue) {
        errorMessages.push(t("BUSINESS.ERROR.PASSWORD_CONFIRM_NOT_MATCH"));
      }
    }

    errorMessages = showErrorMessages(errorMessages);
    if (errorMessages.length > 0) {
      return;
    }
    const responseData = await apiHook({
      fnApiCall: businessAdd,
      params: { 
        data: {
          logoUrl: '--',
          primaryColor: '#000000',
          secondaryColor: '#000000',
          description: '--',
          address: '--',
          rut: '--',
          subdomain: 'broker_' + new Date().getTime().toString(),
          ...getValuesStep1(), 
          ...getValuesStep2(), 
        } 
      },
    });

    if (responseData.ok) {            
      messageContext.setMessage({
        type: "success",
        text: `BROKER.${responseData.data.message}`,
      });
      onAdd(responseData.data.data);
    }
  };

  const validateRequiredFields = (errorMessages, refers) => {
      for (let key in refers) {
        if (refers[key] === "required") {
          errorMessages.push(t(errorsFields[key]));
        }
      }
      errorMessages = showErrorMessages(errorMessages);
      return errorMessages;
  } 

  const showErrorMessages = (errorMessages) => {
    if (errorMessages.length > 0) {
      messageContext.setMessage({
        type: "error",
        text: FormatToastMessage(
          t("BUSINESS.ERROR.TITLE_ERRORS"),
          errorMessages
        ),
      });        
    }
    return errorMessages;
  }

  useEffect(() => {
    return () => { 
      setStep(1);
    }
  },[])
  
  return (
    <>
    { navbar && navbar }
        
      <div
        style={{
          maxWidth: "500px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >         
      <nav className="flex-start">
          {goBackButton && goBackButton}
          <h1 style={{ 
            margin: 0, 
            marginLeft: 'var(--spacing)' 
          }}>{t("BROKER.ADD")}</h1>
      </nav>    
        {step === 1 && (
          <>
            <label>
              {t("BUSINESS.NAME")}
              <Required />
              <input
                type="text"
                placeholder={t("BUSINESS.NAME")}
                onChange={(e) => {
                  setNameValue(e.target.value);
                }}
                value={nameValue || ""}
                required
              />
            </label>         
            <label>
              {t("BUSINESS.COMMISSION")}
              <Required />
              <input
                type="text"
                placeholder={t("BUSINESS.COMMISSION")}
                onChange={(e) => {
                  setCommissionValue(e.target.value);
                }}
                value={commissionValue || ""}
                required
              />
            </label>
            <label>
              {t("BUSINESS.MARKUP")}
              <Required />
              <input
                type="text"
                placeholder={t("BUSINESS.MARKUP")}
                onChange={(e) => {
                  setMarkupValue(e.target.value);
                }}
                value={markupValue || ""}
                required
              />
            </label>
            <div className="grid">              
              <div>
                <label>
                  {t("BUSINESS.PAYMENT_TYPE")}
                  <Required />
                </label>
                <select
                  defaultValue={paymentValue ? paymentValue : "0"}
                  onChange={(e) => {
                    setPaymentValue(e.target.value);
                  }}
                  required
                >
                  <option value="0">{t("SELECT")}</option>
                  <option value="1">
                    {t("BUSINESS.PAYMENT_TYPE_ACCOUNT")}
                  </option>
                  <option value="2">{t("BUSINESS.PAYMENT_TYPE_ONLINE")}</option>
                </select>
              </div>
            </div>
          </>
        )}        
        {step === 2 && (
          <>
            <label>
              {t("BUSINESS.EMAIL")}
              <Required />
              <input
                type="email"
                placeholder={t("BUSINESS.EMAIL")}
                onChange={(e) => {
                  setUserValue(e.target.value);
                }}
                value={userValue || ""}
                required
              />
            </label>
            <label>
              {t("BUSINESS.PASSWORD")}
              <Required />
              <input
                type="password"
                onChange={(e) => {
                  setPasswordValue(e.target.value);
                }}
                value={passwordValue || ""}
                required
              />
            </label>
            <label>
              {t("BUSINESS.PASSWORD_CONFIRM")}
              <Required />
              <input
                type="password"
                onChange={(e) => {
                  SetRepeatPassValue(e.target.value);
                }}
                value={repasswordValue || ""}
                required
              />
            </label>
          </>
        )}
        <RequiredData />
        <div style={{ marginTop: 'var(--spacing)'}} className="grid form-end-buttons">
          {step !== 2 && (
            <>
              <div className="flex-start">
                <button
                  className="secondary outline"
                  onClick={() => moveToStep(step - 1)}
                >
                  {t("GO_BACK")}
                </button>
              </div>
              <div className="flex-end">
                <button className="primary" 
                  onClick={() => moveToStep(step + 1)}>
                  {t("NEXT")}
                </button>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <div className="flex-start">
                <button
                  className="secondary outline"
                  onClick={() => moveToStep(step - 1)}
                >
                  {t("GO_BACK")}
                </button>
              </div>
              <div className="flex-end">
                <button type="submit"              
                className="primary" 
                onClick={onClickAdd}>
                  {t("BROKER.ADD")}
                </button>
              </div>
            </>
          )}
        </div>    
      </div>
    </>
  );
};

export default BrokerAdd;
