import { useRef, useEffect, useState } from "react";
import useApiHook from "../../../hooks/ApiHook";
import { businessFindList } from "../../../services/BusinessService";
import { useTranslation } from "react-i18next";
import OrderTableHeader from "../../../shared/OrderTableHeader";
import ExportSheet from "../../../shared/ExportSheet";

const limit = 100;
let items = [];
let itemsToShow = [];
let orderDirection = "asc";
let orderBy = "code";

const BusinessSearch = ({ onSelect, navbar = null, addButton = null }) => {
  const [stateVersion, setStateVersion] = useState(1);
  const { t } = useTranslation();
  const apiHook = useApiHook();
  const formRef = useRef(null);

  const setItems = (data) => {
    items = data;
  };

  const reRender = () => {
    const version = stateVersion;
    if (version === stateVersion)
      setStateVersion((stateVersion) => stateVersion + 1);
  };

  const findList = async () => {
    const responseData = await apiHook({
      fnApiCall: businessFindList,
      params: null,
    });
    if (!responseData || !responseData.data) {
      return;
    }
    let data = responseData.data.filter(
      (item) => item.businessType.toUpperCase() === "COMPANY"
    );
    data = data.map((item) => {
      return {
        id: item.id ?? null,
        _id: item._id ?? null,
        name: item.name,
        subdomain:
          item.subdomain && item.subdomain.length > 0
            ? item.subdomain.toLowerCase()
            : "",
        rut: item.rut ?? t("NO_DATA"),
        commission: item.commission,
        paymentType: t(
          item.paymentType === 1
            ? "BUSINESS.PAYMENT_TYPE_ACCOUNT"
            : "BUSINESS.PAYMENT_TYPE_ONLINE"
        ),
      };
    });
    setItems(data);
    filterItemsToShow();
    reRender();
  };

  useEffect(() => {
    findList();
  }, []);

  const onClickRow = (item) => {
    resetForm();
    onSelect(item);
  };

  const setOrder = ({ newOrderBy, newOrderDirection }) => {
    orderBy = newOrderBy;
    orderDirection = newOrderDirection;
    filterItemsToShow(formRef.current.value);
  };

  const onSearch = () => {
    filterItemsToShow();
  };

  const resetForm = () => {
    formRef?.current.reset();
    filterItemsToShow();
  };

  const filterItemsToShow = () => {
    if (items == null || items.length === 0) {
      itemsToShow = [];
      reRender();
      return;
    }
    let tempItems = items;
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      if (formData.get("businessName").trim().length > 0) {
        tempItems = tempItems.filter((item) =>
          item.name
            .toUpperCase()
            .includes(formData.get("businessName").trim().toUpperCase())
        );
      }
      const paymentType = formData.get("paymentType");
      if (paymentType !== t("ALL")) {
        tempItems = tempItems.filter(
          (item) => item.paymentType === paymentType
        );
      }
    }

    if (!tempItems || tempItems.length === 0) {
      itemsToShow = [];
      reRender();
      return;
    }
    itemsToShow = tempItems
      .sort((a, b) => {
        const sortDirection = orderDirection === "asc" ? 1 : -1;
        if (a[orderBy] === b[orderBy]) {
          return 0;
        } else if (a[orderBy] > b[orderBy]) {
          return 1 * sortDirection;
        } else {
          return -1 * sortDirection;
        }
      })
      .slice(0, limit);
    reRender();
  };

  /* const clear = () => {
    setItems([]);
    itemsToShow = [];
  }; */

  const getDataForExport = () => {
    const headers = [
      t("BUSINESS.NAME"),
      t("BUSINESS.SUBDOMAIN"),
      t("BUSINESS.RUT"),
      t("BUSINESS.COMMISSION"),
      t("BUSINESS.PAYMENT_TYPE"),
    ];

    const data = itemsToShow.map((item) => {
      const cells = [...Object.values(item)];
      cells.splice(0, 2);
      return cells;
    });

    return [headers, ...data];
  };

  return (
    <>
      <nav className="flex-between flex-wrap">
        <div className="flex-start">
          <h1 style={{ margin: 0 }}>{t("BUSINESS_LIST")}</h1>
          {addButton && addButton}
        </div>
        <ExportSheet
          style={{ marginTop: "10px" }}
          getData={getDataForExport}
          fileName={t("BUSINESS_LIST")}
        />
      </nav>
      <details className="filters flex-start">
        <summary>{t("FILTERS")}</summary>
        <form
          ref={formRef}
          name="search"
          className="menu-navbar filters"
          style={{ justifyContent: "flex-start", alignItems: "flex-end" }}
          onSubmit={(ev) => {
            ev.preventDefault();
          }}
        >
          <div className="mobile-full-width">
            <label htmlFor="plan">{t("SEARCH_BY_NAME")}</label>
            <input
              type="search"
              onChange={onSearch}
              style={{ margin: 0 }}
              name="businessName"
              id="businessName"
              className="search-input"
              placeholder={t("SEARCH_BY_NAME")}
            />
          </div>
          <div className="mobile-full-width">
            <label htmlFor="paymentType">{t("BUSINESS.PAYMENT_TYPE")}</label>
            <select
              id="paymentType"
              name="paymentType"
              onChange={onSearch}
              required
              style={{ margin: 0 }}
              defaultValue={`${t("ALL")}`}
            >
              <option value={`${t("ALL")}`}>{t("ALL")}</option>
              <option value={`${t("BUSINESS.PAYMENT_TYPE_ACCOUNT")}`}>
                {t("BUSINESS.PAYMENT_TYPE_ACCOUNT")}
              </option>
              <option value={`${t("BUSINESS.PAYMENT_TYPE_ONLINE")}`}>
                {t("BUSINESS.PAYMENT_TYPE_ONLINE")}
              </option>
            </select>
          </div>
          <div className="flex-end">
            <button
              type="button"
              className="secondary mobile-full-width"
              style={{ marginBottom: 0 }}
              onClick={resetForm}
            >
              {t("CLEAN")}
            </button>
          </div>
        </form>
      </details>
      {navbar && navbar}
      {itemsToShow.length > 0 ? (
        <figure>
          <table role="grid">
            <thead>
              <tr>
                <OrderTableHeader
                  titleKey={"BUSINESS.NAME"}
                  orderByKey={"name"}
                  onSetOrder={setOrder}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                />
                <OrderTableHeader
                  titleKey={"BUSINESS.SUBDOMAIN"}
                  orderByKey={"subdomain"}
                  onSetOrder={setOrder}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                />
                <OrderTableHeader
                  titleKey={"BUSINESS.RUT"}
                  orderByKey={"rut"}
                  onSetOrder={setOrder}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                />
                <OrderTableHeader
                  titleKey={"BUSINESS.COMMISSION"}
                  orderByKey={"commission"}
                  onSetOrder={setOrder}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                />
                <OrderTableHeader
                  titleKey={"BUSINESS.PAYMENT_TYPE"}
                  orderByKey={"paymentType"}
                  onSetOrder={setOrder}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                />
              </tr>
            </thead>
            <tbody>
              {itemsToShow.map((item, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => onClickRow(item)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{item.name}</td>
                    <td>{item.subdomain}</td>
                    <td>{item.rut}</td>
                    <td>{item.commission}</td>
                    <td>{item.paymentType}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </figure>
      ) : (
        <p>{t("BUSINESS_LIST_NO_RESULTS")}</p>
      )}
    </>
  );
};

export default BusinessSearch;
