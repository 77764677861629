import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom"; //, Link
import Required from "../../../shared/Required";
import RequiredData from "../../../shared/RequiredData";
import FormatToastMessage from "../../../shared/FormatMessage";
/* import {
  inRange,
  sanitizeInputNumber,
} from "../../../shared/validations/Validation"; */
//import ImageCropperModal from "../../../shared/ImageCropperModal";
import MessageContext from "../../../contexts/MessageContext";
import useApiHook from "../../../hooks/ApiHook";
import {
  businessGetById,
  businessEdit,
} from "../../../services/BusinessService";
import ConfirmationModal from "../../../shared/ConfirmationModal";

let isConfirmationEditModalOpen = false;
const errorsFields = {
  name: "BUSINESS.ERROR.NAME_REQUIRED",
  commission: "BUSINESS.ERROR.COMMISSION_REQUIRED",
  paymentType: "BUSINESS.ERROR.PAYMENT_TYPE_REQUIRED",
  user: "BUSINESS.ERROR.USER_REQUIRED",
  password: "BUSINESS.ERROR.PASSWORD_REQUIRED",
  repassword: "BUSINESS.ERROR.PASSWORD_CONFIRM_REQUIRED",
};

const BrokerEdit = ({ onEdit, navbar = null, goBackButton = null }) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const apiHook = useApiHook();
  const { id } = useParams();
  const messageContext = useContext(MessageContext);
  const [responseBusiness, setResponseBusiness] = useState(null);
  const [stateVersion, setStateVersion] = useState(0);
  const [nameValue, setNameValue] = useState(null);  
  const [commissionValue, setCommissionValue] = useState(null);
  const [markupValue, setMarkupValue] = useState(null);
  const [paymentValue, setPaymentValue] = useState("0");

  const getValues = () => { 
    return {
      name: nameValue || "required",
      commission: commissionValue || "required",
      markup: markupValue || "required",
      businessType: 'BROKER',
      paymentType: paymentValue !== "0" ? paymentValue : "required"
    };
  };

  const init = async () => {
    const response = await apiHook({
      fnApiCall: businessGetById,
      params: { id },
    });
    if (response.ok) {
      setResponseBusiness(response.data);
      setNameValue(response.data.name);
      setCommissionValue(response.data.commission);
      setMarkupValue(response.data.markup);
      setPaymentValue(response.data.paymentType);
    }
    reRender();
  };

  useEffect(() => {
    init();
  }, []);

  const reRender = () => {
    setStateVersion(stateVersion + 1);
  };  

  const showConfirmationEditModal = () => {
    isConfirmationEditModalOpen = true;
    reRender();
  };

  const hideConfirmationEditModal = () => {
    isConfirmationEditModalOpen = false;
    reRender();
  };

  const onClickAdd = async () => {
    let errorMessages = [];    
    errorMessages = validateRequiredFields(errorMessages, getValues());
    if (errorMessages.length > 0) {
      return;
    }
    
    const refers = {
      id : responseBusiness._id,
        logoUrl: '--',
        primaryColor: '#000000',
        secondaryColor: '#000000',
        description: '--',
        address: '--',
        rut: '--',
        subdomain: nameValue,
      ...getValues(),
    };
    for (let key in refers) {
      if (refers[key] === responseBusiness[key] && key !== "id") {
        delete refers[key];
      }
    }

    if (Object.keys(refers).length <= 1) {
      return (
        messageContext.setMessage({
          type: "error",
          text: t("BUSINESS.ERROR.NO_CHANGES"),
        }),
        hideConfirmationEditModal()
      );
    }
    hideConfirmationEditModal();
    const responseData = await apiHook({
      fnApiCall: businessEdit,
      params: { data: refers },
    });

    if (responseData.ok) {
      messageContext.setMessage({
        type: "success",
        text: `BROKER.${responseData.data.message}`,
      });
      onEdit(responseData.data.result);
    }
  };

  const validateRequiredFields = (errorMessages, refers) => {
    for (let key in refers) {
      if (refers[key] === "required") {
        errorMessages.push(t(errorsFields[key]));
      }
    }
    errorMessages = showErrorMessages(errorMessages);
    return errorMessages;
} 

const showErrorMessages = (errorMessages) => {
  if (errorMessages.length > 0) {
    messageContext.setMessage({
      type: "error",
      text: FormatToastMessage(
        t("BUSINESS.ERROR.TITLE_ERRORS"),
        errorMessages
      ),
    });        
  }
  return errorMessages;
}

  return (
    <>
    <div
        style={{
          maxWidth: "500px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
      { navbar && navbar }
      <nav className="flex-start">
        {goBackButton && goBackButton}
        <h1 style={{ 
          margin: 0, 
          marginLeft: 'var(--spacing)' 
        }}>{t("BROKER.EDIT")}</h1>
      </nav>                
        {responseBusiness && 
          <>
                <label>
                  {t("BUSINESS.NAME")}
                  <Required />
                  <input
                    type="text"
                    placeholder={t("BUSINESS.NAME")}
                    onChange={(e) => {
                      setNameValue(e.target.value);
                    }}
                    value={nameValue}
                    required
                  />
                </label>        
                <label>
                  {t("BUSINESS.COMMISSION")}
                  <Required />
                  <input
                    type="text"
                    placeholder={t("BUSINESS.COMMISSION")}
                    onChange={(e) => {
                      setCommissionValue(e.target.value);
                    }}
                    value={commissionValue}
                    required
                  />
                </label>
                <label>
                  {t("BUSINESS.MARKUP")}
                  <Required />
                  <input
                    type="text"
                    placeholder={t("BUSINESS.MARKUP")}
                    onChange={(e) => {
                      setMarkupValue(e.target.value);
                    }}
                    value={markupValue}
                    required
                  />
                </label>
                <div className="grid">                  
                  <div>
                    <label>
                      {t("BUSINESS.PAYMENT_TYPE")}
                      <Required />
                    </label>
                    <select
                      defaultValue={paymentValue}
                      onChange={(e) => {
                        setPaymentValue(e.target.value);
                      }}
                      required
                    >
                      <option value="1">
                        {t("BUSINESS.PAYMENT_TYPE_ACCOUNT")}
                      </option>
                      <option value="2">
                        {t("BUSINESS.PAYMENT_TYPE_ONLINE")}
                      </option>
                    </select>
                  </div>
                </div>
            <br />
            <RequiredData />
            <div style={{}} className="grid form-end-buttons">
              
                <div className="flex-start">
                <button
                    className="secondary outline"
                    onClick={() => navigate('/admin/broker/')}
                >
                    {t("GO_BACK")}
                </button>
                </div>
                <div className="flex-end">
                <button
                    type="submit"
                    className="primary"
                    onClick={showConfirmationEditModal}
                >
                    {t("BROKER.EDIT")}
                </button>
                </div>
                
            </div>            
            <ConfirmationModal
              isOpen={isConfirmationEditModalOpen}
              titleKey="BROKER.EDITION.TITLE"
              messageKey="BROKER.EDITION.CONFIRM_MESSAGE"
              onConfirm={onClickAdd}
              onCancel={hideConfirmationEditModal}
            />
          </>
        }
      </div>
    </>
  );
};

export default BrokerEdit;
