export const MoreBenefits1 = () => {
    return (
        <div className="more-benefits-1">
            <div className="container">
                <p>Mejorá <span>tu plan</span></p>
            </div>
        </div>
    )
}

export default MoreBenefits1;