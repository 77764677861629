

const AboutUsHero1 = () => {    
    return (
        <div className="about-us-hero1">
            <div className="about-us-hero1__content container">
                <p>Somos <span>más</span> que un seguro</p>
                <p>Nos dedicamos a la prestación de servicios
                de asistencia nacional e internacional.</p>
            </div>
        </div>
    )
}

export default AboutUsHero1;