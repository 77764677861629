import { useEffect } from "react";
import HeaderPublic from "../components/HeaderPublic";
import FooterPublic from "../components/FooterPublic";
import RequestAssistanceComponent from "../components/RequestAssistance";

const RequestAssistance = () => { 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="public">
            <HeaderPublic />
            <RequestAssistanceComponent />
            <FooterPublic />
        </div>
    );
}

export default RequestAssistance;