import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const sendPayment = async ({ data }) => {
  try {
    const purchase = await axios.post(`${API_URL}/sale/payment`, data);    
    return {
      data: purchase.data,
      ok: purchase.status === 200,
    };
  } catch (error) {
    return {
      errorCode: error.response.data.errorCode,
      error: error.response.data.message,
      tryAgain: error.response.data.error,
      ok: false,
    };
  }
};

export const sendRefund = async ({ data }) => {
  try {
    const refund = await axios.post(`${API_URL}/sale/refund`, data);
    return {
      data: refund.data,
      ok: refund.status === 200,
    };
  } catch (error) {
    return {
      errorCode: error.response.data.errorCode,
      error: error.response.data.message,
      tryAgain: error.response.data.error,
      ok: false,
    };
  }
};

export const cancelPlan = async ({ data }) => {
  try {
    const cancel = await axios.post(`${API_URL}/sale/cancel`, data);
    return {
      data: cancel.data,
      ok: cancel.status === 200,
    };
  } catch (error) {
    return {
      errorCode: error.response.data.errorCode,
      error: error.response.data.message,
      tryAgain: error.response.data.error,
      ok: false,
    };
  }
};
