import { Link } from "react-router-dom";
import BusinessSearch from "../../components/business/BusinessSearch"
//import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import Header from "../../../shared/Header"
import useAuthHook from "../../../hooks/AuthHook";


/* const buttonStyle = {
    display: 'block',
    width: 'fit-content',
    margin:0 
} */

const BusinessList = () => {   
   
    const navigate = useNavigate();
    //const {t} = useTranslation();

    useAuthHook();    

    const onSelect = (item) => {
        return navigate(`/admin/business/${item._id}`);
    }   
    
    return (
        <>
            <Header />
            <div className="container">
                <BusinessSearch 
                    addButton={
                        <Link                                         
                            className="icon-clickable add-btn"
                            to="/admin/business/add"
                        >
                            <FontAwesomeIcon 
                            color="var(--primary-inverse)"
                            size="xl"
                            icon={faPlus} />
                        </Link>
                    }                  
                    onSelect={onSelect} />
            </div>
        </>
    )
}

export default BusinessList;