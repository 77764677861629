import { Link } from "react-router-dom";
import BrokerSearch from "../../components/broker/BrokerSearch"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import Header from "../../../shared/Header"
import useAuthHook from "../../../hooks/AuthHook";

const BrokerList = () => {   
   
    const navigate = useNavigate();

    useAuthHook();    

    const onSelect = (item) => {
        return navigate(`/admin/broker/${item._id}`);
    }   
    
    return (
        <>
            <Header />
            <div className="container">
                <BrokerSearch                 
                    addButton={
                        <Link                                         
                            className="icon-clickable add-btn"
                            to="/admin/broker/add"
                        >
                            <FontAwesomeIcon 
                            color="var(--primary-inverse)"
                            size="xl"
                            icon={faPlus} />
                        </Link>
                    }                  
                    onSelect={onSelect} />
            </div>
        </>
    )
}

export default BrokerList;