import { Link } from "react-router-dom"

const HomePlans = () => {
    return (
        <section id="plans">
            <div className="container flex-center">
                <p>
                    <span>Planes</span>
                    <span className="mob-scale-font">que se adaptan a ti</span>
                </p>
                <div id='plans-list'>
                    <div className="plan">
                        <div className='title'><span>Planes por Viaje</span></div>
                        <div className='description'>Protección por un único viaje de ida y vuelta. Máximo hasta 120 días por viaje.</div>
                    </div>
                    <div className="plan">
                        <div className='title'><span>Anuales - Multiviajes </span></div>
                        <div className='description'>Si viajas con frecuencia, este plan es para ti. Protección durante un año calendario.</div>
                    </div>
                    {/* <div className="plan">
                        <div className='title'><span>Larga estadía</span></div>
                        <div className='description'>Para viajes con duración de más de 60 días. Protección hasta un máximo de 1 año.</div>
                    </div> */}
                </div>
                <Link to="mejora-tu-plan"
                role="button"
                className="secondary more-benefits-btn">
                    Beneficios a sumar
                </Link>
            </div>
        </section>
    )
}

export default HomePlans;