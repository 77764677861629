import { useEffect } from "react";
import AboutUsHero1 from "../components/AboutUsHero1";
import AboutUsHero2 from "../components/AboutUsHero2";
import HeaderPublic from "../components/HeaderPublic";
import FooterPublic from "../components/FooterPublic";
import AboutUsHero3 from "../components/AboutUsHero3";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="public">
      <HeaderPublic />
      <AboutUsHero1 />
      <AboutUsHero2 />
      <AboutUsHero3 />
      <FooterPublic />
    </div>
  );
};

export default AboutUs;
