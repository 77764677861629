import { useTranslation } from "react-i18next";
import Required from "./Required";

const RequiredData = ({ data, children }) => {
    const {t} = useTranslation();

    return (
        <p><Required 
            left="0px" 
            right="7px" 
            />{t('REQUIRED_DATA')}</p>
    )
}

export default RequiredData;