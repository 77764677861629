import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import HomeHeroOne from "../components/HomeHeroOne";
import HomeHeroTwo from "../components/HomeHeroTwo";
import HeaderPublic from "../components/HeaderPublic";
import FooterPublic from "../components/FooterPublic";
import useApiHook from "../../hooks/ApiHook";
import { businessGetBySubdomain } from "../../services/BusinessService";
import BuyingProcessService from "../../services/BuyingProcessService";
import { getCookie, deleteAllCookies } from "../../shared/CookiesManager";
import { InitStyle } from "../../services/StyleService";

const HomeCompany = () => {
  const { subdomain } = useParams();
  const apiHook = useApiHook();
  const navigate = useNavigate();
  //const [responseCompany, setResponseCompany] = useState(null);
  const [errorSubdomain, setErrorSubdomain] = useState(false);
  const [gettingStarted, setGettingStarted] = useState("none");
  let buyingProcessService = BuyingProcessService.getInstance();
  //const [stateVersion, setStateVersion] = useState(0);
  sessionStorage.removeItem("step");
  sessionStorage.removeItem("buyingProcess");

  const init = async () => {
    if (subdomain === "myglobal") {
      return navigate("/");
    }
    const response = await apiHook({
      fnApiCall: businessGetBySubdomain,
      params: { subdomain },
    });
    if (response.ok) {
      buyingProcessService.businessInfo = response.data;
      const currentCompanyData = {
        name: response.data.name,
        faviconUrl: response.data.faviconUrl
      }
      localStorage.setItem("companyData", JSON.stringify(currentCompanyData))
      var event = new Event('companyDataReady');
      document.dispatchEvent(event);

      if (buyingProcessService?.businessInfo?.businessType === "COMPANY") {
        InitStyle(buyingProcessService.businessInfo);
      }
      //setResponseCompany(response.data);
      setErrorSubdomain(false);
      setGettingStarted("block");
    } else {
      setErrorSubdomain(true);
      setGettingStarted("block");
      setTimeout(() => {
        return navigate("/");
      }, 2000);
    }
  };

  useEffect(() => {
    if (getCookie({ name: "_RL" }) === "2") {
      deleteAllCookies({ path: "/" });
      //setStateVersion(1);
    }
    init();
  }, [subdomain]);

  return (
    <>
      {buyingProcessService.businessInfo && !errorSubdomain ? (
        <div className="public" style={{ display: `${gettingStarted}` }}>
          <HeaderPublic businessInfo={buyingProcessService.businessInfo} />
          <HomeHeroOne businessInfo={buyingProcessService.businessInfo} />
          <HomeHeroTwo businessInfo={buyingProcessService.businessInfo} />
          <FooterPublic businessInfo={buyingProcessService.businessInfo} />
        </div>
      ) : (
        <div className="container flex-center">
          <h2>Pagina no encontrada</h2>
        </div>
      )}
    </>
  );
};

export default HomeCompany;
