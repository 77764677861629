import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../../shared/Header";
import useAuthHook from "../../../hooks/AuthHook";
import SalesLiquidation from "../../components/SalesLiquidation";
import { getCookie } from "../../../shared/CookiesManager";

const ProfileLiquidation = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const roleValue = parseInt(getCookie({ name: "_RL" }));
    useAuthHook(roleValue);

    return (
        <>
            <Header />
            <div className="container">
                <SalesLiquidation />
            </div>
        </>
    );
};

export default ProfileLiquidation;