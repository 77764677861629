import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { findDetails } from "../../../services/SalesService";
import useApiHook from "../../../hooks/ApiHook";
import useAuthHook from "../../../hooks/AuthHook";

let saleData = null;
const SaleDetails = () => {
    const params = useParams();
    const apiCall = useApiHook();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useAuthHook();    

    const init = async () => {
        if (!params.id) return navigate("/sales");
        const responseData = await apiCall({
            fnApiCall: findDetails,
            params: {id: params.id},
          });
          saleData = responseData?.data ?? null;          
    }

    useEffect(() => {
        init();

        return () => {
            saleData = null;
        }
    },[]);
    
    return (
        <div className="container">            
            { saleData && 
                <>
                    <h2>{t('SALES.PASSENGERS')} ({saleData.sale.passengersData.length})</h2>
                    <figure>
                        <table role="grid">
                            <thead>
                            <tr>
                                <th>{t('SALES.TABLE_HEADER.NAME')}</th>
                                <th>{t('SALES.TABLE_HEADER.LASTNAME')}</th>
                                <th>{t('SALES.TABLE_HEADER.AGE')}</th>
                                <th>{t('SALES.TABLE_HEADER.DOCTYPE')}</th>
                                <th>{t('SALES.TABLE_HEADER.DOCNUMBER')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {saleData.sale.passengersData.map((item, index) => {
                                return (
                                <tr
                                    key={index}                        
                                >
                                    <td>{item.name}</td>
                                    <td>{item.lastName}</td>
                                    <td>{item.age}</td>
                                    <td>{t(item.typeDoc === 1? 'BUYING_PROCESS.LABEL.DOCTYPE_NATIONAL' : 'BUYING_PROCESS.LABEL.DOCTYPE_PASSPORT')}</td>
                                    <td>{item.docNumber}</td>
                                </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </figure>
                </>
            }
        </div>
    )
}

export default SaleDetails;
