import { useEffect, useState, useRef } from "react";
import useApiHook from "../../../hooks/ApiHook";
import { salesFindList } from "../../../services/SalesService";
import { promoCodeFindList } from "../../../services/PromoCodeService";
import { useTranslation } from "react-i18next";
import OrderTableHeader from "../../../shared/OrderTableHeader";
import ExportSheet from "../../../shared/ExportSheet";
import PriceFormat from "../../../shared/PriceFormat";

const limit = 100;
let arrSales = [];
let items = [];
let itemsSeller = [];
let itemsToShow = [];
let arrNames = [];
let arrPromos = [];
let groupedSales = [];
let orderDirection = "asc";
let orderBy = "code";

const SalesLiquidation = ({ onSelect, navbar = null }) => {

  const formRef = useRef();
  const [stateVersion, setStateVersion] = useState(1);
  const { t } = useTranslation();
  const apiHook = useApiHook();
  const date = new Date();
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  const setItems = (data) => {
    items = data;
  };

  const prepareData = (data) => {
    const currentMonth = `${year}-${month}`;
    let businessName = "";
    let total = 0.0;
    let totalSales = 0;
    let commission = 0.0;
    let discount = 0.0;
    let preNeto = 0.0;
    let neto = 0.0;
    let netoRua = 0.0;
    let markup = 0.0;
    let continentalPrice = 0.0;
    data.forEach((item) => {
      if (item.created.startsWith(currentMonth)) {
        markup = parseFloat((item.sale?.markup / 100) + 1).toFixed(2);          
        businessName = item.businessName;
        total = PriceFormat({
          price: [item.sale.planPrice, total],
          operator: "+",
          expect: "float",
        });
        totalSales++;
        if (
          item.sale.commission !== undefined &&
          item.sale.commission > 0 &&
          commission === 0.0
        ) {
          commission = parseFloat(item.sale.commission).toFixed(2);
        }
        if (
          item.sale?.discountAmount !== undefined &&
          item.sale.discountAmount > 0 &&
          item.sale?.withDiscount === true
        ) {
          const promo = arrPromos.find(
            (promo) => promo.code === item.sale.promoCode
          );
          if (promo) {
            let itemDiscount = 0.0;
            itemDiscount = parseFloat(
              item.sale.discountAmount *
                parseFloat(promo.companyPercentage / 100).toFixed(2)
            ).toFixed(2);
            if (discount === 0.0) {
              discount = itemDiscount;
            } else {
              discount = parseFloat(discount + itemDiscount).toFixed(2);
            }
          } else {
            if (discount === 0.0)
              discount = parseFloat(item.sale.discountAmount).toFixed(2);
            else
              discount = parseFloat(
                discount + item.sale.discountAmount
              ).toFixed(2);
          }
        }

        if (item.sale?.planContinentalPrice !== undefined)
          continentalPrice = parseFloat(total / markup).toFixed(2);

        preNeto = PriceFormat({
          price: [total, discount, continentalPrice],
          operator: "-",
          expect: "float",
        });
        if (commission > 0.0) {
          neto = parseFloat(
            preNeto * parseFloat(commission / 100).toFixed(2)
          ).toFixed(2);
        } else {
          neto = parseFloat(preNeto).toFixed(2);
        }

        netoRua = PriceFormat({price:[total, discount, continentalPrice, neto], operator: "-", expect: "float"});
      }
    });

    commission = parseInt(commission);
    const item = {
      businessName,
      total,
      totalSales,
      commission,
      discount,
      neto,
      netoRua,
      markup,
      continentalPrice,
    };
    if (totalSales > 0) groupedSales.push(item);
    else return false;
  };

  const setItemsSeller = (data) => {
    groupedSales = [];
    itemsSeller = data;
    for (const name of arrNames) {
      const seller = itemsSeller.filter((item) => name === item.businessName);
      if (seller.length > 0) {
        prepareData(seller);
      }
    }
  };

  const reRender = () => {
    setStateVersion((stateVersion) => stateVersion + 1);
  };

  const getDataForExport = () => {
    if (itemsToShow.length === 0 || groupedSales.length === 0) return false;
    const headers = [
      t("SALES.TABLE_HEADER.BUSINESS"),
      t("SALES.TABLE_HEADER.INVOICED"),
      t("SALES.TABLE_HEADER.QUANTITY"),
      t("SALES.TABLE_HEADER.COMMISSION"),
      t("SALES.TABLE_HEADER.DISCOUNT"),
      t("SALES.TABLE_HEADER.CONTINENTAL"),
      t("SALES.TABLE_HEADER.NET"),
      t("SALES.TABLE_HEADER.NET_RUA"),
    ];
    const data = groupedSales.map((item) => {
      return [
        item.businessName,
        item.total,
        item.totalSales,
        item.commission,
        item.discount,
        item.continentalPrice,
        item.neto,
        item.netoRua,
      ];
    });

    return [headers, ...data];
  };

  const findPromoList = async () => {
    const responseData = await apiHook({
      fnApiCall: promoCodeFindList,
      params: null,
    });
    const promosData = responseData?.data ?? null;
    if (!promosData) return false;
    arrPromos = promosData;
    return true;
  };

  const findList = async () => {
    if (stateVersion > 1) return;
    const responseData = await apiHook({
      fnApiCall: salesFindList,
      params: null,
    });
    const salesData = responseData?.data?.data ?? null;
    if (!salesData) return;
    const promos = await findPromoList();
    if (!promos) return false;
    arrSales = salesData;
    const sales = salesData.map((item, index) => {
      arrSales[index].code = `#000${index + 1}`;
      if (!arrNames.includes(item.businessName))
        arrNames.push(item.businessName);
      return item;
    });
    arrNames.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    setItems(sales);
    setItemsSeller(sales);
    filterItemsToShow();
    reRender();
  };

  useEffect(() => {
    findList();
  }, [window.onload]);

  const setFilterData = (data) => {
    let businessName = "";
    let total = 0.0;
    let totalSales = 0;
    let commission = 0.0;
    let discount = 0.0;
    let preNeto = 0.0;
    let neto = 0.0;
    let netoRua = 0.0;
    let markup = 0.0;
    let continentalPrice = 0.0;
    data.forEach((item) => {
      businessName = item.businessName;
      markup = parseFloat((item.sale?.markup / 100) + 1).toFixed(2);        
      total = PriceFormat({
        price: [item.sale.planPrice, total],
        operator: "+",
        expect: "float",
      });
      totalSales++;
      if (
        item.sale.commission !== undefined &&
        item.sale.commission > 0 &&
        commission === 0.0
      ) {
        commission = parseFloat(item.sale.commission).toFixed(2);
      }
      if (
        item.sale?.discountAmount !== undefined &&
        item.sale.discountAmount > 0 &&
        item.sale?.withDiscount === true
      ) {
        const promo = arrPromos.find(
          (promo) => promo.code === item.sale.promoCode
        );
        if (promo) {
          let itemDiscount = 0.0;
          itemDiscount = parseFloat(
            item.sale.discountAmount *
              parseFloat(promo.companyPercentage / 100).toFixed(2)
          ).toFixed(2);
          if (discount === 0.0) {
            discount = itemDiscount;
          } else {
            discount = parseFloat(discount + itemDiscount).toFixed(2);
          }
        }
        else if (discount === 0.0)
          discount = parseFloat(item.sale.discountAmount).toFixed(2);
        else
          discount = parseFloat(discount + item.sale.discountAmount).toFixed(2);
      }

      if (item.sale?.planContinentalPrice !== undefined)
        continentalPrice = parseFloat(total / markup).toFixed(2);

      preNeto = PriceFormat({
        price: [total, discount, continentalPrice],
        operator: "-",
        expect: "float",
      });
      if (commission > 0.0) {
        neto = parseFloat(
          preNeto * parseFloat(commission / 100).toFixed(2)
        ).toFixed(2);
      } else {
        neto = parseFloat(preNeto).toFixed(2);
      }

      netoRua = PriceFormat({price:[total, discount, continentalPrice, neto], operator: "-", expect: "float"});
      
    });

    commission = parseInt(commission);
    const item = {
      businessName,
      total,
      totalSales,
      commission,
      discount,
      neto,
      netoRua,
      markup,
      continentalPrice,
    };
    if (totalSales > 0) {
      groupedSales.push(item);
      reRender();
    } else return false;
  };

  const onFilter = () => {
    if (items == null || items.length === 0) {
      itemsToShow = [];
      reRender();
      return;
    }

    const formData = new FormData(formRef.current);
    let tempItems = items;

    if (formData.get("startDate").length > 0) {
      const startDateValue = parseInt(
        formData.get("startDate").replace(/-/g, "")
      );
      tempItems = tempItems.filter((item) => {
        const itemCreated = parseInt(
          item.created.split("T")[0].replace(/-/g, "")
        );
        return itemCreated >= startDateValue;
      });
    }

    if (formData.get("endDate").length > 0) {
      const endDateValue = parseInt(formData.get("endDate").replace(/-/g, ""));
      tempItems = tempItems.filter((item) => {
        const itemCreated = parseInt(
          item.created.split("T")[0].replace(/-/g, "")
        );
        return itemCreated <= endDateValue;
      });
    }

    if (formData.get("businessName").length > 0) {
      const businessNameValue = formData.get("businessName").toLowerCase();
      tempItems = tempItems.filter((item) => {
        const businessName = item.businessName.toLowerCase();
        return businessName.includes(businessNameValue);
      });
    }

    if (!tempItems || tempItems.length === 0) {
      itemsToShow = [];
      reRender();
      return;
    }
    let filterNames = [];
    tempItems.map((item) => {
      if (!filterNames.includes(item.businessName))
        filterNames.push(item.businessName);
      return item;
    });

    filterNames.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    groupedSales = [];
    for (const name of filterNames) {
      const seller = tempItems.filter((item) => name === item.businessName);
      if (seller.length > 0) {
        setFilterData(seller);
      }
    }
    filterItemsToShow();
    reRender();
  };

  const filterItemsToShow = () => {
    if (items == null || items.length === 0) {
      itemsToShow = [];
      reRender();
      return;
    }
    const formData = new FormData(formRef.current);
    let tempItems = itemsSeller;

    if (formData.get("startDate").length > 0) {
      const startDateValue = parseInt(
        formData.get("startDate").replace(/-/g, "")
      );
      tempItems = tempItems.filter((item) => {
        const itemCreated = parseInt(
          item.created.split("T")[0].replace(/-/g, "")
        );
        return itemCreated >= startDateValue;
      });
    }

    if (formData.get("endDate").length > 0) {
      const endDateValue = parseInt(formData.get("endDate").replace(/-/g, ""));
      tempItems = tempItems.filter((item) => {
        const itemCreated = parseInt(
          item.created.split("T")[0].replace(/-/g, "")
        );
        return itemCreated <= endDateValue;
      });
    }

    if (formData.get("businessName").length > 0) {
      const businessNameValue = formData.get("businessName").toLowerCase();
      tempItems = tempItems.filter((item) => {
        const businessName = item.businessName.toLowerCase();
        return businessName.includes(businessNameValue);
      });
    }


    if (!tempItems || tempItems.length === 0) {
      itemsToShow = [];
      reRender();
      return;
    }

    itemsToShow = tempItems
      .sort((a, b) => {
        const sortDirection = orderDirection === "asc" ? 1 : -1;
        if (a[orderBy] === b[orderBy]) {
          return 0;
        } else if (a[orderBy] > b[orderBy]) {
          return 1 * sortDirection;
        } else {
          return -1 * sortDirection;
        }
      })
      .slice(0, limit);
    reRender();
  };

  const resetForm = () => {
    formRef?.current?.reset();
    setItemsSeller(itemsSeller);
    filterItemsToShow();
  };
  
  return (
    <>
      <nav className="flex-between menu-navbar">
        <h1 style={{ margin: 0 }}>{t("SALES.LIQUIDATIONS")}</h1>
        <ExportSheet
          getData={getDataForExport}
          fileName={t("SALES.LIQUIDATIONS")}
          style={{ marginTop: "10px" }}
        />
      </nav>
      <div className="filters">
        <form
          ref={formRef}
          name="search"
          className="menu-navbar filters"
          onSubmit={(ev) => {
            ev.preventDefault();
          }}
        >
          <div className="flex-end full-width" style={{ alignItems: "center" }}>
            <div>
              <label className="mobile-full-width">
                {t("SEARCH_BY_NAME")}
                <input
                  type="search"
                  /* onChange={onSearch} */
                  defaultValue={""}
                  style={{ margin: 0 }}
                  name="businessName"
                  id="businessName"
                  placeholder={t("SEARCH_BY_NAME")}
                />
              </label>
              <div className="flex-start">
              <label className="mobile-full-width" style={{ width: "219px" }}>
                {t("START_DATE")}
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  defaultValue={`${year}-${month}-01`}
                  /* onChange={onSearch} */
                />
              </label>
              <label className="mobile-full-width" style={{ width: "219px" }}>
                {t("END_DATE")}
                <input
                  type="date"
                  id="endDate"
                  name="endDate"
                  defaultValue={`${year}-${month}-${day}`}
                  /* onChange={onSearch} */
                />
              </label>
              </div>
            </div>
            &nbsp;&nbsp;
            <button
              type="button"
              className="primary mobile-full-width"
              onClick={onFilter}
              style={{ marginBottom: "auto", marginTop: "auto" }}
            >
              {t("APPLY_FILTERS")}
            </button>
            <button
              type="button"
              className="secondary mobile-full-width"
              onClick={resetForm}
              style={{ marginBottom: "auto", marginTop: "auto" }}
            >
              {t("CLEAN")}
            </button>
          </div>
        </form>
        <a
          role="button"
          className="primary mobile-full-width"
          href="#continental"
          style={{ marginBottom: "auto", marginTop: "auto" }}
        >
          {t("SALES.CONTINENTAL")}
        </a>
      </div>
      {navbar && navbar}
      {itemsSeller.length > 0 && itemsToShow.length > 0 ? (
        <>
          <figure>
            <table role="grid">
              <thead>
                <tr>
                  <OrderTableHeader
                    titleKey={"SALES.TABLE_HEADER.BUSINESS"}
                    orderByKey={"businessName"}
                    onSetOrder={() => {
                      return false;
                    }}
                    orderBy={orderBy}
                    orderDirection={orderDirection}
                  />
                  <OrderTableHeader
                    titleKey={"SALES.TABLE_HEADER.INVOICED"}
                    orderByKey={"businessName"}
                    onSetOrder={() => {
                      return false;
                    }}
                    orderBy={orderBy}
                    orderDirection={orderDirection}
                  />
                  <OrderTableHeader
                    titleKey={"SALES.TABLE_HEADER.QUANTITY"}
                    orderByKey={"businessName"}
                    onSetOrder={() => {
                      return false;
                    }}
                    orderBy={orderBy}
                    orderDirection={orderDirection}
                  />
                  <OrderTableHeader
                    titleKey={"SALES.TABLE_HEADER.COMMISSION"}
                    orderByKey={"businessName"}
                    onSetOrder={() => {
                      return false;
                    }}
                    orderBy={orderBy}
                    orderDirection={orderDirection}
                  />
                  <OrderTableHeader
                    titleKey={"SALES.TABLE_HEADER.DISCOUNT"}
                    orderByKey={"businessName"}
                    onSetOrder={() => {
                      return false;
                    }}
                    orderBy={orderBy}
                    orderDirection={orderDirection}
                  />
                  <OrderTableHeader
                    titleKey={"SALES.TABLE_HEADER.NET"}
                    orderByKey={"businessName"}
                    onSetOrder={() => {
                      return false;
                    }}
                    orderBy={orderBy}
                    orderDirection={orderDirection}
                  />
                  <OrderTableHeader
                    titleKey={"SALES.TABLE_HEADER.NET_RUA"}
                    orderByKey={"businessName"}
                    onSetOrder={() => {
                      return false;
                    }}
                    orderBy={orderBy}
                    orderDirection={orderDirection}
                  />
                </tr>
              </thead>
              <tbody>
                {/* {itemsToShow.map((item, index) => { */}
                {groupedSales.map((item, index) => {
                  return (
                    <tr key={index} style={{ cursor: "pointer" }}>
                      <td>{item.businessName}</td>
                      <td>{item.total}</td>
                      <td>{item.totalSales}</td>
                      <td>{item.commission} %</td>
                      <td>{item.discount}</td>
                      <td>{item.neto}</td>
                      <td>{item.netoRua}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </figure>
          <figure>
            <h1 id="continental" style={{ margin: 0 }}>
              {t("SALES.CONTINENTAL")}
            </h1>
            <br></br>
            <table role="grid">
              <thead>
                <tr>
                  <OrderTableHeader
                    titleKey={"SALES.TABLE_HEADER.BUSINESS"}
                    orderByKey={"businessName"}
                    onSetOrder={() => {
                      return false;
                    }}
                    orderBy={orderBy}
                    orderDirection={orderDirection}
                  />
                  <OrderTableHeader
                    titleKey={"SALES.TABLE_HEADER.INVOICED_CONTINENTAL"}
                    orderByKey={"bussinesName"}
                    onSetOrder={() => {
                      return false;
                    }}
                    orderBy={orderBy}
                    orderDirection={orderDirection}
                  />
                  <OrderTableHeader
                    titleKey={"SALES.TABLE_HEADER.QUANTITY"}
                    orderByKey={"bussinesName"}
                    onSetOrder={() => {
                      return false;
                    }}
                    orderBy={orderBy}
                    orderDirection={orderDirection}
                  />
                </tr>
              </thead>
              <tbody>
                {/* {itemsToShow.map((item, index) => { */}
                {groupedSales.map((item, index) => {
                  return (
                    <tr key={index} style={{ cursor: "pointer" }}>
                      <td>{item.businessName}</td>
                      <td>{item.continentalPrice}</td>
                      <td>{item.totalSales}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </figure>
        </>
      ) : (
        <p>{t("SALES.NO_RESULTS")}</p>
      )}
    </>
  );
};

export default SalesLiquidation;
