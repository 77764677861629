const initialBuyingData = {
  refBusiness: null,
  commission: 0,
  markup: 0,
  residenceCountry: 0,
  categoryId: 0,
  categoryName: "",
  startDate: "",
  endDate: "",
  days: 0,
  destinationId: 0,
  destinationName: "",
  passengers: 0,
  ageRange: [],
  birthDate: [],
  planId: 0,
  planPrice: 0,
  initialPrice: 0,
  planCurrency: "",
  planName: "",
  promoCode: "",
  discountPercentage: 0,
  discountAmount: "0.00",
  withDiscount: false,
  buyAccepted: false,
  aditionalBenefits: [],
  familyPlan: 0,
  contactData: {
    email: "",
    phone: "",
    isSubscribed: false,
  },
  passengersData: [],
  paymentData: {},
  saleSuccess: false,  
};

const businessInfo = {};

class BuyingProcessService {
  buyingData = null;
  businessInfo = null;
  selectedPlanDetails = [];

  // esto es para guardar la llamada a beneficios
  benefitsList = [];
  
  static instance = null;

  constructor() {
    this.buyingData = { ...initialBuyingData };
    this.businessInfo = { ...businessInfo };
  }

  static getInstance() {
    if (this.instance == null) {
      this.instance = new BuyingProcessService();
    }
    return this.instance;
  }

  getInvalidStepOn1 = () => {
    if (
      this.buyingData.residenceCountry ===
        initialBuyingData.residenceCountry ||
      this.buyingData.categoryId === initialBuyingData.categoryId ||
      this.buyingData.categoryName === initialBuyingData.categoryName ||
      this.buyingData.startDate === initialBuyingData.startDate ||
      this.buyingData.endDate === initialBuyingData.endDate || 
      this.buyingData.days === initialBuyingData.days ||
      this.buyingData.markup === initialBuyingData.markup
    ) {
      return 1;
    }
    return 0;
  };

  getInvalidStepOn2 = () => {
    if (this.getInvalidStepOn1() > 0) {
      return 1;
    }

    if (this.buyingData.destinationId === initialBuyingData.destinationId || this.buyingData.destinationName === initialBuyingData.destinationName) {
      return 2;
    }

    return 0;
  };

  getInvalidStepOn3 = () => {
    //const invalidStepOn2 = this.getInvalidStepOn2();
    if (this.getInvalidStepOn2() > 0) {
      return 2;
    }

    if (
      this.buyingData.passengers === initialBuyingData.passengers ||
      this.buyingData.ageRange === initialBuyingData.ageRange
    ) {
      return 3;
    }
    return 0;
  };

  getInvalidStepOn4 = () => {
    if (this.getInvalidStepOn3() > 0) {
      return 3;
    }

    if (
      this.buyingData.planId === initialBuyingData.planId ||
      this.buyingData.planPrice === initialBuyingData.planPrice ||
      this.buyingData.planCurrency === initialBuyingData.planCurrency ||
      this.buyingData.planName === initialBuyingData.planName
    ) {
      return 4;
    }
    return 0;
  };

  getInvalidStepOn5 = () => {
    if (this.getInvalidStepOn4() > 0) {
      return 4;
    }

    if (
      this.buyingData.buyAccepted === initialBuyingData.buyAccepted ||
      this.buyingData.aditionalBenefits === initialBuyingData.aditionalBenefits
    ) {
      return 5;
    }
    return 0;
  };

  getInvalidStepOn6 = () => {
    if (this.getInvalidStepOn5() > 0) {
      return 5;
    }

    if (
      this.buyingData.passengersData === initialBuyingData.passengersData
    ) {
      return 6;
    }
    return 0;
  };

  getInvalidStepOn7 = () => {
    if (this.getInvalidStepOn6() > 0) {
      return 6;
    }

    if (
      this.buyingData.contactData.email === initialBuyingData.contactData.email || !this.buyingData.contactData.phone === initialBuyingData.contactData.phone
    ) {
      return 7;
    }
    return 0;
  };
  getInvalidStepOn8 = () => {
    if (this.getInvalidStepOn7() > 0) {
      return 7;
    }

    if (
      this.buyingData.paymentData === initialBuyingData.paymentData || !this.buyingData.saleSuccess
    ) {
      return 8;
    }
    return 0;
  };
}

export default BuyingProcessService;
