import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MessageContext from "../../../contexts/MessageContext";
import FormatToastMessage from "../../../shared/FormatMessage";
import BuyingProcessService from "../../../services/BuyingProcessService";
import HeaderPublic from "../../components/HeaderPublic";
import { validateEmail } from "../../../shared/validations/Validation";
import BuyingProcessDetails from "../../components/BuyingProcessDetails";

const BuyStep7 = ({ step = 7 }) => {
  const buyingData = BuyingProcessService.getInstance().buyingData;
  buyingData.contactData = buyingData.contactData || {};
  const companyData = BuyingProcessService.getInstance().businessInfo;
  //const [stateVersion, setStateVersion] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [emailConfirm, setEmailConfirm] = useState("");
  const [verified, setVerified] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [prevStep, setPrevStep] = useState(`/buy/step-${step - 1}`);
  const [nextStep, setNextStep] = useState(`/buy/step-${step + 1}`);
  const messageContext = useContext(MessageContext);
  /* const reRender = () => {
    setStateVersion(stateVersion + 1);
  }; */
  const init = async () => {
    if (BuyingProcessService.getInstance().getInvalidStepOn6() > 0) {      
      return navigate(prevStep);
    }
  };

  const verifyData = () => {    
    const emailTrusted = (validateEmail(email) && email === emailConfirm);    
    return emailTrusted && verified && phone;
  };

  const onNext = () => {
    if (!verifyData()) {      
      document.scrollingElement.scrollTop = 70;
      return messageContext.setMessage({
        type: "error",
        text: FormatToastMessage(t("BUYING_PROCESS.ERROR.TITLE"), [
          "Por favor, ingresa emails válidos, un celular de contacto y acepta los términos y condiciones.",
        ]),
      });
    }

    const buyingProcessService = BuyingProcessService.getInstance();
    buyingProcessService.buyingData.contactData.email = email;
    buyingProcessService.buyingData.contactData.phone = phone;
    buyingProcessService.buyingData.contactData.isSubscribed = isSubscribed;
    return navigate(nextStep);
  };

  useEffect(() => {
    if(companyData && companyData._id && companyData?.role !== "2"){
      setPrevStep(`/${companyData.subdomain}/buy/step-${step - 1}`);
      setNextStep(`/${companyData.subdomain}/buy/step-${step + 1}`);
    }
    init();
  }, []);

  return (
    <>
      <HeaderPublic businessInfo={companyData?.role !== "2" && companyData.subdomain !== 'myglobal' ? companyData : null} />
      <div
        className="container"
        style={{
          minHeight: "83vh",
          textAlign: "center",
          display: "grid",
          gridTemplateColumns: "1fr",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ lineHeight: "1", marginBottom: "1rem", marginTop: "4rem" }}>
          {t("BUYING_PROCESS.COMPLETE_DATA")}
        </h1>
        <div
          className="complete-data"
        >
          <div className="card wrap-benefits">
            <h3>¿Dónde enviamos el comprobante?</h3>
            <article className="card">              
              <div className="grid">
                <label style={{ textAlign: "left" }}>
                  Email:
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email..."
                  />
                </label>

                <label style={{ textAlign: "left" }}>
                  Confirmar email:
                  <input
                    type="email"
                    value={emailConfirm}
                    onChange={(e) => setEmailConfirm(e.target.value)}
                    placeholder="Confirma el email..."
                  />
                </label>
              </div>              
              <div className="grid">
                <label style={{ textAlign: "left" }}>
                 M&oacute;vil/Celular:
                  <input
                    type="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Celular..."
                  />
                </label>
                <div></div>
              </div>              
              <div className="flex-start" style={{ flexDirection: 'column', alignItems: 'flex-start'}}>
                <div>
                  <label style={{ textAlign: "left", marginTop: '1rem' }}>                    
                    <input
                      type="radio"
                      id="verified"
                      name="verified"
                      value={verified}
                      onChange={(e) => setVerified(true)}                     
                    />
                    <span>Leí y acepto las <a target="_blank" href="https://myglobalassist.s3.sa-east-1.amazonaws.com/Final+CONDICIONES+GENERALES+RUA+-+Operador.pdf" rel="noreferrer">Condiciones Generales</a> del servicio MyGlobalAssist.</span>
                  </label>
                </div>
                <div>
                <label style={{ textAlign: "left", marginTop: '0.8rem' }}>                    
                    <input
                      type="checkbox"
                      id="isSubscribed"
                      name="isSubscribed"
                      value={isSubscribed}
                      onChange={(e) => setIsSubscribed(e.target.checked)}                     
                    />
                    <span>Me quiero suscribir para recibir información de MyGlobal</span>
                  </label>
                </div>
              </div>
            </article>
            <div
              className="flex-between"
              style={{ width: "100%", marginTop: "20px", minWidth: "380px" }}
            >
              <>
                <div className="flex-start" style={{ marginTop: "15px" }}>
                  <button
                    type="button"
                    className="secondary outline"
                    onClick={() => {
                      navigate(prevStep);
                    }}
                  >
                    {t("GO_BACK")}
                  </button>
                </div>
                {/* <div className="flex-end">
                <button
                  type="button"
                  className="primary mx-auto btn-plan"
                  onClick={onNext}
                  style={{ marginTop: "1rem", marginBottom: "0" }}
                >
                  {t("CONFIRM")}
                </button>
                </div> */}
              </>
            </div>
          </div>
          <div className="card details-buy">
            <BuyingProcessDetails 
              buyingData={buyingData}
              aditionalBenefits={BuyingProcessService.getInstance().benefitsList}
              totalPrice={buyingData.planPrice}
              onNext={onNext}
              step={step}
              />  
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyStep7;
