import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useApiHook from "../../../hooks/ApiHook";
import MessageContext from "../../../contexts/MessageContext";
import BuyingProcessService from "../../../services/BuyingProcessService";
import { getDestinations } from "../../../services/DestinationService";
import HeaderPublic from "../../components/HeaderPublic";
import  { SouthAmericaIcon, EuropeIcon, WorldIcon } from "../../../shared/icons/SvgIcons";


let destinations = [];
const southAmericaId = 3;

const BuyStep2 = ({ step = 2 }) => {
  const buyingData = BuyingProcessService.getInstance().buyingData;
  const companyData = BuyingProcessService.getInstance().businessInfo;
  const [stateVersion, setStateVersion] = useState(0);
  const [selectedDestinationId, setSelectedDestinationId] = useState(
    buyingData.destinationId
  );
  const [selectedDestinationName, setDestinationName] = useState(
    buyingData.destinationName
  );
  const navigate = useNavigate();
  const apiCall = useApiHook();
  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);
  const [prevStep, setPrevStep] = useState(`/buy/step-${step - 1}`);
  const [nextStep, setNextStep] = useState("/buy/step-3");

  const reRender = () => {
    setStateVersion(stateVersion + 1);
  };
  const onChangeDestination = (id, name) => {
    const selection = document.querySelector(`#wrapDestination-${id}`);
    setSelectedDestinationId(parseInt(id));
    setDestinationName(name);
    document.querySelectorAll(".card").forEach((card) => {
      card.classList.remove("border-selected");
    });
    selection.classList.add("border-selected");
  };

  const init = async () => {
    if (BuyingProcessService.getInstance().getInvalidStepOn1() > 0) {
      return navigate(prevStep);
    }
    const response = await apiCall({
      fnApiCall: getDestinations,
      params: {},
    });
    if (response.ok) {
      destinations = response.data.data;
      reRender();
    }
  };

  const onNext = () => {
    if (!selectedDestinationId || !selectedDestinationName) {
      return messageContext.setMessage({
        text: "BUYING_PROCESS.ERROR.REQUIRED.DESTINATION",
        type: "error",
      });
    }

    const buyingProcessService = BuyingProcessService.getInstance();
    buyingProcessService.buyingData.destinationId =
      selectedDestinationId === southAmericaId ? 2 : selectedDestinationId;
    buyingProcessService.buyingData.destinationName = selectedDestinationName;
    return navigate(nextStep);
  };

  useEffect(() => {
    if (companyData && companyData._id && companyData?.role !== "2") {
      setPrevStep(`/${companyData.subdomain}/buy/step-${step - 1}`);
      setNextStep(`/${companyData.subdomain}/buy/step-${step + 1}`);
    }
    init();
  }, []);

  return (
    <>
      <HeaderPublic
        businessInfo={companyData?.role !== "2" && companyData.subdomain !== 'myglobal' ? companyData : null}
      />
      <div
        className="container"
        style={{
          minHeight: "83vh",
          textAlign: "center",
          display: "grid",
          gridTemplateColumns: "1fr",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ lineHeight:"1", marginTop:"4rem" }}>{t("WHERE_TRAVELING")}</h1>
        <div
          style={{
            width: "100%",
            maxWidth: "570px",
            margin: "1rem auto",
          }}
        >
          {destinations && destinations.length > 0 && (
            <div className="grid mobile-column-3">
              <article
                onClick={() =>
                  onChangeDestination(southAmericaId, "Sudamérica")
                }
                style={{ cursor: "pointer", paddingBottom: "2.7rem" }}
                id={`wrapDestination-3`}
                className={`card border-hover mobile-mxw-250 ${
                  selectedDestinationName !== undefined &&
                  selectedDestinationName === "Sudamérica"
                    ? "border-selected"
                    : ""
                }`}
                key={`destination-3`}
              >
                <SouthAmericaIcon cls={'icon-primary'} />
                <label
                  htmlFor={`destination-3`}
                  key={`destination-3`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    cursor: "pointer",
                    width: "100%",
                    justifyContent: "center",
                    fontSize: "1.3rem",
                    gap: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  Sudamérica
                  <input
                    type="radio"
                    id={`destination-3`}
                    name="destination"
                    checked={selectedDestinationName === "Sudamérica"}
                    onChange={() =>
                      onChangeDestination(southAmericaId, "Sudamérica")
                    }
                    value={southAmericaId}
                  />
                </label>
              </article>
              {destinations.map((destination, index) => {
                return (
                  <article
                    onClick={() =>
                      onChangeDestination(destination.id, destination.name)
                    }
                    style={{ cursor: "pointer", paddingBottom: "2.7rem" }}
                    id={`wrapDestination-${destination.id}`}
                    className={`card border-hover mobile-mxw-250 ${
                      selectedDestinationName !== undefined &&
                      selectedDestinationName === destination.name
                        ? "border-selected"
                        : ""
                    }`}
                    key={`destination-${index}`}
                  >
                    {destination.id === "1" && (
                      <>
                      <EuropeIcon cls={'icon-primary'} />
                      </>
                    )}
                    {destination.id === "2" && (
                      <>
                      <WorldIcon cls={'icon-primary'} />
                      </>
                    )}
                    
                    <label
                      htmlFor={`destination-${destination.id}`}
                      key={`destination-${index}`}
                      className="buy-step-padding-bottom"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        cursor: "pointer",
                        width: "100%",
                        /* height: "100%", */
                        justifyContent: "center",
                        fontSize: "1.3rem",
                        gap: "1rem",
                        marginTop: "1rem",
                      }}
                    >
                      {destination.name}
                      <input
                        type="radio"
                        id={`destination-${destination.id}`}
                        name="destination"
                        checked={selectedDestinationName === destination.name}
                        onChange={() =>
                          onChangeDestination(destination.id, destination.name)
                        }
                        value={destination.id}
                      />
                    </label>
                  </article>
                );
              })}
            </div>
          )}
          <div
            className="flex-between mobile-column desktop-min-width"
            style={{ width: "100%", marginTop: "20px" }}
          >
            <>
              <div className="flex-start mobile-full-width mobile-order-2">
                <button
                  type="button"
                  className="secondary outline mobile-full-width"
                  onClick={() => {
                    navigate(prevStep);
                  }}
                >
                  {t("GO_BACK")}
                </button>
              </div>
              <div className="flex-end mobile-full-width mobile-order-1">
                <button
                  type="submit"
                  onClick={onNext}
                  className="primary mobile-full-width"
                >
                  {t("NEXT")}
                </button>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyStep2;
