const { Link } = require("react-router-dom");

const HomeHeroOne = ({ businessInfo = null }) => {

  return businessInfo?.businessType === "COMPANY" && businessInfo.subdomain !== "myglobal" &&
    businessInfo?.banners !== undefined && businessInfo.banners[0] !== undefined ? (
    <>
      <Link to={(businessInfo.banners !== undefined && businessInfo.banners[0] !== undefined && businessInfo.banners[0].link !== "") ? businessInfo.banners[0].link : ""}>
        <section
          style={
            businessInfo?.businessType === "COMPANY" && businessInfo?.banners[0]
              ? {
                  ...homeHeroOneStyle,
                  backgroundImage: `url(${businessInfo.banners[0].img})`,
                }
              : {}
          }
          className="flex-center"
          id="home-hero-1"
        >
          <div className="container" style={{ zIndex: "-10" }}>
            <p className="hero-one-lema">
              <strong>Asistencia al viajero</strong> humana y accesible
              <br />
              <button type="button" className="primary mob-quote-btn">
                Cotizar asistencia
              </button>
            </p>
          </div>
        </section>
      </Link>
    </>
  ) : (
    <section
      style={
        businessInfo?.businessType === "COMPANY" && businessInfo?.headerUrl
          ? {
              ...homeHeroOneStyle,
              backgroundImage: `url(${businessInfo.headerUrl})`,
            }
          : {}
      }
      className="flex-center"
      id="home-hero-1"
    >
      <div className="container">
        <p className="hero-one-lema" style={{ minWidth: "fit-content !important" }}>
          Tu viaje, <br></br>
          nuestra asistencia, <br></br>
          <strong>tu tranquilidad</strong>
          <br />
          <Link
            role="button"
            to={
              businessInfo
                ? `/${businessInfo.subdomain}/buy/step-1`
                : "/buy/step-1"
            }
            className="primary mob-quote-btn"
          >
            Cotizar asistencia
          </Link>
        </p>
      </div>
    </section>
  );
};

export default HomeHeroOne;

const homeHeroOneStyle = {
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
};
