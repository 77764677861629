import { useNavigate } from "react-router-dom";
import Header from "../../../shared/Header";
import useAuthHook from "../../../hooks/AuthHook";
import SalesSearch from "../../components/sales/SalesSearch";

const SalesList = () => {

    const navigate = useNavigate();

    useAuthHook();    

    const onSelect = (item) => {
        return navigate(`/admin/sales/${item.id}`);
    };

    return (
        <>
            <Header />
            <div className="container">
                <SalesSearch onSelect={onSelect} />
            </div>
        </>
    )
}

export default SalesList;