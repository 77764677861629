
import {useTranslation} from 'react-i18next';

const ExportSheet = ({ getData, fileName, style = {} }) => {

    const { t } = useTranslation();
    
    const onExport = () => {
        const data = getData();
        if (!data || data.length === 0) {
            return false;
        }
        const csvData = data.map(
            row => row.map(
                cell => { 
                    return `"${cell.toString().
                            trim().replace("'", '').replace('"', '')}"`; 
                }
            ).join(",")
        ).join("\n");
        const blob = window.URL.createObjectURL(new Blob(
            ["\ufeff", csvData], 
            { type: "text/csv;charset=utf-8" }
        ));                
        const link = document.createElement("a");
        link.setAttribute("href", blob);
        link.setAttribute("download", `${fileName}.csv`);
        link.click();
    }

    return (    
        <button 
        className='outline'
        style={{ ...style }}
        type="button" 
        onClick={onExport}>
            {t('EXPORT_SHEET')}
        </button>
    
    )
}

export default ExportSheet;
