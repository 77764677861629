import { Link } from "react-router-dom";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const API_URL = process.env.REACT_APP_API_URL;

const SaleDetails = ({ sale, onClose, showDetailsModal,saleVoucherCodes = [] }) => {
  
  const { t } = useTranslation(); 
  return (
    <dialog open={showDetailsModal}>
      <article
        style={{
          width: "100%",
          maxWidth: "760px",
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <header>
          <a
            href="#close"
            aria-label="Close"
            className="close"
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
          ></a>
          <strong>Detalles de la venta {sale.code}</strong>
        </header>

        {saleVoucherCodes.length > 0 && (
          <div
            className="flex-between"
            style={{
              margin: "0 auto 20px",
              gap: "var(--spacing)",
            }}
          >
            {saleVoucherCodes.map((voucher, index) => {
              return (
                <div
                  key={`voucher_${index}`}
                  className="flex-start"
                  style={{
                    width: "100%",
                    maxWidth: "300px",
                    flexDirection: "column",
                    gap: 0,
                  }}
                >
                  <p style={{ marginBottom: "0", textAlign: "center" }}>
                    Descarga Voucher
                    <br />
                    {sale?.sale?.passengersData[index]?.name || ""}{" "}
                    {sale?.sale?.passengersData[index]?.lastName || ""}
                  </p>
                  <Link
                    to={`${API_URL}/sale/voucherPDF/${voucher.codigo.replace(
                      "CA",
                      "MGA"
                    )}`}
                    id={`voucher-${index + 1}`}
                    target="_blank"
                    className="primary btn-plan"
                    role="button"
                    style={{ borderRadius: "0.5rem" }}
                  >
                    Voucher {voucher.codigo.replace("CA", "MGA")}
                  </Link>
                </div>
              );
            })}
          </div>
        )}

        <table role="grid">
          <tbody>
            <tr>
              <th scope="row">
                <strong>Vendedor:</strong>
              </th>
              <td>{sale.businessName}</td>
            </tr>
            <tr>
              <th scope="row">
                <strong>Plan:</strong>
              </th>
              <td>{sale?.sale?.planName || ""}</td>
            </tr>
            <tr>
              <th scope="row">
                <strong>Precio Plan:</strong>
              </th>
              <td>
                {sale.sale.planCurrency} {sale.sale.initialPrice}
              </td>
            </tr>
            {sale.sale.withDiscount === true && (
              <>
                <tr>
                  <th scope="row">
                    <strong>PromoCode aplicado:</strong>
                  </th>
                  <td>{sale.sale.promoCode}</td>
                </tr>
                <tr>
                  <th scope="row">
                    <strong>Porcentaje descuento:</strong>
                  </th>
                  <td>{sale.sale.discountPercentage}%</td>
                </tr>
                <tr>
                  <th scope="row">
                    <strong>Total descuentos:</strong>
                  </th>
                  <td>
                    {sale.sale.planCurrency} {sale.sale.discountAmount}
                  </td>
                </tr>
              </>
            )}
            <tr>
              <th scope="row">
                <strong>Total beneficios incluidos:</strong>
              </th>
              <td>
                {sale.sale.planCurrency} {sale.sale.planPrice}
              </td>
            </tr>
            <tr>
              <th scope="row">
                <strong>Id del pago:</strong>
              </th>
              <td>
                {sale?.sale?.paymentData?.payData?.PurchaseId ?? t('NO_DATA')}
              </td>
            </tr>
            <tr>
              <th scope="row">
                <strong>Fecha contrataci&oacute;n:</strong>
              </th>
              <td>{sale.purchaseDate}</td>
            </tr>
            <tr>
              <th scope="row">
                <strong>Origen del viaje:</strong>
              </th>
              <td>
                {
                  sale.sale.residenceCountry.split("-")[
                    sale.sale.residenceCountry.split("-").length - 1
                  ]
                }{" "}
              </td>
            </tr>
            <tr>
              <th scope="row">
                <strong>Destino del viaje:</strong>
              </th>
              <td>{sale.sale.destinationName} </td>
            </tr>
            <tr>
              <th scope="row">
                <strong>Fecha salida:</strong>
              </th>
              <td>{sale.sale.startDate.split("-").reverse().join("/")} </td>
            </tr>
            <tr>
              <th scope="row">
                <strong>Fecha retorno:</strong>
              </th>
              <td>{sale.sale.endDate.split("-").reverse().join("/")} </td>
            </tr>
            <tr>
              <th scope="row">
                <strong>Duraci&oacute;n:</strong>
              </th>
              <td>{sale.sale.days} </td>
            </tr>
            <tr>
              <th scope="row">
                <strong>Cantidad de pasajeros:</strong>
              </th>
              <td>{sale.sale.passengers} </td>
            </tr>
            <tr>
              <th scope="row">
                <h5 style={{ marginBottom: "0" }}>Datos de contacto:</h5>
              </th>
              <td> </td>
            </tr>
            <tr>
              <th scope="row">
                <strong>Nombre titular:</strong>
              </th>
              <td>
                {sale.sale.passengersData[0].name}{" "}
                {sale.sale.passengersData[0].lastName}
              </td>
            </tr>
            <tr>
              <th scope="row">
                <strong>Email:</strong>
              </th>
              <td>{sale.sale.contactData.email} </td>
            </tr>
            <tr>
              <th scope="row">
                <strong>Tel&eacute;fono:</strong>
              </th>
              <td>{sale.sale.contactData.phone} </td>
            </tr>
          </tbody>
        </table>
        <h5 style={{ marginBottom: "10px" }}>Datos de los pasajeros:</h5>
        {Array.from({ length: sale.sale.passengers }, (_, index) => (
          <Fragment key={index}>
            <details>
              <summary>
                <strong>Pasajero {index + 1}:</strong>
              </summary>
              {sale.sale.passengersData && (
                <ul>
                  <li style={{ listStyle: "disc" }}>
                    <strong>Nombre:</strong>{" "}
                    {sale.sale.passengersData[index].name}
                  </li>
                  <li style={{ listStyle: "disc" }}>
                    <strong>Apellido:</strong>{" "}
                    {sale.sale.passengersData[index].lastName}
                  </li>
                  <li style={{ listStyle: "disc" }}>
                    <strong>Fecha de nacimiento:</strong>{" "}
                    {sale.sale.passengersData[index].bornDate
                      .split("-")
                      .reverse()
                      .join("/")}
                  </li>
                  <li style={{ listStyle: "disc" }}>
                    <strong>Tipo documento:</strong>{" "}
                    {sale.sale.passengersData[index].typeDoc === "1"
                      ? "Documento Nacional"
                      : "Pasaporte"}
                  </li>
                  <li style={{ listStyle: "disc" }}>
                    <strong>N&uacute;mero documento:</strong>{" "}
                    {sale.sale.passengersData[index].docNumber}
                  </li>
                </ul>
              )}

              {sale.sale.aditionalBenefits.length > 0 && (
                <>
                  <ul>
                    {sale.sale.aditionalBenefits.map((benefit, id) => (
                      <Fragment key={`adben_${id}`}>
                        {benefit.passengerId === index + 1 && (
                          <>
                            {id === 0 && <h6>Beneficios adicionales:</h6>}

                            <li style={{ listStyle: "disc" }}>
                              {benefit.description} hasta {benefit.coverage}
                            </li>
                          </>
                        )}
                      </Fragment>
                    ))}
                  </ul>
                </>
              )}
            </details>
          </Fragment>
        ))}

        <footer>
          <div className="flex-end">
            <button className="secondary" type="button" onClick={onClose}>
              {t("CLOSE")}
            </button>
          </div>
        </footer>
      </article>
    </dialog>
  );
};

export default SaleDetails;
