const Required = ({ 
    left = '7px', 
    right = 'auto'
}) => <span 
    className="required" 
    style={{
        fontSize: 'calc(var(--font-size))',
        color: 'var(--danger)', 
        marginLeft: left,
        marginRight: right, 
        fontWeight: 700
    }}>*</span>;

export default Required;