import { useTranslation } from "react-i18next";
import AuthForgot from "../../../shared/auth/AuthForgot";

const ForgotProfile = () => {
  const { t } = useTranslation();
  return (
    <>
      <header>
        <h1 style={{ textAlign:'center',paddingTop:'3rem' }}>{t("RECOVERY_ACCESS")}</h1>
      </header>
      <AuthForgot role={2} />
    </>
  );
};

export default ForgotProfile;