import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useTranslation } from "react-i18next";
import { useRef } from "react";

const ImageCropperModal = ({ 
    isOpen,
    imageContent, 
    onCrop, 
    onCancel,
    titleKey = "IMAGE_CROPPER_MODAL.TITLE",
    aspectRatio = 1,
    confirmTextKey = 'CONFIRM',
    cancelTextKey = 'CANCEL'
}) => {
    const { t } = useTranslation();
    const cropperRef = useRef(null);

    const onCropEvent = () => {
        const cropper = cropperRef.current?.cropper;
        onCrop(cropper.getCroppedCanvas().toDataURL());
    };

    return (
        <dialog open={isOpen}>
            <article>
                <header>
                    <a href="#close"
                        onClick={onCancel}
                        aria-label="Close" 
                        className="close">
                    </a>
                    <h3>{t(titleKey)}</h3>
                </header>
                <Cropper
                    ref={cropperRef}
                    style={{ maxHeight: '200px' }}
                    zoomTo={0.5}
                    aspectRatio={aspectRatio}
                    src={imageContent}
                    viewMode={2}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                    guides={true}
                />
                <footer>                    
                    <a href="#confirm" 
                        role="button" 
                        className="primary"
                        onClick={onCropEvent}
                    >{t(confirmTextKey)}</a>
                </footer>
            </article>
        </dialog>
    );

}

export default ImageCropperModal;