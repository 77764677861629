import { useState, useContext } from "react";
import MessageContext from "../../contexts/MessageContext";
import LoadingContext from "../../contexts/LoadingContext";
import { sendRefund } from "../../services/PaymentService";

const RequestRefund = () => {    
    const [voucher, setVoucher] = useState("");
    const [document, setDocument] = useState("");
    const [disableClick, setDisableClick] = useState(false);
    const messageContext = useContext(MessageContext);
    const loadingContext = useContext(LoadingContext);
    const handleVoucher = (ev) => {
        const updatedVoucher = ev.target.value;
        setVoucher(updatedVoucher);
    };
    const handleDocument = (ev) => {
        const updatedDocument = ev.target.value;
        setDocument(updatedDocument);
    };
    const sendInfo = async () => {
        if (!voucher || !document) {
            return messageContext.setMessage({
                type: "error",
                text: "Por favor, completa todos los campos",
            });
        }
        loadingContext.change(true);
        setDisableClick(true);
        const response = await sendRefund({
            data: {
                voucher: voucher,
                document: document,
            },
        });
        if (response.ok) {
            messageContext.setMessage({
                type: "success",
                text: "Reintegro solicitado con éxito. Nos pondremos en contacto a la brevedad.",
            });
        }
        else {
            messageContext.setMessage({
                type: "error",
                text: "Hubo un error al solicitar el reintegro. Por favor, verifca los datos e intenta nuevamente.",
            });
        }
        setVoucher("");
        setDocument("");
        setDisableClick(false);
        loadingContext.change(false);
    };
    return (
        <div className="request-refund">
            <div className="container">
                <div className="wrapper">
                    <p>Solicitar <span>reintegro</span></p>
                    <form 
                        method="POST" 
                        action="#">
                        <input
                        value={voucher} 
                        type="text" 
                        name="voucher" 
                        placeholder="Voucher Id"
                        onChange={handleVoucher} 
                        />
                        <input 
                        type="text" 
                        name="document" 
                        value={document}
                        placeholder="Documento / Pasaporte"
                        onChange={handleDocument} 
                        />
                        <button type="button"
                        disabled={disableClick}
                        onClick={sendInfo}                        
                        >
                            Solicitar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default RequestRefund;