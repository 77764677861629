export const isNumber = (value) => {
  return typeof value === "number" && !isNaN(value);
};
export const sanitizeInputNumber = (strNum) => {
  strNum = strNum.trim();
  if (
    isNaN(strNum) ||
    strNum.includes(",") ||
    strNum.includes("e") ||
    strNum.includes("E") ||
    strNum.includes("-")
  ) {
    return false;
  }
  return strNum;
};
export const isText = (value) => {
  return typeof value === "string" && value.trim().length > 0;
};
export const validateEmail = (email) => {
  const userNameRegex = /^[^\s@]{3,}$/;
  const domainRegex = /^[^\s@]+\.[^\s@]{2,}$/;
  const [userName, domain] = email.split("@");

  return userNameRegex.test(userName) && domainRegex.test(domain);
};
export const strongPassword = (password, minLength = 10) => {
  const regex = new RegExp(
    `^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\\-=[\\]{};':"\\\\|,.<>/?]).{${minLength},}$`
  );
  return regex.test(password);
};
export const inRange = (options) => {  
  const operation = Object.keys(options);
  // Comprobar si el valor es un número entero o puede ser convertido a entero
  options[operation[0]].value = Number(options[operation[0]].value);  
  if (isNaN(options[operation[0]].value)) {
    return "NaN";
  }
  //Operaciones de comprobacion segun la key del objeto options
  switch (operation[0]) {
    case "min":      
      return options[operation[0]].value >= options.min.min;
    case "max":
      return options[operation[0]].value <= options.max.max;
    case "range":
      return (
        options.range.value >= options.range.min &&
        options.range.value <= options.range.max
      );
    default:
      return false;
  }
};

export const isValidDate = (date) => {
  // Expresión regular para validar formatos de fecha: dd/mm/yyyy, dd-mm-yyyy, mm/dd/yyyy, mm-dd-yyyy
  const regexDate = /^(\d{4})-(\d{2})-(\d{2})$/;

  // Validar formato de fecha
  if (!regexDate.test(date)) {    
    return false;
  }
  // Convertir fecha a objeto Date
  const isDate = new Date(
    date.replace(/(\d{2})[-/](\d{2})[-/](\d{4})/, "$2/$1/$3")
  );

  // Validar si es una fecha válida
  if (isNaN(isDate.getTime())) {    
    return false;
  }
  return isDate.getTime();
};

export const compareDate = (dates) => {
  if (!isValidDate(dates.after) || !isValidDate(dates.before)) return false;

  const after = new Date(
    dates.after.replace(/(\d{2})[-/](\d{2})[-/](\d{4})/, "$2/$1/$3")
  );
  const before = new Date(
    dates.before.replace(/(\d{2})[-/](\d{2})[-/](\d{4})/, "$2/$1/$3")
  );

  const operator = dates.operator;
  return eval(`${before.getTime()} ${operator}  ${after.getTime()}`);
};

export const diffTimeDays = (dates) => {
  const microStartDate = new Date(dates.startDate);
  const microEndDate = new Date(dates.endDate);
  const diffTime = Math.abs(microEndDate - microStartDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if (diffDays < 0) return false;
  if (dates.min && dates.max) {
    return diffDays >= dates.min && diffDays <= dates.max;
  } else if (dates.min) {
    return diffDays >= dates.min;
  } else if (dates.max) {
    return diffDays <= dates.max;
  } else if (dates.exact) {
    return diffDays === dates.exact;
  }
  return diffDays;
};

export const diffTimeYears = (dates) => {
  const microStartDate = new Date(dates.startDate);
  const microEndDate = new Date(dates.endDate);
  const diffTime = Math.abs(microEndDate - microStartDate);
  const diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365.25));
  if (diffYears < 0) return false;
  if (dates.min && dates.max) {
    return diffYears >= dates.min && diffYears <= dates.max;
  } else if (dates.min) {
    return diffYears >= dates.min;
  } else if (dates.max) {
    return diffYears <= dates.max;
  } else if (dates.exact) {
    return diffYears === dates.exact;
  }
  return diffYears;
};

export const convertToYYYYMMDD = (date) => date.toISOString().split("T")[0];
