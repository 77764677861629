import { useEffect } from "react";
import { useParams } from "react-router-dom";
import HeaderPublic from "../components/HeaderPublic";
import FooterPublic from "../components/FooterPublic";
import DifferenceSlider from "../components/DifferenceSlider";
import HomeContact from "../components/HomeContact";
import HomeHeroOne from "../components/HomeHeroOne";
import HomeHeroTwo from "../components/HomeHeroTwo";
import HomePlans from "../components/HomePlans";
import HomeHeroThree from "../components/HomeHeroThree";
import useApiHook from "../../hooks/ApiHook";
import { businessGetBySubdomain } from "../../services/BusinessService";
import BuyingProcessService from "../../services/BuyingProcessService";

const Home = () => {
  let { subdomain } = useParams();
  const apiHook = useApiHook();
  const buyingProcessService = BuyingProcessService.getInstance();
  sessionStorage.removeItem("step");
  sessionStorage.removeItem("buyingProcess");
  let event = new Event('companyDataReady');
  const init = async () => {
    if (subdomain === undefined) {
      document.dispatchEvent(event);
      localStorage.setItem("companyData", null)
      subdomain = "myglobal";
      sessionStorage.setItem("subdomain", subdomain);
    }
    const response = await apiHook({
      fnApiCall: businessGetBySubdomain,
      params: { subdomain },
    });
    if (response.ok) {
      document.dispatchEvent(event);
      const currentCompanyData = {
        name: response.data.name,
        faviconUrl: response.data.faviconUrl
      }
      localStorage.setItem("companyData", JSON.stringify(currentCompanyData))

      buyingProcessService.businessInfo = response.data;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    init();
  }, []);

  return (
    <div className="public">
      <HeaderPublic />
      <HomeHeroOne />
      <HomeHeroTwo />
      <HomePlans />
      <DifferenceSlider />
      <HomeHeroThree />
      <HomeContact />
      <FooterPublic />
    </div>
  );
};

export default Home;
