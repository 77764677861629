import { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useApiHook from "../../../hooks/ApiHook";
import ConfirmationModal from "../../../shared/ConfirmationModal";
import {
  businessGetById,
  businessDeleteById,
} from "../../../services/BusinessService";
import MessageContext from "../../../contexts/MessageContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Header from "../../../shared/Header";
import GoBackLink from "../../../shared/GoBackLink";
import useAuthHook from "../../../hooks/AuthHook";

let business = null;
let isConfirmationDeleteModalOpen = false;

const BrokerDetails = () => {
  const { t } = useTranslation();
  const apiCall = useApiHook();
  const { id } = useParams();
  const navigate = useNavigate();
  const [stateVersion, setStateVersion] = useState(0);
  const messageContext = useContext(MessageContext);

  useAuthHook();

  const init = async () => {
    const response = await apiCall({
      fnApiCall: businessGetById,
      params: { id },
    });
    if (response.ok) {
      business = response.data;
    }
    reRender();
  };

  useEffect(() => {
    init();

    return () => {
      business = null;
    };
  }, []);

  const showConfirmationDeleteModal = () => {
    isConfirmationDeleteModalOpen = true;
    reRender();
  };

  const hideConfirmationDeleteModal = () => {
    isConfirmationDeleteModalOpen = false;
    reRender();
  };

  const onDelete = async () => {
    hideConfirmationDeleteModal();
    const response = await apiCall({
      fnApiCall: businessDeleteById,
      params: { id },
    });
    if (response.ok) {
      messageContext.setMessage({
        type: "success",
        text: "BROKER.BROKER_DELETE_SUCCESS",
      });
      return navigate("/admin/broker");
    }
  };

  const reRender = () => {
    setStateVersion(stateVersion + 1);
  };

  return (
    <div className="business-details container">
      <Header />
      {business && (
        <>
          <nav className="flex-between">
            <GoBackLink to="/admin/broker" />
            <div className="flex-end" style={{ rowGap: "var(--spacing)" }}>
              <FontAwesomeIcon
                color="var(--secondary)"
                onClick={() => navigate(`/admin/broker/edit/${id}`)}
                icon={faEdit}
                size="lg"
                style={{ cursor: "pointer" }}
              />
              <FontAwesomeIcon
                onClick={showConfirmationDeleteModal}
                icon={faTrash}
                color="var(--danger)"
                size="lg"
                style={{ cursor: "pointer" }}
              />             
            </div>
          </nav>

            <div className="grid">
              <p>
                {business.name && business.name.length > 0 && (
                  <>
                    {t("BUSINESS.NAME")}: <strong>{business.name}</strong>
                  </>
                )}
                {business.user && business.user.length > 0 && (
                  <>
                    <br/>
                    {t("PROFILE.USER")}: <strong>{business.user}</strong>
                  </>
                )}
              </p>              
            </div>
            <div className="grid">
              <p>
                {business.paymentType && (
                  <>
                    {t("BUSINESS.PAYMENT_TYPE")}:{" "}
                    <strong>
                      {t(
                        business.paymentType === 2
                          ? "BUSINESS.PAYMENT_TYPE_ONLINE"
                          : "BUSINESS.PAYMENT_TYPE_ACCOUNT"
                      )}
                    </strong>
                  </>
                )}
                <br />
                {business.commission && (
                  <>
                    {t("BUSINESS.COMMISSION")}:{" "}
                    <strong>{business.commission}</strong>
                  </>
                )}
              </p>              
            </div>          
          <ConfirmationModal
            isOpen={isConfirmationDeleteModalOpen}
            titleKey="BROKER.DELETE.TITLE"
            messageKey="BROKER.DELETE.MESSAGE"
            onConfirm={onDelete}
            onCancel={hideConfirmationDeleteModal}
          />
        </>
      )}
    </div>
  );
};

export default BrokerDetails;
