import { useRef, useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Required from "../Required";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MessageContext from "../../contexts/MessageContext";
import useApiHook from "../../hooks/ApiHook";
import FormatToastMessage from "../FormatMessage";
import { validateEmail } from "../validations/Validation";
import { loginService } from "../../services/AuthService";
import { setCookie, getCookie } from "../CookiesManager";
import useResetStyleHook from "../../hooks/ResetStyleHook";
//import { use } from "i18next";

const errorsFields = {  
  user: "BUSINESS.ERROR.USER_REQUIRED",
  password: "BUSINESS.ERROR.PASSWORD_REQUIRED",
  role: "BUSINESS.ERROR.ROLE_REQUIRED",  
};

const AuthLogin = (props) => {
  useResetStyleHook();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const messageContext = useContext(MessageContext);
  const callLogin = useApiHook();
  const userRef = useRef();
  const passwordRef = useRef();
  const rememberRef = useRef();
  const [showPassword, setShowPassword] = useState(false);
  const [bussy, setBussy] = useState(false);

  useEffect(() => {
    if((getCookie({name:'SSID'}) && getCookie({name:'SSID'}) !== undefined) && (getCookie({name:'_RL'}) && getCookie({name:'_RL'}) !== undefined) && (getCookie({name:'_UI'}) && getCookie({name:'_UI'}) !== undefined)) {
      return navigate(props.role === 1 ? "/admin" : "/profile");
    }
  }, []);

  const requireLogin = async (e) => {    
    e.preventDefault();
    setBussy(true);
    const errorMessages = [];
    const refers = {
      user: userRef.current.value || "required",
      password: passwordRef.current.value || "required",
      role: props.role || "required",      
    };

    for (let key in refers) {
      if (refers[key] === "required") {
        errorMessages.push(t(errorsFields[key]));
      }
    }
    if (errorMessages.length > 0) {
      setBussy(false);
      return messageContext.setMessage({
        type: "error",
        text: FormatToastMessage(
          t("BUSINESS.ERROR.TITLE_ERRORS"),
          errorMessages
        ),
      });
    }
    if (!validateEmail(refers.user) || refers.user.length > 75)
      errorMessages.push(t("BUSINESS.ERROR.EMAIL_INVALID"));
      if (errorMessages.length > 0) {
        setBussy(false);
        return messageContext.setMessage({
          type: "error",
          text: FormatToastMessage(
            t("BUSINESS.ERROR.TITLE_ERRORS"),
            errorMessages
          ),
        });
      }
      
    const response = await callLogin({
      fnApiCall: loginService,
      params: { data: refers },
    });
    setBussy(false);
    
    if (response.ok) {
      const { token, user, name, businessType } = response.data.data;
      let role = token.split('-');
      role = role[role.length - 1].split('T')[0];
      setCookie({name: "SSID", value: token, days: rememberRef.current.checked ? 7 : null});
      setCookie({name: "_RL", value: role, days: rememberRef.current.checked ? 7 : null});
      setCookie({name: "_UI", value: user, days: rememberRef.current.checked ? 7 : null});
      setCookie({name: "_UN", value: name, days: rememberRef.current.checked ? 7 : null});
      setCookie({name: "_BT", value: businessType, days: rememberRef.current.checked ? 7 : null});
      if(rememberRef.current.checked) 
        setCookie({name: "_UR", value: true, days: 7});
      messageContext.setMessage({
        type: "success",
        text: `${response.data.message}`,
      });
      return setTimeout(() => {
        return navigate(props.role === 1 ? "/admin" : "/profile");
      }, 1000);
    }
    return false;
  };

  return (
    <>
      <section>
        <form
          style={{
            maxWidth: "500px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          onSubmit={async (e) => {
            await requireLogin(e);
          }}
        >
          <label>
            {t("BUSINESS.EMAIL")}
            <Required />
            <input
              type="email"
              placeholder={t("BUSINESS.EMAIL")}
              ref={userRef}
            />
          </label>
          <label style={{ position: "relative" }}>
            {t("BUSINESS.PASSWORD")}
            <Required />
            <input
              type={showPassword ? "text" : "password"}
              placeholder={t("BUSINESS.PASSWORD")}
              ref={passwordRef}
            />
            <FontAwesomeIcon
              style={{
                position: "absolute",
                right: "5px",
                top: "50%",
                cursor: "pointer",
                color: "#415462",
              }}
              icon={showPassword ? faEyeSlash : faEye}
              onClick={() => setShowPassword(!showPassword)}
            />
          </label>
          <label style={{ marginBottom: "1rem", marginTop: "1rem" }}>
            <input type="checkbox" ref={rememberRef} />
            {t("REMEMBER_ME")}
          </label>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "2rem",
              width: "100%",
            }}
          >
            <button
              className="secondary outline"
              onClick={() => {
                navigate("/");
              }}
            >
              {t("OUT")}
            </button>
            <button
              type="submit"
              className="primary"
              aria-busy={bussy ? "true" : "false"}
            >
              {t("LOGIN")}
            </button>
          </div>
          <div style={{ textAlign: "center", marginTop: "2rem" }}>
            <Link to={props.role === 1 ? "/admin/forgot" : "/profile/forgot"}>
              {t("FORGOT_PASSWORD")}
            </Link>
          </div>
        </form>
      </section>
    </>
  );
};

export default AuthLogin;
