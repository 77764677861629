import { useRef, useState } from "react";
import {faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let page = 1;
const DifferenceSlider = () => {
    const [stateVersion, setStateVersion] = useState(0);

    const reRender = () => {
        setStateVersion(stateVersion + 1);
    }
    

    const sliderRef = useRef(null);

    const move = (right=true) => {
        if (right) {
            if (page >= 4) {
                page = 1;
            } else {
                page++;
            }                                   
        } else {
            if (page <= 1) {
                page = 4;
            } else {
                page--;
            }
        }
        const pageElem = sliderRef.current.querySelector(`#scroll-diff-${page}`);            
        pageElem.scrollIntoView({behavior: "smooth", block: "nearest", inline: "center"});
        reRender();       
    }

    return (
        <section id="difference">
            <p className="title">Qué nos <span>diferencia</span></p>
            <div className="wrapper" ref={sliderRef}>
                <div className="flex-start slider">
                    <div className="item-page" id="scroll-diff-1">
                        <div className="flex-start item">
                            <div className="img"></div>
                            <div className="flex-start content">
                                <p>Descuentos familiares</p>
                                <p>Descuentos familiares todo el año. Hasta 4 menores de 23 años tienen cobertura sin costo. </p>
                            </div>
                        </div>
                    </div>
                    <div className="item-page" id="scroll-diff-2">
                        <div className="flex-start item">
                            <div className="img"></div>
                            <div className="flex-start content">
                                <p>Cobertura hospitalaria</p>
                                <p>Extendemos la cobertura hospitalaria aún cuando se hubiera expirado la vigencia de tu voucher/plan.</p>
                            </div>
                        </div>
                    </div>
                    <div className="item-page" id="scroll-diff-3">
                        <div className="flex-start item">
                            <div className="img"></div>
                            <div className="flex-start content">
                                <p>Coberturas enfermedades preexistentes</p>
                                <p>Podrás aumentar tu cobertura para Enfermedades preexistentes. </p>
                            </div>                        
                        </div>
                    </div>
                    <div className="item-page" id="scroll-diff-4">
                        <div className="flex-start item">
                            <div className="img"></div>
                            <div className="flex-start content">
                                <p>Beneficios en topes máximos</p>
                                <p>Los topes máximos están dados por los montos.</p>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            <div className="flex-center buttons">
                <button type="button" 
                onClick={() => move(false)}
                className="icon-clickable">
                    <FontAwesomeIcon icon={faArrowLeft}
                    size="xl"
                    color="var(--secondary)" />                    
                </button>
                <button type="button"
                onClick={() => move(true)}
                className="icon-clickable">
                    <FontAwesomeIcon icon={faArrowRight} 
                    size="xl"
                    color="var(--secondary)"/>
                </button>
            </div>
            
        </section>
    )
}
export default DifferenceSlider;