import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MessageContext from "../../../contexts/MessageContext";
import useApiHook from "../../../hooks/ApiHook";
import { businessAdd } from "../../../services/BusinessService";
import { deleteImage } from "../../../services/FileService";
import FormatToastMessage from "../../../shared/FormatMessage";
import ImageCropperModal from "../../../shared/ImageCropperModal";
import ImageDropZone from "../../../shared/ImageDropZone";
import Required from "../../../shared/Required";
import RequiredData from "../../../shared/RequiredData";
import { convertBanners, fromBase64ToBlob, onUploadImage } from "../../../shared/utils";
import {
  inRange,
  sanitizeInputNumber,
  strongPassword,
  validateEmail,
} from "../../../shared/validations/Validation";
import ImageCircleCropperModal from "../../../shared/ImageCircleCrpperModal";

let isLogoImageCropperModalOpen = false;
let isHeaderImageCropperModalOpen = false;
let isFaviconImageCropperModalOpen = false;

let logoImageContent = null;
let faviconImageContent = null;
let headerImageContent = null;

const errorsFields = {
  name: "BUSINESS.ERROR.NAME_REQUIRED",
  rut: "BUSINESS.ERROR.RUT_REQUIRED",
  address: "BUSINESS.ERROR.ADDRESS_REQUIRED",
  commission: "BUSINESS.ERROR.COMMISSION_REQUIRED",
  marhup: "BUSINESS.ERROR.MARKUP_REQUIRED",
  paymentType: "BUSINESS.ERROR.PAYMENT_TYPE_REQUIRED",
  subdomain: "BUSINESS.ERROR.SUBDOMAIN_REQUIRED",
  description: "BUSINESS.ERROR.DESCRIPTION_REQUIRED",
  primaryColor: "BUSINESS.ERROR.PRIMARY_COLOR_REQUIRED",
  secondaryColor: "BUSINESS.ERROR.SECONDARY_COLOR_REQUIRED",
  user: "BUSINESS.ERROR.USER_REQUIRED",
  password: "BUSINESS.ERROR.PASSWORD_REQUIRED",
  repassword: "BUSINESS.ERROR.PASSWORD_CONFIRM_REQUIRED",
  logoUrl: "BUSINESS.ERROR.LOGO_REQUIRED",
};

const BusinessAdd = ({ onAdd, step, setStep, onGoBack, navbar = null, goBackButton = null }) => {

  const { t } = useTranslation();
  const apiHook = useApiHook();
  const messageContext = useContext(MessageContext);
  const [stateVersion, setStateVersion] = useState(0);
  const [nameValue, setNameValue] = useState(null);
  const [rutValue, setRutValue] = useState(null);
  const [addressValue, setAddressValue] = useState(null);
  const [commissionValue, setCommissionValue] = useState(null);
  const [markupValue, setMarkupValue] = useState(null);
  const [paymentValue, setPaymentValue] = useState("0");
  const [subdomainValue, setSubdomain] = useState(null);
  const [descriptionValue, setDescriptionValue] = useState(null);
  const [primaryColorValue, setPrimaryColorValue] = useState("#000000");
  const [secondaryColorValue, setSecondaryColorValue] = useState("#000000");
  const [userValue, setUserValue] = useState(null);
  const [passwordValue, setPasswordValue] = useState(null);
  const [repasswordValue, SetRepeatPassValue] = useState(null);
  const [imageData, setImageData] = useState([]);
  const logoRef = useRef();
  const headerRef = useRef();
  const faviconRef = useRef();

  const getValuesStep1 = () => {
    return {
      name: nameValue || "required",
      rut: rutValue || "required",
      address: addressValue || "required",
      commission: commissionValue || "required",
      markup: markupValue || "required",
      businessType: 'COMPANY',
      paymentType: paymentValue !== "0" ? paymentValue : "required"
    };
  };

  const getValuesStep2 = () => {
    return {
      subdomain: subdomainValue || "required",
      description: descriptionValue || "required",
      primaryColor: primaryColorValue || "required",
      secondaryColor: secondaryColorValue || "required",
      logoUrl: logoImageContent || "required",
      faviconUrl: faviconImageContent || "required",
      headerUrl: headerImageContent || "",
      banners: imageData || [],
    }
  };

  const getValuesStep3 = () => {
    return {
      user: userValue || "required",
      password: passwordValue || "required",
      repassword: repasswordValue || "required",
    };
  };

  const reRender = () => {
    setStateVersion(stateVersion + 1);
  };

  const moveToStep = (stepValue) => {
    if (stepValue < 1) {
      return onGoBack();
    }
    let errorMessages = [];

    switch (stepValue) {
      case 2: {

        errorMessages = validateRequiredFields(errorMessages, getValuesStep1());
        if (errorMessages.length > 0) {
          return;
        }

        errorMessages = [];

        if (nameValue.length > 120) {
          errorMessages.push(t("BUSINESS.ERROR.NAME_MAX_LENGTH"));
        }

        if (inRange({ range: { value: commissionValue, min: 1, max: 100 } }) !== true) {
          if (
            inRange({ range: { value: commissionValue } }) === "NaN" ||
            !sanitizeInputNumber(commissionValue)
          ) {
            errorMessages.push(t("BUSINESS.ERROR.COMMISSION_NOT_NUMBER"));
          }
          else if (!inRange({ range: { value: commissionValue, min: 1, max: 100 } })) {
            errorMessages.push(t("BUSINESS.ERROR.COMMISSION_OUT_RANGE"));
          }
        }
        if (inRange({ range: { value: markupValue, min: 1, max: 100 } }) !== true) {
          if (
            inRange({ range: { value: markupValue } }) === "NaN" ||
            !sanitizeInputNumber(markupValue)
          ) {
            errorMessages.push(t("BUSINESS.ERROR.MARKUP_NOT_NUMBER"));
          }
          else if (!inRange({ range: { value: markupValue, min: 1, max: 100 } })) {
            errorMessages.push(t("BUSINESS.ERROR.MARKUP_OUT_RANGE"));
          }
        }
        errorMessages = showErrorMessages(errorMessages);
        if (errorMessages.length > 0) {
          return;
        }
        errorMessages = [];
        clearImages();
        break;
      }
      case 3: {
        errorMessages = validateRequiredFields(errorMessages, getValuesStep2());
        if (errorMessages.length > 0) {
          return;
        }

        errorMessages = [];
        clearImages();
        break;
      }
      default:
        break;
    }

    setStep(stepValue);
    reRender();
  };

  const clearImages = () => {
    if (logoRef?.current?.value) {
      logoRef.current.value = "";
    }
    if (headerRef?.current?.value) {
      headerRef.current.value = "";
    }
    if (faviconRef?.current?.value) {
      faviconRef.current.value = "";
    }
  }

  const showLogoImageCropperModal = async () => {
    const file = logoRef.current.files[0];
    const image = await onUploadImage(file);
    logoImageContent = image;
    isLogoImageCropperModalOpen = true;
    reRender();
  };

  const showFaviconImageCropperModal = async () => {
    const file = faviconRef.current.files[0];
    const image = await onUploadImage(file);
    faviconImageContent = image;
    isFaviconImageCropperModalOpen = true;
    reRender();
  };

  /* const showHeaderImageCropperModal = async () => {
    const file = headerRef.current.files[0];
    const imageBase64 = await getBase64FromFile(file);
    headerImageContent = imageBase64;
    isHeaderImageCropperModalOpen = true;
    reRender();
  }; */

  const hideLogoImageCropperModal = () => {
    logoRef.current.value = "";
    isLogoImageCropperModalOpen = false;
    reRender();
  };

  const hideFaviconImageCropperModal = () => {
    faviconRef.current.value = "";
    isFaviconImageCropperModalOpen = false;
    reRender();
  };

  /* const hideHeaderImageCropperModal = () => {
    headerRef.current.value = "";
    isHeaderImageCropperModalOpen = false;
    reRender();
  }; */

  const onChangeLogoImage = (image) => {
    showLogoImageCropperModal();
  };

  const onChangeFaviconImage = () => {
    showFaviconImageCropperModal();
  };

  /* const onChangeHeaderImage = (image) => {
    showHeaderImageCropperModal();
  }; */

  const onCropLogoImage = async (croppedImage) => {
    const resizedImage = await fromBase64ToBlob(croppedImage)
    // await deleteImage(logoImageContent)

    const image = await onUploadImage(resizedImage);
    hideLogoImageCropperModal();
    logoImageContent = image;
  };

  const onCropFaviconImage = async (croppedImage) => {
    const resizedImage = await fromBase64ToBlob(croppedImage)
    // await deleteImage(logoImageContent)

    const image = await onUploadImage(resizedImage);
    hideFaviconImageCropperModal();
    faviconImageContent = image;
  };

  /* const onCropHeaderImage = (image) => {
    hideHeaderImageCropperModal();
    headerImageContent = image;
  }; */

  const onClickAdd = async () => {
    let errorMessages = [];
    errorMessages = validateRequiredFields(errorMessages, getValuesStep3());
    if (errorMessages.length > 0) {
      return;
    }
    errorMessages = [];

    if (!validateEmail(userValue) || userValue.length > 75) {
      errorMessages.push(t("BUSINESS.ERROR.EMAIL_INVALID"));
    }
    if (!strongPassword(passwordValue)) {
      errorMessages.push(t("BUSINESS.ERROR.PASSWORD_REQUERIMENTS"));
    }
    if (strongPassword(passwordValue)) {
      if (passwordValue !== repasswordValue) {
        errorMessages.push(t("BUSINESS.ERROR.PASSWORD_CONFIRM_NOT_MATCH"));
      }
    }

    errorMessages = showErrorMessages(errorMessages);
    if (errorMessages.length > 0) {
      return;
    }

    let banners = []
    try {
      banners = await convertBanners(imageData)
    } catch (error) {
      messageContext.setMessage({
        type: "error",
        text: error.message,
      });
      return;
    }

    const values2 = { ...getValuesStep2(), banners }

    const responseData = await apiHook({
      fnApiCall: businessAdd,
      params: {
        data: {
          ...getValuesStep1(),
          ...values2,
          ...getValuesStep3()
        }
      },
    });

    if (responseData.ok) {
      clearImages();
      logoImageContent = null;
      headerImageContent = null;
      setImageData([]);
      messageContext.setMessage({
        type: "success",
        text: `BUSINESS.${responseData.data.message}`,
      });
      onAdd(responseData.data.data);
    }
  };

  const validateRequiredFields = (errorMessages, refers) => {
    for (let key in refers) {
      if (refers[key] === "required") {
        errorMessages.push(t(errorsFields[key]));
      }
    }
    errorMessages = showErrorMessages(errorMessages);
    return errorMessages;
  }

  const showErrorMessages = (errorMessages) => {
    if (errorMessages.length > 0) {
      messageContext.setMessage({
        type: "error",
        text: FormatToastMessage(
          t("BUSINESS.ERROR.TITLE_ERRORS"),
          errorMessages
        ),
      });
    }
    return errorMessages;
  }

  const handleImageDataChange = (newImageData) => {
    setImageData(newImageData);
  };

  useEffect(() => {
    return () => {
      clearImages();
      setStep(1);
    }
  }, [])

  return (
    <>
      {navbar && navbar}

      <div
        style={{
          maxWidth: "500px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <nav className="flex-start">
          {goBackButton && goBackButton}
          <h1 style={{
            margin: 0,
            marginLeft: 'var(--spacing)'
          }}>{t("BUSINESS.ADD")}</h1>
        </nav>
        {step === 1 && (
          <>
            <label>
              {t("BUSINESS.NAME")}
              <Required />
              <input
                type="text"
                placeholder={t("BUSINESS.NAME")}
                onChange={(e) => {
                  setNameValue(e.target.value);
                }}
                value={nameValue || ""}
                required
              />
            </label>
            <label>
              {t("BUSINESS.RUT")}
              <Required />
              <input
                type="text"
                placeholder={t("BUSINESS.RUT")}
                onChange={(e) => {
                  setRutValue(e.target.value);
                }}
                value={rutValue || ""}
                required
              />
            </label>
            <label>
              {t("BUSINESS.ADDRESS")}
              <Required />
              <input
                type="text"
                placeholder={t("BUSINESS.ADDRESS")}
                onChange={(e) => {
                  setAddressValue(e.target.value);
                }}
                value={addressValue || ""}
                required
              />
            </label>
            <label>
              {t("BUSINESS.COMMISSION")}
              <Required />
              <input
                type="text"
                placeholder={t("BUSINESS.COMMISSION")}
                onChange={(e) => {
                  setCommissionValue(e.target.value);
                }}
                value={commissionValue || ""}
                required
              />
            </label>
            <label>
              {t("BUSINESS.MARKUP")}
              <Required />
              <input
                type="text"
                placeholder={t("BUSINESS.MARKUP")}
                onChange={(e) => {
                  setMarkupValue(e.target.value);
                }}
                value={markupValue || ""}
                required
              />
            </label>
            <div className="grid">
              <div>
                <label>
                  {t("BUSINESS.PAYMENT_TYPE")}
                  <Required />
                </label>
                <select
                  defaultValue={paymentValue ? paymentValue : "0"}
                  onChange={(e) => {
                    setPaymentValue(e.target.value);
                  }}
                  required
                >
                  <option value="0">{t("SELECT")}</option>
                  <option value="1">
                    {t("BUSINESS.PAYMENT_TYPE_ACCOUNT")}
                  </option>
                  <option value="2">{t("BUSINESS.PAYMENT_TYPE_ONLINE")}</option>
                </select>
              </div>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <label>
              {t("BUSINESS.SUBDOMAIN")}
              <Required />
              <input
                type="text"
                placeholder={t("BUSINESS.SUBDOMAIN")}
                onChange={(e) => {
                  setSubdomain(e.target.value);
                }}
                value={subdomainValue || ""}
                required
              />
            </label>
            <label>
              {t("BUSINESS.DESCRIPTION")}
              <Required />
              <textarea
                onChange={(e) => {
                  setDescriptionValue(e.target.value);
                }}
                placeholder={t("BUSINESS.DESCRIPTION")}
                value={descriptionValue || ""}
              ></textarea>
            </label>
            <div className="grid">
              <label>
                {t("BUSINESS.PRIMARY_COLOR")}
                <Required />
                <input
                  type="color"
                  onChange={(e) => {
                    setPrimaryColorValue(e.target.value);
                  }}
                  value={primaryColorValue || ""}
                  required
                />
              </label>
              <label>
                {t("BUSINESS.SECONDARY_COLOR")}
                <Required />
                <input
                  type="color"
                  onChange={(e) => {
                    setSecondaryColorValue(e.target.value);
                  }}
                  value={secondaryColorValue || ""}
                  required
                />
              </label>
            </div>
            <div className="grid">
              <label htmlFor="logo">
                {t("BUSINESS.LOGO_URL")}
                <Required />
                <input
                  type="file"
                  id="logo"
                  ref={logoRef}
                  onChange={onChangeLogoImage}
                  name="file"
                />
              </label>
              {/* <label htmlFor="header">
                {t("BUSINESS.HEADER_URL")}
                <Required />
                <input
                  type="file"
                  ref={headerRef}
                  onChange={onChangeHeaderImage}
                  id="header"
                  name="file"
                />
              </label> */}
            </div>
            <div className="grid">
              <div>
                {logoImageContent && <img src={logoImageContent} alt="logo" />}
              </div>
              <div>
                {headerImageContent && (
                  <img src={headerImageContent} alt="header" />
                )}
              </div>
            </div>
            <div className="grid">
              <label htmlFor="favicon">
                {t("BUSINESS.FAVICON_URL")}
                <Required />
                <input
                  type="file"
                  id="favicon"
                  ref={faviconRef}
                  onChange={onChangeFaviconImage}
                  name="file"
                />
              </label>
            </div>
            <div className="grid">
              <div>
                <img src={faviconImageContent} alt="favicon" />
              </div>
            </div>
            <ImageDropZone onImageDataChange={handleImageDataChange} images={imageData} />
          </>
        )}
        {step === 3 && (
          <>
            <label>
              {t("BUSINESS.EMAIL")}
              <Required />
              <input
                type="email"
                placeholder={t("BUSINESS.EMAIL")}
                onChange={(e) => {
                  setUserValue(e.target.value);
                }}
                value={userValue || ""}
                required
              />
            </label>
            <label>
              {t("BUSINESS.PASSWORD")}
              <Required />
              <input
                type="password"
                onChange={(e) => {
                  setPasswordValue(e.target.value);
                }}
                value={passwordValue || ""}
                required
              />
            </label>
            <label>
              {t("BUSINESS.PASSWORD_CONFIRM")}
              <Required />
              <input
                type="password"
                onChange={(e) => {
                  SetRepeatPassValue(e.target.value);
                }}
                value={repasswordValue || ""}
                required
              />
            </label>
          </>
        )}
        <RequiredData />
        <div style={{ marginTop: 'var(--spacing)' }} className="grid form-end-buttons">
          {step !== 3 && (
            <>
              <div className="flex-start">
                <button
                  className="secondary outline"
                  onClick={() => moveToStep(step - 1)}
                >
                  {t("GO_BACK")}
                </button>
              </div>
              <div className="flex-end">
                <button className="primary"
                  onClick={() => moveToStep(step + 1)}>
                  {t("NEXT")}
                </button>
              </div>
            </>
          )}
          {step === 3 && (
            <>
              <div className="flex-start">
                <button
                  className="secondary outline"
                  onClick={() => moveToStep(step - 1)}
                >
                  {t("GO_BACK")}
                </button>
              </div>
              <div className="flex-end">
                <button type="submit"
                  className="primary"
                  onClick={onClickAdd}>
                  {t("BUSINESS.ADD")}
                </button>
              </div>
            </>
          )}
        </div>
        <ImageCropperModal
          isOpen={isLogoImageCropperModalOpen}
          onCancel={hideLogoImageCropperModal}
          onCrop={(croppedImage) => {
            onCropLogoImage(croppedImage);
          }}
          imageContent={logoImageContent}
        />
        <ImageCircleCropperModal
          isOpen={isFaviconImageCropperModalOpen}
          onCancel={hideFaviconImageCropperModal}
          onCrop={(croppedImage) => {
            onCropFaviconImage(croppedImage);
          }}
          imageContent={faviconImageContent}
        />
        {/* <ImageCropperModal
          isOpen={isHeaderImageCropperModalOpen}
          onCancel={hideHeaderImageCropperModal}
          onCrop={(croppedImage) => {
            onCropHeaderImage(croppedImage);
          }}
          imageContent={headerImageContent}
          aspectRatio={16 / 4}
        /> */}
      </div>
    </>
  );
};

export default BusinessAdd;
