import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const businessAdd = ({data, headers}) => {    
    return axios.post(`${API_URL}/business/businessAdd`, data, { headers });
}
export const businessEdit = ({data, headers}) => {    
    return axios.post(`${API_URL}/business/businessEdit`, data, { headers });
}

export const businessFindList = ({headers}) => { 
    return axios.get(`${API_URL}/business/businessList/`, { headers });
}

export const businessGetById = ({data, headers}) => {    
    return axios.get(`${API_URL}/business/businessGet/${data.id}`, { headers });
}
export const businessGetBySubdomain = ({data, headers}) => {    
    return axios.get(`${API_URL}/business/businessFind/${data.subdomain}`, { headers });
}

export const businessDeleteById = ({data, headers}) => {    
    return axios.post(`${API_URL}/business/businessDel/`, {id: data.id}, { headers });
}

export const getBusinessVoucherPdfUrl = (id) => {        
    return `${API_URL}/business/voucherPDF/${id}`;
}

export const businessFind = ({data, headers}) => {
    return axios.get(`${API_URL}/business/businessFind/${data.subdomain}`, { headers });
}