import { Link } from "react-router-dom";

const HomeHeroThree = () => { 
    return (
        <section id="home-hero-3" className='flex-center'>
            <p className="grid single-column-grid" style={{ gridTemplateColumns: '1fr' }}>
                <span className="three-light-message">Un viaje perfecto comienza con</span>
                <strong className="three-bold-message">My Global a tu lado.</strong>
            </p>
            <Link role="button"
            to="/buy/step-1"
            className="secondary mob-btn-nav">
                Cotizar asistencia
            </Link>
        </section>
    )
}

export default HomeHeroThree;