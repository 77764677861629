import { getCookie, setCookie } from "../CookiesManager";

const AuthMiddleware = ({ role }) => {
  const isAuthenticated = getCookie({ name: "SSID" });
  if (!role) return false;
  if (!isAuthenticated) {
    return role === 1 ? "/admin/login" : "/profile/login";
  } else if (isAuthenticated) {
    const userRole = parseInt(getCookie({ name: "_RL" }));
    const username = getCookie({ name: "_UN" });
    if (userRole !== role){      
      return 403;
    }
    else {
      if (getCookie({ name: "_UR" }) === "true") {
        setCookie({
          name: "SSID",
          value: isAuthenticated,
          days: 7
        });
        setCookie({
          name: "_RL",
          value: getCookie({ name: "_RL" }),
          days: 7
        });
        setCookie({
          name: "_UI",
          value: getCookie({ name: "_UI" }),
          days: 7,
        });
        setCookie({ name: "_UR", value: true, days: 7 });
      }
      if (username) {
        setCookie({name: '_UN', value: username, days: 7});
      }
    }
  }
  return true;
};

export default AuthMiddleware;
