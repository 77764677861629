import { useRef, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Required from "../Required";
import { forgotService } from "../../services/AuthService";
import { validateEmail } from "../validations/Validation";
import MessageContext from "../../contexts/MessageContext";
import useApiHook from "../../hooks/ApiHook";
import FormatToastMessage from "../FormatMessage";

const errorsFields = {  
  user: "BUSINESS.ERROR.USER_REQUIRED",
  role: "BUSINESS.ERROR.ROLE_REQUIRED",  
};

const AuthForgot = ( props ) => {
  
  const { t } = useTranslation();
  const navigate = useNavigate();
  const messageContext = useContext(MessageContext);
  const callForgot = useApiHook();
  const userRef = useRef();
  const [bussy, setBussy] = useState(false);

  const requireForgot = async (e) => {    
    e.preventDefault();
    setBussy(true);
    const errorMessages = [];
    const refers = {
      user: userRef.current.value || "required",
      role: props.role || "required",      
    };

    for (let key in refers) {
      if (refers[key] === "required") {
        errorMessages.push(t(errorsFields[key]));
      }
    }
    if (errorMessages.length > 0) {
      setBussy(false);
      return messageContext.setMessage({
        type: "error",
        text: FormatToastMessage(
          t("BUSINESS.ERROR.TITLE_ERRORS"),
          errorMessages
        ),
      });
    }
    if (!validateEmail(refers.user) || refers.user.length > 75)
      errorMessages.push(t("BUSINESS.ERROR.EMAIL_INVALID"));
      if (errorMessages.length > 0) {
        setBussy(false);
        return messageContext.setMessage({
          type: "error",
          text: FormatToastMessage(
            t("BUSINESS.ERROR.TITLE_ERRORS"),
            errorMessages
          ),
        });
      }
      
    const response = await callForgot({
      fnApiCall: forgotService,
      params: { data: refers },
    });
    setBussy(false);
    if (response.ok) {     
      messageContext.setMessage({
        type: "success",
        text: `${response.data.message}`,
      });
      
      return setTimeout(() => {
        return navigate(props.role === 1 ? "/admin/login" : "/profile/login");
      }, 1000);
    }
    return false;
  };

  return (
    <>
      <section>        
        <form
          style={{
            maxWidth: "500px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          onSubmit={async (e) => {
            await requireForgot(e);
          }}
        >
            <p style={{ textAlign: "center"}}>{ t("REGISTERED_EMAIL") }</p>
          <label>
            {t("BUSINESS.EMAIL")}
            <Required />
            <input
              type="email"
              placeholder={t("BUSINESS.EMAIL")}
              ref={userRef}
            />
          </label>   
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "2rem",
              width: "100%",
            }}
          >
            <button
              className="secondary outline"
              onClick={() => {
                navigate("/");
              }}
            >
              {t("OUT")}
            </button>
            <button type="submit" className="primary" aria-busy={bussy ? "true" : "false"}>
              {t("SEND")}
            </button>
          </div>
          <div style={{ textAlign: "center", marginTop: "2rem" }}>
            <Link to={props.role === 1 ? "/admin/login" : "/profile/login"}>{t("BACK_TO_LOGIN")}</Link>
          </div>
        </form>
      </section>
    </>
  );
};

export default AuthForgot;
