import { useState } from "react";
//import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import { getCookie } from "../../shared/CookiesManager";

const HeaderPublic = ({ businessInfo = null }) => {
  //const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const brokerName = getCookie({ name: "_UN" });
  const businessType = getCookie({ name: "_BT" });

  const closeAndNavigate = (path) => {
    setShowMenu(false);
    navigate(path);
  };
  return (
    <header className="container-fluid" id="header-public">
      <nav className="flex-between" style={{ marginBottom: 0 }}>
        <div className="flex-start" style={{ width: "auto" }}>
          <Link
            to={businessInfo?.subdomain ? `/${businessInfo?.subdomain}` : "/"}
            className="link-logo"
          >
            <img
              src={
                businessInfo?.logoUrl ?? `/imgs/Logo-My-Global-Assist-color.png`
              }
              style={{
                width: "130px",
                height: "auto",
              }}
              alt={businessInfo?.name ?? "MyGlobalAsist"}
              className="img-logo"
            />
          </Link>
          {businessType?.toUpperCase() === "BROKER" && brokerName && (
            <p id="header_public_broker_name">Usuario: {brokerName}</p>
          )}
        </div>
        <div className="flex-end" style={{ width: "100%" }}>
          {(!businessInfo?.subdomain ||
            businessInfo.subdomain === "myglobal") && (
            <>
              <Link
                to={`/profile/login`}
                className="broker-button"
                role="button"
              >
                <strong
                  id="btn-sale-agent"
                  style={{ fontSize: "1.2em", fontFamily: "MetrischExtraBold" }}
                >
                  Agente de ventas
                </strong>
              </Link>
            </>
          )}
          <Link
            to={
              businessInfo?.subdomain
                ? `/${businessInfo?.subdomain}/buy/step-1`
                : "/buy/step-1"
            }
            role="button"
            className="secondary-btn mob-btn-nav"
          >
            <strong
              id="btn-quote-assistance"
              style={{ fontSize: "1.2em", fontFamily: "MetrischExtraBold" }}
            >
              Cotizar asistencia
            </strong>
          </Link>
          {!businessInfo?.subdomain || businessInfo.subdomain === "myglobal" ? (
            <button
              /* role="button" */
              className="secondary icon-clickable"
              id="menu-icon"
              onClick={() => setShowMenu(!showMenu)}
            >
              <FontAwesomeIcon icon={faBars} size="lg" color="#fff" />
            </button>
          ) : (
            ""
          )}
        </div>
      </nav>
      <div className={`flex-start${showMenu ? " opened" : ""}`} id="modal-menu">
        <div className="flex-end" id="close-menu-wrapper">
          <FontAwesomeIcon
            id="close-menu-icon"
            style={{ cursor: "pointer" }}
            onClick={() => setShowMenu(!showMenu)}
            icon={faClose}
            size="2x"
            color="var(--text-color)"
          />
        </div>
        <nav className="flex-center" id="menu-links">
          <button
            type="button"
            onClick={() => closeAndNavigate("/sobre-nosotros")}
          >
            Sobre nosotros
          </button>
          <button
            type="button"
            onClick={() => closeAndNavigate("/solicitar-asistencia")}
          >
            Solicitar asistencia
          </button>
          <button
            type="button"
            onClick={() => closeAndNavigate("/cancelar-plan")}
          >
            Cancelar plan
          </button>
          <button
            type="button"
            onClick={() => closeAndNavigate("/solicitar-reintegro")}
          >
            Solicitar reintegro
          </button>
          {/* <button type="button" onClick={() => closeAndNavigate("/")}>
            Preguntas frecuentes
          </button> */}
        </nav>
      </div>
    </header>
  );
};

export default HeaderPublic;
