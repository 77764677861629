import { useState } from "react";
import BusinessSearch from "./BusinessSearch";
//import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import BusinessAddComponent from "./BusinessAdd";

let isCreateMode = false;
let addStep = 1;

const BusinessModalSearch = ({ onSelect, isOpen, onClose }) => {

    const [stateVersion, setStateVersion] = useState(1);
    //const {t} = useTranslation();

    const toggleCreateMode = () => {
        isCreateMode = !isCreateMode;
        reRender();
    }

    const reRender = () => {
        const version = stateVersion;
        if (version === stateVersion)
            setStateVersion(stateVersion => stateVersion + 1);
    }

    const onModalClose = () => {
        isCreateMode = false;
        addStep = 1;
        onClose();
    }

    const onModalSelect = (item) => {
        isCreateMode = false;
        addStep = 1;
        onSelect(item);
    }

    const setStep = (step) => {
        addStep = step;
        reRender();
        return step;
    }

    return (
        <dialog open={isOpen}>
            <article style={{ 
                width: '100%', 
                maxWidth: '100%'
            }}>
                <header>
                    { !isCreateMode &&                    
                        <a href="#close" 
                            onClick={onModalClose}
                            aria-label="Close" 
                            className="close">
                        </a>
                    }
                    &nbsp;
                </header>
                { isCreateMode 
                    ?
                    <>
                        <BusinessAddComponent 
                        onGoBack={toggleCreateMode}
                        onAdd={onModalSelect}
                        goBackButton={                                                    
                            <FontAwesomeIcon 
                            style={{cursor: 'pointer'}}
                            onClick={toggleCreateMode}
                            color="var(--primary)"
                            size="xl"
                            icon={faArrowLeft} />                            
                        }
                        setStep={setStep}
                        step={addStep} />
                    </>
                    :
                    <>
                        <BusinessSearch 
                        addButton={
                            <button
                                /* role="button" */
                                style={{ 
                                    marginBottom:0, 
                                    marginLeft: "var(--spacing)" 
                                }}                                                       
                                className="icon-clickable"
                                onClick={toggleCreateMode}                        
                                >
                                    <FontAwesomeIcon 
                                    color="var(--primary-inverse)"
                                    size="xl"
                                    icon={faPlus} />
                            </button>
                        }
                        onSelect={onModalSelect} />             
                    </>
                }
            </article>
        </dialog>
    );
}

export default BusinessModalSearch;