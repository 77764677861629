import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getCategories = () => {
    return axios.get(`${API_URL}/category/allCategories/`);
};
export const getCategoriesByCountry = ({countryId}) => {
    return axios.get(`${API_URL}/category/getCategoriesByCountry/${countryId}`);
}
export const getPlansByDestination = ({data}) => {
    return axios.post(`${API_URL}/category/plansByDestination`, data);
}
export const getBenefitsCostsByPlan = ({data}) => {
    return axios.post(`${API_URL}/category/benefitsPlansCosts/`, data);
}
export const getAditionalBenefits = ({data}) => {
    return axios.post(`${API_URL}/category/aditionalBenefits/`, data);
}