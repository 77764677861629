import { useEffect, useState, useRef } from "react";
import useApiHook from "../../../hooks/ApiHook";
import { salesFindList } from "../../../services/SalesService";
import { promoCodeFindList } from "../../../services/PromoCodeService";
import { useTranslation } from "react-i18next";
import OrderTableHeader from "../../../shared/OrderTableHeader";
import ExportSheet from "../../../shared/ExportSheet";
import PriceFormat from "../../../shared/PriceFormat";
import GoBackLink from "../../../shared/GoBackLink";

const limit = 100;
//let arrSales = [];
let arrPromos = [];
let items = [];
let itemsSeller = [];
let itemsToShow = [];
let arrCodes = [];
let groupedSales = [];
let orderDirection = "asc";
let orderBy = "code";

const PromoCodeStatistics = ({ onSelect, navbar = null }) => {

  const formRef = useRef();
  const [stateVersion, setStateVersion] = useState(1);
  const { t } = useTranslation();
  const apiHook = useApiHook();
  const date = new Date();
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  const setItems = (data) => {
    items = data;
  };

  const prepareData = (data) => {    
    const currentMonth = `${year}-${month}`;
    const currentCode = arrPromos.find(
      (item) => item.code === data[0]?.sale?.promoCode
    );  
    if(!currentCode) return false;
    let promoCode = "";
    let total = 0.0;
    let totalUses = 0;
    let ownPercentage = currentCode?.ownPercentage && currentCode.ownPercentage > 0
        ? parseFloat(currentCode.ownPercentage).toFixed(2)
        : 0.0;
    let companyPercentage =
      currentCode?.companyPercentage && currentCode.companyPercentage > 0
        ? parseFloat(currentCode.companyPercentage).toFixed(2)
        : 0.0;
    let ruaDiscount = 0.0;
    let companyDiscount = 0.0;
    let preNeto = 0.0;
    let neto = 0.0;
    data.forEach((item) => {
      if (item.created.startsWith(currentMonth)) {
        promoCode = item.sale.promoCode;
        total = PriceFormat({
          price: [item.sale.planPrice, total],
          operator: "+",
          expect: "float",
        });
        totalUses++;
        if (ownPercentage > 0 && ownPercentage !== undefined) {
          ruaDiscount = parseFloat(
            total * parseFloat(ownPercentage / 100).toFixed(2)
          ).toFixed(2);
        }
        if (companyPercentage > 0 && companyPercentage !== undefined) {
          companyDiscount = parseFloat(
            total * parseFloat(companyPercentage / 100).toFixed(2)
          ).toFixed(2);
        }

        let markup = item.sale?.markup
          ? parseFloat(total / item.sale.markup).toFixed(2)
          : parseFloat(total / 2.0).toFixed(2);

        preNeto = PriceFormat({
          price: [total, ruaDiscount, companyDiscount, markup],
          operator: "-",
          expect: "float",
        });

        neto = parseFloat(preNeto).toFixed(2);
      }
    });

    ownPercentage = parseInt(ownPercentage);
    companyPercentage = parseInt(companyPercentage);
    const item = {
      promoCode,
      total,
      totalUses,
      ruaDiscount,
      companyDiscount,
      neto,
    };
    if (totalUses > 0) groupedSales.push(item);
    else return false;
  };

  const setItemsSeller = (data) => {
    groupedSales = [];
    itemsSeller = data;
    for (const code of arrCodes) {
      const seller = itemsSeller.filter(
        (item) => code === item?.sale?.promoCode
      );
      if (seller.length > 0) {
        prepareData(seller);
      }
    }
  };

  const reRender = () => {
    const version = stateVersion;
    if (version === stateVersion)
      setStateVersion((stateVersion) => stateVersion + 1);    
  };

  const getDataForExport = () => {
    if (itemsToShow.length === 0 || groupedSales.length === 0) return false;
    const headers = [
      t("PROMO_CODE.CODE"),
      t("PROMO_CODE.VALUE_INVOICED"),
      t("PROMO_CODE.USE_NUMBER"),
      t("PROMO_CODE.RUA_DISCOUNT"),
      t("PROMO_CODE.BUSINESS_DISCOUNT"),
      t("PROMO_CODE.PROFIT_MARGIN"),
    ];
    const data = groupedSales.map((item) => {
      return [
        item.promoCode,
        item.total,
        item.totalUses,
        item.ruaDiscount,
        item.companyDiscount,       
        item.neto,
      ];
    });

    return [headers, ...data];
  };

  const findPromoList = async () => {
    const responseData = await apiHook({
      fnApiCall: promoCodeFindList,
      params: null,
    });
    const promosData = responseData?.data ?? null;
    if (!promosData) return false;
    arrPromos = promosData;
    return true;
  };

  const findList = async () => {
    const responseData = await apiHook({
      fnApiCall: salesFindList,
      params: null,
    });
    const salesData = responseData?.data?.data ?? null;
    if (!salesData) return false;
    const promos = await findPromoList();
    if (!promos) return false;
    const sales = salesData.filter((item) => {
      if (
        item.sale?.withDiscount === true &&
        !arrCodes.includes(item.sale?.promoCode)
      )
        arrCodes.push(item.sale.promoCode);
      //return item;
      return item.sale?.withDiscount === true;
    });
    arrCodes.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    setItems(sales);
    setItemsSeller(sales);
    filterItemsToShow();
    reRender();
  };

  useEffect(() => {
    findList();
  }, [window.onload]);

  const setFilterData = (data) => {
    const currentCode = arrPromos.find(
      (item) => item.code === data[0]?.sale?.promoCode
    );     
    if(!currentCode) return false;  
    let promoCode = "";
    let total = 0.0;
    let totalUses = 0;
    let ownPercentage =
      currentCode.ownPercentage && currentCode.ownPercentage > 0
        ? parseFloat(currentCode.ownPercentage).toFixed(2)
        : 0.0;
    let companyPercentage =
      currentCode.companyPercentage && currentCode.companyPercentage > 0
        ? parseFloat(currentCode.companyPercentage).toFixed(2)
        : 0.0;
    let ruaDiscount = 0.0;
    let companyDiscount = 0.0;
    let preNeto = 0.0;
    let neto = 0.0;
    data.forEach((item) => {
        promoCode = item.sale.promoCode;
        total = PriceFormat({
          price: [item.sale.planPrice, total],
          operator: "+",
          expect: "float",
        });
        totalUses++;
        if (ownPercentage > 0 && ownPercentage !== undefined) {
          ruaDiscount = parseFloat(
            total * parseFloat(ownPercentage / 100).toFixed(2)
          ).toFixed(2);
        }
        if (companyPercentage > 0 && companyPercentage !== undefined) {
          companyDiscount = parseFloat(
            total * parseFloat(companyPercentage / 100).toFixed(2)
          ).toFixed(2);
        }

        let markup = item.sale?.markup
          ? parseFloat(total / item.sale.markup).toFixed(2)
          : parseFloat(total / 2.0).toFixed(2);

        preNeto = PriceFormat({
          price: [total, ruaDiscount, companyDiscount, markup],
          operator: "-",
          expect: "float",
        });

        neto = parseFloat(preNeto).toFixed(2);
    });

    ownPercentage = parseInt(ownPercentage);
    companyPercentage = parseInt(companyPercentage);
    const item = {
      promoCode,
      total,
      totalUses,
      ruaDiscount,
      companyDiscount,
      neto,
    };
    if (totalUses > 0) {
      groupedSales.push(item);
      reRender();
    } else return false;
  };

  const onFilter = () => {
    if (items == null || items.length === 0) {
      itemsToShow = [];
      reRender();
      return;
    }

    const formData = new FormData(formRef.current);
    let tempItems = itemsSeller;

    if (formData.get("startDate").length > 0) {
      const startDateValue = parseInt(
        formData.get("startDate").replace(/-/g, "")
      );
      tempItems = tempItems.filter((item) => {
        const itemCreated = parseInt(
          item.created.split("T")[0].replace(/-/g, "")
        );
        return itemCreated >= startDateValue;
      });
    }

    if (formData.get("endDate").length > 0) {
      const endDateValue = parseInt(formData.get("endDate").replace(/-/g, ""));
      tempItems = tempItems.filter((item) => {
        const itemCreated = parseInt(
          item.created.split("T")[0].replace(/-/g, "")
        );
        return itemCreated <= endDateValue;
      });
    }

    if (!tempItems || tempItems.length === 0) {
      itemsToShow = [];
      reRender();
      return;
    }
    let filterNames = [];
    tempItems.map((item) => {
      if (!filterNames.includes(item?.sale?.promoCode))
        filterNames.push(item.sale.promoCode);
      return item;
    });

    filterNames.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    groupedSales = [];
    for (const name of filterNames) {
      const seller = tempItems.filter((item) => name === item?.sale?.promoCode);
      if (seller.length > 0) {
        setFilterData(seller);
      }
    }
    filterItemsToShow();
    reRender();
  };

  const filterItemsToShow = () => {
    if (items == null || items.length === 0) {
      itemsToShow = [];
      reRender();
      return;
    }
    const formData = new FormData(formRef.current);
    let tempItems = itemsSeller;

    if (formData.get("startDate").length > 0) {
      const startDateValue = parseInt(
        formData.get("startDate").replace(/-/g, "")
      );
      tempItems = tempItems.filter((item) => {
        const itemCreated = parseInt(
          item.created.split("T")[0].replace(/-/g, "")
        );
        return itemCreated >= startDateValue;
      });
    }

    if (formData.get("endDate").length > 0) {
      const endDateValue = parseInt(formData.get("endDate").replace(/-/g, ""));
      tempItems = tempItems.filter((item) => {
        const itemCreated = parseInt(
          item.created.split("T")[0].replace(/-/g, "")
        );
        return itemCreated <= endDateValue;
      });
    }

    if (!tempItems || tempItems.length === 0) {
      itemsToShow = [];
      reRender();
      return;
    }

    itemsToShow = tempItems
      .sort((a, b) => {
        const sortDirection = orderDirection === "asc" ? 1 : -1;
        if (a[orderBy] === b[orderBy]) {
          return 0;
        } else if (a[orderBy] > b[orderBy]) {
          return 1 * sortDirection;
        } else {
          return -1 * sortDirection;
        }
      })
      .slice(0, limit);
    reRender();
  };

  const resetForm = () => {
    formRef?.current?.reset();
    setItemsSeller(itemsSeller);
    filterItemsToShow();
  };

  //window.onload = findList;

  return (
    <>
      <nav className="flex-between menu-navbar">
        <GoBackLink to="/admin/promo-code" />
        <h1 style={{ margin: 0 }}>{t("PROMO_CODE.STATS")}</h1>
        <ExportSheet
          getData={getDataForExport}
          fileName={t("PROMO_CODE.STATS")}
          style={{ marginTop: "10px" }}
        />
      </nav>
      <div className="filters">
        <form
          ref={formRef}
          name="search"
          className="menu-navbar filters"
          onSubmit={(ev) => {
            ev.preventDefault();
          }}
        >
          <div className="flex-end full-width" style={{ alignItems: "center" }}>
            <label className="mobile-full-width" style={{ width: "219px" }}>
              {t("START_DATE")}
              <input
                type="date"
                id="startDate"
                name="startDate"
                defaultValue={`${year}-${month}-01`}                
              />
            </label>
            <label className="mobile-full-width" style={{ width: "219px" }}>
              {t("END_DATE")}
              <input
                type="date"
                id="endDate"
                name="endDate"
                defaultValue={`${year}-${month}-${day}`}                
              />
            </label>
            <button
              type="button"
              className="primary mobile-full-width"
              onClick={onFilter}
              style={{ marginBottom: "auto", marginTop: "auto" }}
            >
              {t("APPLY_FILTERS")}
            </button>
            <button
              type="button"
              className="secondary mobile-full-width"
              onClick={resetForm}
              style={{ marginBottom: "auto", marginTop: "auto" }}
            >
              {t("CLEAN")}
            </button>
          </div>
        </form>
      </div>
      {navbar && navbar}
      {itemsSeller.length > 0 && itemsToShow.length > 0 ? (
        <figure>
          <table role="grid">
            <thead>
              <tr>
                <OrderTableHeader
                  titleKey={"PROMO_CODE.CODE"}
                  orderByKey={"promoCode"}
                  onSetOrder={() => {
                    return false;
                  }}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                />
                <OrderTableHeader
                  titleKey={"PROMO_CODE.VALUE_INVOICED"}
                  orderByKey={"planPriceValue"}
                  onSetOrder={() => {
                    return false;
                  }}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                />
                <OrderTableHeader
                  titleKey={"PROMO_CODE.USE_NUMBER"}
                  orderByKey={"planPriceValue"}
                  onSetOrder={() => {
                    return false;
                  }}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                />
                <OrderTableHeader
                  titleKey={"PROMO_CODE.RUA_DISCOUNT"}
                  orderByKey={"promoCode"}
                  onSetOrder={() => {
                    return false;
                  }}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                />
                <OrderTableHeader
                  titleKey={"PROMO_CODE.BUSINESS_DISCOUNT"}
                  orderByKey={"passengers"}
                  onSetOrder={() => {
                    return false;
                  }}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                />
                <OrderTableHeader
                  titleKey={"PROMO_CODE.PROFIT_MARGIN"}
                  orderByKey={"holder"}
                  onSetOrder={() => {
                    return false;
                  }}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                />
              </tr>
            </thead>
            <tbody>
              {/* {itemsToShow.map((item, index) => { */}
              {groupedSales.map((item, index) => {
                return (
                  <tr key={index} style={{ cursor: "pointer" }}>
                    <td>{item.promoCode}</td>
                    <td>{item.total}</td>
                    <td>{item.totalUses}</td>
                    <td>{item.ruaDiscount}</td>
                    <td>{item.companyDiscount}</td>
                    <td>{item.neto}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </figure>
      ) : (
        <p>{t("SALES.NO_RESULTS")}</p>
      )}
    </>
  );
};

export default PromoCodeStatistics;
