import { useState } from "react";

const PassengerForm = ({ titulo, subtitulo, handleData, index, benefits, dates }) => {
  
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [typeDoc, setTypeDoc] = useState("0");
  const [docNumber, setDocNumber] = useState("");
  const [bornDate, setBornDate] = useState(dates || "2000-01-01");

  const handleNameChange = (ev) => {
    const updatedName = ev.target.value;
    setName(updatedName);
    handleData(
      { name: updatedName, lastName, typeDoc, docNumber, bornDate },
      index
    );
  };

  const handleLastNameChange = (ev) => {
    const updatedLastName = ev.target.value;
    setLastName(updatedLastName);
    handleData(
      { name, lastName: updatedLastName, typeDoc, docNumber, bornDate },
      index
    );
  };

  const handleTypeDoc = (ev) => {
    const updatedTypeDoc = ev.target.value;
    setTypeDoc(updatedTypeDoc);
    handleData(
      { name, lastName, typeDoc: updatedTypeDoc, docNumber, bornDate },
      index
    );
  };

  const handleDocNumber = (ev) => {
    const updatedDocNumber = ev.target.value;
    setDocNumber(updatedDocNumber);
    handleData(
      { name, lastName, typeDoc, docNumber: updatedDocNumber, bornDate },
      index
    );
  };

  const handleBornDate = (ev) => {
    const updatedBornDate = ev.target.value;
    setBornDate(updatedBornDate);
    handleData(
      { name, lastName, typeDoc, docNumber, bornDate: updatedBornDate },
      index
    );
  };

  const passengerBenefits = benefits?.filter(
    (benefit) => benefit.passengerId === index + 1
  );  

  return (
    <article className="card" style={{ marginTop: 0 }}>
      <h5 style={{ textAlign: "left", marginBottom: ".5rem" }}>
        {titulo} <small style={{ fontSize: ".9rem" }}>{subtitulo || ""}</small>
      </h5>
      <div className="grid">
        <label style={{ textAlign: "left" }}>
          Nombre:
          <input
            type="text"
            value={name}
            onChange={handleNameChange}
            placeholder="Nombre..."
          />
        </label>

        <label style={{ textAlign: "left" }}>
          Apellido:
          <input
            type="text"
            value={lastName}
            onChange={handleLastNameChange}
            placeholder="Apellido..."
          />
        </label>
      </div>
      <div className="grid">
        <label style={{ textAlign: "left" }}>
          Tipo de documento:
          <select defaultValue={typeDoc} onChange={handleTypeDoc}>
            <option value="0">Tipo de documento</option>
            <option value="2">Documento Nacional</option>
            <option value="3">Pasaporte</option>
          </select>
        </label>
        <label style={{ textAlign: "left" }}>
          Número de documento:
          <input
            type="text"
            id="docNumber"
            name="docNumber"
            value={docNumber}
            placeholder="Número de documento..."
            onChange={handleDocNumber}
          />
        </label>
      </div>
      <div className="grid">
        <div>
          <label style={{ textAlign: "left" }}>
            Fecha de nacimiento:
            <input
              type="date"
              id="bornDate"
              name="bornDate"
              value={bornDate}
              onChange={handleBornDate}
              placeholder="Fecha de nacimiento..."
              style={{ textTransform: "uppercase" }}
            />
          </label>
        </div>
        {passengerBenefits && passengerBenefits?.length > 0 ? (
          <>
            <div>
              <label
                className="emphasys"
                style={{ textAlign: "left", fontWeight: "bold" }}
              >
                Beneficios adicionales pasajero {index + 1}:
              </label>
              {passengerBenefits.map((benefit, id) => (
                <p key={id} style={{ textAlign: "left", marginBottom: "5px" }}>
                  {benefit.description} hasta {benefit.coverage}
                </p>
              ))}
            </div>
          </>
        )
          : <div></div>
      }
      </div>
    </article>
  );
};

export default PassengerForm;
