import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../../shared/Header";
import useAuthHook from "../../../hooks/AuthHook";
import PromoCodeStatistics from "../../components/promoCode/PromoCodeStatistics";

const PromoCodeStats = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  useAuthHook();

  return (
    <>
      <Header />
      <div className="container">
        <PromoCodeStatistics />
      </div>
    </>
  );
};

export default PromoCodeStats;
